import React, { Component } from 'react';
import { Row,Col,Input } from "reactstrap";
import { Button } from '../../components';
import { connect } from 'react-redux';
import userAvatar from "../../assets/img/avatar.png";
import SimpleReactValidator from "simple-react-validator";
import SweetAlert from 'sweetalert2-react';
import {changeAdminPass,onDismiss} from '../../actions/userActions';
class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_password:'',
            password:'',
            c_password:'',
        }
        this.validator = new SimpleReactValidator();
        this.onDismissPopup = this.onDismissPopup.bind(this);
    }

    onDismissPopup(){
      this.props.onDismiss();
    }
    handleSubmit=(e) => {
      e.preventDefault();
      var data = {
          current_password:this.state.current_password,
          new_password:this.state.password,
          c_password:this.state.c_password,
          admin_id:this.props.user.id
      }

      if( this.validator.allValid() && this.state.c_password === this.state.password ){
          this.props.onChangePass(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }  

    } 
    componentWillReceiveProps(nextProps) {
      if (nextProps.status === true) {
          this.setState({
              current_password:'',
              password:'',
              c_password:'',
             });
      }
  }

  getLoder=()=> {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    render() { 
        return ( 
            
            <div>
            <div className="content">
            <div className={this.getLoder()}></div>
              <div className="form-section">  
                  <div className="form-filds-wrapper myaccount">
                    <Row>
                      <Col md="8">
                        <div className="form-group">
                         <div className="profile">
                         <img src={userAvatar} alt="wild"/>
                         </div>   
                        </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col md="4">
                  
                          <div className="form-group">
                          <h5>{this.props.user.name !== null ? this.props.user.name : ''}</h5>
                        </div>
                        <div className="form-group">
                        <label className="control-label">{this.props.user.mobile !== null ? this.props.user.mobile : ''}</label>
                        <label className="control-label">{this.props.user.email !== null ? this.props.user.email : ''}</label>
                          </div>
                          <div className="form-group">
                          <Button className="btn-plus" data-toggle="collapse" data-target="#reset" leftLabel="fa c1 icon-edit-1">Change Password</Button>
                          <div className="collapse" id="reset">
                    
                            <form onSubmit = {this.handleSubmit}>
                            <div className="form-group">
                            <label className="control-label"> Current Password</label>
                            <Input type="password" onChange ={(e)=>this.setState({current_password:e.target.value})} value={this.state.current_password} className="form-control" />
                            <div className="errorMsg">{this.validator.message('Current password', this.state.current_password, 'required|min:6')}</div>
                            </div>
                            <div className="form-group">
                            <label className="control-label"> New Password</label>
                            <Input type="password" onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                            <div className="errorMsg">{this.validator.message('New password', this.state.password, 'required|min:6')}</div>
                            </div>
                            <div className="form-group">
                            <label className="control-label">Confirm Password</label>
                            <Input type="password" onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                            <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required|min:6')}</div>
                            <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                            </div>
                            <div className="submit-wrapper">
                            <Button className="btn-submit" >Save</Button>
                            </div>
                            </form>
                          </div>
                          </div>
                        <SweetAlert
                        show={this.props.status && this.props.popup}
                        title="Success"
                        text={this.props.message}
                        onConfirm={this.onDismissPopup}
                        />
                        <SweetAlert
                        show={ !this.props.status && this.props.popup}
                        title="Error"
                        type="error"
                        text={this.props.message}
                        onConfirm={this.onDismissPopup}
                        />
                      </Col>
                    </Row>
                  </div>
              </div>
            </div>
          </div>
         );
    }
}
MyAccount.defaultProps ={
  user:{}
}
const mapStateToProps = (state) => ({
   loggedIn: state.userReducer.loggedIn,
   loading: state.userReducer.loading,
   status:state.userReducer.status,
   message:state.userReducer.message,
   popup:state.userReducer.popup,
    user:state.userReducer.user,
  })


const mapDispatchToProps = (dispatch)=> ({
  onChangePass:(data)=> {
    dispatch(changeAdminPass(data))  
  },
  onDismiss:()=>{
      dispatch(onDismiss())
    }
})
  
export default connect(mapStateToProps,mapDispatchToProps)(MyAccount)
