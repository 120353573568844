import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import { Header, Sidebar } from "../../components";

import dashboardRoutes from "../../routes/dashboard.jsx";

var ps;

class Dashboard extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar {...this.props} routes={dashboardRoutes} />
        <div className="main-panel" ref="mainPanel">
          <Header  {...this.props}/>
          <Switch>
            {dashboardRoutes.map((prop, key) => {
            if (prop.redirect)
            return <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/`+prop.pathTo}  key={key} />;
          return (
            <Route exact path={`${this.props.match.path}/${prop.path}`} component={prop.component} key={key}/>)
            })}
          </Switch>
        </div>
      </div>
    );
  }
}

export default Dashboard;
