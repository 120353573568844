import React,{ useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

import {Button} from '../../../components';
import {getLeafCategories} from '../../../actions/categoryActions';
import {getBrands} from '../../../actions/brandActions';
import {getUnits} from '../../../actions/unitActions';
import {getVariants,onDismiss} from '../../../actions/variantActions'
import {addItem, editItem, getItem} from '../../../actions/itemActions'
import {getAttributes} from '../../../actions/attributeActions';
import HasVariantForm from './components/HasVariantForm';
import { getLoaderClass } from '../../../utils';
import NoVariantForm from './components/NoVariantForm';
import { changeVarientStatus } from '../../../actions/AdminActions';


function ProductForm({
    loading,
    match,
    item,
    onChangeVariantStatus,
    ...props
}){

    const history = useHistory()
    const [ initial, setInitial ] = useState(true)
    const [ hasVariant, setHasVariant ] = useState(true)
    const [ activeTab, setActiveTab ] = useState(0)
    const [ groupInfo, setGroupInfo ] = useState({})
    const [ attributes, setAttributes ] = useState([])
    const [ variants, setVariants ] = useState([])
    const [ items, setItems ] = useState([])
    const [ edit, setEdit ] = useState(false)
    const variantRef = useRef()
    const noVariantRef = useRef()
    const loadRef = useRef()
    const currentItemRef = useRef()
    const params = match.params
    
    useEffect(()=>{
        if(initial){
            onLoad()
            setInitial(false)
            if(params && params.id){
              setEdit(true)
              props.onGetItem(params.id);
            }
        }
        if(loadRef && loadRef.current && !loading){
          if(props.popup){
            if(props.status){
              swal("Successfully", "Product has been saved successfully", "success");
              props.onDismiss()
              if(!edit)reset()
            }else{
              swal("Failed", "Product saving failed", "error");
            }
          }
        }
        if(edit && currentItemRef.current && !currentItemRef.current.id && item && item.id){
          //set values for edit form
          if(variantRef && variantRef.current)variantRef.current.setEditFormValues(item)
        }
        loadRef.current = loading
        currentItemRef.current = item
    })

    const reset = ()=>{
      setVariants([])
      setAttributes([])
      setGroupInfo({})
      if(variantRef && variantRef.current)variantRef.current.reset()
      if(noVariantRef && noVariantRef.current)noVariantRef.current.reset()
    }

    const renderVariantForm = ()=>{
        return(
          <div>
            <HasVariantForm 
                activeTab={activeTab} 
                onChangeTab={onSave} 
                selectedAttributes={variants}
                onChangeStatus={onChangeVariantStatus}
                ref={variantRef}/>
          </div>
        )
    }

    const renderNoVariantForm = ()=>{
      return(
        <NoVariantForm 
          activeTab={activeTab} 
          ref={noVariantRef}/>
      )
    }

    const onRefresh = ()=>{
        onLoad()
    }

    const onSave = (tab=null)=>{
      if(hasVariant){
        if(variantRef && variantRef.current){
          const result =  variantRef.current.onValidate()
          var newTab = activeTab + 1
          if(result){
            if(tab != null && tab < activeTab){
              newTab = tab
            }
            if(activeTab == 0){
              setGroupInfo(result)
              setActiveTab(newTab)
            }
            // if(activeTab == 1){
            //   setAttributes(result)
            //   setActiveTab(newTab)
            // }
            if(activeTab == 1){
              setVariants(result)
              setActiveTab(newTab)
            }
            if(activeTab == 2){
              setItems(result)
              if(newTab > 1 ){
                onSaveData(groupInfo, result)
              }else{
                setActiveTab(newTab)
              }
            }
          }else{
            if(tab < activeTab){
              setActiveTab(tab)
            }
          }
        }
      }else{
        const result =  noVariantRef.current.onValidate()
        if(result){
          setGroupInfo(result)
          onSaveData(result)
        }
      }
    }

    const onSaveData = (d, items=null)=>{
     
      const data = prepareData(d, items)
      if(edit){
        props.onEditItem(data)
      }else{
        props.onAddItem(data)
      }
     
    }

    const prepareData = (data, items)=>{
      data['attributes'] = variants;
      data['group_attributes'] = attributes;

      var v = items?items.map((item)=>{
         const images = item.images.map((imag)=>{
            return {image:imag.url?imag.url:imag.image}
         }).filter((el)=> el.image)
         item['images'] = images;
         if(item.weight) item['weight'] = item.weight.value
         if(item.weight_unit) item['weight_unit'] = item.weight_unit.value
         return item
      }):[]

      var group_images = data.images ? data.images.map((im)=>{ 
        return {image: im.url?im.url:im.image}}
      ).filter((el)=> el.image):[]
      data['images'] = group_images

      if(!hasVariant){
        v = [
          {
            title: data['title'],
            featured_image:"",
            weight: data['weight']
          }
        ]
      }

      if(edit){
        const new_variants = v.filter((el) => !el.hasOwnProperty('id'))
        v  = v.filter((el)=> el.id)
        data['new_variants'] = new_variants;
        data['item_id'] = params.id
      }

      data['existing_items'] = v.map((e)=> e.id).filter(el => el);
      data['variants'] = v;
      return data
    }

    const onLoad = ()=>{
        props.onGetLeafCategories();
        props.onGetBrands();
        props.onGetUnits();
        props.onGetAttributes();
        props.onGetVariants();
    }

    return ( 
        <div>
            <div className="content">
                <div className={getLoaderClass(loading)}></div>
                <div className="form-section">  
                    <div className="sub-wrapper">
                        <Button className="btn-submit" onClick={onRefresh} style={{marginRight:10}}>Refresh</Button>
                        <Button className="btn-submit" onClick={onSave}>Save</Button>
                    </div>
                    {!edit ? <div className="radio-wrapper">
                        <input
                            type="radio"  
                            name="product-v" value="true" id="option1"  
                            onChange={()=>setHasVariant(!hasVariant)} 
                            defaultChecked
                        />
                        <label htmlFor="option1"><span><span/></span>Has Variant</label>
                        <input
                            type="radio"
                            id="option2"
                            value="false"
                            name="product-v"
                            onChange={()=>{
                              setActiveTab(0)
                              setHasVariant(!hasVariant)}
                            } 
                        />
                        <label htmlFor="option2">
                            <span><span/></span>
                            Has No Variant
                        </label>
                    </div>:null}
                    { hasVariant ? renderVariantForm(): renderNoVariantForm()}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) =>({
  leafCategories : state.categoryReducer.leafCategories,
  brands : state.brandReducer.brands,
  loading: state.itemReducer.loading,
  status:state.itemReducer.status,
  message:state.itemReducer.message,
  popup:state.itemReducer.popup,
  item: state.itemReducer.item,
})

const mapDispatchToProps = (dispatch) =>({
  onGetLeafCategories : (skip,take)=>{
    dispatch(getLeafCategories(skip,take))
  },
  onGetBrands:(skip,take)=>{
    dispatch(getBrands(skip,take))
  },
  onGetUnits:(skip,take)=>{
    dispatch(getUnits(skip,take))
  },
  onGetVariants : (skip,take)=>{
    dispatch(getVariants(skip,take))
  },
  onAddItem : (data)=>{
    dispatch(addItem(data))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  },
  onGetAttributes:(skip,take)=>{
    dispatch(getAttributes(skip,take))
  },
  onGetItem:(item_id)=>{
    dispatch(getItem(item_id))
  },
  onEditItem : (data)=>{
    dispatch(editItem(data))
  },
  onChangeVariantStatus:(id, status)=>{
    dispatch(changeVarientStatus(id, status))
  }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(ProductForm);