import React,{Component} from 'react';
import classnames from "classnames";
import { connect } from 'react-redux';
import {editCategory,onDismiss,getCategories,getCategory} from '../../../actions/categoryActions';
import {
    TabContent,
    Row,
    Col,
    Input,
    TabPane,
    Nav,
    NavItem,
    NavLink
  } from "reactstrap";
  import { Button } from '../../../components';
  import FeaturedImage from "../../../components/imageUpload/FeatureImage";
  import Select from "react-select";
  import SweetAlert from 'sweetalert2-react';
  import SimpleReactValidator from 'simple-react-validator';
  
class EditCategory extends Component {

    _id = 0;

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.onDismissPopup = this.onDismissPopup.bind(this);
        this.state = {
          activeTab: "1",
          selectedOption: null,
          name:'',
          code:'',
          isMounted: false,
          image:'',
          note:'',
          category_id:'',
          parent_id:'',
          tags:[],
          categories:[],
          selectedCateory: null,
          selectedTag: []
        };
        this._onImageChange = this._onImageChange.bind(this);
        this.validator = new SimpleReactValidator();
      }
      isValidationError(flag){
        this.setState({isFormValidationErrors:flag});

      }
      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }
      //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
      componentWillReceiveProps(nextProps) {
          if(nextProps.category && typeof nextProps.category.id !== 'undefined' ){
            var newtags = [];
            if(typeof nextProps.category.tags !== 'undefined' ) {
                newtags = nextProps.category.tags.map(function(tag) {
                            return { value: tag.tag, label: tag.tag};
             });
            }
            this.setState({
                name:nextProps.category.name,
                code: nextProps.category.code,
                note:nextProps.category.note,
                image: nextProps.category.featured_image,
                selectedTag:newtags,
                selectedCateory:{value:nextProps.category.parent_id, name:''}
            })
          }
      }

      componentDidMount() {
        var urls = this.props.match.url.split('/');
        this._id = urls[urls.length - 1]
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
        {this.props.onGetCategories();}
        {this.props.onGetCategory(this._id)}
      }
      });
      }
      onDismissPopup(){
        this.props.onDismiss();
        this.props.onGetCategory(this._id)
        this.props.onGetCategories();
      }
      handleTag  = (selectedTag) => {
        this.setState({ selectedTag });
      }
      handleCat = (selectedCateory) => {
        if(selectedCateory.index !== 0 ){
          this.setState({ selectedCateory });
        }else{
          this.setState({ selectedCateory:null });
        }
      };

      _onImageChange(image){
        this.setState({
          image
        })
      }
      handleSubmit = (e) => {
        e.preventDefault();
        var data = {
          code: this.state.code,
          name: this.state.name,
          note:this.state.note,
          featured_image: this.state.image
        };
      
        if(this.state.selectedTag.length > 0){
         
          var obj = this.state.selectedTag.map((item,index)=>{
            return { tag: item.value };
          });
          data['tags'] = obj;
        }

        if(this.state.selectedCateory ){
          data['parent_id'] =  this.state.selectedCateory.value;
        }
        if( this.validator.allValid() ){
            data['category_id'] = this._id;
            this.props.onEditCategory(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }
    
      }

        //loader
        getLoder() {
          let classes = "bt-loader ";
          classes += this.props.loadCat||this.props.loading ? 'bt-show' : 'bt-hide';
          return classes;
        }
    render() { 

      var { selectedCateory } = this.state;
      const { selectedTag } = this.state;

    let catOptions = this.props.categories.map(function(cat,ind) {
      return { value: cat.id, label: cat.name, index:ind+1 };
    });

    if(selectedCateory){
      if(selectedCateory.name == ''){
        selectedCateory = catOptions.filter(function(e) {
          return e.value == selectedCateory.value;
        });
      }
    }
    
    
    catOptions.unshift({ value: "None", label: "None" , index:0});
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <div className="bottom-nav">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1"
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Info
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="form-filds-wrapper">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="flex-wrapper">
                          <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Category Image</FeaturedImage>
                          <div className="errorMsg">{this.validator.message('image', this.state.image, 'required')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="control-label">Category Name</label>
                        <input type="text" className="form-control" value={this.state.name}  onChange = {(e)=> this.setState({name:e.target.value})}/>
                        <div className="errorMsg">{this.validator.message('name', this.state.name, 'required')}</div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="control-label">Category Code</label>
                        <input type="text" className="form-control" value={this.state.code} onChange = {(e)=> this.setState({code:e.target.value})}/>
                        <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                    <div className="form-group">
                        <label className="control-label">Notes</label>
                        <Input type="textarea" name="text" value={this.state.note?this.state.note:""} placeholder="Type Here ..." onChange = {(e)=> this.setState({note:e.target.value})}/>
                      </div>
                    </div>
                  </div>
                  <Row>
                  <Col md="4">
                    <div className="form-group">
                    <label className="control-label">Parent Category</label>
                        <Select onChange ={this.handleCat} value={selectedCateory} options={catOptions}  />
                      </div>
                      </Col>
                    </Row>
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                  </form>
                </div>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
              
              </TabPane>
            </TabContent>
            </div>
          </div>
        </div>
         );
    }
}
 
EditCategory.defaultProps= {
    categories:[],
    tags:[]  
}

const mapStateToProps = (state) => ({
  loading: state.categoryReducer.loading,
  loadCat:state.categoryReducer.loadCat,
  status:state.categoryReducer.status,
  categories: state.categoryReducer.categories,
  category:state.categoryReducer.category,
  message:state.categoryReducer.message,
  popup:state.categoryReducer.popup,
  tags: state.tagReducer.tags
})

const mapDispatchToProps = (dispatch)=> ({
    onEditCategory:(data)=>{
        dispatch(editCategory(data))
      },
      onGetCategory:(cat_id)=>{
        dispatch(getCategory(cat_id))
      },
  onGetCategories:()=>{
    dispatch(getCategories())
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditCategory)
