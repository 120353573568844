import React, { Component } from 'react';
import {Col} from 'reactstrap';
import moment from 'moment'
import { getDeliveryName } from '../../../../utils'

function OrderSidebar({
  orders,
  handleClick,
  selected
}){

  const getDeliveryTime = (order)=>{
    if(order.delivery_mode == 2) {
      if(order.store && order.store.delivery_time){
        return order.store.delivery_time + " Minutes Delivery"
      }
      return "Fast Delivery"
    }
    return moment(order.from, 'h:m a').format('h:m a') + " - " + moment(order.to, 'h:m a').format('h:m a')
  }

  const getPlacedTime = (order)=>{
    return moment(order.created_at, 'YYYY-MM-DD h:m a').format('DD-MM-YYYY h:m a')
  }

  return ( 
    <React.Fragment>
      <Col sm={'3'}>
        <div className="list-order" >
        <ul>
          {(typeof orders !== 'undefined' && orders !== null) ? orders.map((order,index) =>(
            <li className={selected===order.id?"item active":"item"} key={index} onClick={()=>handleClick(order.id, order)}>
            <p className="orderId">{order.order_id}</p>
            <p className="small">{getDeliveryName(order.delivery_mode)}</p>
            <p><strong>Delivery Time:&nbsp;</strong>{getDeliveryTime(order)}</p>
            <p><strong>Placed Time:&nbsp;</strong>{getPlacedTime(order)}</p>
          </li>
          )):
          <li className='item'><p>No Items</p></li>}
          </ul>
        </div>
      </Col>
    </React.Fragment>
   );
}
 
export default OrderSidebar;