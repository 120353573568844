import React, { Component } from 'react';
import {connect} from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Table,InputGroup,InputGroupText,InputGroupAddon,Input,Row,Col} from "reactstrap";

import {Button,Fselect } from '../../../components';
import {getAllReviews} from '../../../actions/reviewAction'
import Excellent from "../../../assets/img/emoji-green.png";
import Bad from "../../../assets/img/emoji-red.png";
import Good from "../../../assets/img/emoji-yellow.png";


class FeedBacks extends Component {
  state = { 
    offset:0,
    limit:12
   }

    componentDidMount() {
        this.onLoad()
    }

    onLoad = (offset=0)=>{
        const { isStore, store } = this.props
        if(isStore && store){
            this.props.onGetReviews(offset,this.state.limit, store.id)
        }else{
            this.props.onGetReviews(offset,this.state.limit)
        }
        
    }

    handlePageClick = (data)=>{
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({offset: skip});
        this.onLoad(skip)
    }


    deliveryType(mode) {
        switch(mode){
        case 3 :
            return Bad;
        case 2:
            return Good;
        case 1 :
            return Excellent;
        default :
            return null;          
        }
    }

    getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
    }

    render() { 
        const {reviews} = this.props;
        let statusData = [{value:1,label:"Bad"},{value:2,label:"Good"},{value:3,label:"Excellent"}]
        return ( 
        <div>
        <div className="content">
        <div className={this.getLoder()}></div>
        {/* filter */}
    <div className="common-filter-wrapper mb-2">
        <form action="">
        <Row className="align-items-center">
            <Col md='5'>
            <InputGroup className="filter-search mr-2">
                <Input  placeholder="Search"  type="text"/>
                <InputGroupAddon addonType="append">
                <InputGroupText>
                    <i className="icon-search-find" />
                </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
            </Col>

            <Col md='5'>
            <Fselect options={statusData} placeholder="Experirnce Status"/>
            </Col>

            <Col md='2'>
            <Button className="btn-submit">Filter</Button>
            </Col>
        </Row>
        </form>
        </div>

            <div className="table-product-wrapper table-orders">
                <Table>
                <thead>
                    <tr>
                    <th>SI.NO</th>
                    <th>Order ID</th>
                    <th>Customer Name</th>
                    <th>Experience</th>
                    <th>Message</th>
                    <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {reviews.length>0 ? reviews.map((review,index)=>(
                    <tr key={index}>
                    <td>{index+1}</td>
                    <td>
                    <p>{review.order.order_id}</p>
                    </td>
                    <td>
                        <p>{(review.order && review.order.customer? review.order.customer.first_name:null) 
                            +' '+(review.order && review.order.customer?review.order.customer.second_name:null)}</p>                     
                    </td>
                    <td className="nameImg">
                        <img src={this.deliveryType(review.rating)} alt="wild" className="img-fluid" />
                    </td>
                    <td>
                        <div className="max-content">
                        <p>{review.review}</p>
                        </div>
                    </td>
                    <td>
                    {review.updated_at}
                    </td>
                    </tr>
                    )):<tr><td colSpan={'5'}>No data found</td></tr>}

                </tbody>
                </Table>
            </div>
            <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
        </div>
        </div>
        </div>
        );
    }
}

FeedBacks.defaultProps = {
  reviews:[]
}

const mapStateToProps = (state)=>({
  loading:state.reviewReducer.loading,
  reviews:state.reviewReducer.reviews,
  count:state.reviewReducer.count,
})

const mapDispatchToProps = (dispatch)=>({
onGetReviews:(offset,limit, store_id)=>{
  dispatch(getAllReviews(offset,limit, store_id))
}
})
 
export default connect(mapStateToProps,mapDispatchToProps)(FeedBacks);