import React, { Component } from 'react';
import { Table} from "reactstrap";
import { Button } from '../../../components';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {getAllProductOffers,delProductOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
class ProductOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMounted: false,
          proLoading:false,
          skip: 0,
          limit:12
        };
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      componentDidMount () {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
   
      }
      });
      setTimeout(()=>{
        this.props.OnGetOffers(this.props.store.id,this.state.skip,this.state.limit);
       },100);
      }
      onDismissPopup () {
        this.props.onDismiss();
        {this.props.OnGetOffers(this.props.store.id,this.state.skip,this.state.limit);}
      }
      trimTime (dateString) {
          dateString = new Date(dateString).toUTCString();
          dateString = dateString.split(' ').slice(0, 4).join(' ');
          return dateString;
      }
      handleDelete(e,id) {
        e.preventDefault();
        this.props.onDeleteOffers(id);
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetCategories(skip,this.state.limit);
      }
      getLoder = () =>{
        let classes = "bt-loader ";
        classes += this.props.proLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
      const {product_offers} = this.props;
        return (
            <div>
                  <div className="content">
                  <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                      <th>ID</th>
                        <th>OFFER NAME</th>
                        <th>DISCOUNT</th>
                        <th>PRICE</th>
                        <th>DATE FROM</th>
                        <th>DATE TO</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {product_offers.length>0 ? product_offers.map((offer,index) => (
                          <tr key={index}>
                          <td>{index+1+this.state.skip}</td>
                          <td>{offer.title}</td>
                          <td>{offer.disc_perc !== "0"? offer.disc_perc + '%' : '-'}</td>
                          <td>{offer.disc_amount !== "0" ? 'INR ' + offer.disc_amount : '-'}</td>
                          <td>{moment(offer.valid_from).format('DD-MM-YYYY')}</td>
                          <td>{moment(offer.valid_upto).format('DD-MM-YYYY')}</td>
                        <td>
                          <div className="group-action">
                            <Link className="btn" to={`/store/edit-product-offer/${offer.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,offer.id))}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'7'}>No data found</td></tr>}
 
                    </tbody>
                  </Table>
                  <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
                </div>
                <Button className="fixed-yellow" onClick={() => this.props.history.push("/store/add-product-offer")}>Add</Button>
            
              </div>
            </div>
         );
    }
}
ProductOffer.defaultProps = {
  product_offers:[]
}
const mapStateToProps = (state) => ({
  proLoading: state.productOfferReducer.proLoading,
  product_offers: state.productOfferReducer.product_offers,
  store:state.storeUserReducer.store,
  status:state.productOfferReducer.status,
  message:state.productOfferReducer.message,
  count:state.productOfferReducer.count,
  popup:state.productOfferReducer.popup
})
const mapDispatchToProps = (dispatch)=> ({
  OnGetOffers : (store_id,skip,take) => {
    dispatch(getAllProductOffers(store_id,skip,take));
  },
  onDeleteOffers:(pro_id)=>{
    dispatch(delProductOffer(pro_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductOffer)
