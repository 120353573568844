import React, { Component } from 'react';
import placeholder from '../../assets/img/imageplaceholder.png';
class FeaturedImage extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            active: false,
            imageSrc: this.props.imageReset,
            loaded: false
        }
        this.onDragEnter  = this.onDragEnter.bind(this);
        this.onDragLeave  = this.onDragLeave.bind(this);
        this.onDrop       = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    
    onDragEnter(e) {
        this.setState({ active: true });
    }
    
    onDragLeave(e) {
        this.setState({ active: false });
    }
    
    onDragOver(e) { 
        e.preventDefault(); 
    }
    
    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }
    
    onFileChange(e, file) {
        var file = file || e.target.files[0],
            pattern = /image-*/,
            reader = new FileReader();
            
        if (!file.type.match(pattern)) {
            alert('Invalid Image');
            return;
        }
        
        this.setState({ loaded: false });
        
        reader.onload = (e) => {
            this.setState({ 
                imageSrc: reader.result, 
                loaded: true 
            }, () => {
                this.props.onImageChange(this.state.imageSrc);
            }); 
        }
        reader.readAsDataURL(file);
    }
    componentWillReceiveProps(nextProps) {

        if(nextProps.imageReset === null) {
            this.setState({
                imageSrc: placeholder  
            })
        }else{
            this.setState({
                imageSrc: nextProps.imageReset  
            }) 
        }
    }
    
    getFileObject() {
        return this.ref.input.files[0];
    }
    
    getFileString() {
        return this.state.imageSrc;
    }
  render() {
    let state = this.state, labelClass  = `uploader ${state.loaded ? 'loaded' :''}`;
    var src = placeholder;
    if(state.imageSrc){
        src = state.imageSrc
    }
    return (
        <div className="flex-div">
           <span className="control-label">{this.props.children}</span>
        <div className="file-wrapper">
          <label className={labelClass} 
               onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave} 
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                >
            <img src={src} className={state.loaded?"loaded":""} />
            <input
              type="file"
              accept="image/*"
              onChange={this.onFileChange}
              ref="input"
            />
          </label>
        </div>
      </div>
    );
  }
}
FeaturedImage.defaultProps ={
    imageReset : placeholder
}
export default FeaturedImage;
