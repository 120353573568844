import * as Actions from "../constants/Actions";
const initialState = {
    adminLoader:false,
    storeLoader:false,
    store_permissions: [],
    admin_permissions: []
  };

  export default function permissionReducer(state = initialState, action) {
    switch (action.type) {
    case Actions.ADMIN_PERMISSION_LOADING:
    return {
        admin_permissions: [],
        adminLoader:true
    };
  case Actions.ADMIN_PERMISSION:
    return {
      ...state,
      admin_permissions: action.admin_permissions,
      adminLoader:false
    };
  case Actions.ADMIN_PERMISSION_FAILED:
    return {
      ...state,
      adminLoader:false,
      admin_permissions: [],
      error: action.error
    };

    case Actions.STORE_PERMISSION_LOADING:
    return {
        store_permissions: [],
        storeLoader:true
    };
  case Actions.STORE_PERMISSION:
    return {
      ...state,
      store_permissions: action.store_permissions,
      storeLoader:false
    };
  case Actions.STORE_PERMISSION_FAILED:
    return {
      ...state,
      store_permissions: [],
      storeLoader:false,
      error: action.error
    };

    default:
    return state;
  }
}