import React, { Component } from 'react';
import { Button, Table } from "reactstrap";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';
import{getCustomers} from '../../../actions/AdminActions';
import ReactPaginate from 'react-paginate';
import userAvatar from "../../../assets/img/avatar.png";
import { downloadExcel } from '../../../actions/userActions';
import { EXPORT_CUSTOMERS } from '../../../constants/AppUrls';
class Customers extends Component {
    constructor(){
        super();
        this.state = { 
            isMounted: false,
            skip: 0,
            limit:12
         }
    }

    componentDidMount () {
      this.setState({ isMounted: true }, () => {
        if (this.state.isMounted) {
          this.setState({ isMounted: false });
      {this.props.onGetCustomers(this.state.skip,this.state.limit)}
    }
    });
    }

    handlePageClick = (data) => {
      let selected = data.selected;
      let skip = selected * this.state.limit
      this.setState({skip: skip});
      this.props.onGetCustomers(skip,this.state.limit);
    }

    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }
    render() { 
      const {customers} = this.props;
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>POFILE PIC</th>
                        <th>NAME </th>
                        <th>MOBILE NO</th>
                        <th>EMAIL </th>
                        <th>ACTION </th>
                      </tr>
                    </thead>
                    <tbody>
                    {customers.map((customer,index) => (
                        <tr key={customer.id}>
                        <td>
                          {index+1 + this.state.skip}
                        </td>
                        <td className="nameImg">
                        <img src={customer.profile_pic !== null ? customer.profile_pic : userAvatar} alt="wild" className="img-round" />
                        </td>
                        <td>{customer.first_name+ ' ' +customer.second_name }</td>
                        <td>{customer.mobile}</td>
                        <td>{customer.email}</td>
                        <td>
                        <div className="group-action text-right pr-10">
                          <Link className="btn" to={`/admin/edit-customer/${customer.id}`}>
                          <i className="ico c1 icon-edit-1 " />
                          </Link>
                        </div>
                        </td>
                      </tr>    
                    ))}
                        
                     
                    </tbody>
                  </Table>
                </div>
                <Button className="fixed-yellow" onClick={()=>{ downloadExcel(EXPORT_CUSTOMERS) }}>Download Excel</Button>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />

                </div>
                </div>
            </div>
        );
    }
}
Customers.defaultProps = {
  customers : []
}

const mapStateToProps = (state) => ({
  loading:state.commonReducer.loading,
  customers: state.commonReducer.customers,
  count:state.commonReducer.count,
})

const mapDispatchToProps = (dispatch)=> ({
  onGetCustomers:(skip,take)=>{
    dispatch(getCustomers(skip,take))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Customers)
