import * as Actions from '../constants/Actions';
import ContentService from '../services/ContentService';

function saveAbout(data){
    return dispatch =>{
        dispatch(request());
        ContentService.saveAbout(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SAVE_ABOUT_LOADING} }
    function success(data) { return { type: Actions.SAVE_ABOUT_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.SAVE_ABOUT_FAILED, error:error} }
}

function savePrivacy(data){
    return dispatch =>{
        dispatch(request());
        ContentService.savePrivacy(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SAVE_PRIVACY_LOADING} }
    function success(data) { return { type: Actions.SAVE_PRIVACY_SUCCESS, data:data} }
    function failed(error) { return { type: Actions.SAVE_PRIVACY_FAILED, error:error} }
}

function getAbout(){
    return dispatch =>{
        dispatch(request());
        ContentService.getAbout()
        .then(res =>{
            var contents = res.data;
            dispatch(success(contents))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ABOUT_LOADING} }
    function success(contents) { return { type: Actions.GET_ABOUT_SUCCESS, contents: contents} }
    function failed(error) { return { type: Actions.GET_ABOUT_FAILED, error:error} }
}

function getPrivacy(){
    return dispatch =>{
        dispatch(request());
        ContentService.getPrivacy()
        .then(res =>{
            var contents = res.data;
            dispatch(success(contents))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_PRIVACY_LOADING} }
    function success(privacy) { return { type: Actions.GET_PRIVACY_SUCCESS, privacy} }
    function failed(error) { return { type: Actions.GET_PRIVACY_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function savePoint (data) {
    return dispatch =>{
        dispatch(request());
        ContentService.savePoint(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SAVE_POINT_LOADING} }
    function success(data) { return { type: Actions.SAVE_POINT_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.SAVE_POINT_FAILED, error:error} }
}

function getPoint(){
    return dispatch =>{
        dispatch(request());
        ContentService.getPoint()
        .then(res =>{
            var points = res.data;
            dispatch(success(points))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_POINT_LOADING} }
    function success(points) { return { type: Actions.GET_POINT_SUCCESS, points: points} }
    function failed(error) { return { type: Actions.GET_POINT_FAILED, error:error} }
}



export {
    saveAbout,
    savePoint,
    getAbout,
    getPoint,
    onDismiss,
    savePrivacy,
    getPrivacy
};