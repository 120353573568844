import * as Actions from '../constants/Actions';
import { setCustomer, setLoading, setStatus } from '../reducers/customer.reducer';
import AdminService from '../services/AdminService';

function getCustomers(skip =null, take = null, query=null){
    return dispatch =>{
        dispatch(request());
        AdminService.getCustomers(skip,take, query)
        .then(res =>{
            var customers = res.data;
            var count = res.count;
            dispatch(success(customers,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ALL_CUSTOMERS_LOADING} }
    function success(customers,count) { return { type: Actions.ALL_CUSTOMERS_SUCCESS, customers: customers,count:count} }
    function failed(error) { return { type: Actions.ALL_CUSTOMERS_FAILED, error:error} }
}

function getCustomer(id){
    return dispatch =>{
        dispatch(setLoading({loading: true}));
        AdminService.getCustomer(id)
        .then(res =>{
            var customer = res.data;
            dispatch(setCustomer({customer: customer}));
        }).catch(error =>{
            dispatch(setLoading({loading: false}));
        })
    }
}

function updateCustomer(data){
    return dispatch =>{
        dispatch(setLoading({loading: true}));
        AdminService.updateCustomer(data)
        .then(res =>{
            dispatch(setLoading({loading: false}));
            dispatch(setStatus({popup: true, status: true, message:'Customer updated successfully'}));
        }).catch(error =>{
            dispatch(setLoading({loading: false}));
            dispatch(setStatus({popup: true, status: false, message: "Customer update failed"}));
        })
    }
}

function getAllStaffs(skip =null, take = null){
    return dispatch =>{
        dispatch(request());
        AdminService.getAdminStaffs(skip,take)
        .then(res =>{
            var staffs = res.data;
            var count = res.count;
            dispatch(success(staffs,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ADMIN_STAFF_LOADING} }
    function success(staffs,count) { return { type: Actions.GET_ADMIN_STAFF_SUCCESS, staffs: staffs,count:count} }
    function failed(error) { return { type: Actions.GET_ADMIN_STAFF_FAILED, error:error} }
}

function getAdminStaff(admin_id){
    return dispatch =>{
        dispatch(request());
        AdminService.getAdminStaff(admin_id)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ADMIN_SINGLE_STAFF_LOADING} }
    function success(staff) { return { type: Actions.GET_ADMIN_SINGLE_STAFF_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.GET_ADMIN_SINGLE_STAFF_FAILED, error:error} }
}

function editStaff(data){
    return dispatch =>{
        dispatch(request());
        AdminService.editStaff(data)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_ADMIN_LOADING} }
    function success(staff) { return { type: Actions.EDIT_ADMIN_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.EDIT_ADMIN_FAILED, error:error} }
}

function deleteStaff(admin_id){
    return dispatch =>{
        dispatch(request());
        AdminService.deleteStaff(admin_id)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.DELETE_ADMIN_LOADING} }
    function success(staff) { return { type: Actions.DELETE_ADMIN_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.DELETE_ADMIN_FAILED, error:error} }
}

function getAdminDashboard(){
    return dispatch =>{
        dispatch(request());
        AdminService.getAdminDashboard()
        .then(res =>{
            var dashboard = res.data;
            dispatch(success(dashboard))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADMIN_DASHBORD_LOADING} }
    function success(dashboard) { return { type: Actions.ADMIN_DASHBORD_SUCCESS, dashboard: dashboard} }
    function failed(error) { return { type: Actions.ADMIN_DASHBORD_FAILED, error:error} }
}


function getBestSellerDashboard(store_id=null){
    return dispatch =>{
        dispatch(request());
        AdminService.getTopSellers(store_id)
        .then(res =>{
            var dashboard = res.data;
            dispatch(success(dashboard))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.BEST_SELLER_LOADING} }
    function success(seller) { return { type: Actions.BEST_SELLER_SUCCESS, seller} }
    function failed(error) { return { type: Actions.BEST_SELLER_FAILED, error:error} }
}

function getSalesChart(period, store=null){
    return dispatch =>{
        dispatch(request());
        AdminService.getSalesChart(period, store)
        .then(res =>{
            var dashboard = res.data;
            dispatch(success(dashboard))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SALES_CHART_LOADING} }
    function success(chart) { return { type: Actions.SALES_CHART_SUCCESS, chart} }
    function failed(error) { return { type: Actions.SALES_CHART_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function addStaff(data){
    return dispatch =>{
        dispatch(request());
        AdminService.addStaff(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_STAFF_LOADING} }
    function success(data) { return { type: Actions.ADD_STAFF_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADD_STAFF_FAILED, error:error} }
}

function changeItemStatus(item_id,status){
    return dispatch =>{
        dispatch(request());
        AdminService.changeItemStatus(item_id,status)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.CHANGE_ITEM_STATUS_LOADING} }
    function success() { return { type: Actions.CHANGE_ITEM_STATUS_SUCCESS} }
    function failed(error) { return { type: Actions.CHANGE_ITEM_STATUS_FAILED, error:error} }
 }

 function changeVarientStatus(variant_id,status){
    return dispatch =>{
        dispatch(request());
        AdminService.changeVarientStatus(variant_id,status)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.CHANGE_ITEM_VARIANT_STATUS_LOADING} }
    function success() { return { type: Actions.CHANGE_ITEM_VARIANT_STATUS_SUCCESS} }
    function failed(error) { return { type: Actions.CHANGE_ITEM_VARIANT_STATUS_FAILED, error:error} }
 }


export {
    getCustomers,
    getAdminDashboard,
    changeItemStatus,
    changeVarientStatus,
    addStaff,
    onDismiss,
    getAllStaffs,
    getAdminStaff,
    editStaff,
    deleteStaff,
    getCustomer,
    updateCustomer,
    getBestSellerDashboard,
    getSalesChart
};