import React,{Component} from 'react';
import {
Row,
Col,FormGroup,
InputGroup, InputGroupAddon, Input,
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {connect} from "react-redux";
import {getStoreBrands} from '../../../actions/brandActions';
import {addBrandOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class AddBrandOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedBrand: '',
          isActive: false,
          isMounted: false,
          brands:[],
          store_id :'',
          valid_from:moment(),
          valid_upto:moment(),
          disc_perc:'',
          title:'',
          week:[]
      }
      this.handleChangeFrom = this.handleChangeFrom.bind(this);
      this.handleChangeTo = this.handleChangeTo.bind(this);
      this.onDismissPopup = this.onDismissPopup.bind(this);
      this.validator = new SimpleReactValidator();
    } 
    onDismissPopup(){
      this.props.onDismiss();
    }
    handleChangeFrom(date) {
      this.setState({
        valid_from:date,
      });
    }
    handleChangeTo(date) {
      this.setState({
        valid_upto: date
      });
    }
    handleCheck = (week) => {
      this.setState({week});
    }
    componentDidMount() {
      this.setState({ isMounted: true }, () => {
        if (this.state.isMounted) {
       this.setState({ isMounted: false });
       setTimeout(() => {
        this.props.onGetBrands(this.props.store.id);
       }, 50);   
    }
    });
    }
    handleCat  = (selectedBrand) => {
      this.setState({ selectedBrand });
    }

    handleLimit = () => {
      this.setState({ isActive: !this.state.isActive });
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        store_id :this.props.store.id,
        brand_id:this.state.selectedBrand.value,
        disc_perc:parseFloat(this.state.disc_perc),
        title:this.state.title,
      }
      let from = this.state.valid_from;
      let upto = this.state.valid_upto;
      data['valid_from'] = from.format('YYYY-MM-DD');
      data['valid_upto'] = upto.format('YYYY-MM-DD');  
      var days_array = this.state.week.map((item)=>{
        return {'day_id':item}
      })
     data['week_days']= days_array;
     if ( this.validator.allValid() ){
    this.props.onAddBrandOffer(data);
     }
     else {
      this.validator.showMessages();
       this.forceUpdate();
     }
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.status === true) {
        this.setState({
          selectedBrand: '',
          valid_from:moment(),
          valid_upto:moment(),
          disc_perc:'',
          title:'',
          week:[]
        });
      }
  }
       //loader
       getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loadbrand || this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
      const { selectedBrand } = this.state;
      let brandOptions = this.props.brands.map(function(brand) {
        return { value: brand.id, label: brand.name};
      });
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
                <div className="form-filds-wrapper">
                <Row>
                <Col md="8">
                      <div className="form-group">
                        <label className="control-label">Offer Name</label>
                        <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})} className="form-control" placeholder="Name" />
                        <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                      </div>
                    </Col>
                </Row>
                  <Row>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Brand</label>
                        <Select onChange ={this.handleCat}  value={selectedBrand} options={brandOptions}   placeholder ={'Choose a brand'}/>
                        <div className="errorMsg">{this.validator.message('brand', selectedBrand.value, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Value</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="select" id="exampleSelect">
                        <option>%</option>
                        </Input>
                        </InputGroupAddon>
                        <Input value={this.state.disc_perc} type={'number'} onChange={(e)=>this.setState({disc_perc:e.target.value})} />
                        <div className="errorMsg">{this.validator.message('disc_perc', this.state.disc_perc, 'required|min:1|max:2')}</div>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Valid From</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_from}
                        onChange={this.handleChangeFrom}
                        dateFormat="YYYY-MM-DD"
                        name="valid_from"
                        />
                      </div>
                      </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Valid To</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_upto}
                        onChange={this.handleChangeTo}
                        dateFormat="YYYY-MM-DD"
                        name="valid_upto"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                      <div className="check-box-inline prvilages">
                      { this.state.isActive ? <CheckFrom onCheckChange ={(week)=>this.handleCheck(week)}/> : null }
                      
                      </div>
                      </Col>  
                    </Row>
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                 <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </form>
            </div>
          </div>
        </div>
         );
    }
}

AddBrandOffer.defaultProps ={
  brands:[]
}

const mapStateToProps = (state) => ({
  loading: state.brandOfferReducer.loading,
  status:state.brandOfferReducer.status,
  brands: state.brandReducer.store_brands,
  message:state.brandOfferReducer.message,
  popup:state.brandOfferReducer.popup,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onAddBrandOffer:(data)=>{
    dispatch(addBrandOffer(data))
  },
  onGetBrands:(store_id)=>{
    dispatch(getStoreBrands(store_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddBrandOffer)
