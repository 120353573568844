import React, { Component } from 'react';
import { Row,Col,Input } from "reactstrap";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'

import { Button } from '../../../../components';
import SweetAlert from "sweetalert2-react";
import FeaturedImage from "../../../../components/imageUpload/FeatureImage";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {addDeliveryBoy,getDeliveryBoy,onDismiss, updateDeliveryBoy} from '../../../../actions/deliveryBoyAction';

const PROOF_TYPES = [
  {value: 'Aadhaar', label:"Aadhaar"},
  {value: 'Voter Id', label:"Voter Id"},
  {value: 'Driving Licence', label:"Driving Licence"}
]

class AddDeliveryBoy extends Component {
    constructor(props) {
      super(props);
      this.state= {
        image:'',
        email:'',
        mobile:'',
        name:'',
        password:'',
        c_password:'',
        unique_number:'',
        proof_type:null,
        proof_id:'',
        address:'',
        otp: '',
        edit: false,
        id: null,
        passv: 'password',
        passc: 'password',
      }
      this.validator = new SimpleReactValidator();
      this.onDismissPopup = this.onDismissPopup.bind(this);
    }
    _onImageChange=(image)=> {
      this.setState({image});
    }

    _onImageChangeProof=(image)=> {
      this.setState({proof_id: image});
    }

    hadlePrev = (permissions)=> {
      this.setState({permissions});
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        profile_pic:this.state.image,
        name:this.state.name,
        email:this.state.email,
        mobile:this.state.mobile,
        password:this.state.password,
        c_password:this.state.c_password,
        unique_number:this.state.unique_number,
        store_id: this.props.store.id,
        proof_id: this.state.proof_id,
        proof_type: this.state.proof_type?this.state.proof_type.value:null,
        address: this.state.address

      }

      if( this.validator.allValid()&& this.state.c_password === this.state.password ){
          if(this.state.edit && this.state.id){
            this.props.onUpdateBoy({...data, id: this.state.id});
          }else{
            this.props.onAddBoy(data);
          }
          
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }
    }
    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
      if(nextProps.status === true && !this.state.edit) {
        this.setState({
          image:'', email:'', mobile:'', name:'',  password:'',  
          c_password:'', unique_number:'', proof_id: null, proof_type:null, address:''
        })
      }
      if(nextProps.delivery_boy && !this.state.mobile && nextProps.delivery_boy.mobile){
        this.setState({
          image: nextProps.delivery_boy.profile_pic, 
          email: nextProps.delivery_boy.email, 
          mobile: nextProps.delivery_boy.mobile, 
          name:nextProps.delivery_boy.name,  
          password:'',  
          c_password:'', 
          unique_number:nextProps.delivery_boy.unique_number, 
          proof_id: nextProps.delivery_boy.proof_id, 
          address: nextProps.delivery_boy.address,
          proof_type: {value: nextProps.delivery_boy.proof_type, label: nextProps.delivery_boy.proof_type }
        })
      }
    }

    onDismissPopup() {
      this.props.onDismiss();
    }

    //loader
    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    componentDidMount(){
      var urls = this.props.match.url.split('/');
      if((urls.length - 2) > 0){
        if(urls[urls.length - 2].includes('edit')){
          this.setState({edit: true, id: urls[urls.length - 1]})
          this.props.onGetBoy(urls[urls.length - 1]);
        }
      }
    }

    render() { 
        return ( 
            <div>
            <div className="content">
            <div className={this.getLoder()}></div>
              <div className="form-section">  
              <form  onSubmit={this.handleSubmit}>
                  <div className="form-filds-wrapper">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Add image</FeaturedImage>
                          </div>
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage imageReset={this.state.proof_id} onImageChange={(image)=>this._onImageChangeProof(image)}>Proof Attachment</FeaturedImage>
                          </div>
                          <div className="errorMsg">{this.validator.message('Proof', this.state.proof_id, 'required')}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                     <Col md="8">
                     <Row>
                     <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Name</label>
                          <Input type="text" className="form-control" onChange ={(e)=>this.setState({name:e.target.value})} value={this.state.name}  placeholder="User name" />
                          <div className="errorMsg">{this.validator.message('Name', this.state.name, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                      <label className="control-label">Mobile Number</label>
                          <PhoneInput defaultCountry="IN" onChange ={(e)=>this.setState({mobile:e})} value={this.state.mobile}  className="form-control" placeholder="Mobile Number" />
                          <div className="errorMsg">{this.validator.message('mobile', this.state.mobile, 'required|phone')}</div>
                        </div>
                        </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Email</label>
                          <Input type="email" onChange ={(e)=>this.setState({email:e.target.value})} value={this.state.email}   className="form-control" placeholder="Email"/>
                          <div className="errorMsg">{this.validator.message('email', this.state.email, 'required|email')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Unique Number
                          </label>
                          <Input type="text" onChange ={(e)=>this.setState({unique_number:e.target.value})} value={this.state.unique_number} className="form-control" />
                          <div className="errorMsg">{this.validator.message('Unique Number', this.state.unique_number, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Address
                          </label>
                          <Input type="text" onChange ={(e)=>this.setState({address:e.target.value})} value={this.state.address} className="form-control" />
                          <div className="errorMsg">{this.validator.message('Address', this.state.address, 'required')}</div>
                        </div>
                      </Col>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="control-label">Proof Type</label>
                          <Select options={PROOF_TYPES} onChange ={(e)=>this.setState({proof_type:e})} value={this.state.proof_type}/>
                          <div className="errorMsg">{this.validator.message('Proof Type', this.state.proof_type, 'required')}</div>
                        </div>
                      </div>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                         Password
                          </label>
                          <Input type={this.state.passv} onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                          <i className={this.state.passv != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passv}) => ({
                                    passv: passv === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                          <div className="errorMsg">{this.validator.message('password', this.state.password, 'required|min:6')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                           Confirm Password
                          </label>
                          <Input type={this.state.passc} onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                          <i className={this.state.passc != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                              onClick={()=>{
                                this.setState(({passc}) => ({
                                  passc: passc === 'text' ? 'password' : 'text'
                                }))
                              }}></i>
                          <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required|min:6')}</div>
                          <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                        </div>
                      </Col>
                     </Row>
                     </Col>
                    </Row>
                    
                    <Row>
                        <Col md="12">
                        <div className="submit-wrapper">
                        <Button className="btn-submit" >Save</Button>
                        </div>
                        </Col>
                    </Row>
                  </div>
                  </form>
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
          </div>
         );
    }
}
 

const mapStateToProps = (state) => ({
  loading: state.deliveryBoyReducer.loading,
  status: state.deliveryBoyReducer.status,
  message: state.deliveryBoyReducer.message,
  popup: state.deliveryBoyReducer.popup,
  store: state.storeUserReducer.store,
  delivery_boy:state.deliveryBoyReducer.delivery_boy
})

const mapDispatchToProps = (dispatch) => ({
onAddBoy:(data) => {
  dispatch(addDeliveryBoy(data));
},
onUpdateBoy:(data) => {
  dispatch(updateDeliveryBoy(data));
},
onDismiss:() => {
  dispatch(onDismiss());
},
onGetBoy: (id) => {
  dispatch(getDeliveryBoy(id))
}
})

export default connect(mapStateToProps,mapDispatchToProps)(AddDeliveryBoy)

