import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function login(mobile,password) {
    return request({
      url:   URLS.ADMIN_LOGIN,
      method: 'POST',
      data:{
           mobile,
           password
      }
    });
}

function logout() {
    return request({
      url:   URLS.ADMIN_LOGOUT,
      method: 'POST'
    });
}

function storeLogin(mobile,password) {
    return request({
      url:   URLS.STORE_LOGIN,
      method: 'POST',
      data:{
           mobile,
           password
      }
    });
}

function storeLogout() {
    return request({
      url:   URLS.STORE_LOGOUT,
      method: 'POST'
    });
}

function getStorePermissions (){
    return request({
      url:   URLS.STORE_PERMISSIONS,
      method: 'GET'
    });
}

function getAdminPermissions (){
    return request({
      url:   URLS.ADMIN_PERMISSIONS,
      method: 'GET'
    });
}


function changeAdminPass(data) {
    return request({
      url:   URLS.ADMIN_CHANGE_PASS,
      method: 'POST',
      data:data
    });
}

function changeStorePass(data) {
    return request({
      url:   URLS.STORE_CHANGE_PASS,
      method: 'POST',
      data:data
    });
}

const UserService = {
    login,
    logout,
    storeLogin,
    storeLogout,
    getStorePermissions,
    getAdminPermissions,
    changeAdminPass,
    changeStorePass
}
  
export default UserService;
  