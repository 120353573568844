import React, { forwardRef, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import classnames from "classnames";
import {
  TabContent,
  Row,
  Col,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

import { Button } from '../../../../components';
import VariantForm from './VariantForm';
import ItemForm from './ItemForm';
import AttributePage from './AttributePage';
import GroupAttribute from './GroupAttribute';

const TABS = [
  {
    label:"Info"
  },
  // {
  //   label:"Attributes"
  // },
  {
    label:"Variants"
  },
  {
    label:"Variant Products"
  }
]

const INITIAL_ITEM = {
    sku: '',
    title: '',
    description: '',
    featured_image: null,
    images: [],
    tags:[],
    barcode:'',
    attribute_values: [],
    weight: '',
    weight_unit: null,
    key: 'key_0',
    selling_price:"",
    stock_level:""
}

function HasVariantForm({
    activeTab,
    onChangeTab,
    selectedAttributes,
    disabled,
    type,
    onChangeStatus
}, ref){

    const [image, setImage] = useState(null)
    const [ images, setImages ] = useState([])
    const [ category, setCategory ] = useState(null)
    const [ brand, setBrand ] = useState(null)
    const [ unit, setUnit ] = useState(null)
    const [ group_name, setGroupName ] = useState('')
    const [ weight, setWeight ] = useState('')
    const [ weightUnit, setWeightUnit ] = useState('')
    const [ group_description, setGroupDescription ] = useState('')
    const [ items, setItems ] = useState([{...INITIAL_ITEM}])
    const [ tags, setTags ] = useState([])
    const [ reflector, setReflector] = useState(false)
    const validator = new SimpleReactValidator()
    const variantValidator = new SimpleReactValidator()
    const groupAttributeRef = useRef()
    const attributeRef = useRef()

    const state = useSelector(state=> state)
    const { units } = state.unitReducer

    React.useImperativeHandle(ref, ()=>({
      onValidate(){
        if(activeTab == 0){
          if( validator.allValid() ){
            return {
              category_id: category.value,
              brand_id: brand.value,
              unit_id: unit.value,
              title: group_name,
              description: group_description,
              featured_image: image,
              images: images,
              tags
            };
          }
          else {
            validator.showMessages();
            setReflector(!reflector)
          }
          return null
        }
        // if(activeTab == 1){
        //   if(groupAttributeRef && groupAttributeRef.current){
        //     const result = groupAttributeRef.current.onValidate()
        //     return result
        //   }
        // }

        if(activeTab == 1){
          if(attributeRef && attributeRef.current){
            const result = attributeRef.current.onValidate()
            return result
          }
        }

        if(activeTab == 2){
          if( variantValidator.allValid() ){
            return items;
          }
          else {
            variantValidator.showMessages();
            setReflector(!reflector)
          }
          return null
        }
      },
      reset(){
        setBrand(null)
        setCategory(null)
        setUnit(null)
        setGroupName('')
        setGroupDescription('')
        setImages([])
        setImage(null)
        setItems([{
          ...INITIAL_ITEM, 
          attribute_values:[],
          images:[],
          tags:[],
          featured_image: null
        }])
        setTags(tags)
        if(attributeRef && attributeRef.current){
          attributeRef.current.onSetSelectedData([])
        }
        if(groupAttributeRef && groupAttributeRef.current){
          groupAttributeRef.current.onSetSelected([])
        }
      },
      setEditFormValues(data){
        if(data){
          setGroupName(data.title)
          setGroupDescription(data.description?data.description:"")
          setBrand({value: data.brand.id, label: data.brand.name})
          setCategory({value: data.category.id, label: data.category.name})
          setUnit({value: data.unit.id, label: data.unit.unit})
          setImage(data.featured_image)
          setWeight(data.weight)
          setImages(data.images)
          setTags(JSON.parse(data.tags))
          const products = data.products.map((product)=>{
            const values = product.attribute_values.map((v)=>{
              return {...v, label: v.value_code} 
            })

            var w = null
            var w_n = null
            if(product.weight){
              w = { value: product.weight, label: product.weight}
            }

            if(product.weight_unit){
              var label = product.weight_unit
              if(units){
                for(var i=0; i<units.length; i++){
                  if(units[i].id == product.weight_unit){
                    label = units[i].unit
                    break
                  }
                }
              }
              w_n = { value: product.weight_unit, label}
            }
           
            return {
              ...product, 
              weight: w,
              weight_unit: w_n,
              attribute_values: values, 
              tags: product.tags?JSON.parse(product.tags):[],
              item_product_id: product.id,
              key: product.id
            }
          })
          setItems(products)
          const groupAttributes  = data.group_attributes.map((attribute)=>{
            var groupAttr = attribute.group_attribute;
            groupAttr['items'] = attribute.items
            groupAttr['label'] = groupAttr.name
            groupAttr['value'] = groupAttr.id
            return groupAttr;
          })
          const attributeValues = data.attributes.map((attribute)=>{
            var atr = attribute.attribute;
            atr['attribute_id'] = attribute.attribute.id;
            atr['attribute_values'] = attribute.attribute_values;
            atr['value'] = attribute.attribute_id;
            atr['label'] = atr.attribute;
            return atr;
          });
          if(groupAttributeRef && groupAttributeRef.current){
            groupAttributeRef.current.onSetSelected(groupAttributes)
          }
          if(attributeRef && attributeRef.current){
            attributeRef.current.onSetSelectedData(attributeValues)
          }
        }
      }
    }))

    const onImageChange = (image)=> {
       setImage(image)
    }
    
    const onImagesChange = (images) => {
        setImages(images)
    }
    
    const addMore = () => {
        items.push({
          ...INITIAL_ITEM, 
          key: 'key_'+items.length, 
          attribute_values:[],
          images:[],
          tags:[],
          featured_image: null
        })
        setItems(items)
        setReflector(!reflector)
    }
    
    const itemDelete = (index) => {
        items.splice(index, 1);
        setItems(items)
        setReflector(!reflector)
    }

    
    const handleItemImageChange = (image, index) => {
        items[index]['featured_image'] = image;
        setItems(items)
        setReflector(!reflector)
    }
    
    const onItemImagesChange = (images,index)=>{
        items[index]['images'] = images;
        setItems(items)
        setReflector(!reflector)
    }

    let attributes = selectedAttributes.map((att) => {
      var obj = {};
      obj['title'] = att.label;
      obj['id'] = att.attribute_id;
      let val = att.attribute_values.map((atv) => {
        return { 
          value: atv.value, 
          label: atv.value_code, 
          value_code:atv.value_code, 
          attribute_id:att.id 
        };
      })
      obj['values'] = val;
      return obj;
    })

    return (
        <React.Fragment>
          <div className="bottom-nav">
            <Nav tabs>
            {TABS.map((tab, index)=>{
                return <NavItem key={index}>
                  <NavLink
                    className={classnames({
                      active: activeTab === index
                    })}
                    onClick={()=>onChangeTab(index)}
                  >
                  {tab.label}</NavLink>
                </NavItem>
              })}
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
              <ItemForm
                image={image}
                images={images}
                category={category}
                brand={brand}
                unit={unit}
                tags={tags}
                validator={validator}
                onChangeTags={(tags)=>{
                  setTags(tags)
                  setReflector(!reflector)
                }}
                onImageChange={onImageChange}
                onImagesChange={onImagesChange}
                onChangeCategory={(category) => setCategory(category)}
                onChangeBrand={(brand) => setBrand(brand)} 
                onChangeUnit={(unit) => setUnit(unit)}
                group_name={group_name}
                weight={weight}
                weight_unit={weightUnit}
                group_description={group_description}
                disabled={disabled}
                onChangeText={(e)=>{
                    if(e.target.name === "group_description"){
                        setGroupDescription(e.target.value)
                    }else if(e.target.name === "weight"){
                        setWeight(e.target.value)
                    }else if(e.target.name === "weight_unit"){
                        setWeightUnit(e.target.value)
                    }else{
                        setGroupName(e.target.value)
                    }
                }}
              />
          </TabPane>
          {/* <TabPane tabId={1}>
            <GroupAttribute ref={groupAttributeRef} disabled={disabled}/>
          </TabPane> */}
          <TabPane tabId={1}>
            <AttributePage ref={attributeRef} disabled={disabled}/>
          </TabPane>
          <TabPane tabId={2}>
            <div className="form-filds-wrapper">
              <Row>
                {type === "StoreProduct"?null: <Col md="12">
                  <div className="form-group">
                    <Button className="btn-simple mr-2" onClick={()=>addMore()}>Add</Button>
                  </div>
                </Col>}
                {
                  items && items.map((item, i) => (
                    <VariantForm 
                      {...item} 
                      index={i} 
                      key={'item'+item.key}
                      elementKey={'item'+item.key}
                      itemDelete={()=>itemDelete(i)}
                      validator={variantValidator} 
                      handleImageChange={(image)=>handleItemImageChange(image, i)}
                      onItemImagesChange={(images)=>onItemImagesChange(images, i)}
                      handleItemAttributes={(values)=>{
                        item['attribute_values'] = values;
                        setReflector(!reflector)
                      }}
                      onChangeText={(e)=>{
                        item[e.target.name] = e.target.value
                        items[i] = item
                        setItems(items)
                        setReflector(!reflector)
                      }}
                      onChangeTags={(tags)=>{
                        item['tags'] = tags
                        setReflector(!reflector)
                      }}
                      units={units}
                      type={type}
                      id={type==="StoreProduct"?item.product_id:item.id}
                      onChangeStatus={onChangeStatus}
                      attributes={attributes}/>
                  ))
                }
              </Row>
            </div>
          </TabPane>
          </TabContent>
        </React.Fragment>
    )
}

export default forwardRef(HasVariantForm);