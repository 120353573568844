import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux';
import Select from "react-select";
import ReactChipInput from "react-chip-input";

import FeaturedImage from "../../../../components/imageUpload/FeatureImage";
import MultipleImage from "../../../../components/imageUpload/MultipleImage";
import { showError } from '../../../../utils';

const WEIGHT_OPTIONS = [
  {value: "1", label:'1'},
  {value: "2", label:'2'},
  {value: "5", label:'5'},
  {value: "250", label:'250'},
  {value: "500", label:'500'},
  {value: "750", label:'750'}

]

function ItemForm({
    image,
    images,
    onImageChange,
    onImagesChange,
    validator,
    group_name,
    onChangeText,
    category,
    onChangeCategory,
    group_description,
    brand,
    unit,
    onChangeUnit,
    onChangeBrand,
    tags,
    onChangeTags,
    disabled,
    weight,
    weight_unit,
    enableWeight=false
}, ref){

    const state = useSelector(state=> state)
    const { leafCategories } = state.categoryReducer
    const { units } = state.unitReducer
    const { brands } = state.brandReducer

    let categoryOptions = leafCategories?leafCategories.map(function (category) {
        return { value: category.id, label: category.name };
    }):[]
  
    let brandOptions = brands?brands.map(function (brand) {
        return { value: brand.id, label: brand.name };
    }):[]

    let unitOptions = units? units.map(function (unit) {
        return { value: unit.id, label: unit.unit };
    }):[]

    const addChip = value => {
      tags.push(value);
      onChangeTags(tags)
    };

    const removeChip = index => {
      const chips = tags.slice();
      chips.splice(index, 1);
      onChangeTags(chips)
    };


    return(
        <div className="form-filds-wrapper mb-5" style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="flex-wrapper">
                            <FeaturedImage imageReset={image} onImageChange={onImageChange}>Featured Image</FeaturedImage>
                            <MultipleImage imageReset={images} onImagesChange={onImagesChange} />
                        </div>
                        <div className="errorMsg">{validator.message('image', image, 'required')}</div>
                        {showError('image', validator.errorMessages)}
                    </div>
                </div>
            </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Item name </label>
                    <input type="text" name="group_name" className="form-control" 
                        value={group_name} onChange={onChangeText} />
                    <div className="errorMsg"> {validator.message('group_name', group_name, 'required')}</div>
                    {showError('group_name', validator.errorMessages)}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Category</label>
                    <Select options={categoryOptions} value={category} onChange={onChangeCategory} />
                    <div className="errorMsg">{validator.message('category', category, 'required')}</div>
                    {showError('category', validator.errorMessages)}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Brand</label>
                    <Select options={brandOptions} value={brand} onChange={onChangeBrand} />
                    <div className="errorMsg">{validator.message('brand', brand, 'required')}</div>
                    {showError('brand', validator.errorMessages)}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label">Unit</label>
                    <Select options={unitOptions} value={unit} onChange={onChangeUnit} />
                    <div className="errorMsg">{validator.message('unit', unit, 'required')}</div>
                    {showError('unit', validator.errorMessages)}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="control-label">Tags</label>
                    <ReactChipInput
                        classes="class1 class2"
                        chips={tags}
                        onSubmit={value => addChip(value)}
                        onRemove={index => removeChip(index)}
                      />
                  </div>
                </div>
                {enableWeight &&  
                <div className="col-md-4">
                  <div className="form-group">
                      <label className="control-label">Weight</label>
                      <Select options={WEIGHT_OPTIONS} value={weight} onChange={(v)=>{
                       onChangeText({target:{
                         name:'weight',
                         value: v
                       }})
                     }} />
                  </div>
                </div>
                }
                {enableWeight &&  
                   <div className="col-md-4">
                   <div className="form-group">
                     <label className="control-label">Weight Unit</label>
                     <Select options={unitOptions} value={weight_unit} onChange={(v)=>{
                       onChangeText({target:{
                         name:'weight_unit',
                         value: v
                       }})
                     }} />
                   </div>
                 </div>
                }
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="control-label">Description</label>
                    <textarea type="text" rows="10" className="form-control" 
                        name="group_description"
                        value={group_description} onChange={onChangeText} />
                  </div>
                </div>
              </div>
            </div>
    )
}

export default forwardRef(ItemForm)