// export const DOMAIN = "http://65.2.165.238:8082/";
// export const DOMAIN = "http://localhost:8082/";
export const DOMAIN = "https://app.wildharbour.co:8443/"

export const SUB_DOMAIN = "api/";
export const VER = "v1";
export const BASE_URL = DOMAIN+SUB_DOMAIN+VER+"/"

//admin login
export const ADMIN_LOGIN = BASE_URL+"admin/login";
export const ADMIN_LOGOUT = BASE_URL+"admin/logout";
export const ADMIN_CHANGE_PASS = BASE_URL+"admin/change-password";

//store login
export const STORE_LOGIN = BASE_URL+"store/login";
export const STORE_LOGOUT = BASE_URL+"store/logout";
export const STORE_CHANGE_PASS = BASE_URL+"store/change-password";

//store permissions
export const STORE_PERMISSIONS = BASE_URL+"get-store-permisions";

//admin permissions
export const ADMIN_PERMISSIONS = BASE_URL+"get-admin-permisions";

// tags
export const ADD_TAG = BASE_URL+"add-tag";
export const GET_TAGS = BASE_URL+"get-tags";
export const DELETE_TAG = BASE_URL+"delete-tag";
export const EDIT_TAG = BASE_URL+"edit-tag";

//category
export const ADD_CATEGORY = BASE_URL+"add-category";
export const GET_CATEGORIES = BASE_URL+"get-all-categories";
export const GET_CATEGORY = BASE_URL+"get-category";
export const GET_STORE_CATEGORIES = BASE_URL+"get-all-store-categories";
export const DELETE_CATEGORY = BASE_URL+"delete-category";
export const EDIT_CATEGORY = BASE_URL+"edit-category";
export const GET_LEAF_CATEGORIES = BASE_URL+"get-leaf-categories";

//brands

export const ADD_BRAND = BASE_URL+"add-brand";
export const GET_BRANDS = BASE_URL+"get-brands";
export const EDIT_BRAND= BASE_URL+"edit-brand";
export const GET_BRAND = BASE_URL+"get-brand";
export const GET_STORE_BRANDS = BASE_URL+"get-all-store-brands";

//units

export const ADD_UNIT = BASE_URL+"add-unit";
export const GET_UNITS = BASE_URL+"get-units";
export const EDIT_UNIT = BASE_URL+"edit-unit";


//variants

export const ADD_VARIANT = BASE_URL+"add-attribute";
export const GET_VARIANTS = BASE_URL+"get-attributes";
export const EDIT_VARIANT= BASE_URL+"edit-attribute";
export const GET_VARIANT = BASE_URL+"get-attribute";


//Attributes

export const ADD_ATTRIBUTE = BASE_URL+"add-group-attribute";
export const GET_ATTRIBUTES = BASE_URL+"get-group-attributes";
export const EDIT_ATTRIBUTE= BASE_URL+"edit-group-attribute";
export const GET_ATTRIBUTE = BASE_URL+"get-group-attribute";

//Add,edit.get store

export const ADD_STORE = BASE_URL+"add-store";
export const GET_STORES = BASE_URL+"get-admin-stores";
export const GET_STORE = BASE_URL+"get-store";
export const EDIT_STORE = BASE_URL+"edit-store";
export const STORE_STATUS = BASE_URL+"change-store-status";

//address fields city,country,state,region

export const GET_COUNTRIES = BASE_URL+"get-countries";
export const GET_STATES = BASE_URL+"get-states";
export const GET_REGIONS = BASE_URL+"get-regions";
export const GET_CITIES = BASE_URL+"get-cities";
export const GET_CURRENCIES = BASE_URL+"get-currencies";

//Volume Offer

export const ADD_VOLUME_OFFER = BASE_URL+"add-volume-offer";
export const GET_ALL_VOLUME_OFFERS = BASE_URL+"get-all-volume-offers";
export const EDIT_VOLUME_OFFER  = BASE_URL+"edit-volume-offer";
export const GET_VOLUME_OFFER  = BASE_URL+"get-volume-offer";

//brand offer
export const ADD_BRAND_OFFER = BASE_URL+"add-brand-offer";
export const GET_ALL_BRAND_OFFERS = BASE_URL+"get-all-brand-offers";
export const EDIT_BRAND_OFFER = BASE_URL+"edit-brand-offer";
export const GET_BRAND_OFFER = BASE_URL+"get-brand-offer";

//category offer
export const ADD_CATEGORY_OFFER = BASE_URL+"add-category-offer";
export const GET_ALL_CATEGORY_OFFERS = BASE_URL+"get-all-category-offers";
export const EDIT_CATEGORY_OFFER = BASE_URL+"edit-category-offer";
export const GET_CATEGORY_OFFER = BASE_URL+"get-category-offer";

//product offer
export const ADD_PRODUCT_OFFER = BASE_URL+"add-product-offer";
export const GET_ALL_PRODUCT_OFFERS = BASE_URL+"get-all-product-offers";
export const EDIT_PRODUCT_OFFER = BASE_URL+"edit-product-offer";
export const GET_PRODUCT_OFFER = BASE_URL+"get-product-offer";

//add sliding banner
export const ADD_OFFER_BANNER = BASE_URL+"add-offer-banner";
export const GET_OFFER_BANNERS = BASE_URL+"get-all-banners";
export const EDIT_OFFER_BANNER = BASE_URL+"edit-offer-banner";
export const GET_OFFER_BANNER = BASE_URL+"get-offer-banner";



//fantastic deals
export const ADD_FANTASTIC_DEAL = BASE_URL+"add-fantastic-deal";
export const GET_FANTASTIC_DEALS = BASE_URL+"get-all-fantastic-deals";
export const EDIT_FANTASTIC_DEAL = BASE_URL+"edit-fantastic-deal";

//Todays deals
export const ADD_TODAYS_DEAL = BASE_URL+"add-todays-deal";
export const GET_TODAYS_DEALS = BASE_URL+"get-all-todays-deals";
export const EDIT_TODYAS_DEAL = BASE_URL+"edit-todays-deal";

//add packs
export const ADD_PACKS = BASE_URL+"add-packs";
export const ADD_STORE_PACKS = BASE_URL+"add-store-packs";
export const GET_ALL_PACKS = BASE_URL+"get-store-packs";
export const EDIT_PACK = BASE_URL+"edit-packs";
export const GET_PACK = BASE_URL+"get-store-product";
export const PRODUCT_STATUS =BASE_URL+"change-product-status";
export const GROUP_STATUS = BASE_URL+"change-store-item-status";

//delete offer
export const DELETE_CATEGORY_OFFER = BASE_URL+"delete-category-offer";
export const DELETE_VOLUME_OFFER = BASE_URL+"delete-volume-offer";
export const DELETE_PRODUCT_OFFER = BASE_URL+"delete-product-offer";
export const DELETE_BRAND_OFFER  = BASE_URL+"delete-brand-offer";

//delete banners and deals

export const DELETE_FANTASTIC = BASE_URL+"delete-fantastic-deal";
export const DELETE_TODAYS= BASE_URL+"delete-todays-deal";
export const DELETE_BANNER = BASE_URL+"delete-banner";


//FAQ
export const ADD_FAQ = BASE_URL+"add-faq";
export const GET_FAQS = BASE_URL+"get-faqs";
export const ADD_FAQ_DIS = BASE_URL+"add-faq-description";
export const DELETE_FAQ = BASE_URL+"delete-faq";
export const DELETE_FAQ_DIS = BASE_URL+"delete-faq-description";

// About and Privacy
export const SAVE_ABOUT = BASE_URL+"save-about";
export const GET_ABOUT = BASE_URL+"get-about";
export const SAVE_POINT_TERMS = BASE_URL+"save-point-terms";
export const GET_POINT_TERMS = BASE_URL+"get-point-terms";
export const SAVE_PRIVACY = BASE_URL+"save-policy";
export const GET_PRIVACY = BASE_URL+"get-policy";

//Dashbord
export const ADMIN_DASHBORD = BASE_URL+"get-admin-dashboard";
export const ALL_CUSTOMERS = BASE_URL+"get-all-customers";
export const GET_CUSTOMER = BASE_URL + "get-customer"
export const UPDATE_CUSTOMER = BASE_URL + "update-customer"

// store dashbord
export const STORE_DASHBORD = BASE_URL+"get-store-dashboard";
export const ALL_STORE_CUSTOMERS = BASE_URL+"get-store-customers";


//staff
export const ADD_STAFF = BASE_URL+"admin/register";
export const GET_ADMIN_STAFFS = BASE_URL+"get-admin-staffs";
export const GET_ADMIN_STAFF = BASE_URL+"get-admin-staff";
export const ADMIN_EDIT = BASE_URL+"admin/edit";
export const ADMIN_DELETE = BASE_URL+"admin/delete";

//STORE STAFF
export const ADD_STORE_STAFF = BASE_URL+"store/register";
export const GET_STORE_STAFFS = BASE_URL+"get-all-store-users";
export const GET_STORE_STAFF = BASE_URL+"get-store-user";
export const STORE_STAFF_EDIT = BASE_URL+"store/edit";
export const STORE_STAFF_DELETE = BASE_URL+"store/delete";

//send notifications

export const SEND_NOTIFICATIONS = BASE_URL+"send-notifications";
export const GET_STORE_NOTIFICATIONS = BASE_URL+"get-store-notification";

//Admin notifications

export const SEND_ADMIN_NOTIFICATIONS = BASE_URL+"send-admin-notifications";
export const GET_ADMIN_NOTIFICATIONS = BASE_URL+"get-admin-notification";

//add propducts
export const ADD_ITEM = BASE_URL+"add-item";
export const ADD_NEW_PRODUCTS = BASE_URL+"add-new-products";
export const GET_STORE_PRODUCTS = BASE_URL+"get-store-products";
export const GET_ALL_PRODUCTS = BASE_URL+"get-all-products";
export const EDIT_PRODUCTS = BASE_URL+"edit-products";
export const ADD_PRODUCTS = BASE_URL+"add-products";

export const EDIT_ITEM_VARIANTS = BASE_URL+"edit-item-variants";
export const ADD_ITEM_VARIANTS = BASE_URL+"add-item-variants";

export const GET_ITEMS = BASE_URL+"get-items";
export const GET_STORE_UNIMPORTED = BASE_URL+"get-store-unimported";
export const GET_STORE_UNIMPORTED_ITEM = BASE_URL+"get-store-unimported-item";

export const GET_STORE_ITEM = BASE_URL+"get-store-item";
export const EDIT_STORE_ITEM = BASE_URL+"edit-store-item";

export const CHANGE_ITEM_STATUS = BASE_URL+"change-item-status";
export const CHANGE_ITEM_VARIANT_STATUS = BASE_URL+"change-item-variant-status";
export const GET_ITEM = BASE_URL+"get-item";
export const EDIT_ITEM = BASE_URL+"edit-item";

//password reset admin
export const ADMIN_SEND_RESET_OTP = BASE_URL+"admin/send-reset-otp";
export const ADMIN_VERIFY_RESET_OTP = BASE_URL+"admin/verify-reset-otp";
export const ADMIN_RESET_PASS= BASE_URL+"admin/reset-password";

//password reset store
export const STORE_SEND_RESET_OTP = BASE_URL+"store/send-reset-otp";
export const STORE_VERIFY_RESET_OTP = BASE_URL+"store/verify-reset-otp";
export const STORE_RESET_PASS = BASE_URL+"store/reset-password";

export const GET_ITEM_PRODUCTS = BASE_URL+"get-item-products";

export const CHECK_UNIQUE_BARCODE = BASE_URL+"check-unique-barcode";
export const CHECK_UNIQUE_SKU = BASE_URL+"check-unique-sku";


export const UPLOAD_STOCK = BASE_URL+"upload-stock";

// delivery slots
export const ADD_SLOTS = BASE_URL+"add-slot";
export const GET_ALL_SLOTS = BASE_URL+"get-slots";
export const GET_SLOT = BASE_URL+"get-slot";
export const EDIT_SLOT = BASE_URL+"edit-slot";
export const DELETE_SLOTS = BASE_URL+"delete-slot";


//orders

export const GET_ALL_ORDERS = BASE_URL+"get-all-orders";
export const GET_STORE_ORDERS = BASE_URL+"get-store-orders";
export const GET_ORDER = BASE_URL+"get-order";
export const CHANGE_ORDER_STATUS = BASE_URL+"change-order-status";
export const START_SHIPPING = BASE_URL + "start-shipping"
export const EDIT_ORDER_ITEM = BASE_URL + "edit-order-item"
export const DELETE_ORDER_ITEM = BASE_URL + "delete-order-item"
export const ADD_ORDER_ITEMS = BASE_URL + "add-order-items"

// deliverboy

export const ADD_DELIVERY_BOY = BASE_URL+"delivery-boy/register";
export const UPDATE_DELIVERY_BOY = BASE_URL+"delivery-boy/update";
export const GET_DELIVERY_BOYS = BASE_URL+"get-delivery-boys";
export const GET_DELIVERY_BOY = BASE_URL+"get-delivery-boy";
export const CHANGE_DELIVERY_BOY = BASE_URL+"change-delivery-boy";


//REIVEW

export const GET_REVIEWS = BASE_URL+"get-reviews";


export const IMPORT_TO_STORES = BASE_URL + 'import-to-stores'

export const CHECK_USER_EXIST = BASE_URL + 'check-customer-exist'
export const GET_CUSTOMER_PRODUCTS = BASE_URL + 'get-store-customer-products'
export const REGISTER_CUSTOMER = BASE_URL + 'customer/register'
export const PLACE_ORDER = BASE_URL+"place-order";

//EXPORT
export const EXPORT_CUSTOMERS = BASE_URL+"export-customers";
export const EXPORT_ORDERS = BASE_URL+"export-orders";

export const BEST_SELLER = BASE_URL+"best-sellers"
export const SALES_CHART = BASE_URL+"sales-chart"


