import React from "react";

class PanelHeader extends React.Component {
  render() {
    return (
      <div
        className="panel-header"  >
      </div>
    );
  }
}

export default PanelHeader;
