import React from 'react';
import SimpleReactValidator from "simple-react-validator";
import { Row, Col, Alert, Input, FormGroup, Button, Table } from "reactstrap";
import Select from "react-select";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from 'moment';

import CustomerProductsSelect from '../product/CustomerProductsSelect';
import { checkCustomerExist, registerCustomer } from '../../../actions/storeStaffActions';
import { placeOrder } from '../../../actions/OrderActions';
import { getLoder } from '../../../utils';

class AddOrder extends React.Component{

    state = {
        mobile:'', 
        name:'', 
        items:[this.initialItem], 
        page:'mobile', 
        delivery_mode:false, 
        payment_mode:false,
        delivery_charge:0,
        date:moment(),
        from:moment(),
        to:moment(),
        delivery_address:''

    }

    constructor(props){
        super(props)
        this.validator = new SimpleReactValidator() 
        this.mobileValidator = new SimpleReactValidator() 
        this.nameValidator = new SimpleReactValidator() 
    }

    get initialItem(){
        return{
            item:'',
            count:1,
            pack:null,
        }
    }

    onAddItem= ()=>{
        const {items} =this.state
        items.unshift(this.initialItem)
        this.setState({items})
    }

    onDelete = (index)=>{
        const {items} =this.state
        items.splice(index, 1)
        this.setState({items})
    }

    onVerifyMobile = ()=>{
        if( this.mobileValidator.allValid()){
            this.props.checkCustomerExist(this.state.mobile)
        }
        else {
            this.mobileValidator.showMessages();
            this.forceUpdate();
        }
    }

    generatePassword=(n)=> 
    {
        let pwd = "";
        while(!pwd || pwd.length < n)
        {
            pwd += Math.random().toString(36).slice(-22);
        }
        return pwd.substring(0, n);
    }

    onChangeDeliveryCharge = (e)=>{
        this.setState({delivery_charge:e.target.value})
    }

    onSignUp = ()=>{
        const password = this.generatePassword(6)
        var data = {
            first_name: this.state.name,
            second_name: this.state.name,
            mobile: this.state.mobile,
            password: password,
            c_password: password,
            email:null
        }
        if( this.nameValidator.allValid()){
            this.props.onRegisterCustomer(data)
        }
        else {
            this.nameValidator.showMessages();
            this.forceUpdate();
        }
        
    }

    changeSellingPrice = (e, index)=>{
        const {items} = this.state
        items[index]['price'] = e.target.value
        this.setState({items})
    }
    
    changeCount = (e, index)=>{
        const {items} = this.state
        items[index]['count'] = e.target.value
        this.setState({items})
    }

    onChangeWeight = (e, index)=>{
        const {items} = this.state
        items[index]['weight'] = e
        // items[index]['price'] = selected.value.packs.packs[e.value].selling_price
        // items[index]['pack'] = selected.value.packs.packs[e.value]
        // this.setState({items})
    }

    onChangeProductSelect = (selected, index)=>{
        const {items} = this.state
        items[index]['item'] = selected
        if(selected.value && selected.value.packs && selected.value.packs.qtys){
            items[index]['weight'] = {value:selected.value.packs.qtys[0].pos, label:selected.value.packs.qtys[0].qty}
            items[index]['price'] = selected.value.packs.packs[selected.value.packs.position].selling_price
            items[index]['pack'] = selected.value.packs.packs[selected.value.packs.position]
        }
        this.setState({items})
    }

    renderMobileNumber = ()=>{
        return(
            <Row>
                <Col md="6">
                    <div className="form-group">
                        <Input 
                            type="number" 
                            className="form-control" 
                            onChange ={(e)=>this.setState({mobile:e.target.value})} 
                            value={this.state.mobile}  
                            placeholder="Mobile Number" />
                        <div className="errorMsg">{this.mobileValidator.message('Mobile', this.state.mobile, 'required|min:10')}</div>
                    </div>
                    <Button onClick={this.onVerifyMobile}>NEXT</Button>
                </Col>
            </Row>
        )
    }

    renderSignupForm = ()=>{
        return(
            <div>
                <h5>{this.state.mobile}</h5>
                <Row>
                    <Col md="6">
                        <div className="form-group">
                            <Input 
                                type="name" 
                                className="form-control" 
                                onChange ={(e)=>this.setState({name:e.target.value})} 
                                value={this.state.name}  
                                placeholder="Name" />
                            <div className="errorMsg">{this.nameValidator.message('Name', this.state.name, 'required')}</div>
                        </div>
                        <Button onClick={this.onSignUp}>NEXT</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    onPressPlaceOrder = ()=>{
        var data = this.prepareOrder()
        this.props.onPlaceOrder(data)
    }

    prepareOrder = ()=>{
        const { items, delivery_mode, payment_mode, delivery_charge, delivery_address } = this.state
        const {user, store} = this.props
        const products = items.map((item)=>{
            if(!item.pack) return null
            return {
                id: item.pack.id,
                selling_price: item.price,
                count: item.count,
                discount:0
            }
        }).filter((el)=>el !=null )
        var data = {
            date: this.state.from.format('YYYY-MM-DD'),
            from: this.state.from.format('HH:mm'),
            to: this.state.to.format('HH:mm'),
            delivery_mode: delivery_mode?1:3,
            payment_mode: payment_mode?2:3,
            delivery_charge: delivery_charge,
            products,
            customer_id: user.id,
            store_id: store.id,
            delivery_address: delivery_address
        }
        return data
    }

    onChangeDeliveryGroup=()=>{
        this.setState({
          delivery_mode:!this.state.delivery_mode
        })
    }

    onChangePaymentMode=()=>{
        this.setState({
          payment_mode:!this.state.payment_mode
        })
    }

    renderCartForm = ()=>{
        const {items} = this.state

        if(!this.props.user) return null

        var total = 0
        for(var i=0; i<items.length; i++){
            var amount = items[i].count * items[i].price
            total += amount
        }

        return(
            <div>
                <Row>
                    <Col md="12">
                        <h5>{this.props.user.mobile}</h5>
                        <h5>{this.props.user.first_name}</h5>
                    </Col>
                    <Col md="3">
                        <div className="form-group">
                            <label className="control-label">Delivery Date</label>
                            <DatePicker 
                                className="form-control" 
                                selected={this.state.date}
                                onChange={(e)=>this.setState({date:e})}
                                dateFormat="YYYY-MM-DD"
                                name="date"/>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="form-group">
                            <label className="control-label">Time From</label>
                            <DatePicker 
                                className="form-control" 
                                selected={this.state.from}
                                onChange={(e)=>this.setState({from:e})}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                timeIntervals={30}
                                name="from"/>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="form-group">
                            <label className="control-label">Time To</label>
                            <DatePicker 
                                className="form-control" 
                                selected={this.state.to}
                                onChange={(e)=>this.setState({to:e})}
                                showTimeSelect
                                showTimeSelectOnly
                                timeFormat="HH:mm"
                                dateFormat="HH:mm"
                                timeIntervals={30}
                                name="to"/>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="form-group">
                            <label className="control-label">Delivery Charge</label>
                            <Input 
                                type="number" 
                                className="form-control" 
                                onChange ={this.onChangeDeliveryCharge} 
                                value={this.state.delivery_charge}  
                                placeholder="Delivery Charge" />
                        </div>
                    </Col>
                    <Col md="12">
                        <div className="radio-wrapper">
                            <input
                                type="radio"  
                                name="product-v1" 
                                value="true" 
                                id="optiona"  
                                onChange={this.onChangeDeliveryGroup} 
                                defaultChecked/>
                            <label htmlFor="optiona">
                                <span>
                                    <span />
                                </span>
                                TAKE AWAY</label>
                            <input
                                type="radio"
                                id="optionb"
                                value="false"
                                name="product-v1"
                                onChange={this.onChangeDeliveryGroup}
                            />
                            <label htmlFor="optionb">
                                <span>
                                    <span />
                                </span>
                                ON DELIVERY
                            </label>
                        </div>
                    </Col>
                    {this.state.delivery_mode ? 
                    <Col md="6">
                        <div className="form-group">
                            <label className="control-label">Delivery Address</label>
                            <Input 
                                multiple={true}
                                className="form-control" 
                                onChange ={(e)=>this.setState({delivery_address:e.target.value})} 
                                value={this.state.delivery_address}  
                                placeholder="Delivery Address" />
                        </div>
                    </Col>:null
                    }
                    <Col md="12">
                        <div className="radio-wrapper">
                            <input
                                type="radio"  
                                name="product-v" 
                                value="true" 
                                id="option1"  
                                onChange={this.onChangeRadioGroup} 
                                defaultChecked/>
                            <label htmlFor="option1">
                                <span>
                                    <span />
                                </span>
                                CASH ON DELIVERY</label>
                            <input
                                type="radio"
                                id="option2"
                                value="false"
                                name="product-v"
                                onChange={this.onChangeRadioGroup}
                            />
                            <label htmlFor="option2">
                                <span>
                                    <span />
                                </span>
                                CARD ON DELIVERY
                            </label>
                        </div>
                    </Col>
                    {items.map((item, index)=>(
                        <Col md="12" key={index} style={{marginTop:30}}>
                            <div className="form-group">
                                <Row>
                                    <Col md="6">
                                        <label className="control-label">Product</label>
                                        <CustomerProductsSelect 
                                            value={item.item}
                                            onChange={(selected)=>this.onChangeProductSelect(selected, index)}/>
                                        <div className="errorMsg">{this.validator.message('Name', this.state.name, 'required')}</div>
                                    </Col>
                                    {
                                        (item && item.item && item.item.value && item.item.value.packs)?
                                        <Col md="6">
                                            <Row>
                                                <Col md="3">
                                                    <label className="control-label">Quantity</label>
                                                    <Input 
                                                        type="number" 
                                                        className="form-control" 
                                                        onChange ={(e)=>this.changeCount(e, index)} 
                                                        value={item.count}  
                                                        placeholder="Quantity" />
                                                </Col>
                                                <Col md="4">
                                                    <label className="control-label">Weight</label>
                                                    <Select
                                                        options={
                                                            item.item.value.packs.qtys.map((qty)=>{
                                                                return {value: qty.pos, label:qty.qty}
                                                            })
                                                        }
                                                        onChange={(e)=>this.onChangeWeight(e, index)}
                                                        value={item.weight}
                                                    />
                                                </Col>
                                                <Col md="4">
                                                    <label className="control-label">Selling Price</label>
                                                    <Input 
                                                        type="number" 
                                                        className="form-control" 
                                                        onChange ={(e)=>this.changeSellingPrice(e, index)} 
                                                        value={item.price}  
                                                        placeholder="Selling Price" />
                                                </Col>
                                                <Col md="1">
                                                    <button className="btn" onClick={() => this.onDelete(index)}>
                                                        <i className="ico icon-delete" />
                                                    </button>
                                                </Col>  
                                            </Row>
                                        </Col>:null
                                    }
                                </Row>
                            </div>
                            
                        </Col>
                    ))}
                    <Col md="12">
                        <Button onClick={this.onAddItem}>ADD MORE</Button>
                        <Button onClick={this.onPressPlaceOrder}>PLACE ORDER</Button>
                    </Col>
                    <Col md="12">
                        <Table>
                            <tfoot>
                            <tr>
                                <td colSpan={'5'} className="text-right">
                                    <p>Sub Total</p>
                                    <p>Delivery charge</p>
                                    <p className="total">Grand Total</p>
                                </td>
                                <td className="price">
                                    <p>{total}</p>
                                    <p>{this.state.delivery_charge}</p>
                                    <p className="total">{parseFloat(this.state.delivery_charge) + parseFloat(total)}</p>
                                </td>
                            </tr>
                            </tfoot>
                        </Table> 
                    </Col>
                </Row>
            </div>
        )
    }

    componentDidUpdate(prevProps){
        if(prevProps.verifyLoading && !this.props.verifyLoading){
            if(this.props.user){
                this.setState({page:'order'})
            }else{
                this.setState({page:'signup'})
            }
        }
        if(prevProps.regLoading && !this.props.regLoading){
            if(this.props.user){
                this.setState({page:'order'})
            }
        }
        if(prevProps.placeLoading && !this.props.placeLoading){
            if(this.props.success){
                this.props.history.push('/store/processing')
            }
        }
    }

    render(){
        const {regLoading, verifyLoading, placeLoading} = this.props
        return(
            <div>
                <div className="content">
                    <div className={getLoder(regLoading||verifyLoading||placeLoading)}></div>
                    <div className="form-section">  
                        <form >
                            {
                                this.state.page === 'mobile'?this.renderMobileNumber():
                                this.state.page === 'signup'?this.renderSignupForm():
                                this.renderCartForm()
                            }
                        </form>
                    </div>
                </div>
            </div>
           
        )
    }

}

const mapStateToProps = (state) => ({
    verifyLoading: state.commonReducer.loading,
    regLoading: state.commonReducer.regLoading,
    placeLoading: state.orderReducer.loading,
    success: state.orderReducer.status,
    user: state.commonReducer.user,
    store:state.storeUserReducer.store,
    products: state.productReducer.customerProducts
});

const mapDispatchToProps = (dispatch) => ({
    checkCustomerExist:(mobile)=>{
        dispatch(checkCustomerExist(mobile))
    },
    onRegisterCustomer:(data)=>{
        dispatch(registerCustomer(data))
    },
    onPlaceOrder:(data)=>{
        dispatch(placeOrder(data))
    }
});

export default connect(mapStateToProps,mapDispatchToProps)(AddOrder);