import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  unitLoading: false,
  units: []
};

export default function unitReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_UNIT_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_UNIT:
      return {
        ...state,
        loading: false,
        message: "Unit added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_UNIT_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Unit add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_UNIT_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_UNIT:
      return {
        ...state,
        loading: false,
        message: "Unit edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_UNIT_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Unit edit failed",
        status: false,
        error: action.error
      }

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.GET_UNITS_LOADING:
      return {
        unitLoading: true,
        units: []
      };
    case Actions.GET_UNITS:
      return {
        ...state,
        unitLoading: false,
        units: action.units,
        count: action.count
      };
    case Actions.GET_UNITS_FAILED:
      return {
        ...state,
        unitLoading: false,
        units: [],
        error: action.error
      };
      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
