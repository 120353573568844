import * as Actions from "../constants/Actions";

const initialState = {
  staffLoading: false,
  staffs: [],
  count:0,
  staff:{},
  singleStaffLoading:false,
  editLoading:false,
  loading: false,
  deleteLoading:false

};

export default function StaffReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_STORE_STAFF_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.ADD_STORE_STAFF_SUCCESS:
    return {
      ...state,
      loading: false,
      message:" Staff added successfully",
      popup: true,
      status: true
    };
  case Actions.ADD_STORE_STAFF_FAILED:
    return {
      ...state,
      loading: false,
      popup: true,
      message:"Add staff failed",
      status: false,
      error: action.error
    };
    case Actions.GET_STORE_STAFF_LOADING:
      return {
        staffLoading: true,
        staffs: [],
        count:0
      };
    case Actions.GET_STORE_STAFF_SUCCESS:
      return {
        staffLoading: false,
        staffs: action.staffs,
        count: action.count
      };

      case Actions.GET_STORE_STAFF_FAILED:
      return {
          ...state,
        staffLoading: false,
        staffs: [],
        count:0
      }; 

      case Actions.GET_STORE_SINGLE_STAFF_LOADING:
      return {
          ...state,
          staff: {},
          singleStaffLoading: true
      };
    case Actions.GET_STORE_SINGLE_STAFF_SUCCESS:
      return {
        ...state,
          staff: action.staff,
          singleStaffLoading: false
      };

      case Actions.GET_STORE_SINGLE_STAFF_FAILED:
      return {
        ...state,
        staff: {},
        singleStaffLoading: false
      }; 

      case Actions.EDIT_STORE_STAFF_LOADING:
      return {
          ...state,
          status: false,
          editLoading:true,
          popup: false
      };
    case Actions.EDIT_STORE_STAFF_SUCCESS:
      return {
        ...state,
          status: true,
          popup: true,
          editLoading:false,
          message:"Staff Edited Successfully",
          singleStaffLoading: false
      };

      case Actions.EDIT_STORE_STAFF_FAILED:
      return {
        ...state,
        status: false,
        editLoading:false,
        message:"Staff Editing Failed",
        popup: true
      }; 

      case Actions.DELETE_STORE_STAFF_LOADING:
      return {
          ...state,
          status: false,
          deleteLoading:true,
          popup: false
      };
    case Actions.DELETE_STORE_STAFF_SUCCESS:
      return {
        ...state,
          status: true,
          popup: true,
          deleteLoading:false,
          message:"Staff Deleted Successfully"
      };

      case Actions.DELETE_STORE_STAFF_FAILED:
      return {
        ...state,
        status: false,
        deleteLoading:false,
        message:"Staff Delete Failed",
        popup: true
      }; 

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    default:
      return state;
  }
}
