import React, { Component } from "react";
import { Table,Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button,Checkbox } from '../../../components';
import {connect} from "react-redux";
import {addUnit,editUnit,getUnits,onDismiss} from "../../../actions/unitActions";
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
import SimpleReactValidator from 'simple-react-validator';
class Units extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUnit:[],
            editModal:false,
            haveParent:false,
            modal: false,
            isMounted: false,
            unitLoading:false,
            unit:'',
            symbol:'',
            fields: [],
            unit_type:1,
            skip: 0,
            limit:12

        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validator = new SimpleReactValidator();
      }
      toggle() {
        this.setState({
          modal: !this.state.modal
        });
      }
      toggle2 = ()=> {
        this.setState({
          modal: !this.state.modal,
          unit:'',
          symbol:'',
          fields:[],
          haveParent:false
        });
      }
      childUI(){
        return this.state.fields.map((el, i) => 
            <div className="form-box"  key={i}>
            <select name="parent_unit" value={el.parent_unit} className="form-control" onChange={(e)=>this.handleChange(e, i)}>
            <option value="">Select a parent unit</option>
            {this.props.units.map((unit,index) => (
            <option value={unit.id} key={index}>{unit.unit}</option>
            ))}
            </select>
            <div className="errorMsg">{this.validator.message('parent unit', el.convertion_value, 'required')}</div>
            <input type="number" name="convertion_value" value={el.convertion_value} onChange={(e)=>this.handleChange(e, i)} className="form-control mt-10" placeholder="Convertion"/>
            <div className="errorMsg">{this.validator.message('convertion value', el.convertion_value, 'required')}</div>
          <Button className="btn-plus btn-abs" onClick={this.removeClick.bind(this, i)} leftLabel="fa red fa-trash"></Button>
            </div>   
        )
     }
     handleChange (e, i) {
      var obj = {};
      let fields = this.state.fields;
      if(fields.length > i){
        obj =  fields[i];
      }
     
      if( e.target.name === "parent_unit"){
        obj['parent_unit'] = e.target.value;
      }

      if( e.target.name === "convertion_value"){
        obj['convertion_value'] = e.target.value;
      }

      fields[i] = obj;
      this.setState({ fields:fields });

   }
   addClick (){
    this.setState(prevState => ({ fields: [...prevState.fields, {"parent_unit":"","convertion_value":""}]}))
  }
 
  removeClick (i){
    let fields = [...this.state.fields];
    this.validator = new SimpleReactValidator();
    fields.splice(i,1);
    this.setState({ fields });
 }
      getLoder = () => {
        let classes = "bt-loader ";
        classes += this.props.unitLoading ||this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
      onDismissPopup = () =>{
        this.props.onDismiss();
        this.props.onGetUnits(this.state.skip,this.state.limit);
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetUnits(skip,this.state.limit);
      }
      handleBtn = () => {
        this.setState({
          haveParent:!this.state.haveParent
        })
      }
      componentDidMount = () => {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
        {this.props.onGetUnits(this.state.skip,this.state.limit)}
      }
      });
      }

      handleSubmit = (e) => {
        e.preventDefault();
        var data = {
          unit: this.state.unit,
          symbol: this.state.symbol,
          unit_type: 1,
          relations:this.state.fields
        };
        if( this.validator.allValid() ){
        this.props.onAddUnits(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }  
      }

      handleEdit(e,unit) {
        e.preventDefault();
        var stat;
        var realtions=[];
        
        if(typeof unit.relations !== 'undefined' ) {
          realtions = unit.relations.map(function(result) {
         return{parent_unit:result.parent_unit,convertion_value:result.convertion_value};
       });
      }
        if(unit.relations.length>0) {
         stat = true;
        }
        this.setState({
           editModal: true,
           haveParent:stat,
           unit:unit.unit,
           symbol:unit.symbol,
           selectedUnit:unit,
           fields:realtions
          });
      }

      handleEditsubmit = (e) => {
        e.preventDefault();
        var data = {
          unit: this.state.unit,
          symbol: this.state.symbol,
          unit_type: 1,
          relations:this.state.fields
        };
        if( this.validator.allValid() ){
          this.props.onEditUnit(data);
          data['unit_id'] = this.state.selectedUnit.id;
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        } 
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.status === true && !this.state.editModal) {
          this.setState({
            unit:'',
            symbol:'',
            unit_type: '',
            fields:[]
           });
        }
    }

    render() { 
      const {units} = this.props;
        return ( 
                <div>
                  <div className="content">
                  <div className ={this.getLoder()}></div>
                    <div className="table-product-wrapper">
                      <Table>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Unit Name</th>
                            <th>Unit Symbol</th>
                            <th>Parent Units</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                        {units.length>0 ? units.map((unit,index) => (
                            <tr key={unit.id}>
                            <td>
                              {index+1 + this.state.skip}
                            </td>
                            <td>{unit.unit}</td>
                            <td>{unit.symbol}</td>
                            <td>
                              {unit.childs.length>0 ? unit.childs.map((rel,i) => (
                                 <span className="child-unit" key={i}>{i>0?', ':''}{rel.unit}</span>
                              )):null}
                            </td>
                            <td>
                              <div className="group-action">
                              <button className="btn" onClick={(e)=>this.handleEdit(e,unit)}>
                              <i className="ico c1 icon-edit-1 " />
                              </button>
                              </div>
                            </td>
                          </tr>    
                        )):<tr><td colSpan={'5'}>No data found</td></tr>}
                            
                         
                        </tbody>
                      </Table>
                      <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                    </div>
                    <Button className="fixed-yellow" onClick={this.toggle2}>Add</Button>
                  <div className="pagination-wrapper">
                  <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  pageLinkClassName={"page-link"}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(this.props.count/this.state.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  activeClassName={"active"} />
                  </div>
                  {/* add */}
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className='common-modal' >
                    <ModalHeader>Add New Unit Type</ModalHeader>
                    <form  onSubmit ={this.handleSubmit}>
                    <ModalBody>
                       <div className="form-model">
                       <div className="form-group">
                        <label className="control-label">Unit Name</label>
                        <input type="text" value={this.state.unit} onChange={(e)=>this.setState({unit:e.target.value})} className="form-control" placeholder="Type here"/>
                        <div className="errorMsg">{this.validator.message('unit', this.state.unit, 'required')}</div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Unit Symbol</label>
                        <input type="text" value={this.state.symbol} onChange={(e)=>this.setState({symbol:e.target.value})} className="form-control" placeholder="Type here"/>
                        <div className="errorMsg">{this.validator.message('symbol', this.state.symbol, 'required')}</div>
                      </div>
                    <div className="form-group">
                    <div className="check-wrapper">
                            <Checkbox label="Add Parent Unit" checked={this.state.haveParent} onChange={this.handleBtn} />
                          </div>
                    </div>
                      <div className="form-group">
                      <div className="add-childs">
                      {this.state.haveParent ? this.childUI()  : ''}
                      {this.state.haveParent ?  <Button className="btn-plus" onClick={this.addClick.bind(this)} leftLabel="fa fa-plus">Add Parent Unit</Button> :''}

                      </div>
                      
                      </div>
                       </div>     
                    </ModalBody>
                    <ModalFooter>
                    <Button className="btn-cancel" onClick={this.toggle}>Cancel</Button>
                    <Button className="btn-submit" type="submit">Save</Button>{' '}
                    </ModalFooter>
                    </form>
                    </Modal>

                   {/* edit unit                */}

                    <Modal isOpen={this.state.editModal}  className='common-modal' >
                    <ModalHeader>Edit Unit Type</ModalHeader>
                    <form  onSubmit ={this.handleEditsubmit}>
                    <ModalBody>
                       <div className="form-model">
                       <div className="form-group">
                        <label className="control-label">Unit Name</label>
                        <input type="text" value={this.state.unit} onChange={(e)=>this.setState({unit:e.target.value})} className="form-control" placeholder="Type here"/>
                        <div className="errorMsg">{this.validator.message('unit', this.state.unit, 'required')}</div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Unit Symbol</label>
                        <input type="text" value={this.state.symbol} onChange={(e)=>this.setState({symbol:e.target.value})} className="form-control" placeholder="Type here"/>
                        <div className="errorMsg">{this.validator.message('symbol', this.state.symbol, 'required')}</div>
                      </div>
                    <div className="form-group">
                    <div className="check-wrapper">
                            <Checkbox label="Add Parent Unit" checked={this.state.haveParent} onChange={this.handleBtn} />
                          </div>
                    </div>
                      <div className="form-group">
                      <div className="add-childs">
                      {this.state.haveParent ? this.childUI()  : ''}
                      {this.state.haveParent ?  <Button className="btn-plus" onClick={this.addClick.bind(this)} leftLabel="fa fa-plus">Add Parent Unit</Button> :''}

                      </div>
                      
                      </div>
                       </div>     
                    </ModalBody>
                    <ModalFooter>
                    <Button className="btn-cancel" onClick={() => this.setState({editModal:false})}>Cancel</Button>
                    <Button className="btn-submit" type="submit">Save</Button>{' '}
                    </ModalFooter>
                    </form>
                    </Modal>

                  </div>
                </div>
              );
            }
}
 
const mapStateToProps = (state) => ({
  loading: state.unitReducer.loading,
  status:state.unitReducer.status,
  unitLoading:state.unitReducer.unitLoading,
  units: state.unitReducer.units,
  message:state.unitReducer.message,
  count:state.unitReducer.count,
  popup:state.unitReducer.popup
});

const mapDispatchToProps = (dispatch)=> ({
  onAddUnits:(data)=>{
    dispatch(addUnit(data))
  },
  onEditUnit:(data) =>{
    dispatch(editUnit(data));
  },
  onGetUnits:(skip,take)=>{
    dispatch(getUnits(skip,take))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Units);