import StoreDashbord from "../views/StoreAdmin/Dashbord.jsx";
import StoreProducts from "../views/StoreAdmin/Product/product";
import Category from "../views/StoreAdmin/Category/Category";
import Brands from "../views/StoreAdmin/Brands/Brands";
import Tags from "../views/StoreAdmin/Tags/Tags.jsx";
import Users from "../views/StoreAdmin/Users/Users";
import AddUser from "../views/StoreAdmin/Users/AddUser";
import Customers from "../views/StoreAdmin/Customers/Customers";
import NotiFication from "../views/StoreAdmin/Notifications/Notification";
import VolumeOffer from "../views/StoreAdmin/Offers/VolumeOffer";
import AddVolumeOffer from "../views/StoreAdmin/Offers/AddVolume";
import CategoryOffer from "../views/StoreAdmin/Offers/CategoryOffer";
import BrandOffer from "../views/StoreAdmin/Offers/BrandOffer";
import ProductOffer from "../views/StoreAdmin/Offers/ProductOffer";
import ComboPackOffer from "../views/StoreAdmin/Offers/ComboPackOffer";
import AddComboPack from "../views/StoreAdmin/Offers/AddComboPackOffer";
import AddProductOffer from "../views/StoreAdmin/Offers/AddProductOffer";
import AddBrandOffer from "../views/StoreAdmin/Offers/AddBrandOffer";
import AddCategoryOffer from "../views/StoreAdmin/Offers/AddCategoryOffer";
import TodaysDeal from "../views/StoreAdmin/Content/TodaysDeals";
import FantasticDeal from "../views/StoreAdmin/Content/FantasticDeals";
import StillBanner from "../views/StoreAdmin/Content/StillBanner";
import SlidingBanner from "../views/StoreAdmin/Content/SlidingBanner";
import AddStillBanner from "../views/StoreAdmin/Content/AddStillBanner";
import AddSlidingBanner from "../views/StoreAdmin/Content/AddSlidingBanner";
import MyAccount from "../views/StoreAdmin/MyAccount.jsx";
import EditProductOffer from "../views/StoreAdmin/Offers/EditProductOffer.jsx";
import EditBrandOffer from "../views/StoreAdmin/Offers/EditBrandOffer.jsx";
import EditVolume from "../views/StoreAdmin/Offers/EditVolume.jsx";
import EditCategoryOffer from "../views/StoreAdmin/Offers/EditCategoryOffer.jsx";
import EditSlidingBanner from "../views/StoreAdmin/Content/EditSlidingBanner.jsx";
import EditStillBanner from "../views/StoreAdmin/Content/EditStillBanner.jsx";
import EditComboPack from "../views/StoreAdmin/Offers/EditComboPack.jsx";
import EditUser from "../views/StoreAdmin/Users/EditUser.jsx";
import Notifications from "../views/StoreAdmin/Notifications/Notification";
import createNotification from "../views/StoreAdmin/Notifications/createNotification.jsx";
import AdminProducts from "../views/StoreAdmin/Product/AdminProducts.jsx";
import CouponCodes from "../views/StoreAdmin/Offers/coupons/couponCodes.jsx";
import processing from "../views/common/order/processing/index.jsx";
import AllOrders from "../views/StoreAdmin/Orders/AllOrders/AllOrders.jsx";
import Accounts from "../views/StoreAdmin/Orders/Accounts/index";
import DeliverySlots from "../views/SuperAdmin/Orders/DeliverySlots/DeliverySlots.jsx";
import AddDeliverySlot from "../views/SuperAdmin/Orders/DeliverySlots/AddDeliverySlot.jsx";
import AddOrder from "../views/common/order/AddOrder.jsx";
import StoreProductForm from "../views/StoreAdmin/Product/StoreProductForm.jsx";
import DeliveryBoys from "../views/SuperAdmin/Orders/DeliveryBoys/DeliveryBoys.jsx";
import AddDeliveryBoy from "../views/SuperAdmin/Orders/DeliveryBoys/AddDeliveryBoy.jsx";
import DeliveryBoy from "../views/SuperAdmin/Orders/DeliveryBoys/DeliveryBoy.jsx";
import FeedBacks from "../views/StoreAdmin/Orders/Feedback/index";
import OrderDetail from "../views/common/order/OrderDetail.jsx";


const storeRoutes = [
  {
    path:"dashboard",
    name:"Dashboard",
    component:StoreDashbord,
  },
  {
    path: "products",
    name: "Products",
    component:StoreProducts
  },
  {
    path: "add-product",
    name: "Add Product",
    component: StoreProductForm
  },
  {
    path: "edit-product/:item_id",
    name: "Edit Product",
    component: StoreProductForm
  },
  {
    path: "category",
    name: "Categories",
    component: Category
  },
  // {
  //   path: "edit-category/:id",
  //   name: "Categories - Edit",
  //   component: EditCategory
  // },
  // {
  //   path: "add-category",
  //   name: "Categories - Add New",
  //   component: AddCategory
  // },
  {
    path: "brands",
    name: "Brands",
    component: Brands
  },
  // {
  //   path: "add-brand",
  //   name: "Add Brand",
  //   component: AddBrand
  // },
  // {
  //   path: "edit-brand/:id",
  //   name: "Edit Brand",
  //   component: EditBrand
  // },
  // {
  //   path: "units",
  //   name: "Units",
  //   component: Units
  // },
  {
    path: "tags",
    name: "Tags",
    component: Tags
  },
  // {
  //   path:"variants",
  //   name:"Variants",
  //   component:Variants
  // },
  // {
  //   path:"add-variant",
  //   name:"Add Variant",
  //   component:AddVariant
  // },
  // {
  //   path:"edit-variant/:id",
  //   name:"Edit Variant",
  //   component:EditVariant
  // },
  // {
  //   path:"attributes",
  //   name:"Attributes",
  //   component:Attributes
  // },
  // {
  //   path:"add-attribute",
  //   name:"Add Attribute",
  //   component:AddAttribute
  // },
  // {
  //   path:"edit-attribute/:id",
  //   name:"Edit Attribute",
  //   component:EditAttribute
  // },
  {
    path:"processing",
    name:"Processing",
    component:processing
  },
  {
    path:"all-orders",
    name:"All",
    component:AllOrders
  },
  {
    path:"add-order",
    name:"Add Order",
    component:AddOrder
  },

  {
    path:"accounts",
    name:"Accounts",
    component:Accounts
  },

  {
    path:"delivery-slots",
    name:"Delivery Slots",
    component:DeliverySlots
  },

  {
    path:"add-slot",
    name:"Add delivery slots",
    component:AddDeliverySlot
  },
  {
    path:"edit-slot/:id",
    name:"Add delivery slots",
    component:AddDeliverySlot
  },
  {
    path:"delivery-boys",
    name:"Delivery boys",
    component:DeliveryBoys
  },

  {
    path:"add-delivery-boy",
    name:"Add delivery boy",
    component:AddDeliveryBoy
  },
  {
    path:"edit-delivery-boy/:id",
    name:"Edit delivery boy",
    component:AddDeliveryBoy
  },
  {
    path:"delivery-boy/:id",
    name:"Delivery Boy",
    component:DeliveryBoy
  },
  {
    path:"feedbacks",
    name:"Feedbacks",
    component:FeedBacks
  },
  {
    path:"store-staffs",
    name:"Staffs",
    component:Users
  },
  {
    path:"add-store-staff",
    name:"Add Staff",
    component:AddUser
  },
  {
    path:"edit-store-staff/:id",
    name:"Edit Staff",
    component:EditUser
  },
  {
    path:"customers",
    name:"Customers",
    component:Customers
  },
  {
    path:"notifications",
    name:"Notifications",
    component:NotiFication
  },
  {
    name:"Category Offers",
    path: "category-offers",
    component: CategoryOffer
  },
  {
    name:"Add Category Offer",
    path:"add-category-offer",
    component:AddCategoryOffer
  },
  {
    name:"Edit Category Offer",
    path:"edit-category-offer/:id",
    component:EditCategoryOffer
  },
  {
    name:"Group Products",
    path: "group-products",
    component: ComboPackOffer
  },
  {
    name:"Add Group Product",
    path: "add-group-product",
    component: AddComboPack
  },
  {
    name:"Edit Group Product",
    path: "edit-group-product/:id",
    component: EditComboPack,
  },
  {
    name:" Product Offers",
    path: "product-offers",
    component: ProductOffer
  },
  {
    name:"Add Product Offer",
    path: "add-product-offer",
    component:AddProductOffer
  },
  {
    name:"Edit Product Offer",
    path: "edit-product-offer/:id",
    component:EditProductOffer
  },
  {
    name:"Brand Offers",
    path: "brand-offers",
    component: BrandOffer
  },
  {
    name:"Add Brand Offer",
    path: "add-brand-offer",
    component: AddBrandOffer
  },
  {
    name:"Edit Brand Offer",
    path: "edit-brand-offer/:id",
    component: EditBrandOffer
  },
  {
    name:"Volume Offers",
    path: "volume-offers",
    component: VolumeOffer,
  },
  {
    name:"Add Volume Offer",
    path: "add-volume-offer",
    component: AddVolumeOffer
  },
  {
    name:"Edit Volume Offer",
    path: "edit-volume-offer/:id",
    component: EditVolume
  },
  {
    name:"Coupon Codes",
    path: "coupon-codes",
    component: CouponCodes,
  },
  {
    name:"Todays Deals",
    path: "todays-deals",
    component: TodaysDeal
  },
  {
    name:"Fantastic Deals",
    path: "fantastic-deals",
    component: FantasticDeal
  },
  {
    name:"Sliding Banners",
    path: "sliding-banners",
    component: SlidingBanner
  },
  {
    name:"Notifications",
    path:"notifications",
    component:Notifications
  },
  {
    name:"Send Notification",
    path:"send-notification",
    component:createNotification
  },
  {
    name:"Add Sliding Banner",
    path: "add-sliding-banner",
    component: AddSlidingBanner
  },
  {
    name:"Edit Sliding Banner",
    path: "edit-sliding-banner/:id",
    component: EditSlidingBanner
  },
  {
    name:"Still Banners",
    path: "still-banners",
    component: StillBanner
  },
  {
    name:"Add Still Banner",
    path: "add-still-banner",
    component: AddStillBanner
  },
  {
    name:"Edit Still Banner",
    path: "edit-still-banner/:id",
    component: EditStillBanner
  },
  {
    name:"My Account",
    path: "myaccount",
    component: MyAccount
  },
  {
    name:"Admin Products",
    path: "admin-products",
    component: AdminProducts
  },
  {
    name:"Admin Products",
    path: "add-products/:id",
    component: StoreProductForm
  },
  {
    path:"order-detail/:id",
    name:"Order Detail",
    component: OrderDetail
  },
  { redirect: true, path: "/", pathTo: "dashboard", name: "Dashboard" }
];

export default storeRoutes;

