import React, { Component } from 'react';
import { Table,Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button } from '../../../components';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import SimpleReactValidator from 'simple-react-validator';
import SweetAlert from 'sweetalert2-react';
import {addTags, getTags,editTags, onDismiss, delTags} from '../../../actions/tagActions';
class Tags extends Component {
    constructor(props) {
      super(props);
      this.validator = new SimpleReactValidator();
        
        this.state = {
            editModal:false,
            selectedTag:{},
            modal: false,
            isMounted: false,
            tagsLoading:false,
            tag: '',
            skip: 0,
            limit:12
        };
        this.toggle = this.toggle.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDismissPopup = this.onDismissPopup.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
      
      }



      handleChangeName(event) {
        let tag = event.target.value;
        this.setState({tag:tag});
      }

      componentDidMount() {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
        {this.props.onGetTags(this.state.skip,this.state.limit);}
      }
      });
      }

      toggle() {
        this.setState({
          modal: !this.state.modal
        });
      }

      toggle2 = ()=> {
        this.setState({
          modal: !this.state.modal,
          tag:''
        });
      }
      
      handleDelete(e,id) {
        e.preventDefault();
        this.props.onDeleteTags(id);
      }
      handleEdit(e,tag) {
        e.preventDefault();
        this.setState({ editModal: true,selectedTag:tag,tag:tag.tag});
      }

      handleEditsubmit = (e) => {
        e.preventDefault();
        if( this.validator.allValid() ){
          this.props.onEditTags(this.state.selectedTag.id,this.state.tag);
        }
        this.setState({ tag: ''});
      }

      onDismissPopup(){
        this.props.onDismiss();
        this.props.onGetTags(this.state.skip,this.state.limit);
      }

      getLoder() {
        let classes = "bt-loader ";
        classes += this.props.tagsLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }

      handleSubmit(event) {
        event.preventDefault();
        if( this.validator.allValid() ){
          this.props.onAddTags(this.state.tag);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }
      }
      //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
      componentWillReceiveProps(nextProps) {
        if (nextProps.status === true) {
          this.setState({ tag: ''});
        }
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetTags(skip,this.state.limit);
      };
    

    render() { 
      const {tags} = this.props;
        return ( 
            <div>
           
              <div className="content">
              <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>TAG</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    { tags.length > 0 ? tags.map((tag,index) => (
                        <tr key={tag.id}>
                        <td>{index+1 + this.state.skip }</td>
                        <td>{tag.tag}</td>
                        <td>
                          <div className="group-action text-right pr-10">
                            <button className="btn" onClick={(e)=>this.handleEdit(e,tag)}>
                              <i className="ico c1 icon-edit-1 " />
                            </button>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,tag.id))} >
                              <i className="ico c2 icon-trash-b"/>
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'3'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
         
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </div>
                <Button className="fixed-yellow" onClick={this.toggle2}>Add</Button>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                       nextLabel={<i className="fas fa-angle-right"></i>}
                       pageLinkClassName={"page-link"}
                       breakClassName={"break-me"}
                       pageCount={Math.ceil(this.props.count/this.state.limit)}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       onPageChange={this.handlePageClick}
                       containerClassName={"pagination"}
                       pageClassName={"page-item"}
                       activeClassName={"active"} />
               
                </div>
                {/* add */}

                <Modal isOpen={this.state.modal} toggle={this.toggle} className='common-modal' >
                <ModalHeader>Add Tag</ModalHeader>
                <form onSubmit={this.handleSubmit}>
                <ModalBody>
                   <div className="form-model">
                   <div className="form-group">
                    <label className="control-label">Tag Name</label>
                    <input value={this.state.tag} name="tag" onChange={this.handleChangeName} type="text"  className="form-control" placeholder="Type here"/>
                    <div className="errorMsg"> {this.validator.message('tag', this.state.tag, 'required')}</div>
                  </div>
               
                   </div>     
                </ModalBody>
                <ModalFooter>
                <Button className="btn-cancel" onClick={this.toggle}>Cancel</Button>
                <Button type="submit" className="btn-submit">Save</Button>{' '}
                </ModalFooter>
                </form>
                </Modal>

                {/* edit */}

               <Modal isOpen={this.state.editModal}  className='common-modal' >
                <ModalHeader>Edit Tag</ModalHeader>
                <form onSubmit={ this.handleEditsubmit }>
                <ModalBody>
                   <div className="form-model">
                   <div className="form-group">
                    <label className="control-label">Tag Name</label>
                    <input value={this.state.tag} name="tag" onChange={this.handleChangeName} type="text" className="form-control" placeholder="Type here"/>
                    <div className="errorMsg"> {this.validator.message('tag', this.state.tag, 'required')}</div>
                  </div>
               
                   </div>     
                </ModalBody>
                <ModalFooter>
                <Button className="btn-cancel" onClick={() => this.setState({editModal:false})}>Cancel</Button>
                <Button type="submit" className="btn-submit">Save</Button>{' '}
                </ModalFooter>
                </form>
                </Modal>
              </div>
            </div>
          );
    }
}
const mapStateToProps = (state) => ({
  loading: state.tagReducer.loading,
  status:state.tagReducer.status,
  tagsLoading:state.tagReducer.tagsLoading,
  tags: state.tagReducer.tags,
  message:state.tagReducer.message,
  count:state.tagReducer.count,
  popup:state.tagReducer.popup
})

const mapDispatchToProps = (dispatch)=> ({
  onAddTags:(tag)=>{
    dispatch(addTags(tag))
  },
  onGetTags:(skip,take)=>{
    dispatch(getTags(skip,take))
  },
  onDeleteTags:(tag_id)=>{
    dispatch(delTags(tag_id))
  },
  onEditTags:(tag_id,tag)=>{
    dispatch(editTags(tag,tag_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Tags)
