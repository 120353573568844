import React, { Component, useEffect, useState } from 'react';
import {Row, Col,Table, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import {ActionBtn, Button} from '../../../../components';
import Select from "react-select";
import moment from 'moment'
import {connect} from 'react-redux';
import {deleteOrderItem, editOrderItem, getOrder} from '../../../../actions/OrderActions';
import {getAllDeliveryBoys } from '../../../../actions/deliveryBoyAction';

import * as Constant from '../../../../constants/AppConstants';
import OrderSidebar from './OrderSidebar';
import SearchBarOrder from './Search';
import { 
  getDeliveryName, 
  getLoaderClass,
   getOrderStatus, 
   getPayMethod, 
   getPayStatus,
   getPayStatusClass 
  } from '../../../../utils';
import swal from '@sweetalert/with-react';

const OrderCard = ({
  delivery_boys,
  selectedBoy,
  order,
  onChangeDelivery,
  reflector,
  onDeleteOrderItem,
  onEditOrderItem
})=>{

  const [editModal, setEditModel] = useState(false)
  const [qty, setQty] = useState('')
  const [pid, setPid] = useState('')
  const [selling_price, setSellingPrice] = useState('')
  var boyOptions = []
  if(delivery_boys){
    boyOptions = delivery_boys.map((boy)=>{
      return {label: boy.name, value: boy.id}
    })
  }

  if(order === 'undefined' || order===null || !order.order_id){
    return <h4 style={{height:400, textAlign:'center'}}>Please select order</h4>
  } 

  if(reflector){}

  const onEditClick = (qty, sp, pid)=>{
    if(order){
      setEditModel(true)
      setQty(qty)
      setSellingPrice(sp)
      setPid(pid)
    }
  }

  const onDeleteClick = (product_id)=>{
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        onDeleteOrderItem({
          order_id: order.id,
          product_id
        })
      } 
    });
  }

  const updateItem = ()=>{
    if(qty && selling_price){
      onEditOrderItem({
        order_id: order.id,
        product_id: pid,
        count:qty,
        selling_price
      })
      setEditModel(false)
    }
    
  }

  const getDeliveryTime = (order)=>{
    if(order.delivery_mode == 2) {
      if(order.store && order.store.delivery_time){
        return order.store.delivery_time + " Minutes FAST DELIVERY"
      }
      return "Fast Delivery"
    }
    return moment(order.from, 'h:m a').format('h:m a') + " - " + moment(order.to, 'h:m a').format('h:m a')
  }

  const getPlacedTime = (order)=>{
    return moment(order.created_at, 'YYYY-MM-DD h:m a').format('DD-MM-YYYY h:m a')
  }

  return (
    <div>
      <div className="order-detail-wrapper">
        <div className="d-flex flex-row mb-3">
          <div className="p-2 bd-highlight">
            <h5>Payment Details</h5>
            <div className="price">{order.store.currency.symbol}&nbsp;<strong>{order.final_amount}</strong></div>
            <p>{getPayMethod(order.type)}</p>
          </div>
          <div className="p-2 bd-highlight">
            <h5>Delivery Details</h5>
            <p>{getDeliveryName(order.mode)}</p>
            <p><strong>Delivery Time:&nbsp;</strong>{getDeliveryTime(order)}</p>
            <p><strong>Placed Time:&nbsp;</strong>{getPlacedTime(order)}</p>
            <p>Store: <span className="t-yellow">{order.store.title}</span></p>
          </div>
          <div className="p-2 bd-highlight">
            <h5>Status</h5>
            <div className="status">
              <p>Fullfillment Status <span className="st-block s-red">{getOrderStatus(order.status)}</span></p>
              <p>Payment Status <span className={getPayStatusClass(order.payment_mode)}>
                  {getPayStatus(order.payment_status)}</span></p>
            </div>
          </div>
        </div>
      <div className="d-flex flex-row mb-3">
        <div className="p-2 bd-highlight">
          <h5>Customer Details</h5>
          <div className="address">
            <p>{(order && order.customer)? order.customer.first_name:null}&nbsp;
                {(order && order.customer)? order.customer.second_name:null}</p>
            <p>{(order && order.customer)? order.customer.mobile:null}</p>
            <p>{(order && order.customer && order.customer.point && order.customer.point.card_number)? order.customer.point.card_number:null}</p>
            <p><span className="t-yellow">Completed orders: {(order && order.customer)? order.customer.total_orders:0}</span></p>
          </div>
        </div>
        <div className="p-2 bd-highlight">
          <h5>Delivery Address</h5>
          <div className="address">
          <p>{ order.delivery_address}</p>
          </div>
        </div>
        {(order.status > 1 && order.status<5 && order.delivery_mode != 3)  && <div className="p-2 bd-highlight">
          <h5>Delivery boy</h5>
          <div className="status-changer">
            <Select onChange ={onChangeDelivery} value={selectedBoy} options={boyOptions}  />
            {!selectedBoy && <div className="errorMsg">Choose a delivery boy</div>}
          </div>
        </div>
        }
      </div>
    </div>
    <div className="table-product-wrapper">
      <Table>
          <thead>
            <tr><th></th>
                <th>Item</th>
                <th>Weight</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Total</th>
                <p>Actions</p>
              </tr>
          </thead>
          <tbody>
            {(order&& order.items)?order.items.map((item,index)=>(
              <tr key={index}>
              <td className="nameImg">
                <img src={item.product.featured_image} alt="wild" className="img-ico" />
              </td>
              <td>
                <div className="name">{item.product.title}</div>
                <div className="sku">{item.product.sku}</div>
              </td>
              <td>{item.product.weight}</td>
              <td>{item.selling_price}</td>
              <td>{item.qty}</td>
              <td>{(item.selling_price)*(item.qty)}</td>
              <td>
                <ActionBtn 
                    download={false} 
                    edit={true} 
                    view={false}
                    editClick={()=>onEditClick(item.qty, item.selling_price, item.product_id)}
                    deleteClick={()=>onDeleteClick(item.product_id)}
                    delete={true}/>
              </td>
            </tr>
            )):<tr><td colSpan={'4'}>No record found</td></tr>}
          </tbody>
          <tfoot>
            <tr>
                <td colSpan={'5'} className="text-right">
                  <p>Sub Total</p>
                  <p>Offer Discount</p>
                  <p>Delivery Charge</p>
                  <p>Voucher redeemed</p>
                  <p className="total">Grand Total</p>
                </td>
                <td className="price">
                  <p>{order.total}</p>
                  <p>{order.discount}</p>
                  <p>{order.shipping_charge}</p>
                  <p>{order.voucher_redeemed}</p>
                  <p className="total">{order.final_amount}</p>
                </td>
            </tr>
          </tfoot>
        </Table> 
      </div>
      <Modal isOpen={editModal}  className='common-modal' >
      <ModalHeader>Edit Item</ModalHeader>
      <ModalBody>
          <div className="form-model">
          <div className="form-group">
          <label className="control-label">Quantity</label>
          <input value={qty} name="qty" onChange={(e)=>{setQty(e.target.value)}} type="text" className="form-control" placeholder="Type here"/>
          <label className="control-label">Selling Price</label>
          <input value={selling_price} name="selling_price" onChange={(e)=>{setSellingPrice(e.target.value)}} type="text" className="form-control" placeholder="Type here"/>
        </div>
          </div>     
      </ModalBody>
      <ModalFooter>
      <Button className="btn-cancel" onClick={()=> setEditModel(false)}>Cancel</Button>
      <Button type="submit" className="btn-submit" onClick={()=>updateItem()}>Save</Button>
      </ModalFooter>
      </Modal>
    </div>
  )
}

function OrderPage({
  loading,
  onSearch,
  onChangeDate,
  date,
  search,
  onFilter,
  orders,
  orderLoading,
  handleStatus,
  deliveryBoys,
  ...props
}){

  const [ selected, setSelected ] = useState(0)
  const [ order, setOrder ] = useState(null)
  const [ reflector, setReflector ] = useState(null)
  const [ initial, setInitial ] = useState(true)
  const [ selectedBoy, setSelectedBoy ] = useState(null)

  useEffect(()=>{
    if(initial){
      setInitial(false)
      props.onGetDeliveryBoys()
    }
    
  })

  const handleClick = (id, order)=>{
    setOrder(order)
    setReflector(!reflector)
  }

  const buttonName = (mode)=> {
    switch(mode){
      case Constant.ORDER_CREATED :
          return 'MARK AS PROCESSING';
      case Constant.ORDER_ACCEPTED:
          return 'MARK AS SHIPPING' ;
      case Constant.ORDER_SHIPPING :
          return 'MARK AS DELIVERD';  
      default :
          return null;          
    }
  }

  return ( 
    <React.Fragment>
      <div className={getLoaderClass(loading)}></div> 
        <SearchBarOrder 
          onChange={onSearch} 
          onChangeDate={onChangeDate} 
          date={date} 
          search={search} 
          onFilter={onFilter}/>
        <div className="full-order-wrapper">
          <Row>    
            <OrderSidebar 
              orders={orders} 
              handleClick={handleClick} 
              selected={order ? order.id:0}/>
            <Col sm={'9'} className="bg-white-wrapper">
              {orderLoading? <div></div>: 
              <OrderCard
                selectedBoy={selectedBoy}
                order={order}
                delivery_boys={deliveryBoys}
                reflector={reflector}
                onDeleteOrderItem={props.onDeleteOrderItem}
                onEditOrderItem={props.onEditOrderItem}
                onChangeDelivery={setSelectedBoy}/>}   
            </Col>
          </Row>
          <div className="footer-buttons-fixed d-flex justify-content-between">
          <dir></dir>
          <div className="group-btn">

            {order && <Button className="btn-submit b-green" 
            onClick = {()=>handleStatus(order.status,order.id, order.delivery_mode, selectedBoy?selectedBoy.value:null)}>
                {buttonName(order.status)}</Button>}
          </div>
          </div>
        </div>
    </React.Fragment>
   );
}

OrderPage.defaultProps = {
  new_orders:[],
  order:{}
}
const mapStateToProps = (state)=>({
  loading:state.orderReducer.loading,
  deliveryBoys:state.deliveryBoyReducer.delivery_boys,
  orderLoading:state.orderReducer.orderLoading,
  order:state.orderReducer.order,
  orders:state.orderReducer.all_orders,
  loading: state.orderReducer.loading,
})

const mapDispatchToProps = (dispatch)=>({
    onGetOrder:(order_id)=>{
      dispatch(getOrder(order_id))
    },
    onGetDeliveryBoys:()=>{
      dispatch(getAllDeliveryBoys())
    },
    onEditOrderItem:(data)=>{
      dispatch(editOrderItem(data))
    },
    onDeleteOrderItem:(data)=>{
      dispatch(deleteOrderItem(data))
    }
    
})
 
export default connect(mapStateToProps,mapDispatchToProps)(OrderPage);