import React, { Component } from 'react';
import {Row,Col,Table} from 'reactstrap';
import {getDeliveryBoy} from '../../../../actions/deliveryBoyAction';
import { ActionBtn,Button } from '../../../../components';
import {connect} from 'react-redux';
class DeliveryBoy extends Component {
  _id = 0;
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    var urls = this.props.match.url.split('/');
    this._id = urls[urls.length - 1]
    this.props.onGetBoy(this._id);
  }
  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }

  renderField = (field,title) => {
    if(!field) return null
    return(
      <div className="p-2 flex-fill">
      <div className="small-name">{title}</div>
      <div className="large-name">{field}</div>
  </div>
    )
  }
     
  render() { 
    const {delivery_boy} = this.props;
    return ( 
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
        <div className="user-detils">
          <Row>
            <Col md={'12'}>
              <div className="detail-flex-wrapper">
                <div className="d-flex">
                {this.renderField(delivery_boy.name, "Name")}
                {this.renderField(delivery_boy.mobile, "Mobile")}
                {this.renderField(delivery_boy.email, "Email")}
                {this.renderField(delivery_boy.unique_number, "Unique Id")}
                <div className="p-2 flex-fill">
                    <div className="small-name">Pending Task</div>
                    <div className="large-name">nil</div>
                </div>
                <div className="p-2 flex-fill">
                    <div className="small-name">Done task</div>
                    <div className="large-name">Nil</div>
                </div>
                <div className="p-2 flex-fill">
                    <div className="small-name">Cash on Hand</div>
                    <div className="large-name">0 <Button className="text-btn">COLLECT</Button></div>
                </div>
               
                </div>
              </div>
            </Col>
          </Row>
          <div className="table-product-wrapper table-orders">
          <div className="title">
            <h5>Orders</h5>
          </div>
              <Table>
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Order ID</th>
                    <th>Date</th>
                    <th>Customer Name</th>
                    <th>Payment Status </th>
                    <th>Fullfilment Status</th>
                    <th>Amount </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>00000011</td>
                    <td>Dec 12, 2018. 16:13</td>
                    <td>
                      <p>Riyas</p>
                      <p className="small">9847658680</p>
                    </td>
                    <td>Awaiting</td>
                    <td>New</td>
                    <td>280 | 4 items</td>
                    <td>
                    <ActionBtn/>
                    </td>
                  </tr>
                </tbody>
              </Table>
          </div>
        </div>
      
        </div>
      </div>
     );
  }
}

DeliveryBoy.defaultProps = {
  delivery_boy:{}
}

const mapStateToProps = (state) => ({
  loading:state.deliveryBoyReducer.loading,
  delivery_boy:state.deliveryBoyReducer.delivery_boy
})

const mapDispatchToProps = (dispatch) =>({
  onGetBoy: (id) => {
    dispatch(getDeliveryBoy(id))
  }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(DeliveryBoy);