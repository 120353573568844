import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addDeliverySlot(data) {
    return request({
      url:   URLS.ADD_SLOTS,
      method: 'POST',
      data:data
    });
}

function deleteDeliverySlot(slot_id) {
  var sub_url = '/'+slot_id;
  return request({
    url:URLS.DELETE_SLOTS + sub_url,
    method:'GET'
  })
}

function getAllDeliverySlots(skip,take, store_id) {
  var sub_url = '';
  if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
  if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
  if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
  return request({
    url:URLS.GET_ALL_SLOTS + sub_url,
    method:'GET'
  })
}

function getDeliverySlot(slot_id) {
  var sub_url = '/'+slot_id;
  return request({
    url:URLS.GET_SLOT + sub_url,
    method:'GET'
  })
}

function editDeliverySlot(data){
    return request({
      url:URLS.EDIT_SLOT,
      method:'POST',
      data:data
    })
}

const deliverySlotService = {
  addDeliverySlot,
  getAllDeliverySlots,
  deleteDeliverySlot,
  editDeliverySlot,
  getDeliverySlot
}

export default deliverySlotService;