import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { connect } from "react-redux";
import { getAllProducts } from "../../../actions/productActions";
import { addProductOffer, onDismiss } from "../../../actions/OfferActions";
import SweetAlert from "sweetalert2-react";
import SimpleReactValidator from 'simple-react-validator';
import StoreProductSelect from "../Product/StoreProductSelect";
class AddProductOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      isActive: false,
      isMounted: false,
      brands: [],
      store_id: "",
      valid_from: moment(),
      valid_upto: moment(),
      disc_perc: "",
      disc_amount:'',
      type:1,
      title: "",
      week: [],
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }
  onDismissPopup() {
    this.props.onDismiss();
  }
  handleChangeFrom(date) {
    this.setState({
      valid_from: date
    });
  }
  handleChangeTo(date) {
    this.setState({
      valid_upto: date
    });
  }
  handleCheck = week => {
    this.setState({ week });
  };
  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        {
          
        }
      }
    });

    setTimeout(()=>{
      this.props.onGetProducts(this.props.store.id);
    },100)
  }
  handleProduct = selectedProducts => {
    this.setState({ selectedProducts});
  };

  handleLimit = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleSubmit = e => {
    e.preventDefault();
    var data = {
      store_id:  this.props.store.id,
      product_ids: this.state.selectedProducts.map((item)=>{
        return item.value
      }),
      title: this.state.title
    };
    if(parseFloat(this.state.type) === 1) {
      data['disc_perc']=parseFloat(this.state.disc_perc);
    }
    if(parseFloat(this.state.type) === 2){
      data['disc_amount']=parseFloat(this.state.disc_perc);
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data["valid_from"] = from.format("YYYY-MM-DD");
    data["valid_upto"] = upto.format("YYYY-MM-DD");
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
   data['week_days']= days_array;
    if( this.validator.allValid() ){

     this.props.onAddProductOffer(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.status === true) {
      this.setState({
        selectedProducts: [],
        valid_from: moment(),
        valid_upto: moment(),
        disc_perc: "",
        disc_amount:"",
        title: "",
        week: []
      });
    }
}
     //loader
     getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }
  render() {
    const{selectedProducts} = this.state;
    let productOptions = this.props.all_products.map(function(product) {
      return { value: product.id, label: product.title};
    });
    
    return (
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
          <div className="form-section">
          <form onSubmit={this.handleSubmit}>
            <div className="form-filds-wrapper">
              <Row>
                <Col md="8">
                  <div className="form-group">
                    <label className="control-label">Offer Name</label>
                    <input
                      type="text"
                      value={this.state.title}
                      onChange={e => this.setState({ title: e.target.value })}
                      className="form-control"
                      placeholder="Name"
                    />
                    <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                  </div>
                </Col>
                </Row>
                <Row>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Product</label>
                    <StoreProductSelect
                    onChange={this.handleProduct}
                    value={selectedProducts}
                    isMulti
                    />
                    
                   <div className="errorMsg">{this.validator.message('product_id', selectedProducts.length, 'required')}</div>
                  </div>
                </Col>
                <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Value</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="type" value={this.state.type}  onChange={(e)=>{this.setState({type: e.target.value })}}>
                        <option value={1}>%</option>
                        <option value={2}>INR</option>
                        </Input>
                        </InputGroupAddon>
                        <Input value={this.state.disc_perc} type={'number'} onChange={(e)=>this.setState({disc_perc:e.target.value})} />
                        <div className="errorMsg">{this.validator.message('discount', this.state.disc_perc, 'required')}</div>
                        </InputGroup>
                      </div>
                    </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Valid From</label>
                    <DatePicker
                      className="form-control"
                      selected={this.state.valid_from}
                      onChange={this.handleChangeFrom}
                      dateFormat="YYYY-MM-DD"
                      name="valid_from"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Valid To</label>
                    <DatePicker
                      className="form-control"
                      selected={this.state.valid_upto}
                      onChange={this.handleChangeTo}
                      dateFormat="YYYY-MM-DD"
                      name="valid_upto"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <div className="check-wrapper">
                      <Checkbox
                        label="Limit Days"
                        checked={this.state.isActive}
                        onChange={e => {
                          this.handleLimit(e);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="submit-wrapper">
                    <Button className="btn-submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </div>
            <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
AddProductOffer.defaultProps ={
  all_products:[]
}


const mapStateToProps = state => ({
  loading: state.productOfferReducer.loading,
  status: state.productOfferReducer.status,
  all_products: state.productReducer.all_products,
  message: state.productOfferReducer.message,
  popup: state.productOfferReducer.popup,
  store:state.storeUserReducer.store
});

const mapDispatchToProps = dispatch => ({
  onAddProductOffer: data => {
    dispatch(addProductOffer(data));
  },
  onGetProducts: (store_id) => {
    dispatch(getAllProducts(store_id));
  },
  onDismiss: () => {
    dispatch(onDismiss());
  }
});
export default connect(mapStateToProps,mapDispatchToProps)(AddProductOffer);
