import React, { forwardRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {useSelector} from 'react-redux';
import Select from "react-select";
import {
    Row,
    Col,
    FormGroup,
    Table,
    Input
} from "reactstrap";
import { Button } from '../../../../components';
import {getAttributes} from '../../../../actions/attributeActions';
import { showError } from '../../../../utils';

function GroupAttribute({disabled, ...props}, ref){

    const attributes = useSelector(state => state.attributeReducer.attributes)
    const [ selectedGroups, setSelectedGroups ] = useState([])
    const [ reflector, setReflector ] = useState(true)
    const validator = new SimpleReactValidator()

    let attributeOptions = attributes?attributes.map(function (attr,i) {
        const items = attr.items.map((it)=>{
            return {title: it.attribute,value:''}
        })
        return { value: attr.id, label: attr.name, index: i, attribute_id:attr.id, items:items};
    }):[]

    React.useImperativeHandle(ref, ()=>({
        onValidate(){
          return isValid()
        },
        onSetSelected(items){
            setSelectedGroups(items)
        }
    }))

    const isValid = () => {
        if( validator.allValid() ){
            return selectedGroups;
          }
          else {
            validator.showMessages();
            setReflector(!reflector)
          }
          return null
    }

    const changeText = (e, i, i2) => {
        let selectedItems = selectedGroups;
        var obj = {};
        if (selectedItems.length>i){
            obj = selectedItems[i].items[i2];
        }
        obj[e.target.name] = e.target.value;
        setSelectedGroups(selectedItems)
        setReflector(!reflector)
    }

    const addText = (index) => {
        let selectedItems = selectedGroups;
        var obj = {title:"",value:''}
        selectedItems[index].items.push(obj)
        setSelectedGroups(selectedItems)
        setReflector(!reflector)
    }

    const deleteText = (index,i) => {
        let selectedItems = selectedGroups;
        var items = selectedItems[index].items;
        items.splice(i,1);
        setSelectedGroups(selectedItems)
        setReflector(!reflector)
    }

    const addMore = ()=>{
        var array = selectedGroups;
        array.push({});
        setSelectedGroups(array)
        setReflector(!reflector)
    }
     
    const handleChange = (index,  selected)=>{
       var array = selectedGroups;
       array[index] = selected;
       setSelectedGroups(array)
       setReflector(!reflector)
    }
 
    const removeAttribute = (index)=>{
       selectedGroups.splice(index, 1);
       setSelectedGroups(selectedGroups)
       setReflector(!reflector)
    }

    return ( 
        <div className="attribute-wrapper" style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
        {selectedGroups.map((item,index)=>(
          <div className="form-filds-wrapper" key={index}>
            <Row>
              <Col md="4">
                <FormGroup>
                    <Select  
                        options={attributeOptions} 
                        onChange ={(selected)=>handleChange(index,selected)} 
                        value={item}/>
                    {validator.message('select', item.value, 'required')}
                    {showError('select', validator.errorMessages)}
                </FormGroup>
              </Col>
              <Col md="4">
                <Button 
                    className="btn-tsp" 
                    leftLabel="icon-delete fa red" 
                    onClick={()=>removeAttribute(index)}></Button>
              </Col>
              {typeof item !== 'undefined' ? 
              <Col md="8">
                <div className="inner-table-wrapper">
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeof item.items !== 'undefined' && item.items.map((item,i)=>{
                                return (
                                    <tr key={i}>
                                        <td >
                                            <Input 
                                                type="text" 
                                                name="title" 
                                                value={item.title ? item.title : ''} 
                                                onChange={(e)=>changeText(e, index,i)} 
                                                placeholder="Attribute name" />
                                            <div className="errorMsg"> 
                                                {validator.message('title', item.title, 'required')}</div>
                                                {showError('title', validator.errorMessages)}
                                        </td>
                                        <td >
                                            <Input 
                                                type="text" 
                                                name="value" 
                                                value={item.value ? item.value : ''} 
                                                onChange={(e)=>changeText(e,index,i)} 
                                                placeholder="Attribute value"/>
                                            <div className="errorMsg"> 
                                                {validator.message('value', item.value, 'required')}</div>
                                                {showError('value', validator.errorMessages)}
                                        </td>
                                        <td>
                                            <Button className="btn-tsp" 
                                            onClick={(e)=>deleteText(index,i) } 
                                            leftLabel="fa red fa-times"></Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Button className="btn-plus" 
                        onClick = {()=>addText(index)} 
                        leftLabel="fa fa-plus">ADD ATTRIBUTE</Button>
                </div>
              </Col>
              :null}
            </Row>
        </div> 
        ))}
        <div className="add-btn-wraper">
            <Button className="btn-simple" 
                leftLabel="fa fa-plus" 
                onClick={addMore}> Add Attributes</Button>
        </div> 
      </div>  
     );
}
 
export default forwardRef(GroupAttribute);