import React, { Component } from 'react';
import {Table} from 'reactstrap';
import {Link} from  'react-router-dom';
import { Button } from '../../../components';
import {connect} from 'react-redux';
import {getVariants} from '../../../actions/variantActions';
import ReactPaginate from 'react-paginate';
class Variants extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      attrLoading:false,
      isMounted: false,
      skip: 0,
      limit:12
     }
  }


     componentDidMount = () => {
      this.setState({ isMounted: true }, () => {
        if (this.state.isMounted) {
          this.setState({ isMounted: false });
      {this.props.onGetAttributes(this.state.skip,this.state.limit);}
    }
    });
     }

     handlePageClick = (data) => {
      let selected = data.selected;
      let skip = selected * this.state.limit
      this.setState({skip: skip});
      this.props.onGetAttributes(skip,this.state.limit);
    }
    
     getLoder = () => {
      let classes = "bt-loader ";
      classes += this.props.attrLoading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    render() { 
      const {attributes} = this.props;
        return (
        <div>
            <div className="content">
            <div className={this.getLoder()}></div>
            <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>TYPE</th>
                        <th>CODE</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {attributes.length>0 ? attributes.map((attribute,index) => (
                        <tr key={attribute.id}>
                        <td>{index+1 + this.state.skip}</td>
                        <td>{attribute.attribute}</td>
                        <td>{attribute.type === 1 ? 'Text' : 'Color'}</td>
                        <td>{attribute.code}</td>
                        <td>
                          <div className="group-action text-right pr-10">
                            <Link className="btn" to={`/admin/edit-variant/${attribute.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            {/* <button className="btn">
                              <i className="ico icon-delete" />
                            </button> */}
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'4'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
                </div>
                <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-variant")}>Add</Button>
                <div className="pagination-wrapper">
        <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
        nextLabel={<i className="fas fa-angle-right"></i>}
        pageLinkClassName={"page-link"}
        breakClassName={"break-me"}
        pageCount={Math.ceil(this.props.count/this.state.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        activeClassName={"active"} />
        </div>
           </div>    
        </div>  );
    }
}


Variants.defaultProps = {
  attributes : []
}
 
const mapStateToProps = (state) =>({
  attrLoading:state.variantReducer.attrLoading,
  attributes: state.variantReducer.variants,
  message:state.variantReducer.message,
  count:state.variantReducer.count,
  popup:state.variantReducer.popup
});

const mapDispatchToProps = (dispatch) =>({
  onGetAttributes:(skip,take)=>{
    dispatch(getVariants(skip,take))
  }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(Variants);