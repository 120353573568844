import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addBrand(data) {
    return request({
      url:   URLS.ADD_BRAND,
      method: 'POST',
      data:data
    });
}

function editBrand(data) {
    return request({
      url:   URLS.EDIT_BRAND,
      method: 'POST',
      data:data
    });
}
function getBrand(brand_id) {
    var sub_url = '/'+brand_id;
    return request({
      url:   URLS.GET_BRAND + sub_url,
      method: 'GET'
    });
}

function getBrands(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_BRANDS + sub_url,
      method: 'GET'
    });
}

function getStoreBrands(store_id,skip,take) {
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_STORE_BRANDS + sub_url,
      method: 'GET'
    });
}


const BrandService = {
    addBrand,
    editBrand,
    getBrands,
    getBrand,
    getStoreBrands
}

export default BrandService;

