import React,{ useEffect, useRef, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

import {Button} from '../../../components';
import {getLeafCategories} from '../../../actions/categoryActions';
import {getBrands} from '../../../actions/brandActions';
import {getUnits} from '../../../actions/unitActions';
import {getVariants,onDismiss} from '../../../actions/variantActions'
import {addItem, addNewProducts, editItem, editStoreItem, getItem, getStoreItem, getStoreUnimportedItem} from '../../../actions/itemActions'
import {getAttributes} from '../../../actions/attributeActions';
import { getLoaderClass } from '../../../utils';
import HasVariantForm from '../../SuperAdmin/Products/components/HasVariantForm';
import { packStatus } from '../../../actions/OfferActions';


function StoreProductForm({
    loading,
    match,
    item,
    store_item,
    loadingImport,
    onChangeStatus,
    ...props
}){

    const store = useSelector(state=> state.storeUserReducer.store)
    const history = useHistory()
    const [ initial, setInitial ] = useState(true)
    const [ hasVariant, setHasVariant ] = useState(true)
    const [ activeTab, setActiveTab ] = useState(0)
    const [ groupInfo, setGroupInfo ] = useState({})
    const [ attributes, setAttributes ] = useState([])
    const [ variants, setVariants ] = useState([])
    const [ items, setItems ] = useState([])
    const [ edit, setEdit ] = useState(false)
    const variantRef = useRef()
    const noVariantRef = useRef()
    const loadRef = useRef()
    const loadImportRef = useRef()
    const currentItemRef = useRef()
    const params = match.params
    
    useEffect(()=>{
        if(initial){
            onLoad()
            setInitial(false)
            if(params && params.id && store && store.id){
              props.onGetStoreUnimportedItem(store.id, params.id);
            }
            if(params && params.item_id && store && store.id){
              setEdit(true)
              props.onGetStoreItems(params.item_id, store.id)
            }
        }
        if(loadRef && loadRef.current && !loading){
          if(props.itemPopup){
            if(props.itemStatus){
              swal("Successfully", "Product has been saved successfully", "success");
              if(!edit)reset()
            }else{
              swal("Failed", "Product saving failed", "error");
            }
            props.onDismiss()
          }
        }
        if(loadImportRef && loadImportRef.current && !loadingImport){
          if(props.popup){
            if(props.status){
              swal("Successfully", "Product has been saved successfully", "success");
              if(!edit){
                reset()
                history.goBack()
              }
            }else{
              swal("Failed", "Product saving failed", "error");
            }
            props.onDismiss()
          }
        }

        if( currentItemRef && (!currentItemRef.current || !currentItemRef.current.id ) && item && item.id && item.id == params.id){
          if(variantRef && variantRef.current)variantRef.current.setEditFormValues(item)
          currentItemRef.current = item
        }
        if(edit && currentItemRef && (!currentItemRef.current || !currentItemRef.current.id) 
        && store_item && store_item.id && store_item.id == params.item_id){
          if(variantRef && variantRef.current)variantRef.current.setEditFormValues(store_item)
          currentItemRef.current = store_item
        }
        loadRef.current = loading
        loadImportRef.current = loadingImport 
    })

    const reset = ()=>{
      if(variantRef && variantRef.current)variantRef.current.reset()
    }

    const onRefresh = ()=>{
        onLoad()
    }

    const onSave = (tab=null)=>{
      if(hasVariant){
        if(variantRef && variantRef.current){
          const result =  variantRef.current.onValidate()
          var newTab = activeTab + 1
          if(result){
            if(tab != null && tab < activeTab){
              newTab = tab
            }
            if(activeTab == 0){
              setGroupInfo(result)
              setActiveTab(newTab)
            }
            // if(activeTab == 1){
            //   setAttributes(result)
            //   setActiveTab(newTab)
            // }
            if(activeTab == 1){
              setVariants(result)
              setActiveTab(newTab)
            }
            if(activeTab == 2){
              setItems(result)
              if(newTab > 1 ){
                onSaveData(groupInfo, result)
              }else{
                setActiveTab(newTab)
              }
            }
          }else{
            if(tab < activeTab){
              setActiveTab(tab)
            }
          }
        }
      }else{
        const result =  noVariantRef.current.onValidate()
        if(result){
          setGroupInfo(result)
          onSaveData(result)
        }
      }
    }

    const onSaveData = (d, items=null)=>{
      const data = prepareData(d, items)
      if(edit){
        props.onEditNewProducts(data)
      }else{
        props.onAddNewProducts(data)
      }
    }

    const prepareData = (data, items)=>{
      data['item_id'] = edit?params.item_id:params.id

      var v = items?items.map((item)=>{
         const images = item.images.map((imag)=>{
           return {image:imag.url}
         }).filter((e)=> e.image)
         item['images'] = images;
         if(item.weight) item['weight'] = item.weight.value
         if(item.weight_unit) item['weight_unit'] = item.weight_unit.value
         return item
      }):[]

      data['variants'] = v;
      data['store_id'] = store.id;
      return data
    }

    const onLoad = ()=>{
        props.onGetLeafCategories();
        props.onGetBrands();
        props.onGetUnits();
        props.onGetAttributes();
        props.onGetVariants();
    }

    return ( 
        <div>
            <div className="content">
                <div className={getLoaderClass(loading || loadingImport)}></div>
                <div className="form-section">  
                    <div className="sub-wrapper">
                        <Button className="btn-submit" onClick={onSave}>Save</Button>
                    </div>
                    <HasVariantForm  
                        activeTab={activeTab} 
                        onChangeTab={onSave} 
                        selectedAttributes={variants}
                        disabled={true}
                        type="StoreProduct"
                        onChangeStatus={onChangeStatus}
                        ref={variantRef}/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) =>({
  leafCategories : state.categoryReducer.leafCategories,
  brands : state.brandReducer.brands,
  loading: state.itemReducer.loading,
  loadingImport: state.importReducer.loading,
  status:state.importReducer.status,
  itemStatus:state.itemReducer.status,
  message:state.importReducer.message,
  itemMessage:state.itemReducer.message,
  popup:state.importReducer.popup,
  itemPopup:state.itemReducer.popup,
  store_item: state.itemReducer.store_item,
  item: state.importReducer.item,
  store:state.storeUserReducer.store,
  
})

const mapDispatchToProps = (dispatch) =>({
  onGetLeafCategories : (skip,take)=>{
    dispatch(getLeafCategories(skip,take))
  },
  onGetBrands:(skip,take)=>{
    dispatch(getBrands(skip,take))
  },
  onGetUnits:(skip,take)=>{
    dispatch(getUnits(skip,take))
  },
  onGetVariants : (skip,take)=>{
    dispatch(getVariants(skip,take))
  },
  onEditNewProducts : (data)=>{
    dispatch(editStoreItem(data))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  },
  onGetAttributes:(skip,take)=>{
    dispatch(getAttributes(skip,take))
  },
  onAddNewProducts : (data)=>{
    dispatch(addNewProducts(data))
  },
  onGetStoreItems:(id, store_id) => {
    dispatch(getStoreItem(id, store_id))
  },
  onGetStoreUnimportedItem:(store_id,item_id)=>{
    dispatch(getStoreUnimportedItem(store_id,item_id))
  },
  onChangeStatus : (id, status)=>{
    dispatch(packStatus(id, status))
  }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(StoreProductForm);