import * as Actions from '../constants/Actions';
import StoreService from '../services/StoreService';


function addStore(data){
    return dispatch =>{
        dispatch(request());
        StoreService.addStore(data)
        .then(res =>{
            var stores = res.data;
            dispatch(success(stores))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_STORE_LOADING} }
    function success(stores) { return { type: Actions.ADD_STORE,stores:stores} }
    function failed(error) { return { type: Actions.ADD_STORE_FAILED, error:error} }
}

function getStores(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        StoreService.getStores(skip,take)
        .then(res =>{
            var stores = res.data;
            var count = res.count;
            dispatch(success(stores,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORES_LOADING} }
    function success(stores,count) { return { type: Actions.GET_STORES, stores: stores,count:count} }
    function failed(error) { return { type: Actions.GET_STORES_FAILED, error:error} }
}

//get store

function getStore(store_id){
    return dispatch =>{
        dispatch(request());
        StoreService.getStore(store_id)
        .then(res =>{
            var store = res.data;
            dispatch(success(store))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_LOADING} }
    function success(store) { return { type: Actions.GET_STORE, store: store} }
    function failed(error) { return { type: Actions.GET_STORE_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}

function editStore(data){
    return dispatch =>{
        dispatch(request());
        StoreService.editStore(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_STORE_LOADING} }
    function success(data) { return { type: Actions.EDIT_STORE,data:data} }
    function failed(error) { return { type: Actions.EDIT_STORE_FAILED, error:error} }
}

//store status
function storeStatus(store_id,status){
    return dispatch =>{
        dispatch(request());
        StoreService.storeStatus(store_id,status)
        .then(res =>{
            var store = res.store;
            dispatch(success(store))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.STORE_STATUS_LOADING} }
    function success(store) { return { type: Actions.STORE_STATUS, store: store} }
    function failed(error) { return { type: Actions.STORE_STATUS_FAILED, error:error} }
}


export {
    addStore,
    getStores,
    getStore,
    editStore,
    storeStatus,
    onDismiss
} 