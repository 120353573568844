import React,{Component} from 'react';
import {Row,Col, Input} from "reactstrap";
import { Button } from '../../../components';
import { connect } from 'react-redux';
import {addBrand,onDismiss} from '../../../actions/brandActions';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class AddBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image:null,
      name:'',
      code:'',
      note:''
    }
    this.validator = new SimpleReactValidator();
  }
        _onImageChange = (image) => {
            this.setState({image});
        }
        onDismissPopup = () =>{
          this.props.onDismiss();
        }

        handleSubmit = (e) => {
          e.preventDefault();
          var data = {
            name: this.state.name,
            code: this.state.code,
            note:this.state.note,
            logo: this.state.image
          };
          if( this.validator.allValid() ){
            this.props.onAddBrand(data);
          }    
          else {
            this.validator.showMessages();
            this.forceUpdate();
          }      
        }
        componentWillReceiveProps(nextProps) {
          if (nextProps.status === true) {
            this.setState({
              name:'',
              code:'',
              note:'',
              image:null
             });
          }
      }
        //loader
        getLoder() {
          let classes = "bt-loader ";
          classes += this.props.loading ? 'bt-show' : 'bt-hide';
          return classes;
        }
    render() { 
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
              <div className="form-filds-wrapper">
                  <Row>
                    <Col md="8">
                      <div className="form-group">
                        <div className="flex-wrapper">
                          <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Brand Image</FeaturedImage>
                        </div>
                        <div className="errorMsg">{this.validator.message('image', this.state.image, 'required')}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Brand Name</label>
                        <input type="text" onChange={(e)=>this.setState({name:e.target.value})} value={this.state.name} className="form-control" />
                        <div className="errorMsg">{this.validator.message('name', this.state.name, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Brand Code</label>
                        <input type="text" onChange={(e)=>this.setState({code:e.target.value})} value={this.state.code} className="form-control" />
                        <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="8">
                    <div className="form-group">
                        <label className="control-label">Notes</label>
                        <Input type="textarea" onChange={(e)=>this.setState({note:e.target.value})} value={this.state.note} name="text" placeholder="Type Here ..." />
                      </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                </form>  
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
          </div>
        </div>
         );
    }
}
 

const mapStateToProps = (state) => ({
  loading: state.brandReducer.loading,
  status:state.brandReducer.status,
  message:state.brandReducer.message,
  popup:state.brandReducer.popup
})

const mapDispatchToProps = (dispatch)=> ({
  onAddBrand:(data)=>{
    dispatch(addBrand(data))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddBrand)