import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function adminSendOtp(data) {
    return request({
      url:   URLS.ADMIN_SEND_RESET_OTP,
      method: 'POST',
      data:data
    });
}

function adminVerifyOtp(otp,admin_id) {
    var sub_url = '';
    if(otp !== null && typeof otp !== 'undefined') sub_url = sub_url+'/'+otp;
    if(admin_id !== null && typeof admin_id !== 'undefined') sub_url = sub_url+'/'+admin_id;
    return request({
      url:   URLS.ADMIN_VERIFY_RESET_OTP + sub_url,
      method: 'GET'
    });
}

function adminResetPass(data) {
    return request({
      url:   URLS.ADMIN_RESET_PASS,
      method: 'POST',
      data:data
    });
}

function storeSendOtp(data) {
    return request({
      url:   URLS.STORE_SEND_RESET_OTP,
      method: 'POST',
      data:data
    });
}

function storeVerifyOtp(otp,user_id) {
    var sub_url = '';
    if(otp !== null && typeof otp !== 'undefined') sub_url = sub_url+'/'+otp;
    if(user_id !== null && typeof user_id !== 'undefined') sub_url = sub_url+'/'+user_id;
    return request({
      url:   URLS.STORE_VERIFY_RESET_OTP + sub_url,
      method: 'GET'
    });
}

function storeResetPass(data) {
    return request({
      url:   URLS.STORE_RESET_PASS,
      method: 'POST',
      data:data
    });
}

const resetService = {
    adminSendOtp,
    adminVerifyOtp,
    adminResetPass,
    storeSendOtp,
    storeVerifyOtp,
    storeResetPass
}
  
export default resetService;
  