import React, { Component } from 'react';
import { Table } from "reactstrap";
import { Button } from '../../../components';
import {Link} from 'react-router-dom'
import { getAllStaffs, deleteStaff } from '../../../actions/AdminActions';
import {connect} from 'react-redux';
import userAvatar from "../../../assets/img/avatar.png";
import ReactPaginate from 'react-paginate';
import SweetAlert from 'sweetalert2-react';
import{onDismiss} from '../../../actions/storeActions';

class Users extends Component {
    constructor(){
        super();
        this.state = { 
           skip:0,
           limit:12,
           alert:false,selectedAdminId:0
         }
    }

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentWillMount() {
      this.props.onGetStaffs(this.state.skip,this.state.limit)
    }

    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.staffLoading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    getLoder2() {
      let classes = "bt-loader ";
      classes += this.props.staffLoading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    handlePageClick = (data) => {
      let selected = data.selected;
      let skip = selected * this.state.limit
      this.setState({skip: skip});
      this.props.onGetStaffs(skip,this.state.limit);
    }

    onDismissPopup =()=>{
      this.props.onDismiss();
      this.props.onGetStaffs(this.state.skip,this.state.limit)
    }

    onConfirm = ()=>{
     
      this.props.onDeleteStaff(this.state.selectedAdminId);
      this.setState({alert:false})
      // {this.props.onGetStores(this.state.skip,this.state.limit)}
    }

    onDelete = (admin_id)=>{
      this.setState({
        alert:true,
        selectedAdminId:admin_id
      })
    }

    render() { 
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className={this.getLoder2()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th/>
                        <th>USERNAME</th>
                        <th>MOBILE NO </th>
                        <th>EMAIL</th>
                        <th>ACTIONS</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.staffs.map((user,index) => (
                        <tr key={user.id}>
                        <td>
                          {index+1+this.state.skip}

                        </td>
                        <td className="nameImg">
                        <img src={user.profile_pic !== null ? user.profile_pic : userAvatar} alt="wild" className="img-round" />
                        </td>
                        <td>{user.name}</td>
                        <td>{user.mobile}</td>
                        <td>{user.email}</td>
                        <td>
                          <div className="group-action">
                            <Link className="btn" to={`/admin/edit-staff/${user.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={() => this.onDelete(user.id)}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    ))}
                        
                     
                    </tbody>
                  </Table>
                 
                <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-staff")}>Add</Button>
                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  pageLinkClassName={"page-link"}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(this.props.count/this.state.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  activeClassName={"active"} 
                  />
                  </div>

                  <SweetAlert
                    show={this.state.alert }
                    onConfirm={this.onConfirm}
                    onCancel = {() => this.setState({ alert: false })}
                    title= {'Are you sure?'}
                    text="Change status"
                    type= {'warning'}
                    showCancelButton= {true}
                    confirmButtonText= {'Yes, Confirm it!'}
                    cancelButtonText= {'No, keep it'}
                  />
                  <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />



                </div>

            </div>
        );
    }
}

const mapStateToProps = state=>({
  staffs: state.adminReducer.staffs,
  status: state.adminReducer.status,
  deleteLoading:state.adminReducer.deleteLoading,
  popup: state.adminReducer.popup,
  count:state.adminReducer.count,
  staffLoading: state.adminReducer.staffLoading
})

const mapDispatchToProps = dispatch=>({
  onGetStaffs:(skip,take)=>{
    dispatch(getAllStaffs(skip,take))
  },
  onDeleteStaff:(admin_id)=>{
    dispatch(deleteStaff(admin_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})


export default connect(mapStateToProps,mapDispatchToProps) (Users);