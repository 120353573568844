import * as Actions from '../constants/Actions';
import BannerService from '../services/BannerService';

//add banner
function addBanner(data){
    return dispatch =>{
        dispatch(request());
        BannerService.addBanner(data)
        .then(res =>{
            var banners = res.data;
            dispatch(success(banners))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_BANNER_OFFER_LOADING} }
    function success(banners) { return { type: Actions.ADD_BANNER_OFFER,banners:banners} }
    function failed(error) { return { type: Actions.ADD_BANNER_OFFER_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}

//fantastic deals
function addFantasticDeal(data){
    return dispatch =>{
        dispatch(request());
        BannerService.addFantasticDeal(data)
        .then(res =>{
            var fan_delas = res.data;
            dispatch(success(fan_delas))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_FANTASTIC_DEAL_LOADING} }
    function success(fan_delas) { return { type: Actions.ADD_FANTASTIC_DEAL,fan_delas:fan_delas} }
    function failed(error) { return { type: Actions.ADD_FANTASTIC_DEAL_FAILED, error:error} }
}

//add todays deals
function addTodayDeal(data){
    return dispatch =>{
        dispatch(request());
        BannerService.addTodayDeal(data)
        .then(res =>{
            var to_deals = res.data;
            dispatch(success(to_deals))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_TODAYS_DEAL_LOADING} }
    function success(to_deals) { return { type: Actions.ADD_TODAYS_DEAL,to_deals:to_deals} }
    function failed(error) { return { type: Actions.ADD_TODAYS_DEAL_FAILED, error:error} }
}

//get all banners
function getAllbanners(store_id,type,skip = null , take=null){
    return dispatch =>{
        dispatch(request());
        BannerService.getAllbanners(store_id,type,skip,take)
        .then(res =>{
            var banners = res.data;
            var count = res.count;
            dispatch(success(banners,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_BANNERS_LOADING} }
    function success(banners,count) { return { type: Actions.GET_ALL_BANNERS, banners: banners,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_BANNERS_FAILED, error:error} }
}

//get all todays deals
function getTodayDeals(store_id,skip = null , take=null){
    return dispatch =>{
        dispatch(request());
        BannerService.getTodayDeals(store_id,skip,take)
        .then(res =>{
            var to_deals = res.data;
            var count = res.count;
            dispatch(success(to_deals,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_TODAYS_DEALS_LOADING} }
    function success(to_deals,count) { return { type: Actions.GET_TODAYS_DEALS, to_deals: to_deals,count:count} }
    function failed(error) { return { type: Actions.GET_TODAYS_DEALS_FAILED, error:error} }
}

//get all fantastic deals

function getFantasticDeals(store_id,skip = null , take=null){
    return dispatch =>{
        dispatch(request());
        BannerService.getFantasticDeals(store_id,skip,take)
        .then(res =>{
            var fa_deals = res.data;
            var count = res.count;
            dispatch(success(fa_deals,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_FANTASTIC_DEALS_LOADING} }
    function success(fa_deals,count) { return { type: Actions.GET_FANTASTIC_DEALS, fa_deals: fa_deals,count:count} }
    function failed(error) { return { type: Actions.GET_FANTASTIC_DEALS_FAILED, error:error} }
}

//delete deals

function deleteFatastic(id) {
    return dispatch => {
        dispatch(request());
        BannerService.deleteFatastic(id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_FANTASTIC_LOADING} }
    function success() { return { type: Actions.DELETE_FANTASTIC} }
    function failed(error) { return { type: Actions.DELETE_FANTASTIC_FAILED, error:error} }

}

function deleteToday(id) {
    return dispatch => {
        dispatch(request());
        BannerService.deleteToday(id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_TODAYS_LOADING} }
    function success() { return { type: Actions.DELETE_TODAYS} }
    function failed(error) { return { type: Actions.DELETE_TODAYS_FAILED, error:error} }

}

function deleteBanner(id) {
    return dispatch => {
        dispatch(request());
        BannerService.deleteBanner(id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_BANNER_LOADING} }
    function success() { return { type: Actions.DELETE_BANNER} }
    function failed(error) { return { type: Actions.DELETE_BANNER_FAILED, error:error} }

}


function editFantasticDeal(data) {
    return dispatch => {
        dispatch(request());
        BannerService.editFantasticDeal(data).then(
            res => {
                var data = res.data;
                dispatch(success(data))
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_FANTASTIC_DEAL_LOADING} }
    function success(data) { return { type: Actions.EDIT_FANTASTIC_DEAL,data:data} }
    function failed(error) { return { type: Actions.EDIT_FANTASTIC_DEAL_FAILED, error:error} }
}


function editTodayDeal(data) {
    return dispatch => {
        dispatch(request());
        BannerService.editTodayDeal(data).then(
            res => {
                var data = res.data;
                dispatch(success(data))
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_TODAYS_DEAL_LOADING} }
    function success(data) { return { type: Actions.EDIT_TODAYS_DEAL,data:data} }
    function failed(error) { return { type: Actions.EDIT_TODAYS_DEAL_FAILED, error:error} }
}

function editBanner(data) {
    return dispatch => {
        dispatch(request());
        BannerService.editBanner(data).then(
            res => {
                var data = res.data;
                dispatch(success(data))
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_BANNER_OFFER_LOADING} }
    function success(data) { return { type: Actions.EDIT_BANNER_OFFER,data:data} }
    function failed(error) { return { type: Actions.EDIT_BANNER_OFFER_FAILED, error:error} }
}

function getBanner(banner_id){
    return dispatch =>{
        dispatch(request());
        BannerService.getBanner(banner_id)
        .then(res =>{
            var banner = res.data;

            dispatch(success(banner))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_OFFER_BANNER_LOADING} }
    function success(banner) { return { type: Actions.GET_OFFER_BANNER_SUCCESS, banner: banner} }
    function failed(error) { return { type: Actions.GET_OFFER_BANNER_FAILED, error:error} }
}


export {
    addBanner,
    addTodayDeal,
    addFantasticDeal,
    getAllbanners,
    getFantasticDeals,
    getTodayDeals,
    deleteFatastic,
    deleteToday,
    deleteBanner,
    onDismiss,
    editBanner,
    editFantasticDeal,
    editTodayDeal,
    getBanner
} 