import React, { useState } from "react";
import { CardTitle, Card, Table, Row, Col } from 'reactstrap'
import { Chart } from "react-google-charts";
import Select from "react-select";

const SELECT_OPTIONS = [
    {value:"Week", label:"Week"},
    {value:"Month", label:"Month"},
    {value:"Year", label:"Year"},
]

export const options = {
  chart: {
    title: "",
    subtitle: "",
  },
};

export default function SalesChart({onChange, chart}) {

    const [value, setValue] = useState({value:"Week", label:"Week"})

    const onChangeValue = (v)=>{
        setValue(v)
        if(onChange)onChange(v.value)
    }

    var data = [["Date", "Sales", "Quantity"]]
    if(chart && chart.length > 0){
        chart.map((c)=>{
            data.push([c.date, c.price, c.qty])
        })
    }

  return (
       <Card className="form-group" style={{minHeight: 250}}>
            <CardTitle >
                 <Row className="align-items-center">
                    <Col xs={12} md={10}>
                        <h2 style={{fontSize: 14, padding: 20}}>Sales Chart</h2>
                    </Col>
                    <Col xs={12} md={2}>
                         <Select
                            value={value}
                            onChange={onChangeValue}
                            options={SELECT_OPTIONS}
                            />
                    </Col>
                </Row>
            </CardTitle>
            <Chart
                chartType="Bar"
                width="100%"
                height="300px"
                data={data}
                options={options}
                />
        </Card>
  );
}
