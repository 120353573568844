import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addStore(data) {
    return request({
      url:   URLS.ADD_STORE,
      method: 'POST',
      data:data
    });
}

function getStores(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_STORES + sub_url,
      method: 'GET'
    });
}

//get store

function getStore(store_id) {
    var sub_url = '/'+store_id;
    return request({
      url:   URLS.GET_STORE + sub_url,
      method: 'GET'
    });
}

//Store status

//get store

function storeStatus(store_id,status) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(status !== null && typeof status !== 'undefined') sub_url = sub_url+'/'+status;
    return request({
      url:   URLS.STORE_STATUS + sub_url,
      method: 'GET'
    });
}

//EDIT STORE

function editStore(data) {
    return request({
      url:   URLS.EDIT_STORE,
      method: 'POST',
      data:data
    });
}

const StoreService = {
    addStore,
    getStores,
    getStore,
    storeStatus,
    editStore
}

export default StoreService;