import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function getStoreProducts(store_id,skip,take, query) {
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
    return request({
      url:   URLS.GET_STORE_PRODUCTS + sub_url,
      method: 'GET'
    });
}

function getAllProducts(store_id, skip,take, query) {
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
    return request({
      url:   URLS.GET_ALL_PRODUCTS + sub_url,
      method: 'GET'
    });
}

function groupStatus(store_id, group_id,status) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(group_id !== null && typeof group_id !== 'undefined') sub_url = sub_url+'/'+group_id;
    if(status !== null && typeof status !== 'undefined') sub_url = sub_url+'/'+status;
    return request({
      url:   URLS.GROUP_STATUS + sub_url,
      method: 'GET'
    });
}

function uploadStockPrice(data){
  return request({
    url:   URLS.UPLOAD_STOCK ,
    method: 'POST',
    data
  });
}

function importToStores(data){
  return request({
    url:   URLS.IMPORT_TO_STORES ,
    method: 'POST',
    data
  });
}

function getCustomerProducts(store_id, skip,take, query) {
  var sub_url = '/'+store_id;
  if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
  if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
  if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
  return request({
    url:   URLS.GET_CUSTOMER_PRODUCTS + sub_url,
    method: 'GET'
  });
}


const ProductService = {
    getStoreProducts,
    getAllProducts,
    groupStatus,
    uploadStockPrice,
    importToStores,
    getCustomerProducts
}

export default ProductService;

