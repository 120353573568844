import * as Actions from '../constants/Actions';
import UnitService from '../services/UnitService';

function addUnit(data){
    return dispatch =>{
        dispatch(request());
        UnitService.addUnit(data)
        .then(res =>{
            var units = res.data;
            dispatch(success(units))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_UNIT_LOADING }}
    function success(units) { return { type: Actions.ADD_UNIT,units:data} }
    function failed(error) { return { type: Actions.ADD_UNIT_FAILED, error:error} }
}

function getUnits(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        UnitService.getUnits(skip,take)
        .then(res =>{
            var units = res.data;
            var count = res.count;
            dispatch(success(units,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_UNITS_LOADING} }
    function success(units,count) { return { type: Actions.GET_UNITS, units: units,count:count} }
    function failed(error) { return { type: Actions.GET_UNITS_FAILED, error:error} }
}

function editUnit (data) {
    return dispatch => {
        dispatch(request());
        UnitService.editUnit(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_UNIT_LOADING} }
    function success() { return { type: Actions.EDIT_UNIT} }
    function failed(error) { return { type: Actions.EDIT_UNIT_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
    addUnit,
    editUnit,
    getUnits,
    onDismiss
} 