import * as Actions from '../constants/Actions';
import AttributeService from '../services/AttributeService';

function addAttribute(data){
    return dispatch =>{
        dispatch(request());
        AttributeService.addAttribute(data)
        .then(res =>{
            var attributes = res.data;
            dispatch(success(attributes))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_ATTRIBUTE_LOADING} }
    function success(attributes) { return { type: Actions.ADD_ATTRIBUTE,attributes:attributes} }
    function failed(error) { return { type: Actions.ADD_ATTRIBUTE_FAILED, error:error} }
}

function editAttribute(data){
    return dispatch =>{
        dispatch(request());
        AttributeService.editAttribute(data)
        .then(res =>{
            var attributes = res.data;
            dispatch(success(attributes))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_ATTRIBUTE_LOADING} }
    function success(attributes) { return { type: Actions.EDIT_ATTRIBUTE,attributes:attributes} }
    function failed(error) { return { type: Actions.EDIT_ATTRIBUTE_FAILED, error:error} }
}


function getAttribute(attr_id){

    return dispatch =>{
        dispatch(request());
        AttributeService.getAttribute(attr_id)
        .then(res =>{
            var attribute = res.data;;
            dispatch(success(attribute))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ATTRIBUTE_LOADING} }
    function success(attribute) { return { type: Actions.GET_ATTRIBUTE, attribute: attribute} }
    function failed(error) { return { type: Actions.GET_ATTRIBUTE_FAILED, error:error} }
}



function getAttributes(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        AttributeService.getAttributes(skip,take)
        .then(res =>{
            var attributes = res.data;
            var count = res.count;
            dispatch(success(attributes,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ATTRIBUTES_LOADING} }
    function success(attributes,count) { return { type: Actions.GET_ATTRIBUTES, attributes: attributes,count:count} }
    function failed(error) { return { type: Actions.GET_ATTRIBUTES_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
    addAttribute,
    getAttributes,
    editAttribute,
    getAttribute,
    onDismiss
} 