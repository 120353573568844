import React,{Component} from 'react';
class SwitchButton extends Component {
	constructor(props) {
		super(props);
		this.state = {liked: this.props.enabled};
		this.handleClick = this.handleClick.bind(this);
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
      if(nextProps.enabled !== this.props.enabled){
          this.setState({liked:nextProps.enabled})
      }
  }
	
	handleClick() {
        this.props.changeStatus(!this.props.enabled)
    }

    render() { 
        const{liked} = this.state;
        const { enabled } = this.props
        const Btnswitch = enabled ? 'green-bg-btn' : 'grey-bg-btn';
        return (
            <div onClick={this.handleClick} className={Btnswitch}>
				 <i className="fa fa-power-off"></i>
			</div>
          );
    }
}
 
export default SwitchButton;



