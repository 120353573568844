import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function saveAbout(data) {
    return request({
      url:   URLS.SAVE_ABOUT,
      method: 'POST',
      data:data
    });
}

function savePoint(data) {
    return request({
      url:   URLS.SAVE_POINT_TERMS,
      method: 'POST',
      data:data
    });
}

function savePrivacy(data) {
  return request({
    url:   URLS.SAVE_PRIVACY,
    method: 'POST',
    data:data
  });
}


function getAbout() {
    return request({
      url:   URLS.GET_ABOUT ,
      method: 'GET'
    });
}

function getPoint() {
    return request({
      url:   URLS.GET_POINT_TERMS ,
      method: 'GET'
    });
}

function getPrivacy() {
  return request({
    url:   URLS.GET_PRIVACY ,
    method: 'GET'
  });
}


const ContentService = {
    saveAbout,
    savePoint,
    getAbout,
    getPoint,
    savePrivacy,
    getPrivacy
}
export default ContentService;