import React, { Component } from 'react';
import { Button } from "../../components";
import {
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
  } from "reactstrap";
class Counter extends Component {
    
         state = {
            counter:0
         }
    
    
      increment =() =>{
        this.setState({
          counter: this.state.counter + 1
        });
      }
    
      decrement = () =>{
        if(this.state.counter == 0){
            this.setState({
                counter:0
            },()=>this.props.onCountChange(this.state.counter));
          }else {
            this.setState({
                counter: this.state.counter - 1
            },()=>this.props.onCountChange(this.state.counter));
          }
      }
    render() { 
        return ( 
            <React.Fragment>
                
                  {this.props.children ? (<Label className="control-label">{this.props.children}</Label>) : null}     
                       
                          <InputGroup>
                          <InputGroupAddon addonType="prepend">
                          <Button onClick={this.decrement.bind(this)} className="btn-append" leftLabel="fa fa-minus"/>
                          </InputGroupAddon>
                          <Input className="text-center" type="text" value={this.state.counter}/>
                          <InputGroupAddon addonType="append">
                          <Button onClick={this.increment.bind(this)} className="btn-append" leftLabel="fa fa-plus" />
                          </InputGroupAddon>
                          </InputGroup>
            </React.Fragment>
         );
    }
}
 
export default Counter;