import React from "react";
import {
  Card,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { connect } from 'react-redux';
import { CardCategory } from "../../components";
import ImgBrand from "../../assets/img/customers.png";
import Customers from "../../assets/img/customers.png";
import Products from "../../assets/img/products.png";
import slidingBanner from "../../assets/img/sliding-banner.png";
import StillBanner from "../../assets/img/still-banner.png";
import Volume from "../../assets/img/volume-offer.png";
import Combo from "../../assets/img/cmobo-pack-offer.png";
import ProductImg from "../../assets/img/Produvt-offer.png"

import {storeLogout} from '../../actions/userActions';
import {getStoreDashboard} from '../../actions/storeStaffActions';
import{ getBestSellerDashboard, getSalesChart} from '../../actions/AdminActions';
import SellingTable from '../common/dashboard/SellingTable'
import SalesChart from '../common/dashboard/SalesChart'
import AllOrders from '../common/order/AllOrders';


class StoreDashbord extends React.Component {

    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
//  componentDidMount() {
//    if(!this.props.storeloggedIn){
//     this.props.onStoreLogout();
//    }
//  }
    //WARNING! To be deprecated in React v17. Use componentDidMount instead.
    componentDidMount() {
      setTimeout(()=>{
        if(this.props.store){
        this.props.adminDashboard(this.props.store.id)
        this.props.adminBestSellerDashboard(this.props.store.id)
        this.props.getCharts("Week", this.props.store.id)
        }
       
       },100);
    }

    onChangeChart = (period)=>{
      this.props.getCharts(period, this.props.store.id)
    }

  render() {
    const{dashboard, seller, chart}= this.props;
    return (
      <div>
        <div className="content">
          <Row>
          <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Products</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.product !== undefined ? dashboard.product :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Products} className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            {/* <Col xs={12} md={4} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Packed Products</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.pack !== undefined ? dashboard.pack :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Combo} className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col> */}
            <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Customers</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.customer !== undefined ? dashboard.customer :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Customers}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            {/* <Col xs={12} md={4} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Volume Offers</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.volume !== undefined ? dashboard.volume :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Volume}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col> */}
          <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Product Offers</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.product_offer !== undefined ? dashboard.product_offer :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={ProductImg}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
    
            {/* <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Category Offers</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.cat_offer !== undefined ? dashboard.cat_offer :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={ImgBrand}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col> */}
           
            <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Completed Orders</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.orders !== undefined ? dashboard.orders :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={slidingBanner}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Total Sales</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.sales !== undefined ? dashboard.sales :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={slidingBanner}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
             <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Sliding Banners</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.slide !== undefined ? dashboard.slide :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={slidingBanner}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Still Banners</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.still !== undefined ? dashboard.still :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={StillBanner}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={12}>
              <SalesChart
                chart={chart}
                onChange={this.onChangeChart}/>
            </Col>
            <Col xs={12} md={12}>
                <Card className="card-dash">
                  <CardTitle style={{fontSize:14, paddingLeft: 10, paddingTop:15}}>Pending Orders</CardTitle>
                  <AllOrders isStore={true} history={this.props.history} disableSearch={true} orderStatus={1}/>
                </Card>
            </Col>
            <Col xs={12} md={6}>
              <SellingTable
                title="Top Selling Products"
                items={seller && seller.top ? seller.top:[]}/>
            </Col>
            <Col xs={12} md={6}>
              <SellingTable
                title="Least Selling Products"
                items={seller && seller.less ? seller.less:[]}/>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

StoreDashbord.defaultProps = {
  dashboard:{}
}

const mapStateToProps = (state) => ({
  storeloggedIn: state.storeUserReducer.storeloggedIn,
  loading:state.commonReducer.loading,
  dashboard: state.commonReducer.store_dashboard,
  store:state.storeUserReducer.store,
  seller: state.commonReducer.seller,
  chart: state.commonReducer.chart,
})

const mapDispatchToProps = (dispatch)=> ({
  onStoreLogout:()=>{
    dispatch(storeLogout())
  },
  adminDashboard:(store_id)=>{
    dispatch(getStoreDashboard(store_id))
  },
  adminBestSellerDashboard:()=>{
    dispatch(getBestSellerDashboard())
  },
  getCharts:(period)=>{
    dispatch(getSalesChart(period))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreDashbord)

