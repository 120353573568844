import React, { Component } from "react";
import { Table } from "reactstrap";
import {Link} from 'react-router-dom';
import { Button } from '../../../components';
import { connect } from 'react-redux';
import {getCategories, delCategory, onDismiss} from '../../../actions/categoryActions';
import ReactPaginate from 'react-paginate';
import SweetAlert from 'sweetalert2-react';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catLoading:false,
      isMounted: false,
      skip: 0,
      id:0,
      dialogue: false,
      limit:12
    }
  }
 
  componentDidMount () {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
    {this.props.onGetCategories(this.state.skip,this.state.limit);}
  }
  });
  }

  onDismissPopup = ()=>{
    this.props.onDismiss();
    this.props.onGetCategories(this.state.skip,this.state.limit);
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetCategories(skip,this.state.limit);
  }

  onStatus = ()=>{
    this.setState({
      dialogue: false
    })
    this.props.onDeleteCategory(this.state.id);
  }
  
  handleDelete = (e,id)=> {
    e.preventDefault();
    this.setState({
      id : id,
      dialogue: true
    })
  }
  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.catLoading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() { 
    const {categories} = this.props;

    return ( 
      <div>
      <div className="content">
      <div className={this.getLoder()}></div>
        <div className="table-product-wrapper">
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>CATEGORY NAME</th>
                <th>CATEGORY CODE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {categories.length >0 ? categories.map((category,index) => (
              <tr key = {category.id}>
              <td>{index+1 + this.state.skip }</td>
            <td className="nameImg">
            <img src={category.featured_image} alt="wild" className="img-ico" />
              <span className="p-name">{category.name}</span>
            </td>
            <td>{category.code}</td>
            <td className="nameImg">
            </td>
            <td>
            <div className="group-action text-right pr-10">
          <Link className="btn" to={`/admin/edit-category/${category.id}`}>
          <i className="ico c1 icon-edit-1 " />
          </Link>
          <button className="btn" onClick={((e)=>this.handleDelete(e,category.id))} >
          <i className="ico c2 icon-trash-b"/>
          </button>
            </div>
            </td>
            </tr>
              )):<tr><td colSpan={'5'}>No data found</td></tr>}
           
 
            </tbody>
          </Table>
        <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-category")}>Add</Button>
        </div>
        <div className="pagination-wrapper">
        <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
        nextLabel={<i className="fas fa-angle-right"></i>}
        pageLinkClassName={"page-link"}
        breakClassName={"break-me"}
        pageCount={Math.ceil(this.props.count/this.state.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        activeClassName={"active"} />
        </div>
        <SweetAlert
        show={this.state.dialogue }
        onConfirm={this.onStatus}
        onCancel = {() => this.setState({ dialogue: false })}
        title= {'Are you sure want to delete'}
        text="Change status"
        type= {'warning'}
        showCancelButton= {true}
        confirmButtonText= {'Yes, Confirm it!'}
        cancelButtonText= {'No, keep it'}
        />
        <SweetAlert
        show={this.props.status && this.props.popup}
        title="Success"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
        <SweetAlert
        show={ !this.props.status && this.props.popup}
        title="Error"
        type="error"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
      </div>
    </div>
     );
  }
}

Category.defaultProps = {
  categories : []
}
 

const mapStateToProps = (state) => ({
  catLoading:state.categoryReducer.catLoading,
  categories: state.categoryReducer.categories,
  message:state.categoryReducer.message,
  count:state.categoryReducer.count,
  popup:state.categoryReducer.popup,
  catLoading: state.categoryReducer.loading,
  status:state.categoryReducer.status,
  message:state.categoryReducer.message
})

const mapDispatchToProps = (dispatch)=> ({
  onGetCategories:(skip,take)=>{
    dispatch(getCategories(skip,take))
  },
  onDismiss:()=>{
    dispatch(onDismiss());
  },
  onDeleteCategory:(cat_id)=>{
    dispatch(delCategory(cat_id));
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Category)


