import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, UncontrolledCollapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { connect } from "react-redux";
import logo from "../../logo.svg";
import * as Constant from "../../constants/AppConstants";
var ps;

class StoreSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    ps = new PerfectScrollbar(this.refs.sidebar, {
      suppressScrollX: true,
      suppressScrollY: false
    });
  }
  componentWillUnmount() {
    ps.destroy();
  }
  checkValue(value, arr) {
    if (typeof arr === "undefined") return false;
    var status = false;
    for (var i = 0; i < arr.length; i++) {
      var name = arr[i];
      if (name == value) {
        status = true;
        break;
      }
    }

    return status;
  }
  render() {
    const { store } = this.props;
    var active = "";
    var deal = "";
    var selected= "";
    if(this.props.location.pathname.indexOf('/store/volume-offers') > -1) {
    active = "active";
    }
    if (this.props.location.pathname.indexOf('/store/product-offers') > -1) {
    active = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/brand-offers') > -1 ) {
    active = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/category-offers') > -1 ) {
      active = "active";
      }
    if(this.props.location.pathname.indexOf('/store/products') > -1) {
    selected = "active";
    }
    if (this.props.location.pathname.indexOf('/store/category') > -1) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/brands') > -1 ) {
    selected = "active";
    }
    if(this.props.location.pathname.indexOf('/store/units') > -1) {
    selected = "active";
    }
    if (this.props.location.pathname.indexOf('/store/tags') > -1) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/attributes') > -1 ) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/variants') > -1 ) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/store/todays-deals') > -1 ) {
    deal = "active";
    }

    if ( this.props.location.pathname.indexOf('/store/fantastic-deals') > -1 ) {
    deal = "active";
    }

    if ( this.props.location.pathname.indexOf('/store/sliding-banners') > -1 ) {
    deal = "active";
    }

    if ( this.props.location.pathname.indexOf('/store/still-banners') > -1 ) {
    deal = "active";
    }
        

    if (typeof store === "undefined") return null;
    var permissions = store.permissions;
    return (
      <div className="sidebar" data-color="black">
        <div className="logo">
          <Link to="/store" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </Link>
          <h8 style={{color: 'white', padding: 20, paddingTop: 30}}>Store Dashboard</h8>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            <li>
              <NavLink className="nav-link" to="/store/dashboard">
                <i className="icon-graph-bar" />
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li>
                <a className='' aria-expanded="false" id="orders">
                  <i className="icon-graph-bar" />
                  <p>Orders</p>
                </a>
                <UncontrolledCollapse toggler="#orders">
                  <NavLink className="dropdown-item" to="/store/processing">
                  Processing
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/all-orders">
                  All
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/accounts">
                  Accounts
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/delivery-slots">
                  Delivery Slots
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/delivery-boys">
                  Delivery Boys
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/feedbacks">
                  Feedbacks
                  </NavLink>
                </UncontrolledCollapse>
              </li>
            {this.checkValue(Constant.MENU_TYPE_CATALOGUE, permissions) ? (
              <li>
                <a className={selected} aria-expanded="false" id="Catologue">
                  <i className="icon-catolougue" />
                  <p>Catologue</p>
                </a>
                <UncontrolledCollapse toggler="#Catologue">
                  <NavLink className="dropdown-item" to="/store/products">
                    Products
                  </NavLink>
                  {/* <NavLink className="dropdown-item" to="/store/group-products">
                    Product Packs
                  </NavLink> */}
                  {/* <NavLink className="dropdown-item" to="/store/category">
                    Categories
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/brands">
                    Brands
                  </NavLink> */}
                  {/* <NavLink className="dropdown-item" to="/store/units">
                    Unit
                  </NavLink> */}
                  {/* <NavLink className="dropdown-item" to="/store/tags">
                    Tags
                  </NavLink> */}
                  {/* <NavLink className="dropdown-item" to="/store/attributes">
                    Attributes
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/variants">
                    Variants
                  </NavLink> */}
                </UncontrolledCollapse>
              </li>
            ) : null}

            {this.checkValue(Constant.MENU_TYPE_OFFER, permissions) ? (
              <li>
                <a className={active} aria-expanded="false" id="Offers">
                  <i className="icon-graph-bar" />
                  <p>Offers</p>
                </a>
                <UncontrolledCollapse toggler="#Offers">
                  <NavLink className="dropdown-item" to="/store/volume-offers">
                    Volume Offers
                  </NavLink>
                  {/* <NavLink className="dropdown-item" to="/store/coupon-codes">Coupon Codes</NavLink> */}
                  <NavLink className="dropdown-item" to="/store/product-offers">
                    Product Offers
                  </NavLink>
                  {/* <NavLink className="dropdown-item" to="/store/brand-offers">
                    Brand Offers
                  </NavLink> */}
                  {/* <NavLink className="dropdown-item" to="/store/category-offers" >
                    Category Offers
                  </NavLink> */}
                </UncontrolledCollapse>
              </li>
            ) : null}

            {this.checkValue(Constant.MENU_TYPE_CONTENT, permissions) ? (
              <li>
                <a className={deal} aria-expanded="false" id="Content">
                  <i className="icon-content" />
                  <p>Content</p>
                </a>
                <UncontrolledCollapse toggler="#Content">
                  <NavLink className="dropdown-item" to="/store/todays-deals">
                    Todays Deals
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    to="/store/fantastic-deals"
                  >
                    Fantastic Deals
                  </NavLink>
                  <NavLink
                    className="dropdown-item"
                    to="/store/sliding-banners"
                  >
                    Sliding Banners
                  </NavLink>
                  <NavLink className="dropdown-item" to="/store/still-banners">
                    Still Banners
                  </NavLink>
                </UncontrolledCollapse>
              </li>
            ) : null}
            {this.checkValue(Constant.MENU_TYPE_CUSTOMER, permissions) ? (
              <li>
                <NavLink className="nav-link" to="/store/customers">
                  <i className="icon-customer" />
                  <p>Customers</p>
                </NavLink>
              </li>
            ) : null}

            <li>
              <NavLink className="nav-link" to="/store/store-staffs">
                <i className="icon-user" />
                <p>Staffs</p>
              </NavLink>
            </li>

            {this.checkValue(Constant.MENU_TYPE_ACCOUNT) ? (
              <li>
                <NavLink className="nav-link" to="/store">
                  <i className="icon-widget" />
                  <p>Settings</p>
                </NavLink>
              </li>
            ) : null}
            <li>
              <NavLink className="nav-link" to="/store/notifications">
                <i className="icon-bell" />
                <p>Notifications</p>
              </NavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storeloggedIn: state.storeUserReducer.storeloggedIn,
  store: state.storeUserReducer.store
});
const mapDispatchToProps = dispatch => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreSideBar);
