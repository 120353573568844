import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function getCountries() {
    return request({
      url:   URLS.GET_COUNTRIES,
      method: 'GET'
    });
}
function getStates(country_id) {
    var sub_url ="/"+country_id;
    return request({
      url:   URLS.GET_STATES+sub_url,
      method: 'GET'
    });
}
function getRegions(state_id) {
    var sub_url ="/"+state_id;
    return request({
      url:   URLS.GET_REGIONS+sub_url,
      method: 'GET'
    });
}
function getCities(region_id) {
    var sub_url="/"+region_id;
    return request({
      url:   URLS.GET_CITIES+sub_url,
      method: 'GET'
    });
}

function getCurrencies() {
    return request({
      url:   URLS.GET_CURRENCIES,
      method: 'GET'
    });
}


const AddressService = {
    getCountries,
    getStates,
    getRegions,
    getCities,
    getCurrencies
}

export default AddressService;

