import * as Actions from "../constants/Actions";
const initialState = {
  loading: false,
  status: false,
  slots: [],
  slot: {},
};

export default function slotReducer(state = initialState, action) {
  switch(action.type) {
    case Actions.ADD_DELIVERY_SLOT_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.ADD_DELIVERY_SLOT_SUCCESS:
    return {
      ...state,
      loading: false,
      message: "Delivery slot added successfully",
      popup: true,
      status: true
    };
  case Actions.ADD_DELIVERY_SLOT_FAILED:
    return {
      ...state,
      loading: false,
      popup: true,
      message: "Delivery slot add failed",
      status: false,
      error: action.error
    };


    case Actions.GET_ALL_SLOTS_LOADING:
    return {
      loading: true,
      slots: []
    };
  case Actions.GET_ALL_SLOTS_SUCCESS:
    return {
      ...state,
      loading: false,
      slots: action.slots,
      count: action.count
    };
  case Actions.GET_ALL_SLOTS_FAILED:
    return {
      ...state,
      loading: false,
      slots: [],
      error: action.error
    };


    case Actions.DELETE_SLOT_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.DELETE_SLOT_SUCCESS:
    return {
      ...state,
      loading: false,
      message:"Delete slot successful",
      popup: true,
      status: true
    };
  case Actions.DELETE_SLOT_FAILED:
    return {
      ...state,
      loading: false,
      message:"Delete slot failed",
      popup: true,
      status: false
    };

    case Actions.EDIT_DELIVERY_SLOT_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.EDIT_DELIVERY_SLOT_SUCCESS:
    return {
      ...state,
      loading: false,
      message: "Slot edited successfully",
      popup: true,
      status: true
    };
  case Actions.EDIT_DELIVERY_SLOT_FAILED:
    return {
      ...state,
      loading: false,
      popup: true,
      message: "Slot editing failed",
      status: false,
      error: action.error
    };

    case Actions.GET_SLOT_LOADING:
    return {
      slot: {},
      loading:true,
    };
  case Actions.GET_SLOT_SUCCESS:
    return {
      ...state,
      loading:false,
      slot: action.slot,
    };
  case Actions.GET_SLOT_FAILED:
    return {
      ...state,
      slot: {},
      loading:false,
      error: action.error
    };



    case Actions.ALERT_DISMISS:
    return {
      ...state,
      status: false,
      popup: false
    };

    default: 
      return state
  }

}