import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore  from "./store/configureStore";
import { PersistGate } from 'redux-persist/integration/react'
import 'react-phone-number-input/style.css'
const {store,persistor} = configureStore();
var rootEl = document.getElementById('root');
ReactDOM.render(
<Provider store={store}>
  <App />
</Provider>, rootEl)

// Are we in development mode?
if (module.hot) {
    // Whenever a new version of App.js is available
    module.hot.accept(
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <App />
      </PersistGate>
    </Provider>, function () {
      // Require the new version and render it instead
      ReactDOM.render(
        <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </Provider>, rootEl)
    })
  }

