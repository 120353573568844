import * as Actions from '../constants/Actions';

const initialState = {
  loading: false,
  status: false,
  delivery_boys: [],
  delivery_boy: {},
};

export default function deliveryBoyReducer(state = initialState, action) {
  switch(action.type) {

    case Actions.ADD_DELIVERY_BOY_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.ADD_DELIVERY_BOY_SUCCESS:
    return {
      ...state,
      loading: false,
      message: "Delivery boy added successfully",
      popup: true,
      status: true
    };
  case Actions.ADD_DELIVERY_BOY_FAILED:
    return {
      ...state,
      loading: false,
      popup: true,
      message: "Delivery boy add failed",
      status: false,
      error: action.error
    };

    case Actions.UPDATE_DELIVERY_BOY_LOADING:
    return {
      ...state,
      popup: false,
      status: false,
      loading: true
    };
  case Actions.UPDATE_DELIVERY_BOY_SUCCESS:
    return {
      ...state,
      loading: false,
      message: "Delivery boy updated successfully",
      popup: true,
      status: true
    };
  case Actions.UPDATE_DELIVERY_BOY_FAILED:
    return {
      ...state,
      loading: false,
      popup: true,
      message: "Delivery boy update failed",
      status: false,
      error: action.error
    };

    case Actions.GET_ALL_DELIVERY_BOYS_LOADING:
    return {
      loading: true,
      delivery_boys: []
    };
  case Actions.GET_ALL_DELIVERY_BOYS_SUCCESS:
    return {
      ...state,
      loading: false,
      delivery_boys: action.delivery_boys,
      count: action.count
    };
  case Actions.GET_ALL_DELIVERY_BOYS_FAILED:
    return {
      ...state,
      loading: false,
      delivery_boys: [],
      error: action.error
    };


    case Actions.GET_DELVERY_BOY_LOADING:
    return {
      delivery_boy: {},
      loading:true,
    };
  case Actions.GET_DELVERY_BOY_SUCCESS:
    return {
      ...state,
      loading:false,
      delivery_boy: action.delivery_boy,
    };
  case Actions.GET_DELVERY_BOY_FAILED:
    return {
      ...state,
      delivery_boy: {},
      loading:false,
      error: action.error
    };

    case Actions.CHANGE_DELIVERY_BOY_LOADING:
    return {
      ...state,
      loading: true
    };
  case Actions.CHANGE_DELIVERY_BOY_SUCCESS:
    return {
      ...state,
      loading: false,
      message:"successful",
      popup: true,
      status: true
    };
  case Actions.CHANGE_DELIVERY_BOY_FAILED:
    return {
      ...state,
      loading: false,
      message:"failed",
      popup: true,
      status: false
    };

    case Actions.ALERT_DISMISS:
    return {
      ...state,
      status: false,
      popup: false
    };
    default:
      return state;

  }
  
} 