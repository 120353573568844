import React, { Component } from 'react';
import {Checkbox } from "../../components";
class CheckDeals extends Component {
   constructor(props) {
       super(props);
       this.state = {  
        today:false,
        fatastic:false
       }
   }
   _handleChange1 = () => {
    this.setState({
        today:!this.state.today,
    },()=>{
        this.props.onDealChange(this.state.today,'today')
    })
}
    _handleChange2 = () => {
        this.setState({
            fatastic:!this.state.fatastic
        },()=>{
            this.props.onDealChange(this.state.fatastic,'fantastic')
        })
   }
   
    render() { 
        const {today,fatastic} = this.state;
        return ( 
            <div className="check-wrapper">
            <Checkbox label="Today Deals" name="today" checked ={today} onChange={this._handleChange1}/>
            <Checkbox label="Fantastic Deals" name="fatastic"  checked={fatastic} onChange={this._handleChange2} />
            </div>
         );
    }
}
 
export default CheckDeals;