import * as Actions from '../constants/Actions';

const initialState={
    loading:false,
    loggedIn:false,
    logoutLoading:false,
    permissions:[]
}

export default function userReducer(state=initialState,action){
    switch(action.type){

        case Actions.ADMIN_LOGIN_LOADING:
            return{
                ...state,
                permissions:[],
                loading:true
            }
        case Actions.ADMIN_LOGIN:
            return{
                ...state,
                loading:false,
                permissions:action.permissions,
                token: action.token,
                user: action.user,
                loggedIn:true
            }
        case Actions.ADMIN_LOGIN_FAILED:
            return{
                permissions:[],
                loading:false,
                error: action.error
            }
            

        case Actions.ADMIN_LOGOUT_LOADING:
            return{
                ...state,
                logoutLoading:true,
            }
        case Actions.ADMIN_LOGOUT_SUCCESS:
            return{
                logoutLoading:false,
                loggedIn:false
            }

            case Actions.LOGOUT_RESET:
            return{
               
            }


            case Actions.ADMIN_CHANGE_PASS_LOADING:
            return {
              ...state,
              loading: true
            };
            case Actions.ADMIN_CHANGE_PASS:
            return {
              ...state,
              loading: false,
              message:"Change password successfully",
              popup: true,
              status: true
            };
          case Actions.ADMIN_CHANGE_PASS_FAILED:
            return {
              ...state,
              loading: false,
              popup: true,
              message:"Password changing failed",
              status: false,
              error: action.error
            };   

            case Actions.ALERT_DISMISS:
            return {
              ...state,
              status: false,
              popup: false
            };

        default:
            return state;
    }
}