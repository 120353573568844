import React, { Component } from 'react';
import { Table} from "reactstrap";
import {Link} from  'react-router-dom';
import { Button } from '../../../../components';
import SwitchButton from '../../../../components/CustomButton/switchButton';
class CouponCodes extends Component {
  state = { 
    status:1
   }
   handleStatus = (id) => {
    this.setState({
      status:false
    });
  }
  render() { 
    return ( 
      <div>
      <div className="content">
    <div className="table-product-wrapper">
      <Table>
        <thead>
          <tr>
            <th>SI.NO</th>
            <th>STATUS</th>
            <th>COUPEN NAME</th>
            <th>COUPEN CODE</th>
            <th>DISCOUNT TYPE</th>
            <th>DISCOUNT VALUE</th>
            <th>MAXIMUM AMOUNT</th>
            <th>VALIDITY</th>
            <th />
          </tr>
        </thead>
        <tbody>
            <tr>
              <td>1</td>
              <td><SwitchButton  changeStatus={(()=>this.handleStatus(1))}  enabled={this.state.status ===1? true:false}/></td>
              <td>1</td>
              <td>XCVBFG</td>
              <td>Price</td>
              <td>INR 100</td>
              <td>INR 100</td>
              <td>3-09-2018 to 05-11-2018</td>
              <td>
              <div className="group-action">
              <Link className="btn" to={''}>
                <i className="ico icon-edit" />
              </Link>
              <button className="btn">
                <i className="ico icon-delete" />
              </button>
            </div>
              </td>
            </tr>
        </tbody>
      </Table>
    </div>

    <Button className="fixed-yellow">Add New Coupon</Button>

  </div>
</div>
     );
  }
}
 
export default CouponCodes;