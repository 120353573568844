import React,{Component} from 'react';
import { Table,Modal, ModalHeader, ModalBody, ModalFooter, Row,
  Col,FormGroup } from "reactstrap";
import Select from "react-select";
import SimpleReactValidator from 'simple-react-validator';
import { Button,Checkbox } from '../../../components';
import {Link} from  'react-router-dom';
import {getItems} from '../../../actions/itemActions';
import ReactPaginate from 'react-paginate';
import {connect} from 'react-redux';
import * as Constant from '../../../constants/AppConstants';
import SweetAlert from 'sweetalert2-react';
import {onDismiss, uploadStockPrice, importToStores} from "../../../actions/productActions";
import { changeItemStatus } from '../../../actions/AdminActions';
import SwitchButton from '../../../components/CustomButton/switchButton';
import { getStores } from '../../../actions/storeActions';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      skip: 0,
      alert:false,
      dialogue:false,
      limit:12,
      selectedId:0,
      selected:0,
      modal: false,
      priModal: false,
      file:null,
      priceExport: true,
      stockExport: true,
      selectedStores:[]
    }
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
    this.validator2 = new SimpleReactValidator();
  }

  handleStock = ()=>{
    this.setState({
      stockExport : !this.state.stockExport
    })
  }

  handlePrice = ()=>{
    this.setState({
      priceExport : !this.state.priceExport
    })
  }

  componentDidMount () {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
    this.props.onGetStores();
    setTimeout(()=>{
      this.props.onGetItems(this.state.skip,this.state.limit);
     },200);
  }
  onDismissPopup (){
    this.props.onDismiss();
    this.props.onGetItems(this.state.skip,this.state.limit);
  }
  
  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetItems(skip,this.state.limit);
  }
  handleStatus = (stat,id) => {
    this.setState({
      stat:stat,
      selected:id,
      dialogue:true
    });
  }
  handleDelete = (e,id) => {
     e.preventDefault();
     this.setState({
      selectedId:id,
      alert:true
    }); 
  }
  onStatus = () =>{
    var status ;
    if(typeof this.state.stat !== undefined && this.state.stat == true) {
      status = Constant.ON_SALE_AVAILABLE;
    }
    else {
      status = Constant.ON_SALE_DISABLED;
    }
    this.props.onDeleteProduct(this.state.selected,status);
    this.setState({dialogue:false})
  }

  onConfirm = ()=>{
    this.props.onDeleteProduct(this.state.selectedId,Constant.ON_SALE_BLOCKED);
    this.setState({alert:false})
  }

  getLoder() {
    let classes = "bt-loader ";
    classes += (this.props.loading || this.props.prloading) ? 'bt-show' : 'bt-hide';
    return classes;
  }

  toggle = ()=>{
    this.setState({
      modal: !this.state.modal
    })
  }

  toggleImportProduct = ()=>{
    this.setState({
      priModal: !this.state.priModal
    })
  }

  onImportToStore = (e)=>{
    e.preventDefault()
    const {selectedStores} = this.state
    const stores = selectedStores.map((store)=>store.value)
    if(stores.length > 0){
      this.props.onImportToStores({stores})
    }
    this.toggleImportProduct()
  }

  onChangeFile = (e)=>{
    this.setState({
      file:e.target.files[0]
    })
  }

  onUpload = (e)=>{
    e.preventDefault()
    if( this.validator.allValid() ){
      var bodyFormData = new FormData();
      bodyFormData.append('stock', this.state.stockExport);
      bodyFormData.append('price', this.state.priceExport);
      bodyFormData.append('file', this.state.file); 
      this.props.onUpload(bodyFormData)
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
   
  }

  render() { 
    const {file} = this.state
    const{products, stores} = this.props;

  return (
    <div>
    <div className="content">
    <div className={this.getLoder()}></div>
  <div className="table-product-wrapper">
    <Table>
      <thead>
        <tr>
          <th>SI.NO</th>
          <th>STATUS</th>
          <th>PRODUCT NAME</th>
          <th>VARIANTS</th>
          <th />
        </tr>
      </thead>
      <tbody>
      {products.length >0 ? products.map((pack,index) => (
          <tr key={index}>
          <td>{index+1 + this.state.skip}</td>
          <td> <SwitchButton changeStatus={((stat)=>this.handleStatus(stat,pack.id))}  enabled={pack.status ===1? true:false}/></td>
          <td className="nameImg">
          <img src={pack.featured_image} alt="wild" className="img-ico" />
          <span className="p-name">{pack.title}</span>
          </td>
          <td className="nameImg">
              <span className="tag-name">{pack.count}</span>
            </td>
          <td>
            <div className="group-action">
              <Link className="btn" to={`/admin/edit-product/${pack.id}`}>
                <i className="ico icon-edit" />
              </Link>
              <button className="btn" onClick={((e)=>this.handleDelete(e,pack.id))}>
                <i className="ico icon-delete" />
              </button>
            </div>
          </td>
        </tr>    
      )):<tr><td colSpan={'5'}>No data found</td></tr>}
          
       
      </tbody>
    </Table>
  </div>
  <div className="pagination-wrapper">
  <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
  nextLabel={<i className="fas fa-angle-right"></i>}
  pageLinkClassName={"page-link"}
  breakClassName={"break-me"}
  pageCount={Math.ceil(this.props.count/this.state.limit)}
  marginPagesDisplayed={2}
  pageRangeDisplayed={5}
  onPageChange={this.handlePageClick}
  containerClassName={"pagination"}
  pageClassName={"page-item"}
  activeClassName={"active"} />
  </div>
  <div className="row">
        <div>
          <Button className="fixed-yellow" onClick={() => this.props.history.push("/admin/add-product")}>Add</Button>
        </div>
        <div>
          <Button  className="fixed-blue" onClick={this.toggle}>Update Stock</Button>
        </div>
  </div>
  <Modal isOpen={this.state.modal} toggle={this.toggle} className='common-modal' >
    <ModalHeader>Update stock and price</ModalHeader>
      <form  >
        <ModalBody>
            <input type='file' className='form-control' accept=".csv" accept="text/plain" onChange={this.onChangeFile}></input>
            <div className="errorMsg">{this.validator.message('File', file, 'required')}</div>
            <Row>
              <Col md="4">
                <FormGroup>
                  <div className="check-wrapper">
                    <Checkbox label="Stock" checked={this.state.stockExport} onChange={(e)=>{this.handleStock(e)}} />
                  </div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <div className="check-wrapper">
                    <Checkbox label="Price" checked={this.state.priceExport} onChange={(e)=>{this.handlePrice(e)}} />
                  </div>
                </FormGroup>
              </Col>  
            </Row>
        </ModalBody>
        <ModalFooter>
        <Button className="btn-cancel" onClick={this.toggle}>Cancel</Button>
        <Button className="btn-submit" type="submit" onClick={this.onUpload}>Save</Button>
        </ModalFooter>
      </form>
    </Modal>
  <SweetAlert
  show={this.state.alert }
  onConfirm={this.onConfirm}
  onCancel = {() => this.setState({ alert: false })}
  title= {'Are you sure? All the store products under this item will be deleted'}
  text="Delete Product"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
     <SweetAlert
  show={this.state.dialogue }
  onConfirm={this.onStatus}
  onCancel = {() => this.setState({ dialogue: false })}
  title= {'Are you sure? All the store product under this item will be affected'}
  text="Change status"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
  <SweetAlert
  show={this.props.status && this.props.popup}
  title="Success"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
  <SweetAlert
  show={ !this.props.status && this.props.popup}
  title="Error"
  type="error"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
    <SweetAlert
  show={this.props.prstatus && this.props.prpopup}
  title="Success"
  text={this.props.prmessage}
  onConfirm={this.onDismissPopup}
  />
  <SweetAlert
  show={ !this.props.prstatus && this.props.prpopup}
  title="Error"
  type="error"
  text={this.props.prmessage}
  onConfirm={this.onDismissPopup}
  />
</div>
</div>
  );
}
}
Products.defaultProps= {
  products:[]
}
const mapStateToProps = (state)=>({
    products : state.itemReducer.items,
    loading: state.itemReducer.loading,
    count:state.itemReducer.count,
    status:state.itemReducer.status,
    message:state.itemReducer.message,
    popup:state.itemReducer.popup,
    prstatus:state.productReducer.status,
    prmessage:state.productReducer.message,
    prloading:state.productReducer.loading,
    prpopup:state.productReducer.popup,
    stores: state.storeReducer.stores,
})
const mapDispatchToProps = (dispatch)=>({
    onGetItems : (skip,take)=>{
      dispatch(getItems(skip,take))
    },
    onUpload : (data)=>{
      dispatch(uploadStockPrice(data))
    },
    onDismiss:()=>{
      dispatch(onDismiss());
    },
    onDeleteProduct:(id,status) => {
      dispatch(changeItemStatus(id,status))
    },
    onGetStores:(skip,take)=>{
      dispatch(getStores(skip,take))
    },
    onImportToStores:(data)=>{
      dispatch(importToStores(data))
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(Products);
