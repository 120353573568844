import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  banner: {},
  banners:[]
};

export default function bannerReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_BANNER_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_BANNER_OFFER:
      return {
        ...state,
        loading: false,
        message: "Banner offer added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_BANNER_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Banner offer add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_BANNER_OFFER_LOADING:
      return {
        loading: true,
        popup: false,
      };
    case Actions.EDIT_BANNER_OFFER:
      return {
        ...state,
        loading: false,
        message: "Banner offer edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_BANNER_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Banner offer edit failed",
        status: false,
        error: action.error
      };


      case Actions.DELETE_BANNER_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_BANNER:
      return {
        ...state,
        loading: false,
        message:"Delete banner successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_BANNER_FAILED:
      return {
        ...state,
        loading: false,
        message:"Delete banner failed",
        popup: true,
        status: false
      };


      case Actions.GET_ALL_BANNERS_LOADING:
      return {
        loading: true,
        banners: []
      };
    case Actions.GET_ALL_BANNERS:
      return {
        ...state,
        loading: false,
        banners: action.banners,
        count: action.count
      };
    case Actions.GET_ALL_BANNERS_FAILED:
      return {
        ...state,
        loading: false,
        banners: [],
        error: action.error
      };
      

      case Actions.GET_OFFER_BANNER_LOADING:
      return {
      banner: {},
      loading:true,
      };
    case Actions.GET_OFFER_BANNER_SUCCESS:
      return {
        ...state,
        loading:false,
        banner: action.banner,
      };
    case Actions.GET_OFFER_BANNER_FAILED:
      return {
        ...state,
        banner: {},
        loading:false,
        error: action.error
      };


      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
