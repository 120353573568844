import React,{Component} from 'react';
import {
Row,
Col,FormGroup,
InputGroup, InputGroupAddon, Input,
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import MultipleImage from "../../../components/imageUpload/MultipleImage";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import CheckDeals from "../../../components/Common/CheckBoxDeals";
import Select from "react-select"; 
import { connect } from 'react-redux';
import {getUnits} from '../../../actions/unitActions';
import {getTags} from '../../../actions/tagActions';
import { getAllProducts } from "../../../actions/productActions";
import {addPack,onDismiss} from "../../../actions/OfferActions";
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class AddComboPack extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMounted: false,
          haveOffer:false,
          valid_from:moment(),
          valid_upto:moment(),
          title:'',
          sku:'',
          barcode:'',
          images:[],
          stock_details:{stock:'',preorder_level:''},
          wholessale_price:'',
          selling_price:'',
          disc_perc:'',
          image:null,
          isActive: false,
          items:[{selected_item:'',qty:1}],
          selectedTag: [],
          selectedProduct: ' ',
          week_days:[],
          group_description:'',
          tags:[],
          isTodays:false,
          isFantastic:false,
          weight:'0',
          by:1,
          variation:0,
          weight_unit:0
      }
      this.validator = new SimpleReactValidator();
      this.onDismissPopup = this.onDismissPopup.bind(this);
      this.handleChangeFrom = this.handleChangeFrom.bind(this);
      this.handleChangeTo = this.handleChangeTo.bind(this);
      this.child = React.createRef();
    } 
        componentDidMount() {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
        {this.props.onGetTags();
        this.props.onGetUnits();
        setTimeout(()=>{
          this.props.onGetProducts(this.props.store.id);
        },100)
        
        }
      }
      });
      }
      handleOffer = () => {
        this.setState({ haveOffer: !this.state.haveOffer });
      }
      onDismissPopup(){
        this.props.onDismiss();
      }
      handleTag  = (selectedTag) => {
        this.setState({ selectedTag });
      }
      handleProduct = (selectedProduct,i) => {
        var items = this.state.items;
        var item = items[i];
        item['selected_item'] = selectedProduct;
        items[i] = item;
        var product = selectedProduct
        this.setState({ 
          items,
          title : product.title,
          image: product.fields.featured_image,
          group_description: product.description,
          barcode: product.fields.barcode,
          sku: product.fields.sku,
          weight: product.weight,
          weight_unit:product.weight_unit
         },()=>{
          this.calculatePrice()
        });
      };
    handleChangeFrom(date) {
      this.setState({
        valid_from:date,
      });
    }
    handleChangeTo(date) {
      this.setState({
        valid_upto: date
      });
    }
    handleCheck = (week) => {
      this.setState({week_days:week});
    }
    handleLimit = () => {
      this.setState({ isActive: !this.state.isActive });
    }
    addProduct = () => {
      this.setState(prevState => ({ items: [...prevState.items, {"selected_item":null,"qty":1}]}))
    }
    deleteProduct = (e,i) => {
       let items = this.state.items;
       items.splice(i,1);
       this.setState({items});      
    }
    _onImagesChange = (images) => {
        this.setState({images})
    }
    _onImageChange = (image) => {
      this.setState({image})
    }
    handleDeal = (deal,type) => {
      if(type === 'today'){
        this.setState({isTodays:deal});
      }

      if(type === 'fantastic'){
        this.setState({isFantastic:deal});
      }
      
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var itemObj = this.state.items.map((item,index)=>{
        return { item_id: item.selected_item.value,qty:item.qty, by:this.state.by, variation:this.state.variation };
      });
      var images =  this.state.images;
      images = images.map((img)=>{
          return {image:img.url}
      })
      var packs = [{
        title:this.state.title,
        base_unit:1,
        sku:this.state.sku,
        barcode:this.state.barcode,
        featured_image:this.state.image,
        images:images,
        weight:this.state.weight,
        weight_unit:this.state.weight_unit,
        stock_details:this.state.stock_details,
        description:this.state.group_description
      }];
      if(this.state.selectedTag.length > 0){
         
        var obj = this.state.selectedTag.map((item,index)=>{
          return { tag: item.value };
        });
        packs[0]['tags'] = obj;
      }
      packs[0]['items'] = itemObj;

      var offer = {
        title:this.state.title,
        disc_perc:this.state.disc_perc,
        isTodays:this.state.isTodays,
        isFantastic:this.state.isFantastic,
      }
      var days_array = this.state.week_days.map((item)=>{
        return {'day_id':item}
      })
      offer['week_days']= days_array;
        
      let from = this.state.valid_from;
      let upto = this.state.valid_upto;
      offer['valid_from'] = from.format('YYYY-MM-DD');
      offer['valid_upto'] = upto.format('YYYY-MM-DD');
      if(this.state.haveOffer) {
        packs[0]['offer']=offer;
        }
   

      var prices = {
        wholessale_price:'',
        selling_price:this.state.selling_price,
      }
      packs[0]['prices'] =prices

      var data = {
      store_id:this.props.store.id,
      item_id:0,
      }
      data['packs'] =packs;

  
      if( this.validator.allValid() ){
        this.props.onAddPack(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }

    }
    //loader
    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.status === true) {
        // this.child.current.reset();
        this.setState({
          valid_from:moment(), valid_upto:moment(),
          title:'', isTodays:false,  isFantastic:false,
          sku:'', barcode:'', group_description:'',
          images:[],  stock_details:{stock:'',preorder_level:''},
          wholessale_price:'', selling_price:'',
          disc_perc:'',  image:null, items:[{selected_item:null,qty:1}],
          selectedTag: [], selectedProduct: ' ', week_days:[], group_description:'',
          tags:[], weight:'0', weight_unit:0
        });
      }
    }

    calculatePrice (){
      var price = 0
      var items = this.state.items;
      items.forEach(item => {
        if(item.qty && item.selected_item.price){
          price = price + item.selected_item.price * item.qty
        }
        
      });
      this.setState({selling_price:price.toString()})

      if(items.length == 1){
        if(!items[0].qty || !items[0].selected_item.weight){
          return 
        }
        var qty = items[0].qty;
        var weight = items[0].selected_item.weight;
        var weight_unit = items[0].selected_item.weight_unit;
        this.setState({
          weight: weight*qty,
          weight_unit: weight_unit
        })
      }
    }

    groupProductUI() {
      let productOptions = this.props.all_products.map(function(product) {
        return { value: product.id, label: product.title, price:product.selling_price, weight:product.weight, weight_unit:product.weight_unit, ...product};
      });
   
        return    this.state.items.map((item,i) =>(
            <Row key ={i}>
            <Col md="7">
            <Select onChange={(e)=>this.handleProduct(e,i)} value={item.selected_item} options={productOptions} placeholder={"Select a Product"}  />
            <div className="errorMsg">{this.validator.message('selected_item', item.selected_item, 'required')}</div>
            </Col>
            <Col md="4">
            <input type="number" name="qty" pattern="^\d*(\.\d{0,2})?$" value = {item.qty} onChange={(e)=>{
              var it = item;
              // if(!e.target.value) {
              //   return
              // }else{

              // };
              it['qty'] = e.target.value;
              var items = this.state.items;
              items[i] = it;
                this.setState({items:items},()=>{
                  this.calculatePrice()
                })
            }}  className="form-control" placeholder="Quantity" />
            <div className="errorMsg">{this.validator.message('qty', item.qty, 'required')}</div>
            </Col>
            <Col md="1">
            <div className="del-wrapp">
            <Button className="btn-tsp" onClick={(e)=>this.deleteProduct(e,i)} leftLabel="icon-delete fa red"></Button>
            </div>
            </Col>
            </Row>
        )) 
    }


    render() { 
      const { selectedTag } = this.state;
      let Tagoptions = this.props.tags.map(function (tag) {
        return { value: tag.tag, label: tag.tag };
      })
      let unitOptions = this.props.units.map(function (unit) {
        return { value: unit.id, label: unit.unit };
      })
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
                <div className="form-filds-wrapper">
                  <Row>
                  <Col md="12">
                      <div className="form-group">
                      <div className="flex-wrapper">
                        <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Featured Image</FeaturedImage>
                        <MultipleImage  imageReset={this.state.images} onImagesChange={(images) => this._onImagesChange(images)} />
                        </div>
                        <div className="errorMsg">{this.validator.message('image', this.state.image, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Combo Title</label>
                        <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})} className="form-control" placeholder="Mixed Fruit super combo" />
                        <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                      <div className="form-group">
                        <label className="control-label">SKU</label>
                        <input type="text" value={this.state.sku} onChange={(e)=>this.setState({sku:e.target.value})} className="form-control" placeholder="SKU" />
                        <div className="errorMsg">{this.validator.message('sku', this.state.sku, 'required')}</div>
                      </div>
                    </Col>  
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label"> Barcode</label>
                        <input type="text" value={this.state.barcode} onChange={(e)=>this.setState({barcode:e.target.value})} className="form-control" placeholder="Barcode" />
                        <div className="errorMsg">{this.validator.message('barcode', this.state.barcode, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label className="control-label">Opening Stock</label>
                        <input type="number" className="form-control" value={this.state.stock_details.stock} onChange={(e)=>{
                            var it = this.state.stock_details;
                            it['stock'] = e.target.value;;
                            this.setState({stock_details:it})
                          }}/>
                        </FormGroup>
                      </Col>  
                      <Col md="4">
                      <FormGroup>
                      <label className="control-label">Low Stock Threshold</label>
                      <input type="number" className="form-control" placeholder="5 Unit" value={this.state.stock_details.preorder_level} onChange={(e)=>{
                            var it = this.state.stock_details;
                            it['preorder_level'] = e.target.value;;
                            this.setState({stock_details:it})
                          }}/>
                         </FormGroup>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Price variation by</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="select" id="variation" value={this.state.by} onChange={(e)=>{this.setState({by:e.target.value})}}>
                        <option value={1}>INR</option>
                        <option value={2}>%</option>
                        </Input>
                        </InputGroupAddon>
                        <Input type={"number"} value={this.state.variation} onChange={(e)=>this.setState({variation:e.target.value})} className="form-control" placeholder="Price in INR" />
                        {/* <div className="errorMsg">{this.validator.message('variation', this.state.variation, 'required')}</div> */}
                        </InputGroup>
                      </div>
                      </Col>
                      <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Weight</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                          <Input type="select" name="select" value={this.state.weight_unit}  onChange={(e)=>{this.setState({weight_unit: e.target.value })}}>
                          {unitOptions.map((opt,ind)=>(
                            <option value={opt.value} key={ind}>{opt.label}</option>
                          ))}
                          </Input>
                          </InputGroupAddon>
                          <Input type={'number'} value={this.state.weight} onChange={(e)=>this.setState({weight:e.target.value})} />
                          </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="12">
                        <div className="formg-group">
                        <label className="control-label">Products and Quantity</label>
                        </div>
                        <div className="combos">
                            <div className="form-group">
                          {this. groupProductUI()}
                        <Button className="btn-simple" onClick={()=>this.addProduct()} leftLabel="fa fa-plus">ADD PRODUCT</Button>
                        </div>
                        </div>
                      </Col>
                  </Row>
                            
                  <Row>
                  
                      <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Tags</label>
                        <Select  onChange ={this.handleTag} value={selectedTag} options={Tagoptions} isMulti />
                      </div>
                      </Col>  
                      <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Description</label>
                        <textarea name="description" value={this.state.group_description} onChange={(e) => { this.setState({ group_description: e.target.value }) }} className="form-control" rows="8"></textarea>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                    <div className="form-group">
                    <div className="check-wrapper">
                            <Checkbox label="Add Offer" checked={this.state.haveOffer} onChange={this.handleOffer} />
                          </div>
                    </div>
                    </Col>
                  </Row>
                  {this.state.haveOffer ? this.offerAdd():null}
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </form>
            </div>
          </div>
        </div>
         );

 
    }
    offerAdd(){
      return (
        <Row>          
        <Col md="4">
        <div className="form-group">
            <label className="control-label">Offer </label>
            <InputGroup>
            <InputGroupAddon addonType="prepend">
            <Input type="select" name="select" id="exampleSelect">
            <option>%</option>
            </Input>
            </InputGroupAddon>
            <Input value={this.state.disc_perc} type={'number'} onChange={(e)=>this.setState({disc_perc:e.target.value})} />
            <div className="errorMsg">{this.validator.message('disc_perc', this.state.disc_perc, 'required')}</div>
            </InputGroup>
          </div>
        </Col>
        <Col md="4">
        <div className="form-group">
            <label className="control-label">Valid From</label>
            <DatePicker className="form-control" 
            selected={this.state.valid_from}
            onChange={this.handleChangeFrom}
            dateFormat="YYYY-MM-DD"
            name="valid_from"
            />
          </div>
          </Col>
        <Col md="4">
          <div className="form-group">
            <label className="control-label">Valid To</label>
            <DatePicker className="form-control" 
            selected={this.state.valid_upto}
            onChange={this.handleChangeTo}
            dateFormat="YYYY-MM-DD"
            name="valid_upto"
            />
          </div>
        </Col>
        <Col md="4">
        <div className="form-group">
        <label className="control-label">Deals</label>
        <div className="check-box-inline prvilages">
          <CheckDeals onDealChange= {(deal,type)=>this.handleDeal(deal,type)}/>
          </div>
        </div>
        </Col>
        <Col md="12">
              <div className="prvilages">
              <div className="check-wrapper">
              <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}}/>
              { this.state.isActive ? <CheckFrom onCheckChange ={(week)=>this.handleCheck(week)}/> : null }
              </div>
              </div>
              </Col>
      </Row>
      )
    }
}


AddComboPack.defaultProps = {
  tags: [],
  week_days:[],
  all_products:[]  
}
const mapStateToProps = (state) => ({
  loading: state.PackReducer.loading,
  status:state.PackReducer.status,
  message:state.PackReducer.message,
  popup:state.PackReducer.popup,
  tags: state.tagReducer.tags,
  all_products: state.productReducer.all_products,
  units:state.unitReducer.units,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onAddPack:(data)=>{
    dispatch(addPack(data))
  },
  onGetTags:()=>{
    dispatch(getTags())
  },
  onGetUnits:()=>{
    dispatch(getUnits())
  },
  onGetProducts: (store_id) => {
    dispatch(getAllProducts(store_id));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddComboPack)