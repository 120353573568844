import * as Actions from '../constants/Actions';
import resetService from '../services/resetService';

function adminSendOtp(data){
    return dispatch =>{
        dispatch(request());
        resetService.adminSendOtp(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failed(error.data.message));
                }else{
                  dispatch(failed(error.data));
                }
              }else{
                dispatch(failed(error));
              }
        })
    }
    function request() { return { type: Actions.ADMIN_SEND_RESET_OTP_LOADING }}
    function success(data) { return { type: Actions.ADMIN_SEND_RESET_OTP_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADMIN_SEND_RESET_OTP_FAILED, error:error} }
}

function adminVerifyOtp(otp,admin_id){
    return dispatch =>{
        dispatch(request());
        resetService.adminVerifyOtp(otp,admin_id)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADMIN_VERIFY_RESET_OTP_LOADING }}
    function success(data) { return { type: Actions.ADMIN_VERIFY_RESET_OTP_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADMIN_VERIFY_RESET_OTP_FAILED, error:error} }
}

function adminResetPass(data){
    return dispatch =>{
        dispatch(request());
        resetService.adminResetPass(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADMIN_RESET_PASS_LOADING }}
    function success(data) { return { type: Actions.ADMIN_RESET_PASS_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADMIN_RESET_PASS_FAILED, error:error} }
}


//store reset password

function storeSendOtp(data){
    return dispatch =>{
        dispatch(request());
        resetService.storeSendOtp(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failed(error.data.message));
                }else{
                  dispatch(failed(error.data));
                }
              }else{
                dispatch(failed(error));
              }
        })
    }
    function request() { return { type: Actions.STORE_SEND_RESET_OTP_LOADING }}
    function success(data) { return { type: Actions.STORE_SEND_RESET_OTP_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.STORE_SEND_RESET_OTP_FAILED, error:error} }
}

function storeVerifyOtp(otp,user_id){
    return dispatch =>{
        dispatch(request());
        resetService.storeVerifyOtp(otp,user_id)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.STORE_VERIFY_RESET_OTP_LOADING }}
    function success(data) { return { type: Actions.STORE_VERIFY_RESET_OTP_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.STORE_VERIFY_RESET_OTP_FAILED, error:error} }
}

function storeResetPass(data){
    return dispatch =>{
        dispatch(request());
        resetService.storeResetPass(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.STORE_RESET_PASS_LOADING }}
    function success(data) { return { type: Actions.STORE_RESET_PASS_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.STORE_RESET_PASS_FAILED, error:error} }
}


function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}

function dataReset(){
    return dispatch =>{
        dispatch(success());
    }
    function success() { return { type: Actions.DATA_RESET} }
}

export {
    adminSendOtp,
    adminVerifyOtp,
    adminResetPass,
    storeSendOtp,
    storeVerifyOtp,
    storeResetPass,
    dataReset,
    onDismiss
} 