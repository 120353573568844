import * as Actions from '../constants/Actions';
import TagService from '../services/TagService';

function addTags(tag){

    return dispatch =>{
        dispatch(request());
        TagService.addTags(tag)
        .then(res =>{
            var tag = res.data;
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_TAG_LOADING} }
    function success(tag) { return { type: Actions.ADD_TAG,tag:tag} }
    function failed(error) { return { type: Actions.ADD_TAG_FAILED, error:error} }
}

function getTags(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        TagService.getTags(skip,take)
        .then(res =>{
            var tags = res.data;
            var count = res.count;
            dispatch(success(tags,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_TAGS_LOADING} }
    function success(tags,count) { return { type: Actions.GET_TAGS, tags: tags,count:count} }
    function failed(error) { return { type: Actions.GET_TAGS_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function delTags (tag_id) {
    return dispatch => {
        dispatch(request());
        TagService.delTag(tag_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_TAG_LOADING} }
    function success() { return { type: Actions.DELETE_TAG} }
    function failed(error) { return { type: Actions.DELETE_TAG_FAILED, error:error} }
}

function editTags (tag,tag_id) {
    return dispatch => {
        dispatch(request());
        TagService.editTag(tag,tag_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_TAG_LOADING} }
    function success() { return { type: Actions.EDIT_TAG} }
    function failed(error) { return { type: Actions.EDIT_TAG_FAILED, error:error} }
}



export {
    addTags,
    getTags,
    onDismiss,
    editTags,
    delTags
};