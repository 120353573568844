import * as Actions from '../constants/Actions';
import BrandService from '../services/BrandService';

function addBrand(data){
    return dispatch =>{
        dispatch(request());
        BrandService.addBrand(data)
        .then(res =>{
            var brands = res.data;
            dispatch(success(brands))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_BRAND_LOADING} }
    function success(brands) { return { type: Actions.ADD_BRAND,brands:data} }
    function failed(error) { return { type: Actions.ADD_BRAND_FAILED, error:error} }
}

function editBrand(data){
    return dispatch =>{
        dispatch(request());
        BrandService.editBrand(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_BRAND_LOADING} }
    function success(data) { return { type: Actions.EDIT_BRAND,data:data} }
    function failed(error) { return { type: Actions.EDIT_BRAND_FAILED, error:error} }
}

function getBrand(brand_id){

    return dispatch =>{
        dispatch(request());
        BrandService.getBrand(brand_id)
        .then(res =>{
            var brand = res.data;;
            dispatch(success(brand))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_BRAND_LOADING} }
    function success(brand) { return { type: Actions.GET_BRAND, brand: brand} }
    function failed(error) { return { type: Actions.GET_BRAND_FAILED, error:error} }
}


function getBrands(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        BrandService.getBrands(skip,take)
        .then(res =>{
            var brands = res.data;
            var count = res.count;
            dispatch(success(brands,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_BRANDS_LOADING} }
    function success(brands,count) { return { type: Actions.GET_BRANDS, brands: brands,count:count} }
    function failed(error) { return { type: Actions.GET_BRANDS_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function getStoreBrands(store_id,skip = null,take=null) {
    return dispatch =>{
        dispatch(request());
        BrandService.getStoreBrands(store_id,skip,take)
        .then(res =>{
            var store_brands = res.data;
            var count = res.count;
            dispatch(success(store_brands,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_BRANDS_LOADING} }
    function success(store_brands,count) { return { type: Actions.GET_STORE_BRANDS, store_brands: store_brands,count:count} }
    function failed(error) { return { type: Actions.GET_STORE_BRANDS_FAILED, error:error} }
}


export {
    addBrand,
    getBrands,
    editBrand,
    getBrand,
    getStoreBrands,
    onDismiss
} 