import React,{Component} from 'react';
import {
Row,
Col,FormGroup,
InputGroup, InputGroupAddon, Input,
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from "react-redux";
import {editBrandOffer,getBrandOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class EditBrandOffer extends Component {
  _id = 0;
    constructor(props) {
        super(props);
        this.state = {
          brandName: ' ',
          isActive: false,
          isMounted: false,
          valid_from:moment(),
          valid_upto:moment(),
          disc_perc:'',
          title:'',
          week:[]
      }
      this.handleChangeFrom = this.handleChangeFrom.bind(this);
      this.handleChangeTo = this.handleChangeTo.bind(this);
      this.onDismissPopup = this.onDismissPopup.bind(this);
      this.validator = new SimpleReactValidator();
    } 
    onDismissPopup(){
      this.props.onDismiss();
      this.props.onGetOffer(this._id);
    }
    handleChangeFrom(date) {
      this.setState({
        valid_from:date,
      });
    }
    handleChangeTo(date) {
      this.setState({
        valid_upto: date
      });
    }
    handleCheck = (week) => {
      this.setState({week});
    }
    componentDidMount() {
      var urls = this.props.match.url.split('/');
      this._id = urls[urls.length - 1];
      this.setState({ isMounted: true }, () => {
        if (this.state.isMounted) {
          this.setState({ isMounted: false });
          {
            this.props.onGetOffer(this._id);
          }
        }
      });
    }



    handleLimit = () => {
      this.setState({ isActive: !this.state.isActive });
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.offer && typeof nextProps.offer.id !== 'undefined' ){ 

        var dateTimeFrom = new Date(nextProps.offer.valid_from);
        var dateTimeTo = new Date(nextProps.offer.valid_upto);
        var to = moment(dateTimeTo).format("YYYY-MM-DD");
        var from = moment(dateTimeFrom).format("YYYY-MM-DD"); 
        var days = nextProps.offer.week_days.map((item)=>{
          return item.day_id;
        })
        this.setState({
          disc_perc:nextProps.offer.disc_perc,
          title: nextProps.offer.title,
          brandName:nextProps.offer.brand.name,
          isActive:true,
          valid_from: moment(from),
          valid_upto: moment(to),
          week:days
        })
      }
  }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        store_id :this.props.store.id,
        disc_perc:parseFloat(this.state.disc_perc),
        title:this.state.title
      }
      let from = this.state.valid_from;
      let upto = this.state.valid_upto;

  
      data['valid_from'] = from.format('YYYY-MM-DD');
      data['valid_upto'] = upto.format('YYYY-MM-DD');  
      var days_array = this.state.week.map((item)=>{
        return {'day_id':item}
      })
     data['week_days']= days_array;
     if ( this.validator.allValid() ){
      data['offer_id'] = this._id; 
    this.props.onEditBrandOffer(data);
     }
     else {
      this.validator.showMessages();
       this.forceUpdate();
     }
    }
       //loader
       getLoder() {
        let classes = "bt-loader ";
        classes +=  this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
                <div className="form-filds-wrapper">
                <Row>
                <Col md="8">
                <div className="form-group">
                    <label className="control-label">Brand Name</label>
                    <h6 className="p-bname">{this.state.brandName}</h6>
                  </div>
               </Col>
                </Row>
                  <Row>
                  <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Name</label>
                        <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})} className="form-control" placeholder="Name" />
                        <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Value</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="select" id="exampleSelect">
                        <option>%</option>
                        </Input>
                        </InputGroupAddon>
                        <Input value={this.state.disc_perc} type={'number'} onChange={(e)=>this.setState({disc_perc:e.target.value})} />
                        <div className="errorMsg">{this.validator.message('disc_perc', this.state.disc_perc, 'required|min:1|max:2')}</div>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Valid From</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_from}
                        onChange={this.handleChangeFrom}
                        dateFormat="YYYY-MM-DD"
                        name="valid_from"
                        />
                      </div>
                      </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Valid To</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_upto}
                        onChange={this.handleChangeTo}
                        dateFormat="YYYY-MM-DD"
                        name="valid_upto"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                  <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom isEditable={true} week={this.state.week}
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                </Col>
                    </Row>
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                 <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </form>
            </div>
          </div>
        </div>
         );
    }
}
EditBrandOffer.defaultProps ={
  offer:{}
}

const mapStateToProps = (state) => ({
  loading: state.brandOfferReducer.loading,
  status:state.brandOfferReducer.status,
  message:state.brandOfferReducer.message,
  popup:state.brandOfferReducer.popup,
  offer:state.brandOfferReducer.offer,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onEditBrandOffer:(data)=>{
    dispatch(editBrandOffer(data))
  },
  onGetOffer:(offer_id)=>{
    dispatch(getBrandOffer(offer_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditBrandOffer)
