import React,{Component} from 'react';
import { Alert } from 'reactstrap';
import {Link} from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import './Login.css';
import logo from "../../assets/img/logo.png";
import textLogo from "../../assets/img/logotwxt.png";
import { connect } from 'react-redux';
import {adminSendOtp,dataReset} from '../../actions/resetActions';
import SimpleReactValidator from 'simple-react-validator';

class ForgotPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile : ''
    }
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    setTimeout(()=>{
      this.props.onDataReset();
    },100)
  }


  componentWillReceiveProps(nextProps) {
   
    if( nextProps.status && nextProps.admin_id != null){
       this.props.history.push("/admin/verify");
    }
   }
  


getLoder() {
  let classes = "bt-loader ";
  classes += this.props.loading ? 'bt-show' : 'bt-hide';
  return classes;
}
  signUp(e)  {
    var data = {
      mobile:this.state.mobile
    }
    e.preventDefault();
    if( this.validator.allValid() ){
        this.props.sendOtp(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 
    render() {
        return(
              <div className="login-wrapper">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="bg-white col-md-6 order-2">
          <div className="login-form-wrapper width-cst">
            <div className="login-box">
              <div className="logo">
                <img src={textLogo} alt="wild" className="img-fluid" />
              </div>
              <div className="login-dis">
                Forgot Password
              </div>

              <form className="form-vertical">
              <div className="login-message">
                <Alert color={this.props.error ?"danger" : ""}>
                {this.props.error ? this.props.error : null}
                </Alert>
                </div>
                <div className="form-group">
                  <PhoneInput defaultCountry="IN" type="text" onChange={event => this.setState({mobile:event})}  placeholder="Mobile number" className="form-control" />
                  <div className="errorMsg">{this.validator.message('mobile', this.state.mobile, 'required|phone')}</div>
                </div>
                <div className="form-group row">

                  <div className="col-md-12">
                    <div className="forgot text-right">
                    <Link className="d-block small" to="/admin/login">Back to home</Link>
                    </div>
                  </div>
                </div>

                <div className="bt-wrapper">
                  <button onClick ={(e)=>this.signUp(e)} className="waves-effect waves-light btn">Submit</button>
                  <span className={this.getLoder()}></span>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="bg-yellow col-md-6 order-1">
          <div className="img-logo width-cst">
            <img src={logo} alt="wild" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
        )
    }
}
ForgotPassword.deaultProps = {
  error:[]
}
const mapStateToProps = (state) => ({
  loading: state.resetReducer.loading,
  status:state.resetReducer.status,
  error:state.resetReducer.error,
  admin_id:state.resetReducer.admin_id
})

const mapDispatchToProps = (dispatch)=> ({
  sendOtp:(data)=>{
    dispatch(adminSendOtp(data))
  },
  onDataReset:()=>{
    dispatch(dataReset())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)




