const weeks = [
    {
      id: "0",
      name:"Sunday",  
    },
    {
      id: "1",
      name:"Monday",  
    },
    {
      id: "2",
      name:"Tuesday",  
    },
    {
      id: "3",
      name:"Wenedsday",  
    },
    {
      id: "4",
      name:"Thursday",  
    },
    {
      id: "5",
      name:"Friday",  
    },
    {
      id: "6",
      name:"Saturday",  
    }
  ];
  
  export function getWeeks() {
    return weeks;
  }
  
