import { Card } from '@material-ui/core'
import { initial } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardTitle, Col, Row, Table } from 'reactstrap'
import { getOrder } from '../../../actions/OrderActions'
import { getDeliveryName, getLoaderClass, getOrderStatus, getPayMethod, getPayStatus, parseTime } from '../../../utils'

export default function OrderDetail({
    match
}){

    const dispatch = useDispatch()
    const orderReducer = useSelector(state=> state.orderReducer)
    const { order, orderLoading } = orderReducer
    const [ initial, setInitial ] = useState(true)
    const params = match.params

    useEffect(()=>{
        if(initial && params && params.id){
            dispatch(getOrder(params.id))
            setInitial(false)
        }
    })

    return(
        <div>
            <div className="content">
                <div className={getLoaderClass(orderLoading)}></div>
                {order && order.customer && <Row>
                    
                    <ItemCard title="Customer Details">
                        <Item title="Name" value={order.customer.first_name}/>
                        <Item title="Mobile" value={order.customer.mobile}/>
                        <Item title="Address" value={order.delivery_address}/>
                    </ItemCard>
                    <ItemCard title="Invoice Details">
                        <Item title="Amount" value={order.total}/>
                        <Item title="Delivery Charge" value={order.shipping_charge}/>
                        <Item title="Total" value={order.final_amount}/>
                    </ItemCard>
                    <ItemCard title="Order Details">
                        <Item title="Order ID" value={order.order_id}/>
                        <Item title="Delivery Mode" value={getDeliveryName(order.delivery_mode)}/>
                        <Item title="Created At" value={order.order_time}/>
                        <Item title="Delivery At" value={order.date +' '+ parseTime(order.from) +' - ' +parseTime(order.to)}/>
                        {/* <Item title="Expected Delivery" value="Success"/> */}
                        <Item title="Order Status" value={getOrderStatus(order.status)}/>
                        
                        {/* <Item title="Delivered At" value="#dddd"/> */}
                    </ItemCard>
                    <ItemCard title="Payment Details">
                        <Item title="Payment Mode" value={getPayMethod(order.payment_mode)}/>
                        <Item title="Payment Status" value={getPayStatus(order.payment_status)}/>
                        {order.transaction_id && <Item title="Transaction Id" value={order.transaction_id}/>}
                    </ItemCard>
                    <ItemCard title="Items" md={12}>
                    <div className="table-product-wrapper">
                        <Table>
                            <thead>
                                <tr><th></th>
                                    <th>Item</th>
                                    <th>Weight</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(order&& order.items)?order.items.map((item,index)=>(
                                <tr key={index}>
                                <td className="nameImg">
                                    <img src={item.product.featured_image} alt="wild" className="img-ico" />
                                </td>
                                <td>
                                    <div className="name">{item.product.title}</div>
                                    <div className="sku">{item.product.sku}</div>
                                </td>
                                <td>{item.product.weight}</td>
                                <td>{item.selling_price}</td>
                                <td>{item.qty}</td>
                                <td>{(item.selling_price)*(item.qty)}</td>
                                </tr>
                                )):<tr><td colSpan={'4'}>No record found</td></tr>}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={'5'} className="text-right">
                                    <p>Sub Total</p>
                                    <p>Offer Discount</p>
                                    <p>Delivery Charge</p>
                                    <p>Voucher redeemed</p>
                                    <p className="total">Grand Total</p>
                                    </td>
                                    <td className="price">
                                    <p>{order.total}</p>
                                    <p>{order.discount}</p>
                                    <p>{order.shipping_charge}</p>
                                    <p>{order.voucher_redeemed}</p>
                                    <p className="total">{order.final_amount}</p>
                                    </td>
                                </tr>
                            </tfoot>
                            </Table> 
                            </div>
                    </ItemCard>
                </Row>}
            </div>
        </div>
    )
}

const ItemCard = ({children, title, md=4})=>{
    return(
        <Col xs={12} md={md} style={{marginTop: 10}}>
            <Card className="card-dash">
                <div className="card-details">
                    <CardTitle tag="h8">{title}</CardTitle>
                    <div style={{marginTop: 10}}>
                        {children}
                    </div>
                </div>
            </Card>
        </Col>
    )
}

const Item = ({title, value})=>{
    return(
        <Row style={{marginTop: 5}}>
            <Col xs={12} md={6}>
                <span style={{fontSize:12}}>{title}</span>
            </Col>
            <Col xs={12} md={6}>
                <span style={{fontSize:12}}>{value}</span>
            </Col>
        </Row>
    )
}