import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { Button } from '../../../components';
import{getStoreBrands} from '../../../actions/brandActions';
import ReactPaginate from 'react-paginate';
class Brands extends Component {
  state = {
    brandsLoading:false,
    isMounted: false,
    skip: 0,
    limit:12
  };
  componentDidMount = () => {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        setTimeout(() => {
          this.props.onGetBrands(this.props.store.id,this.state.skip,this.state.limit)
        }, 50);
  }
  });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetBrands(this.props.store.id,skip,this.state.limit);
  }

  getLoder = () =>{
    let classes = "bt-loader ";
    classes += this.props.brandstoreLoading ? 'bt-show' : 'bt-hide';
    return classes;
  }

  render() {
    const {store_brands} = this.props;
    return (
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
          <div className="table-product-wrapper">
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>BRAND NAME</th>
                  <th>BRAND CODE</th>
                </tr>
              </thead>
              <tbody>
                {store_brands.length > 0 ? store_brands.map((brand,index) => (
                  <tr key={brand.id}>
                  <td>
                    {index+1 + this.state.skip }
                  </td>
                    <td className="nameImg">
                      <img src={brand.logo} alt="wild" className="img-ico" />
                      <span className="p-name">{brand.name} </span>
                    </td>
                    <td>
                      {brand.code}
                    </td>
                    {/* <td>
                      <div className="group-action">
                        <Link className="btn" to={`/admin/edit-brand/${brand.id}`}>
                          <i className="ico icon-edit" />
                        </Link>
                        <button className="btn">
                          <i className="ico icon-delete" />
                        </button>
                      </div>
                    </td> */}
                  </tr>
                )):<tr><td colSpan={'4'}>No data found</td></tr>}
              </tbody>
            </Table>
          </div>
          {/* <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-brand")}>Add</Button> */}
          <div className="pagination-wrapper">
        <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
        nextLabel={<i className="fas fa-angle-right"></i>}
        pageLinkClassName={"page-link"}
        breakClassName={"break-me"}
        pageCount={Math.ceil(this.props.count/this.state.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        activeClassName={"active"} />
        </div>
        </div>
      </div>
    );
  }
}


Brands.defaultProps = {
  store_brands : []
}

const mapStateToProps = (state) => ({
  brandstoreLoading:state.brandReducer.brandstoreLoading,
  store_brands: state.brandReducer.store_brands,
  count:state.brandReducer.count1,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onGetBrands:(store_id,skip,take)=>{
    dispatch(getStoreBrands(store_id,skip,take))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Brands)
