import React, { Component, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { Table} from "reactstrap";
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { getAllDeliverySlots, deleteDeliverySlot, onDismiss } from '../../../../actions/deliverySlotActions';
import { ActionBtn,Button } from "../../../../components";
import SwitchButton from '../../../../components/CustomButton/switchButton';
import { getLoaderClass, WEEK_OPTIONS } from '../../../../utils';

function DeliverySlots({
  history,
  store
}){

  const state = useSelector(state=> state)
  const dispatch = useDispatch()
  const [ initial, setInitial ] = useState(true)
  const [ skip, setSkip ] = useState(0)
  const take = 10
  const { slots, loading, count, popup, status } = state.slotReducer

  useEffect(()=>{
    if(initial){
      onLoad()
      setInitial(false)
    }
    if(popup){
      if(status){
        onLoad(skip)
        swal("Suceess!", "Slot deleted successfully!", "success");
      }else{
        swal("Failed!", "Slot deleted failed!", "error");
      }
      dispatch(onDismiss())
    }
  })

  const onLoad = (skip=0)=>{
    if(store && store.id){
      dispatch(getAllDeliverySlots(skip, take, store.id))
    }else{
      setTimeout(()=>{
        dispatch(getAllDeliverySlots(skip, take, store.id))
      }, 5000)
    }
  }

  const handlePageClick = (data)=>{
    let selected = data.selected;
    let newSkip = selected * take
    setSkip(newSkip)
    onLoad(newSkip);
  }

  const onDelete = (id)=>{
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        dispatch(deleteDeliverySlot(id))
      } 
    });
  }

  return ( 
    <div>
      <div className="content" >
        <div className={getLoaderClass(loading)}></div>
      <div className="table-product-wrapper" >
        <Table>
          <thead>
            <tr>
              <th>Slot Day</th>
              <th>Start Time </th>
              <th>End Time</th>
              <th>Delivery Charge </th>
              <th />
            </tr>
          </thead>
          <tbody>
              {slots && slots.map((slot, index)=>(
                 <tr key={index}>
                  <td>{WEEK_OPTIONS[slot.week_day - 1].label}</td>
                  <td>{slot.from}</td>
                  <td>{slot.to}</td>
                  <td>{slot.shipping_charge}</td>
                  <td>
                    <ActionBtn 
                      deleteClick={()=>onDelete(slot.id)}
                      edit={true} editClick={() => history.push("/store/edit-slot/"+slot.id)}/>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <div className="pagination-wrapper">
      <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
      nextLabel={<i className="fas fa-angle-right"></i>}
      pageLinkClassName={"page-link"}
      breakClassName={"break-me"}
      pageCount={Math.ceil(count/take)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      pageClassName={"page-item"}
      activeClassName={"active"} />
      </div>
      <Button className="fixed-yellow"  onClick={() => history.push("/store/add-slot")}>Add new slot</Button>
    </div>
  </div>
  );
}

 

const mapStateToProps = (state) => ({
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({

})
export default connect(mapStateToProps, mapDispatchToProps)(DeliverySlots)