import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function addNotification(data) {
    return request({
      url:   URLS.SEND_NOTIFICATIONS,
      method: 'POST',
      data:data
    });
}

function getStoreNotification(store_id,skip,take) {
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
        url:   URLS.GET_STORE_NOTIFICATIONS + sub_url,
        method: 'GET'
    });
}


function addAdminNotification(data) {
    return request({
      url:   URLS.SEND_ADMIN_NOTIFICATIONS,
      method: 'POST',
      data:data
    });
}

function getAdminNotification(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
        url:   URLS.GET_ADMIN_NOTIFICATIONS + sub_url,
        method: 'GET'
    });
}

const NotificationService = {
    addNotification,
    getStoreNotification,
    addAdminNotification,
    getAdminNotification
}
export default NotificationService;