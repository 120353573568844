import * as Actions from '../constants/Actions';
import NotificationService from '../services/notificationService';

function addNotification(data){
    return dispatch =>{
        dispatch(request());
        NotificationService.addNotification(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SEND_NOTIFICATIONS_LOADING} }
    function success(data) { return { type: Actions.SEND_NOTIFICATIONS_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.SEND_NOTIFICATIONS_FAILED, error:error} }
}

function getStoreNotification(store_id,skip=null,take=null){

    return dispatch =>{
        dispatch(request());
        NotificationService.getStoreNotification(store_id,skip,take)
        .then(res =>{
            var notifications = res.data;
            var count = res.count;
            dispatch(success(notifications,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_NOTIFICATIONS_LOADING} }
    function success(notifications,count) { return { type: Actions.GET_STORE_NOTIFICATIONS_SUCCESS, notifications: notifications,count} }
    function failed(error) { return { type: Actions.GET_STORE_NOTIFICATIONS_FAILED, error: error} }
}

function addAdminNotification(data){
    return dispatch =>{
        dispatch(request());
        NotificationService.addAdminNotification(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.SEND_ADMIN_NOTIFICATIONS_LOADING} }
    function success(data) { return { type: Actions.SEND_ADMIN_NOTIFICATIONS_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.SEND_ADMIN_NOTIFICATIONS_FAILED, error:error} }
}

function getAdminNotification(skip=null,take=null){

    return dispatch =>{
        dispatch(request());
        NotificationService.getAdminNotification(skip,take)
        .then(res =>{
            var notifications = res.data;
            var count = res.count;
            dispatch(success(notifications,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ADMIN_NOTIFICATIONS_LOADING} }
    function success(notifications,count) { return { type: Actions.GET_ADMIN_NOTIFICATIONS_SUCCESS, notifications: notifications,count} }
    function failed(error) { return { type: Actions.GET_ADMIN_NOTIFICATIONS_FAILED, error: error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}

export {
    addNotification,
    onDismiss,
    getStoreNotification,
    addAdminNotification,
    getAdminNotification
};