import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addAttribute(data) {
    return request({
      url:   URLS.ADD_ATTRIBUTE,
      method: 'POST',
      data:data
    });
}
function editAttribute(data) {
    return request({
      url:   URLS.EDIT_ATTRIBUTE,
      method: 'POST',
      data:data
    });
}

function getAttribute(attr_id) {
    var sub_url = '/'+attr_id;
    return request({
      url:   URLS.GET_ATTRIBUTE + sub_url,
      method: 'GET'
    });
}

function getAttributes(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ATTRIBUTES + sub_url,
      method: 'GET'
    });
}


const AttributeService = {
    addAttribute,
    getAttributes,
    editAttribute,
    getAttribute
}

export default AttributeService;
