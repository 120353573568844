import React, { Component } from 'react';
import classnames from "classnames";
import userAvatar from "../../../assets/img/avatar.png";
import ReactPaginate from 'react-paginate';
import {
    TabContent,
    Row,
    Col,
    Table,
    TabPane,
    Nav,
    NavItem,
    NavLink
  } from "reactstrap";
import { Button } from "../../../components";
import { connect } from "react-redux";
import { getStoreNotification,onDismiss } from "../../../actions/notificationActions";
class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state= {
            activeTab: "2",
            skip:0,
            limit:10
        }  
        this.toggle = this.toggle.bind(this);  
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

    componentDidMount() {
        setTimeout(()=>{
            this.props.onGetNtfs(this.props.store.id,this.state.skip,this.state.limit);
           },100);   
    } 
    handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetNtfs(skip,this.state.limit);
      }

    getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
        const {notifications} = this.props;
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className="form-section">  
                        <Row>
                            <Col md="12">
                            <div className="bottom-nav">
                            <Nav tabs>
                                {/* <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === "1"
                                    })}  onClick={() => {   this.toggle("1");   }} >Inbox</NavLink>
                                </NavItem> */}
                                <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === "2"
                                    })}  onClick={() => {   this.toggle("1");}}>Sent</NavLink>
                                </NavItem>
                            </Nav>
                            </div>
                            </Col>
                        </Row>
                        <TabContent activeTab={this.state.activeTab}>
                        {/* <TabPane tabId="1">
                        <div className="list-notifications notification-wrapper">
                        <Row>
                            <Col md="4">
                            <div className="noti-wrapper">
                            <div className="del-wrapp ab-sr">
                            <Button className="btn-tsp" leftLabel="icon-delete fa red"></Button>
                            </div>
                            <div className="img-w">
                            <img src={noti} alt="wild" className="left"/>
                            </div>
                            <p>
                            <span className="dark-text">Alex Johnson <span className="d-t">15min</span> </span>  
                            <span>Lorem ipsum dolor sit amet Consectetur…</span>
                            </p>
                            </div>  
                            </Col>
                            <Col md="4">
                            <div className="noti-wrapper">
                            <div className="del-wrapp ab-sr">
                            <Button className="btn-tsp" leftLabel="icon-delete fa red"></Button>
                            </div>
                            <div className="img-w">
                            <img src={noti} alt="wild" className="left"/>
                            </div>
                            <p>
                            <span className="dark-text">Alex Johnson <span className="d-t">15min</span> </span>  
                            <span>Lorem ipsum dolor sit amet Consectetur…</span>
                            </p>
                            </div>  
                            </Col>
                        </Row>
                    </div>
                    </TabPane> */}

                    {/* sent */}

                    <TabPane tabId="2">
                    <div className="table-product-wrapper mt-2">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>IMAGE</th>
                        <th>TITTLE</th>
                        <th>USER COUNT</th>
                        <th>DATE</th>
                      </tr>
                    </thead>
                    <tbody>
                    {notifications.length>0 ? notifications.map((noti,index) => (
                        <tr key={noti.id}>
                        <td>
                          {index+1 + this.state.skip}
                        </td>
                        <td className="nameImg">
                        <img src={noti.image !== null ? noti.image : userAvatar} alt="wild" className="img-round" />
                        </td>
                       <td>{noti.title} </td>
                        <td>{noti.count }</td>
                        <td>{noti.created_at}</td>
                      </tr>    
                    )):<tr><td colSpan={'4'}>No data found</td></tr>}
                      
                    </tbody>
                    </Table>
                    </div>
                    <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />

                </div>
                    </TabPane>                    

                    </TabContent>
                </div>
                </div>
                <Button className="fixed-yellow"  onClick={() => this.props.history.push("/store/send-notification")}>Create</Button>
            
            </div>
         );
    }
}
 
Notifications.defaultProps = {
    notifications:[]
}

const mapStateToProps = state => ({
    loading: state.notificationReducer.loading,
    notifications:state.notificationReducer.notifications,
    store:state.storeUserReducer.store,
    count:state.notificationReducer.count
  });
  
  const mapDispatchToProps = dispatch => ({
    onGetNtfs: (store_id,skip,limit) => {
      dispatch(getStoreNotification(store_id,skip,limit));
    },
    onDismiss: () => {
      dispatch(onDismiss());
    }
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notifications);
  