import * as Actions from '../constants/Actions'
const initialState = {
  loading: false,
  status: false,
  orderLoading:false,
  all_orders: [],
  store_orders:[]
};

export default function orderReducer(state = initialState, action) {
  switch(action.type) {
    case Actions.GET_ALL_ORDERS_LOADING:
    return {
      loading: true,
      all_orders: []
    };
  case Actions.GET_ALL_ORDERS_SUCCESS:
    return {
      ...state,
      loading: false,
      all_orders: action.all_orders,
      tab: action.tab,
      count: action.count
    };
  case Actions.GET_ALL_ORDERS_FAILED:
    return {
      ...state,
      loading: false,
      all_orders: [],
      error: action.error
    };

    case Actions.GET_STORE_ORDERS_LOADING:
    return {
      loading: true,
      store_orders: []
    };
  case Actions.GET_STORE_ORDERS_SUCCESS:
    return {
      ...state,
      loading: false,
      store_orders: action.store_orders,
      tab: action.tab,
      count: action.count
    };
  case Actions.GET_STORE_ORDERS_FAILED:
    return {
      ...state,
      loading: false,
      store_orders: [],
      error: action.error
    };


    case Actions.GET_ORDER_LOADING:
    return {
      order: {},
      orderLoading:true,
    };
  case Actions.GET_ORDER_SUCCESS:
    return {
      ...state,
      orderLoading:false,
      order: action.order,
    };
  case Actions.GET_ORDER_FAILED:
    return {
      ...state,
      order: {},
      orderLoading:false,
      error: action.error
    };

  case Actions.CHANGE_ORDER_STATUS_LOADING:
    return {
      loading:true,
      status: true,
      popup: false,
    };  

  case Actions.CHANGE_ORDER_STATUS_SUCCESS:
    return {
      ...state,
      loading:false,
      popup: true,
      status: true,
      message: "Successfull",  
    };
  case Actions.CHANGE_ORDER_STATUS_FAILED:
    return {
      ...state,
      loading:false,
      popup: true,
      message: "Something went wrong",
      status: false,
      error: action.error
    };


  case Actions.EDIT_ORDER_ITEM_LOADING:
    return {
      ...state,
      loading:true,
      status: false,
      popup: false,
    };  

  case Actions.EDIT_ORDER_ITEM_SUCCESS:
    return {
      ...state,
      loading:false,
      popup: true,
      status: true,
      message: "Successfull",  
    };
  case Actions.EDIT_ORDER_ITEM_FAILED:
    return {
      ...state,
      loading:false,
      popup: true,
      message: "Something went wrong",
      status: false,
      error: action.error
    };

    case Actions.DELETE_ORDER_ITEM_LOADING:
      return {
        ...state,
        loading:true,
        status: false,
        popup: false,
      };  
  
    case Actions.DELETE_ORDER_ITEM_SUCCESS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",  
      };
    case Actions.DELETE_ORDER_ITEM_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };

    case Actions.START_SHIPPING_LOADING:
    return {
      loading:true,
      status: true,
      popup: false,
    };  

  case Actions.START_SHIPPING_SUCCESS:
    return {
      ...state,
      loading:false,
      popup: true,
      status: true,
      message: "Successfull",  
    };
  case Actions.START_SHIPPING_FAILED:
    return {
      ...state,
      loading:false,
      popup: true,
      message: "Something went wrong",
      status: false,
      error: action.error
    };

    case Actions.PLACE_ORDER_LOADING:
      return {
        loading:true,
        status: false,
        popup: false,
      };  
  
    case Actions.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",  
      };
    case Actions.PLACE_ORDER_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };

    case Actions.ALERT_DISMISS:
    return {
      ...state,
      status: false,
      popup: false
    };

    default :
    return state;
  }



}