import React, { Component } from 'react';
import { Checkbox } from "../../components";
import {connect} from 'react-redux';
import {getStorePermissions} from '../../actions/userActions';
class Privilages extends Component {
   constructor(props){
       super(props);
       this.state={
               isMounted: false,
               levels: []
       }
   }
   componentDidMount = () => {
       this.setState({ isMounted: true }, () => {
         if (this.state.isMounted) {
        this.setState({ isMounted: false });
       {this.props.onGetPermissions();}
     }
     });
   }

   //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
   componentWillReceiveProps(nextProps) {
       if(nextProps.levels && nextProps.isEditable){
           this.setState({
               levels: nextProps.levels
           })
       }
   }

     handleCheckbox = (e,index) => {
       var levels = this.state.levels;
       const isChecked = e.target.checked;

       if(isChecked){
           var obj = {};
           obj['level_id'] = e.target.name;
           levels[index] = obj;
       }else{
           if(typeof levels[index] !== 'undefined')
           levels[index] = undefined;
       }
       this.setState({levels},()=>(
           this.props.handlePrivilage(this.state.levels)
            ));

     }
   render() {
       return (
           <div className="check-wrapper">
           {this.props.store_permissions.map((item,index)=>(
                 <Checkbox key={index} label={item.type} name={item.id} checked={typeof this.state.levels[index] !== 'undefined'? true:false} onChange={(e)=>this.handleCheckbox(e,index)}/>
           ))}
         </div>
        );
   }
}
Privilages.defaultProps = {
   store_permissions:[],
   isEditable:false
}

const mapStateToProps = (state) => ({
   store_permissions:state.permissionReducer.store_permissions
 })
 const mapDispatchToProps = (dispatch)=> ({
   onGetPermissions:()=>{
       dispatch(getStorePermissions())
     }
 })
 export default connect(mapStateToProps, mapDispatchToProps)(Privilages)