import React, { useState } from 'react';
import placeholder from '../../assets/img/imageplaceholder.png';

function MultipleImage({
    onImagesChange,
    imageReset
}){

    const [ reflector, setReflector ] = useState(true)
    
    const onDrop = (e, key) =>{
        e.preventDefault();
        onFileChange(e, e.dataTransfer.files[0], key);
    }

    const onDelete = (e,idx) => {
        let items = imageReset;
        items.splice(idx,1);
        onImagesChange(items);  
        setReflector(!reflector)
    }
    
    const onFileChange = (e, file, key)=> {
        var file = file || e.target.files[0],
            pattern = /image-*/,
            reader = new FileReader();
            
        if (!file.type.match(pattern)) {
            alert('error');
            return;
        }
        
        reader.onload = (e) => {
            var object = {key:key, url:reader.result};
            if (typeof imageReset !== 'undefined' && imageReset !== null){
                imageReset[key] = object; 
            }else{
                imageReset.push(object);
            }
            onImagesChange(imageReset);
            setReflector(!reflector)
        }
        reader.readAsDataURL(file);
    }

    let labelClass  = `uploader loaded`;

    return (
        <div className="flex-div file-wrapper">
            <span className="control-label">Images</span>
            {imageReset && imageReset.map((multiImages, idx) => (
                <div className={labelClass+ '' + "relative-wrapper"} key={idx}>
                    <label className={labelClass} 
                        onDrop={(e)=>onDrop(e,idx)}
                        >
                        <img 
                            src={multiImages.url?multiImages.url:multiImages.image} 
                            className={multiImages.newImage ? "loaded":''} key={idx}/>
                        
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e,file)=>onFileChange(e,file,idx)}
                            />
                    </label>
                    <span 
                        className="delete-bt" 
                        onClick={(e)=>onDelete(e,idx)}> 
                        <i className="ico icon-cross" /></span>
                </div>
            ))}
            <label className={labelClass} key={imageReset.length}
                onDrop={(e)=>onDrop(e,imageReset.length)}
                >
                    <img src={placeholder} className={ "loaded"} key={'default'}/>
                    <input
                    type="file"
                    accept="image/*"
                    onChange={(e,file)=>onFileChange(e, file, imageReset.length)}
                    />
            </label>
        </div>
    );
}

MultipleImage.defaultProps = {
    imageReset:[]
}
 
export default  MultipleImage;