import React, { Component } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import Select from "react-select";
import * as Constant from '../../../constants/AppConstants';
import {connect} from 'react-redux';
import SweetAlert from 'sweetalert2-react';
import { getAllProducts } from "../../../actions/productActions";
import {getAllCategories} from '../../../actions/categoryActions';
import {editBanner,getBanner,onDismiss} from '../../../actions/BannerActions';
import SimpleReactValidator from 'simple-react-validator';
class EditStillBanner extends Component {
    _id=0;
  constructor(props) {
    super(props);
    this.state = {
      radio_selected: '1',
      isMounted: false,
      productSelected : [],
      catSelected : [],
      isActive: false,
      title:'',
      valid_from:moment(),
      valid_upto:moment(),
      mobile: null,
      clickable:1,
      web: null,
      offer_type:'',
      week:[],
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }
  onDismissPopup(){
    this.props.onDismiss();
  }
  isValidationError(flag){
    this.setState({isFormValidationErrors:flag});
  }
  componentDidMount () {
    var urls = this.props.match.url.split('/');
    this._id = urls[urls.length - 1];
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
     this.setState({ isMounted: false });
    this.props.onGetProductOffers(this.props.store.id);
    this.props.onGetCategoryOffers(this.props.store.id);
    this.props.onGetOffer(this._id);
  }
  });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.banner && typeof nextProps.banner.id !== 'undefined'){
        var from = moment(nextProps.banner.valid_from).format("YYYY-MM-DD");
        var to = moment(nextProps.banner.valid_upto).format("YYYY-MM-DD");
        var days = nextProps.banner.week_days.map((item)=>{
          return item.day_id;
        });

        var items = [];
        if(nextProps.banner.offer_type === 1) {
         items = nextProps.banner.childs.map(function(child) {
        return { value: child.id, label: child.title}; });       
        }
        else {
            items = nextProps.banner.childs.map(function(child) {
                return { value: child.id, label: child.name}; });
        }

      this.setState({
        productSelected : items,
        catSelected : items,
        title:nextProps.banner.title,
        isActive:true,
        valid_from: moment(from),
        valid_upto: moment(to),
        mobile: nextProps.banner.mob_image,
        web: nextProps.banner.web_image,
        offer_type:nextProps.banner.offer_type,
        clickable: nextProps.banner.clickable,
        week:days
      })
     }
  }

  handleCheckBox = (e)=>{
    if(e.target.checked){
      this.setState({
        clickable:1
      })
    }else{
      this.setState({
        clickable:2
      })
    }
  }

  _onWebImageChange= (web)=> {
    this.setState({web});
  }

  _onMobImageChange = (mobile) => {
    this.setState({mobile});
  }

  handleProduct = (productSelected) => {
    this.setState({productSelected});
  }
  handleCat = (catSelected) => {
    this.setState({catSelected});
  }

  handleChangeFrom(date) {
    this.setState({
      valid_from:date,
    });
  }
  handleChangeTo(date) {
    this.setState({
      valid_upto: date
    });
  }
  handleCheck = (week) => {
    this.setState({week});
  }
  handleLimit = () => {
    this.setState({ isActive: !this.state.isActive });
  }
  handleSubmit=(e)=> {
    e.preventDefault();
    var data = {
      store_id:this.props.store.id,
      title :this.state.title,
      web_image:this.state.web,
      mob_image:this.state.mobile,
      banner_type:Constant.BANNER_TYPE_STILL,
      offer_type:this.state.radio_selected,
      clickable: this.state.clickable
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data['valid_from'] = from.format('YYYY-MM-DD');
    data['valid_upto'] = upto.format('YYYY-MM-DD');  
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
   data['week_days']= days_array;

   if(this.state.offer_type === 1){
    var obj = this.state.productSelected.map((item)=>{
      return { item_id: item.value };
    });
    data['items'] = obj;
   }
   else {
    var obj = this.state.catSelected.map((item)=>{
      return { item_id: item.value };
    });
    data['items'] = obj;
   }
   if( this.validator.allValid() ){
    data['banner_id'] = this._id;   
    this.props.onEditBanner(data);
    }
    else {
     this.validator.showMessages();
     this.forceUpdate();
   }  
  }


   //loader
   getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() {
   const {productSelected,catSelected, clickable} = this.state;
  let catOptions = this.props.store_categories.map(function (offer) {
    return { value: offer.id, label: offer.name};
   });
   let productOptions = this.props.all_products.map(function(product){
     return {value:product.id,label:product.title}
   })
    return (
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
          <div className="form-section">
          <form onSubmit={this.handleSubmit}>
            <div className="form-filds-wrapper">
              <Row>
                <Col md="8">
                  <div className="form-group">
                    <div className="flex-wrapper">
                      <FeaturedImage imageReset={this.state.mobile}
                        onImageChange={(mobile) => this._onMobImageChange(mobile)}
                      >
                        For Mobile(150px X 110 Px)
                      </FeaturedImage>
                      <FeaturedImage imageReset={this.state.web}
                        onImageChange={(web) => this._onWebImageChange(web)}
                      >
                        For Web(1920x X 650 Px)
                      </FeaturedImage>
                    </div>
                    <div className="errorMsg">{this.validator.message('image', this.state.mobile, 'required')}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <div className="form-group">
                    <label className="control-label">Banner Name</label>
                    <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})}  className="form-control" />
                    <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                  </div>
                </Col>
                <Col md="8">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Clickable Banner" checked={clickable == 1?true:false}  onChange={(e)=>{this.handleCheckBox(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                {clickable == 1 &&
                <Col md="8">
                <div className="form-group">
                <label className="control-label">{this.state.offer_type === 1 ? 'Product name' : 'Category name' }</label>
                  {this.state.offer_type === 1 ?<Select onChange={this.handleProduct} value={productSelected} options={productOptions} isMulti placeholder={"Search and choose Product Offers"}/>:
                <Select onChange={this.handleCat} options={catOptions} value={catSelected} isMulti placeholder={"Search and choose Category Offers"}/>}
                  
              </div>
              </Col>
                }
                
              </Row>
              <Row>
              <Col md="4">
                    <div className="form-group">
                    <label className="control-label">Valid From</label>
                    <DatePicker className="form-control" 
                    selected={this.state.valid_from}
                    onChange={this.handleChangeFrom}
                    dateFormat="YYYY-MM-DD"
                    name="valid_from"
                    />
                    </div>
                    </Col>
                    <Col md="4">
                    <div className="form-group">
                    <label className="control-label">Valid To</label>
                    <DatePicker className="form-control" 
                    selected={this.state.valid_upto}
                    onChange={this.handleChangeTo}
                    dateFormat="YYYY-MM-DD"
                    name="valid_upto"
                    />
                    </div>
                    </Col>
              </Row>
              <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                      <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom isEditable={true} week={this.state.week}
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                      </Col> 
                    </Row>
              <Row>
                <Col md="12">
                  <div className="submit-wrapper">
                    <Button className="btn-submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </div>
            <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
          </form>
          </div>
        </div>
      </div>
    );
  }
}
EditStillBanner.defaultProps = {
  store_categories:[],
  all_products:[],
  banner:{}
}

const mapStateToProps = (state) => ({
  store_categories: state.categoryReducer.store_categories,
  all_products: state.productReducer.all_products,
  loading:state.bannerReducer.loading,
  status:state.bannerReducer.status,
  message:state.bannerReducer.message,
  popup:state.bannerReducer.popup,
  banner:state.bannerReducer.banner,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onGetProductOffers:(store_id)=>{
    dispatch(getAllProducts(store_id))
  },
  onGetCategoryOffers:(store_id)=>{
    dispatch(getAllCategories(store_id))
  },
  onGetOffer:(banner_id) => {
    dispatch(getBanner(banner_id));
  },
  onEditBanner:(data)=> {
    dispatch(editBanner(data));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditStillBanner)
