import React,{Component, useEffect, useRef, useState} from 'react';
import {Row,Col, Input} from "reactstrap";
import swal from 'sweetalert';
import { Button,Fselect } from "../../../../components";
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import moment from "moment";
import {onDismiss} from '../../../../actions/brandActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
import { getLoaderClass, WEEK_OPTIONS, showError } from '../../../../utils';
import { addDeliverySlot, editDeliverySlot, getDeliverySlot } from '../../../../actions/deliverySlotActions';


function DeliverySlotForm({
  loading,
  slot,
  match,
  store,
  ...props
}){

  const [start_time, setStartTime] = useState(null)
  const [end_time, setEndTime] = useState(null)
  const [week_day, setWeekDay] = useState(null)
  const [shipping_charge, setShippingCharge] = useState('')
  const [ edit, setEdit ] = useState(false)
  const [ initial, setInitial ] = useState(true)
  const validator = new SimpleReactValidator()
  const params = match.params
  const loadRef = useRef()
  const currentItemRef = useRef()

  useEffect(()=>{
    if(initial){
      if(params && params.id){
        setEdit(true)
        props.onGetSlot(params.id)
      }
      setInitial(false)
      
    }

    if(loadRef && loadRef.current && !loading){
      if(props.popup){
        if(props.status){
          swal("Successfully", "Slot has been saved successfully", "success");
          props.onDismiss()
          if(!edit)reset()
        }else{
          swal("Failed", "Slot saving failed", "error");
        }
      }
    }

    if(edit && currentItemRef.current && !currentItemRef.current.id && slot && slot.id){
      setStartTime(moment(slot.from, 'HH:mm'))
      setEndTime(moment(slot.to, 'HH:mm'))
      setShippingCharge(slot.shipping_charge)
      setWeekDay(WEEK_OPTIONS[slot.week_day - 1])
    }
    loadRef.current = loading
    currentItemRef.current = slot
  })

  const reset = ()=>{
    setWeekDay(null)
    setStartTime(null)
    setEndTime(null)
    setShippingCharge('')
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if( validator.allValid() ){
      var data = {
        shipping_charge,
        from: moment(start_time).format('h:m a'),
        to: moment(end_time).format('h:m a'),
        store: store.id,
        week_day: week_day.value
      }
      if(edit){
        data['slot_id'] = params.id
        props.onUpdateSlot(data)
      }else{
        props.onAddSlot(data)
      }
    }    
    else {
      validator.showMessages();
    }      
  }

  return ( 
    <div>
      <div className="content">
        <div className={getLoaderClass(loading)}></div>
        <div className="form-section">  
          <form onSubmit={handleSubmit}>
            <div className="form-filds-wrapper">
                <Row>
                  <Col md="4">
                    <div className="form-group">
                      <label className="control-label">Choose Day*</label>
                      <Fselect 
                        placeholder="Choose" 
                        options={WEEK_OPTIONS} 
                        value={week_day}
                        handleChange={(e)=>setWeekDay(e)}/>
                       <div className="errorMsg">{validator.message('week_day', week_day, 'required')}</div>
                       {showError('week_day', validator.errorMessages)}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label className="control-label">Starting Time *</label>
                        <DatePicker className="form-control"
                        selected={start_time}
                        onChange={(e)=>setStartTime(e)}
                        placeholderText="Date from"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:m a"
                        />
                    </div>
                    <div className="errorMsg">{validator.message('start_time', start_time, 'required')}</div>
                    {showError('start_time', validator.errorMessages)}
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label className="control-label">Ending Time *</label>
                      <DatePicker className="form-control"
                        selected={end_time}
                        onChange={(e)=>setEndTime(e)}
                        placeholderText="Date end"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="h:m a"
                        />
                        <div className="errorMsg">{validator.message('end_time', end_time, 'required')}</div>
                        {showError('end_time', validator.errorMessages)}
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col md="4">
                    <div className="form-group">
                      <label className="control-label">Shipping Charge*</label>
                      <input type="text" onChange={(e)=>setShippingCharge(e.target.value)} value={shipping_charge} className="form-control" />
                      <div className="errorMsg">{validator.message('charge', shipping_charge, 'required')}</div>
                      {showError('charge', validator.errorMessages)}
                    </div>
                  </Col>
                  {/* <Col md="8">
                    <div className="form-group">
                        <label className="control-label">Default Delivery Boy </label>
                        <Fselect placeholder="Choose"/>
                      </div>
                    </Col> */}
                </Row>
                <Row>
                    <Col md="12">
                    <div className="submit-wrapper">
                    <Button className="btn-submit" >Save</Button>
                    </div>
                    </Col>
                </Row>
              </div>
            </form>  
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.slotReducer.loading,
  status:state.slotReducer.status,
  message:state.slotReducer.message,
  popup:state.slotReducer.popup,
  slot: state.slotReducer.slot,
  store:state.storeUserReducer.store,
})

const mapDispatchToProps = (dispatch)=> ({

  onDismiss:()=>{
    dispatch(onDismiss())
  },
  onGetSlot:(id)=>{
    dispatch(getDeliverySlot(id))
  },
  onAddSlot:(data)=>{
    dispatch(addDeliverySlot(data))
  },
  onUpdateSlot:(data)=>{
    dispatch(editDeliverySlot(data))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(DeliverySlotForm)