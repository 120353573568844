import React, { Component } from 'react';
import { Row,Col } from "reactstrap";
import { Button } from '../../../components';
import {connect} from "react-redux";
import {saveAbout,getAbout,onDismiss} from "../../../actions/ContentActions";
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from "simple-react-validator";
class AboutUs extends Component {
    constructor (props) {
        super(props);
        this.state = {
          isMounted: false,
          content:''
        }
        this.onDismissPopup = this.onDismissPopup.bind(this);
        this.validator = new SimpleReactValidator();
      }
      componentDidMount() {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
               this.props.onGetAbout();
      }
      });
      }
      //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
      componentWillReceiveProps(nextProps) {
        if(nextProps.contents && typeof nextProps.contents.id !== 'undefined') {
          this.setState({
            content:nextProps.contents.content
          })
        }
      }

      handleSubmit = (e) => {
      e.preventDefault();
        var data = {
          content: this.state.content
        }

    if (this.validator.allValid()) {
      this.props.onSaveAbout(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
 
      }
      onDismissPopup(){
        this.props.onDismiss();
        this.props.onGetAbout();
      }
      getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
    return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <form onSubmit = {this.handleSubmit}>
                  <div className="form-section border">  
                    <div className="form-filds-wrapper mt-0">
                        <Row>
                            <Col md="12">
                                <div className="dis-editor">
                                  <textarea className="form-control" value={this.state.content} onChange={(e)=>this.setState({content:e.target.value})}  rows="25"></textarea>
                                  <div className="errorMsg">  {this.validator.message("content",this.state.content, "required" )}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                  </div>
                  <Button type="submit" className="fixed-yellow">Save</Button>
                  </form>
                </div>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
         );
    }
}
 

AboutUs.defaultProps = {
  contents:{}  
}
const mapStateToProps = (state) => ({
  loading: state.aboutReducer.loading,
  status:state.aboutReducer.status,
  contents: state.aboutReducer.contents,
  message:state.aboutReducer.message,
  popup:state.aboutReducer.popup
});

const mapDispatchToProps = (dispatch)=> ({
  onSaveAbout:(data)=>{
    dispatch(saveAbout(data))
  },
  onGetAbout:()=>{
    dispatch(getAbout())
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);