import React, { Component } from "react";
import Login from "./layouts/Login/Login.jsx";
import ForgotPassword from "./layouts/Login/forgotPassword.jsx";
import verifyOtp from "./layouts/Login/verifyOTP.jsx";
import ResetPassword from "./layouts/Login/resetPassword.jsx";
import StoreLogin from "./layouts/StoreLogin/StoreLogin.jsx";
import ForgotStorePassword from "./layouts/StoreLogin/forgotPassword.jsx";
import StoreverifyOtp from "./layouts/StoreLogin/verifyOTP.jsx";
import ResetStorePassword from "./layouts/StoreLogin/resetPassword.jsx";
import {Route,Switch,BrowserRouter} from'react-router-dom';
import Dashboard from "./layouts/Dashboard/Dashboard.jsx";
import StoreDashboard from "./layouts/StoreDashboard/StoreDashboard.jsx";
import 'bootstrap/dist/js/bootstrap.min.js'
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/dashboard.css";
import "./assets/css/main.css";
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {

  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            {/* admin */}
            <Route exact path="/admin/login" component={Login} />
            <Route  path="/admin/forgot" component={ForgotPassword} />
            <Route  path="/admin/verify" component={verifyOtp} />
            <Route  path="/admin/reset" component={ResetPassword} />
            <Route  path="/admin" component={Dashboard} />
              {/* Store */}
            <Route exact path="/store/login" component={StoreLogin} />
            <Route  path="/store/forgot" component={ForgotStorePassword} />
            <Route  path="/store/verify" component={StoreverifyOtp} />
            <Route  path="/store/reset" component={ResetStorePassword} />
            <Route path="/store" component={StoreDashboard} />

          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </div>
      
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.userReducer.loggedIn,
  storeloggedIn: state.storeUserReducer.storeloggedIn
})


const mapDispatchToProps = (dispatch)=> ({
 
})
export default connect(mapStateToProps, mapDispatchToProps)(App)









