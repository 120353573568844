import React,{Component} from 'react';
import { Button } from '../../../components';
import {Link} from 'react-router-dom';
import { Table } from "reactstrap";
import SwitchButton from '../../../components/CustomButton/switchButton';
import {connect} from 'react-redux';
import{getStores,storeStatus,onDismiss} from '../../../actions/storeActions';
import * as Constant from '../../../constants/AppConstants';
import ReactPaginate from 'react-paginate';
import SweetAlert from 'sweetalert2-react';
class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      storeLoading:false,
      skip:0,
      alert:false,
      limit:12,
      selectedId:0,
      show: false
    }
    this.onDismissPopup = this.onDismissPopup.bind(this);
  }
      componentDidMount = () => {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
        {this.props.onGetStores(this.state.skip,this.state.limit)}
      }
      });
      }

      onDismissPopup (){
        this.props.onDismiss();
        {this.props.onGetStores(this.state.skip,this.state.limit)}
      }

      onConfirm = ()=>{
        var status ;
          if(typeof this.state.stat !== undefined && this.state.stat == true) {
            status = Constant.STORE_AVAILABLE;
          }
          else {
            status = Constant.STORE_DISABLED;
          }
        this.props.onChangeStatus(this.state.selectedId,status);
        this.setState({alert:false})
        // {this.props.onGetStores(this.state.skip,this.state.limit)}
      }
      
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetStores(skip,this.state.limit);
      };
      getLoder = () => {
        let classes = "bt-loader ";
        classes += this.props.storeLoading || this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
      handleStatus = (stat,id) => {
        this.setState({
          stat:stat,
          selectedId:id,
          alert:true
        });

        
      }
    render() { 
      const {stores} = this.props;
        return ( 
<div>
      <div className="content">
      <div className={this.getLoder()}></div>
        <div className="table-product-wrapper">
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>AVAILABILITY</th>
                <th>STORE NAME </th>
                <th>CONTACT ADDRESS</th>
                <th/>
              </tr>
            </thead>
            <tbody>
                {stores.length>0?stores.map((store,index) => (
                    <tr key={store.id}>
                    <td>{index+1+this.state.skip}</td>
                  <td> <SwitchButton changeStatus={((stat)=>this.handleStatus(stat,store.id))}  enabled={store.status ===1? true:false}/></td>
                  <td className="nameImg">
                  <img src={store.featured_image} alt="wild" className="img-ico" />
                    <span className="p-name">{store.title} </span>
                  </td>
                  {/* <td>
                  {store.contacts.length>0 ? store.contacts.map((contact,index) => (
              <span key={index} className="group-name">{contact.contact}</span>
            ) ):null} 
                  </td> */}
                  <td>
                  <div className="group-action text-right pr-10">
                    <Link className="btn" to={`/admin/edit-store/${store.id}`}>
                    <i className="ico c1 icon-edit-1 " />
                    </Link>
                  </div>
                  </td>
                </tr> 
                ) ):null}
             
            </tbody>
          </Table>
        <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-store")}>Add</Button>
        </div>
        <div className="pagination-wrapper">
        <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                       nextLabel={<i className="fas fa-angle-right"></i>}
                       pageLinkClassName={"page-link"}
                       breakClassName={"break-me"}
                       pageCount={Math.ceil(this.props.count/this.state.limit)}
                       marginPagesDisplayed={2}
                       pageRangeDisplayed={5}
                       onPageChange={this.handlePageClick}
                       containerClassName={"pagination"}
                       pageClassName={"page-item"}
                       activeClassName={"active"} />
        </div>
        <SweetAlert
                  show={this.state.alert }
                  onConfirm={this.onConfirm}
                  onCancel = {() => this.setState({ alert: false })}
                  title= {'Are you sure?'}
                  text="Change status"
                  type= {'warning'}
                  showCancelButton= {true}
                  confirmButtonText= {'Yes, Confirm it!'}
                  cancelButtonText= {'No, keep it'}
                  />
                  <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
      </div>
    </div>
         );
    }
}

Stores.defaultProps = {
  stores:[] 
}

const mapStateToProps = (state) => ({
  loading: state.storeReducer.loading,
  status:state.storeReducer.status,
  message:state.storeReducer.message,
  popup:state.storeReducer.popup,
  storeLoading:state.storeReducer.storeLoading,
  stores: state.storeReducer.stores,
  count:state.storeReducer.count,
})

const mapDispatchToProps = (dispatch)=> ({
  onGetStores:(skip,take)=>{
    dispatch(getStores(skip,take))
  },
  onChangeStatus:(store_id,status)=>{
    dispatch(storeStatus(store_id,status))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Stores)