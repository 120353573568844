import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  items:[],
  item:{}
};

export default function importReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_STORE_UNIMPORTED_LOADING:
      return {
          ...state,
        loading: true,
      };
    case Actions.GET_STORE_UNIMPORTED_SUCCESS:
      return {
        ...state,
        loading: false,
        count: action.count,
        items: action.data
      };
    case Actions.GET_STORE_UNIMPORTED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case Actions.GET_STORE_UNIMPORTED_ITEM_LOADING:
      return {
          ...state,
        loading: true,
      };
    case Actions.GET_STORE_UNIMPORTED_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.data
      };
    case Actions.GET_STORE_UNIMPORTED_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case Actions.ADD_NEW_PRODUCTS_LOADING:
      return {
        loading: true,
        popup: false,
        status:false
      };
    case Actions.ADD_NEW_PRODUCTS:
      return {
        loading: false,
        message: "Products added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_NEW_PRODUCTS_FAILED:
      return {
        loading: false,
        popup: true,
        message: "Products add failed",
        status: false,
        error: action.error
      };


      case Actions.PRODUCT_STATUS_LOADING:
      return {
        loading:true,
        status: true,
        popup: false,
      };
    case Actions.PRODUCT_STATUS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",
      };
    case Actions.PRODUCT_STATUS_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };
   
      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
