import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  privacy: null
};

export default function privacyReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_PRIVACY_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.SAVE_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Privacy added successfully",
        popup: true,
        status: true
      };
    case Actions.SAVE_PRIVACY_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Failed to add Privacy",
        status: false,
        error: action.error
      };

    case Actions.GET_PRIVACY_LOADING:
      return {
        loading: true,
        parivacy: state.privacy
      };
    case Actions.GET_PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        privacy: action.privacy
      };
    case Actions.GET_PRIVACY_FAILED:
      return {
        ...state,
        loading: false,
        privacy: null,
        error: action.error
      };

    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


      
    default:
      return state;
  }
}
