import React, { Component } from "react";
import { Row, Col, Input, FormGroup } from "reactstrap";
import PhoneInput from 'react-phone-number-input'
import { Button,Fselect, Checkbox } from '../../../components';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import Select from "react-select";
import LocationSearchInput from './mapFeild';  
import moment from "moment";
import SweetAlert from 'sweetalert2-react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import {editStore,getStore,onDismiss} from '../../../actions/storeActions';
import { getCountries,getStates,getRegions,getCities} from '../../../actions/addressActions';
import {getWeeks} from '../../api/week';
import SimpleReactValidator from 'simple-react-validator';
import CurrencySelect from "../../../components/SelectComponents/CurrencySelect";
class EditSrore extends Component {
  _id = 0;
  constructor(props) {
    super(props);
    this.state = {
      weeks:getWeeks(),
      title:'',
      code:'',
      open:null,
      close:null,
      building:'',
      street:'',
      city:'',
      region:'',
      zip:'',
      emailid:'',
      phone:'',
      store_map:'',
      country_selected:'',
      region_selected:'',
      state_selected:'',
      city_selected:'',
      isMounted: true,
      markers:[],
      details:[{week_day:'',open:null,close:null}],
      radius:'',
      min_amount:'',
      number_orders:'',
      offer: '',
      fast_delivery:true,
      normal_delivery:true,
      take_away: true,
      currency: null,
      latitude:'',
      longitude:'',
      delivery_charge: '',
      delivery_time: 30,
      enable_cod: true,
      enable_online: true
    }
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentWillReceiveProps(nextProps,prevProps) {
    if(this.props.loading && !nextProps.loading && nextProps.store && !prevProps.store && 
      typeof nextProps.store.id !== 'undefined' ){
      var currency = { value: nextProps.store.currency.id, label: nextProps.store.currency.currency};

      var locations = [];
      locations.push({latitude: parseFloat(nextProps.store.latitude), longitude: parseFloat(nextProps.store.longitude) });
      this.setState({
        title:nextProps.store.title,
        code:nextProps.store.code,
        radius:nextProps.store.radius,
        min_amount:nextProps.store.min_amount,
        number_orders:nextProps.store.number_orders,
        fast_delivery:nextProps.store.fast_delivery,
        normal_delivery:nextProps.store.normal_delivery,
        take_away:nextProps.store.take_away,
        offer: nextProps.store.offer,
        store_unique_id:nextProps.store.store_unique_id,
        store_image:nextProps.store.featured_image,
        building:nextProps.store.building,
        emailid: nextProps.store.email,
        phone: nextProps.store.contact,
        markers: locations,
        street: nextProps.store.street,
        currency,
        delivery_time: nextProps.store.delivery_time,
        enable_cod: nextProps.store.enable_cod,
        enable_online: nextProps.store.enable_online,
        delivery_charge: nextProps.store.delivery_charge,
        store_map:{
          latitude: nextProps.store.latitude,
          longitude: nextProps.store.longitude
        }
    })
    }
  }

  componentDidMount() {
    var urls = this.props.match.url.split('/');
    this._id = urls[urls.length - 1]
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
     this.setState({ isMounted: false });
    {this.props.onGetCountries();}
    {this.props.onGetStore(this._id)}
  }
  });
  }

  _onImageChange = (store_image) => {
    this.setState({store_image});
  }
  _onProfileChange = (admin_image) => {
    this.setState({admin_image});
  }
  handleRegion = (region_selected) => {
      this.setState({region_selected});
      this.props.onGetCities(region_selected.value); 
  }
  handleCountry = (country_selected) =>{
    this.setState({country_selected});
    this.props.onGetStates(country_selected.value);
  }

  handleState = (state_selected) => {
    this.setState({state_selected});
    this.props.onGetRegions(state_selected.value);
  }
  handleCity = (city_selected) => {
    this.setState({city_selected});
  }
  selectLocation= (store_map)=> {
    this.setState({store_map});
  }
  addRow = () => {
    this.setState(prevState => ({ details: [...prevState.details, {"week_day":null,"open":null,"close":null}]}))
  }
  deleteRow = (e,i) => {
    let details = this.state.details;
    details.splice(i,1);
    this.setState({details}); 

  }
  onDismissPopup (){
    this.props.onDismiss();
    this.props.onGetStore(this._id);
  }
  handleStartTime = (time,i) => {
    var items = this.state.details;
    items[i]['open'] = time;
    this.setState({
      open:items
    })
  }
  handleEndTime = (time,i) => {
    var items = this.state.details;
    items[i]['close'] = time;
    this.setState({
      close:items
    })
  }
  handleWeek = (week_day,i) => {
    var items = this.state.details;
    items[i]['week_day'] = week_day;
    this.setState({
      week_day:items
    })
  }

  handleCurrency = (currency) =>{
    this.setState({currency});
  }

  handleSubmit = (e) => {
    e.preventDefault();

    var data = {
      title:this.state.title,
      code:this.state.title,
      store_unique_id:parseInt(this.state.store_unique_id),
      featured_image:this.state.store_image,
      min_amount: this.state.min_amount,
      offer: this.state.offer,
      radius: this.state.radius,
      number_orders: this.state.number_orders,
      normal_delivery: this.state.normal_delivery,
      fast_delivery: this.state.fast_delivery,
      take_away: this.state.take_away,
      building:this.state.building,
      street:this.state.street,
      contact: this.state.phone,
      email: this.state.emailid,
      latitude: this.state.store_map.latitude,
      longitude: this.state.store_map.longitude,
      currency: this.state.currency?this.state.currency.value:null,
      delivery_charge: this.state.delivery_charge,
      delivery_time: this.state.delivery_time,
      enable_cod: this.state.enable_cod,
      enable_online: this.state.enable_online
    }

    if( this.validator.allValid() ){
      data['store_id'] = this._id;
      this.props.onEditStore(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
     //loader
     getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    AvailabilityUI() {
      let weekOptions = this.state.weeks.map(function(week,ind) {
        return { value: week.id, label: week.name, index:ind+1 };
      });
      return    this.state.details.map((item,i) =>(
       <Row key={i}>
        <Col md="4">
          <div className="form-group">
            <label className="control-label">Choose Day</label>
            <Fselect placeholder="Choose" options={weekOptions} handleChange={(e)=>this.handleWeek(e,i)} value={item.week_day}/>
            <div className="errorMsg">{this.validator.message('week day', item.week_day, 'required')}</div>
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
          <label className="control-label">Opening time</label>
          <DatePicker className="form-control"
          selected={item.open}
          onChange={(e)=>this.handleStartTime(e,i)}
          placeholderText="Opening time"
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          timeIntervals={30}
          dateFormat="HH:mm"
          />
          <div className="errorMsg">{this.validator.message('From time', item.open, 'required')}</div>
        </div>
      </Col>
      <Col md="3">
        <div className="form-group">
          <label className="control-label">
            Closing time
          </label>
          <DatePicker className="form-control"
          selected={item.close}
          onChange={(e)=>this.handleEndTime(e,i)}
          placeholderText="Closing time"
          showTimeSelect
          showTimeSelectOnly
          timeFormat="HH:mm"
          timeIntervals={30}
          dateFormat="HH:mm"
          />
        <div className="errorMsg">{this.validator.message('Closing time', item.close, 'required')}</div>
        </div>
      </Col>
      <Col md="2">
        <Button className="btn-tsp mt-4" onClick={(e)=>this.deleteRow(e,i)} leftLabel="icon-delete fa red"></Button>
      </Col>
       </Row>
      ))
    }
 
  render() {
    const {longitude, normal_delivery, fast_delivery, latitude,} = this.state;
    const {country_selected} = this.state;
    const {state_selected} = this.state;
    const {city_selected} = this.state;

    let countryOptions = this.props.countries.map(function (country) {
      return { value: country.id, label: country.country };
    });
    let stateOptions = this.props.states.map(function (stat) {
      return { value: stat.id, label: stat.state };
    });
    let regionOptions = this.props.regions.map(function(region){
      return {value: region.id, label: region.region}
    });
    let cityOPtions = this.props.cities.map(function(city){
      return {value:city.id,label:city.city}
    }) 

    return (
      <div>
        <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">
            <form onSubmit={this.handleSubmit}>
              <div className="form-filds-wrapper mt-0">
                <Row>
                  <Col md="10">
                    <Row>
                      <Col md="12">
                        <div className="smal-heading">
                          <h6>Store Details</h6>
                        </div>
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage  imageReset={this.state.store_image} onImageChange={(store_image)=>this._onImageChange(store_image)} />
                          </div>
                          <div className="errorMsg">{this.validator.message('image', this.state.store_image, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Fast Delivery" checked={fast_delivery} onChange={(e)=>{this.setState({fast_delivery:e.target.checked})}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Normal Delivery" checked={normal_delivery} onChange={(e)=>{this.setState({normal_delivery:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Enable COD" checked={this.state.enable_cod} onChange={(e)=>{this.setState({enable_cod:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Take Away" checked={take_away} onChange={(e)=>{this.setState({take_away:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Store name</label>
                          <Input value={this.state.title} onChange ={(e)=>this.setState({title:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Code
                          </label>
                          <Input value={this.state.code} onChange ={(e)=>this.setState({code:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Delivery Radius
                          </label>
                          <Input value={this.state.radius} onChange ={(e)=>this.setState({radius:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('radius', this.state.radius, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Delivery Charge
                          </label>
                          <Input value={this.state.delivery_charge} onChange ={(e)=>this.setState({delivery_charge:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('delivery_charge', this.state.delivery_charge, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Fast Delivery Time
                          </label>
                          <Input value={this.state.delivery_time} onChange ={(e)=>this.setState({delivery_time:e.target.value})} 
                            type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('delivery_time', this.state.delivery_time, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Minimum Delivery Amount
                          </label>
                          <Input value={this.state.min_amount} onChange ={(e)=>this.setState({min_amount:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('amount', this.state.min_amount, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Number of Orders
                          </label>
                          <Input value={this.state.number_orders} onChange ={(e)=>this.setState({number_orders:e.target.value})} type="text" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Offer by %
                          </label>
                          <Input value={this.state.offer} onChange ={(e)=>this.setState({offer:e.target.value})} type="text" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Store Unique ID
                          </label>
                          <Input value={this.state.store_unique_id?this.state.store_unique_id:''} onChange ={(e)=>this.setState({store_unique_id:e.target.value})} type="number" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Building
                          </label>
                          <Input value={this.state.building} onChange ={(e)=>this.setState({building:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('building', this.state.building, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Street
                          </label>
                          <Input value={this.state.street} onChange ={(e)=>this.setState({street:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('street', this.state.street, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Currency</label>
                          <CurrencySelect  onChange ={this.handleCurrency} value={this.state.currency} placeholder={'Choose a currency'}/>
                          <div className="errorMsg">{this.validator.message('country', this.state.currency && this.state.currency.value, 'required')}</div>
                      </div>
                      </Col>
                      {/* <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Country</label>
                          <Select options={countryOptions} onChange ={this.handleCountry} value={country_selected} placeholder={'Choose a country'}/>
                          <div className="errorMsg">{this.validator.message('country', country_selected.value, 'required')}</div>
                      </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">State</label>
                          <Select options={stateOptions} onChange ={this.handleState} value={state_selected} placeholder={'Choose a state'}/>
                          <div className="errorMsg">{this.validator.message('country', country_selected.value, 'required')}</div>
                       </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Region
                          </label>
                          <Select options={regionOptions} onChange ={this.handleRegion} value={region_selected} placeholder={'Choose a region'} />
                          <div className="errorMsg">{this.validator.message('region', region_selected.value, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            City
                          </label>
                          <Select options={cityOPtions} onChange ={this.handleCity} value={city_selected} placeholder="Choose a city" />
                          <div className="errorMsg">{this.validator.message('city', city_selected.value, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Zip/ Postal Code
                          </label>
                          <Input value={this.state.zip} onChange ={(e)=>this.setState({zip:e.target.value})} type="number" className="form-control" />
                          <div className="errorMsg">{this.validator.message('zip', this.state.zip, 'required')}</div>
                        </div>
                      </Col> */}
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Contact Number
                          </label>
                          <PhoneInput defaultCountry="IN" value={this.state.phone} onChange ={(e)=>this.setState({phone:e})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('phone', this.state.phone, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Email Address
                          </label>
                          <Input value={this.state.emailid} onChange ={(e)=>this.setState({emailid:e.target.value})} type="email" className="form-control" />
                        </div>
                      </Col>
                      </Row>
                      {/* {this.AvailabilityUI()}
                      <Button className="btn-plus" onClick = {()=>this.addRow()} leftLabel="fa fa-plus">Add Availabity</Button> */}
                      <Row>
                      <Col md="12">
                        <div className="form-group">
                        <label className="control-label">Add map place</label>
                        <LocationSearchInput isEditable ={true}
                          onMapChange={(store_map)=>this.selectLocation(store_map)}
                          center={latitude, longitude}
                          markers2 = {this.state.markers}
                          markers = {this.state.markers}/>
                          </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <div className="submit-wrapper">
                      <Button type="submit" className="btn-submit">Update</Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
              
              </form>
            </div>
          </div>
        </div>
      </div>
    );
    
  }

}
const mapStateToProps = (state) => ({
  loading: state.storeReducer.loading,
  status:state.storeReducer.status,
  message:state.storeReducer.message,
  popup:state.storeReducer.popup,
  store:state.storeReducer.store,
  addrLoading: state.AddressReducer.addrLoading,
  countries: state.AddressReducer.countries,
  states: state.AddressReducer.states,
  regions: state.AddressReducer.regions,
  cities: state.AddressReducer.cities,
})

EditSrore.defaultProps = {
  store:{}
}

const mapDispatchToProps = (dispatch)=> ({
  onEditStore:(data)=>{
    dispatch(editStore(data));
  },
  onGetCountries:()=>{
    dispatch(getCountries())
  },
  onGetStates:(country_id)=>{
    dispatch(getStates(country_id));
  },
  onGetRegions:(state_id)=>{
    dispatch(getRegions(state_id));
  },
  onGetCities:(region_id)=>{
    dispatch(getCities(region_id));
  },
  onGetStore:(store_id)=>{
    dispatch(getStore(store_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditSrore)
