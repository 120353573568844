import React, { useEffect, useState } from 'react';
import ReactChipInput from 'react-chip-input';
import Select from "react-select";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import swal from 'sweetalert';

import { Button } from '../../../../components';
import SwitchButton from '../../../../components/CustomButton/switchButton';
import FeaturedImage from "../../../../components/imageUpload/FeatureImage";
import MultipleImage from "../../../../components/imageUpload/MultipleImage";
import { showError } from '../../../../utils';

const WEIGHT_OPTIONS = [
    {value: "1", label:'1'},
    {value: "2", label:'2'},
    {value: "5", label:'5'},
    {value: "250", label:'250'},
    {value: "500", label:'500'},
    {value: "750", label:'750'}
  
  ]

export default function VariantForm({
    handleImageChange,
    index,
    featured_image,
    images,
    onItemImagesChange,
    validator,
    itemDelete,
    attributes,
    attribute_values,
    handleItemAttributes,
    onChangeText,
    elementKey,
    tags,
    onChangeTags,
    type,
    units,
    onChangeStatus,
    unit,
    ...item
}){

    var wu = item.weight_unit
    if(wu){
        if(typeof wu === 'object'){

        }else if(unit){
            wu = {value: unit.id, label: unit.unit}
        }
    }

    const [ values, setAttributeValue ] = useState([])
    const [ reflector, setReflector ] = useState(false)

    const unitOptions = units?units.map((unit)=>{
        return { value: unit.id, label: unit.unit}
    }):[]

    const onChangeSelect= (item, index, i, attribute_id)=>{
        values[i] = {...item, attribute_id}
        setAttributeValue(values)
        setReflector(!reflector)
        handleItemAttributes(values)
    }

    useEffect(()=>{
        if(attribute_values && attribute_values.length>0 && values.length < 1){
            setAttributeValue(attribute_values)
        }
    })

    const onChangeItemStatus = (id, status)=>{
        swal({
            title: "Are you sure?",
            text: "Do you want to change the status",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                if(onChangeStatus)onChangeStatus(id, status)
            } 
          });
    }


    const addChip = value => {
        tags.push(value);
        onChangeTags(tags)
    };

    const removeChip = index => {
        const chips = tags.slice();
        chips.splice(index, 1);
        onChangeTags(chips)
    };

    const renderStoreProductFields = ()=>{

        if(type != "StoreProduct" ) return null

        return(
            <>
                <Col md="4">
                    <FormGroup>
                        <Label className="control-label">Selling Price</Label>
                        <Input type="text" 
                            value={item.selling_price} 
                            name={"selling_price"}
                            onChange={onChangeText} />
                            <div className="errorMsg">
                                {validator.message('selling_price', item.selling_price, 'required')}</div>
                                {showError('selling_price', validator.errorMessages)}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label className="control-label">Discount Price</Label>
                        <Input type="text" 
                            value={item.discount_amount} 
                            name={"discount_amount"}
                            onChange={onChangeText} />
                            <div className="errorMsg">
                                {validator.message('discount_amount', item.discount_amount, 'required')}</div>
                                {showError('discount_amount', validator.errorMessages)}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label className="control-label">SKU</Label>
                        <Input type="text" 
                            value={item.sku} 
                            name={"sku"}
                            onChange={onChangeText} />
                            <div className="errorMsg">
                                {validator.message('sku', item.sku, 'required')}</div>
                                {showError('sku', validator.errorMessages)}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label className="control-label">Stock</Label>
                        <Input type="text" 
                            value={item.stock_level} 
                            name={"stock_level"}
                            onChange={onChangeText} />
                            <div className="errorMsg">
                                {validator.message('stock_level', item.stock_level, 'required')}</div>
                                {showError('stock_level', validator.errorMessages)}
                    </FormGroup>
                </Col>
            </>
            
        )
    }

    return(
        <Col md="12" key={'a_'+elementKey}>
            <div className="variants-wrapper">
                <div className="form-filds-wrapper">
                    <Row>
                        <div className="col-md-12" >
                            <div className="form-group">
                                <div className="flex-wrapper" style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }}>
                                    <FeaturedImage 
                                        imageReset={featured_image} 
                                        onImageChange={handleImageChange}>Featured Image</FeaturedImage>
                                    <MultipleImage 
                                        imageReset={images} 
                                        key={'im'+elementKey}
                                        onImagesChange={onItemImagesChange}/>
                                </div>
                                {/* <div className="errorMsg">
                                    {validator.message('featured_image', featured_image, 'required')}
                                </div> */}
                                {item.id ? <div className="del-wrapp ab-right-80">
                                    <SwitchButton 
                                        changeStatus={(s)=>onChangeItemStatus(item.id, s?1:2)}
                                        enabled={item.status === 1}/>
                                </div>: null}
                                <div className="del-wrapp ab-right">
                                    <Button className="btn-tsp" 
                                        leftLabel="icon-delete fa red" 
                                        onClick={itemDelete}></Button>
                                </div>
                            </div>
                        </div>
                        {attributes.map((attribute, i) => (
                            <Col md="4" key={'attr'+elementKey + i} style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }} >
                                <FormGroup>
                                    <Label className="control-label">{attribute.title}</Label>
                                    <Select 
                                        options={attribute.values} 
                                        value={typeof values[i] !== 'undefined' && values[i] } 
                                        onChange={(item)=>onChangeSelect(item, index, i, attribute.id)}/>
                                    <div className="errorMsg">
                                        {validator.message('Attribute', values[i], 'required')}</div>
                                        {showError('Attribute', validator.errorMessages)}
                                </FormGroup>
                            </Col>
                        ))}
                        <Col md="4">
                            <FormGroup>
                                <Label className="control-label">Product name</Label>
                                <Input type="text" 
                                    value={item.title} 
                                    name={"title"}
                                    onChange={onChangeText} />
                            </FormGroup>
                        </Col>
                        {renderStoreProductFields()}
                        <Col md="4" style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }}>
                            <div className="form-group">
                                <label className="control-label">Weight</label>
                                <Select 
                                    name="weight"
                                    id={"weight_id"+index}
                                    options={WEIGHT_OPTIONS} value={item.weight} onChange={(v)=>{
                                    if(v){
                                        onChangeText({target:{
                                            name:'weight',
                                            value: v
                                        }})
                                    }
                                }} />
                            </div>
                        </Col>
                        <Col md="4" style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }}>
                            <div className="form-group">
                                <label className="control-label">Weight Unit</label>
                                <Select 
                                     name="weight_unit"
                                     id={"weight_unit_id"+index}
                                    options={unitOptions} value={item.weight_unit} onChange={(v)=>{
                                    if(v){
                                        onChangeText({target:{
                                            name:'weight_unit',
                                            value: v
                                        }})
                                    }
                                
                                }} />
                            </div>
                        </Col>
                        <Col md="12" style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }}>
                            <FormGroup>
                                <Label className="control-label">Tags</Label>
                                <ReactChipInput
                                    classes="class1 class2"
                                    chips={tags}
                                    onSubmit={value => addChip(value)}
                                    onRemove={index => removeChip(index)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12" style={{ pointerEvents: type == "StoreProduct" ? 'none' : 'auto' }}>
                            <FormGroup>
                                <Label className="control-label">Description</Label>
                                <textarea type="text" rows="10" className="form-control" 
                                    value={item.description} 
                                    name={"description"}
                                    onChange={onChangeText} />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md="4">
                            <div className="form-group">
                            <label className="control-label">Weight</label>
                            <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Input type="select" name="select" id="exampleSelect" onChange={(e)=>{
                                var weight_unit = e.target.value
                                item['weight_unit'] = weight_unit 
                                var itms = this.state.items;
                                itms[i] = item;
                                this.setState({ 
                                    items:itms
                                })
                                }} value={item.weight_unit}>
                                {unitOptions2.map((opt,ind)=>(
                                <option value={opt.value} data-id={ind} key={ind}>{opt.label} </option>
                                ))}
                                </Input>
                            </InputGroupAddon>
                            <Input  value={item.weight} onChange={(e)=>{
                                var it = item;
                                it['weight'] = e.target.value;
                                var items = this.state.items;
                                items[i] = it;
                                this.setState({items:items})
                            }}/>
                            </InputGroup>
                            </div>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </Col>
    )
}