import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Echo from "laravel-echo"
import Sound from 'react-sound';

import ring from '../../assets/sound/ring.mp3';

export default function OrderNotification({
    channel_id, isStore, store, onGetOrders,
    loading,
    history
}){
    const [ initial, setInitial ] = useState(true)
    const [ toggle, setToggle] = useState(false)

    useEffect(()=>{
        if(initial){
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: '970b87571ae91f5338bd',
                cluster: 'ap2',
                forceTLS: true
            });
            var channel = window.Echo.channel(channel_id);
            channel.listen('.order-notification-event', function(data) {
                onChange()
                toast(data.message)
            });
            setInitial(false)
        }
    }, [])

    const onChange = ()=>{
        setToggle(true)

        if(!onGetOrders || loading) return
        var data = {
            offset: 0, 
            limit: 12
        }

        if(isStore && store){
            data = {...data, store:store.id}
            onGetOrders(data)
            history.push("/store/all-orders");
        }else{
            onGetOrders(data)
            history.push("/admin/all-orders");
        }
    }
    
    return  <div >
            <Sound
            url={ring}
            playStatus={toggle?Sound.status.PLAYING:Sound.status.STOPPED}
            playFromPosition={0}
            onFinishedPlaying={()=> {
                setToggle(false)
            }}
            onError={()=>setToggle(false)}
            onPause={()=>setToggle(false)}
        />
        </div>  
   
}