import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  brandLoading:false,
  brand_offers:[],
  offer:{}
};

export default function brandOfferReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_BRAND_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_BRAND_OFFER:
      return {
        ...state,
        loading: false,
        message: "Brand offer added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_BRAND_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Brand offer add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_BRAND_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_BRAND_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Brand offer edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_BRAND_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Brand offer edit failed",
        status: false,
        error: action.error
      };


      case Actions.DELETE_BRAND_OFFER_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_BRAND_OFFER:
      return {
        ...state,
        loading: false,
        message:"Brand offer delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_BRAND_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        message:"Brand offer delete failed",
        popup: true,
        status: false
      };

      case Actions.GET_ALL_BRAND_OFFER_LOADING:
      return {
        brandLoading: true,
        brand_offers: []
      };
    case Actions.GET_ALL_BRAND_OFFER:
      return {
        ...state,
        brandLoading: false,
        brand_offers: action.brand_offers,
        count: action.count
      };
    case Actions.GET_ALL_BRAND_OFFER_FAILED:
      return {
        ...state,
        brandLoading: false,
        brand_offers: [],
        error: action.error
      };

      case Actions.GET_BRAND_OFFER_LOADING:
      return {
        offer: {},
      loading:true,
      };
    case Actions.GET_BRAND_OFFER_SUCCESS:
      return {
        ...state,
        loading:false,
        offer: action.offer,
      };
    case Actions.GET_BRAND_OFFER_LOADING:
      return {
        ...state,
        offer: {},
        loading:false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
