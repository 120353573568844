import React, { Component, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import {connect} from 'react-redux';
import NewOrders from './NewOrder';
import {getAllOrders,onDismiss,orderStatus, startShipping} from '../../../../actions/OrderActions';
import SweetAlert from 'sweetalert2-react';
import moment from "moment";

import * as Constant from '../../../../constants/AppConstants';

class Processing extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 1,
      status:1,
      orders:{new:[], processing:[], shipping:[]},
      dialogue:false,
      selected: null,
      search:'',
      date: moment(),
      time_from:null,
      time_to: null,
      deliveryBoy: null,
      mode:1
    };
  }


  componentDidMount() {
    var data = {
      status: Constant.ORDER_CREATED,
      date: this.state.date.format('l'),
      time_from: this.state.date.format('hh:mm:ss')
    }
    this.props.onGetOrders(data, Constant.ORDER_CREATED)
  }

  onSearch = (e)=>{
    this.setState({search: e.target.value})

  }

  onChangeDate = (date)=>{
    this.setState({date})
  }

  onFilter = ()=>{
    const {activeTab, date, search} = this.state
    var data = {
      status: activeTab,
      date: date.format('l'),
      time_from: date.format('hh:mm:ss'), 
      search: search
    }
    this.props.onGetOrders(data, activeTab)
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
    var data = {
      status: tab,
      date: this.state.date.format('l'),
      time_from: this.state.date.format('hh:mm:ss')
    }
    this.props.onGetOrders(data, tab)
  }

  onDismissPopup =()=>{
    this.props.onDismiss();
    var data = {
      status: this.state.activeTab,
      date: this.state.date.format('l'),
      time_from: this.state.date.format('hh:mm:ss')
    }
    if(!this.props.loading ){
      this.props.onGetOrders(data, this.state.activeTab)
    }
  }

  handleStatus = (status,id, mode, delivery_boy) => {
    this.setState({
      selected:id,
      status:status,
      dialogue:true,
      mode: mode,
      deliveryBoy: delivery_boy
    })
  }

  onStatus = () => {
    var status ;
      if(typeof this.state.status !== undefined && this.state.status == Constant.ORDER_CREATED) {
        status = Constant.ORDER_ACCEPTED;
      }
      else if(typeof this.state.status !== undefined && this.state.status == Constant.ORDER_ACCEPTED) {
        status = Constant.ORDER_SHIPPING;
      }
      else {
        status = Constant.ORDER_COMPLETED;
      }
      this.setState({
        dialogue: false
      })
      if(status === Constant.ORDER_SHIPPING && this.state.mode != 3){
        const {selected, deliveryBoy} = this.state
        var data = {order_id:selected, delivery_boy_id:deliveryBoy}
        if(!deliveryBoy) return 
        this.props.onStartShipping(data)
      }else{
        this.props.onChangeStatus(this.state.selected,status);
      }
      
  }

  componentDidUpdate(prevProps){
    if(!this.props.loading && prevProps.loading){
      if(this.props.tab === Constant.ORDER_CREATED){
        var orders = this.state.orders
        orders['new'] = this.props.all_orders
        this.setState({orders})
      }
      if(this.props.tab === Constant.ORDER_ACCEPTED){
        var orders = this.state.orders
        orders['processing'] = this.props.all_orders
        this.setState({orders})
      }
      if(this.props.tab === Constant.ORDER_SHIPPING){
        var orders = this.state.orders
        orders['shipping'] = this.props.all_orders
        this.setState({orders})
      }

    }
  }

  render() { 
    const {orders} = this.state
    return ( 
      <div>
      <div className="content">
      <div className="tab-bg-wrapper">
      <div className="bottom-nav">
           <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: this.state.activeTab === Constant.ORDER_CREATED })}
                  onClick={() => { this.toggle(Constant.ORDER_CREATED); }} > New</NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active:this.state.activeTab === Constant.ORDER_ACCEPTED })}  onClick={() => { this.toggle(Constant.ORDER_ACCEPTED); }}>  Processing </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({active: this.state.activeTab ===Constant.ORDER_SHIPPING })}  onClick={() => { this.toggle(Constant.ORDER_SHIPPING); }}>  Shipping </NavLink>
              </NavItem>
            </Nav>
          </div>
      </div>
      <div className="order-wrapper">
          <div className="tabs">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId={Constant.ORDER_CREATED}>
              <NewOrders new_orders={orders['new']} 
                  handleStatus= {this.handleStatus} 
                  onSearch={this.onSearch}
                  date = {this.state.date}
                  search={this.state.search}
                  onFilter={this.onFilter}
                  onChangeDate={this.onChangeDate}/>
            </TabPane>
            <TabPane tabId={Constant.ORDER_ACCEPTED}>
              <NewOrders new_orders={orders['processing']} 
                handleStatus= {this.handleStatus} 
                onSearch={this.onSearch}
                date = {this.state.date}
                search={this.state.search}
                onFilter={this.onFilter}
                onChangeDate={this.onChangeDate}/>
            </TabPane>
            <TabPane tabId={Constant.ORDER_SHIPPING}>
              <NewOrders new_orders={orders['shipping']} 
              handleStatus= {this.handleStatus} 
              onSearch={this.onSearch}
              date = {this.state.date}
              search={this.state.search}
              onFilter={this.onFilter}
              onChangeDate={this.onChangeDate}/>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <SweetAlert
        show={this.state.dialogue }
        onConfirm={this.onStatus}
        onCancel = {() => this.setState({ dialogue: false })}
        title= {'Are you sure?'}
        text="Change status"
        type= {'warning'}
        showCancelButton= {true}
        confirmButtonText= {'Yes, Confirm it!'}
        cancelButtonText= {'No, keep it'}
        />
        <SweetAlert
        show={this.props.status && this.props.popup}
        title="Success"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
        <SweetAlert
        show={ !this.props.status && this.props.popup}
        title="Error"
        type="error"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
      </div>
      </div>
     );
  }
}
Processing.defaultProps = {
  all_orders:[]
}
const mapStateToProps = (state)=>({
  loading:state.orderReducer.loading,
  all_orders:state.orderReducer.all_orders,
  tab: state.orderReducer.tab,
  status:state.orderReducer.status,
  message:state.orderReducer.message,
  popup:state.orderReducer.popup,
})

const mapDispatchToProps = (dispatch)=>({
  onGetOrders:(data, tab)=> {
    dispatch(getAllOrders(data, tab))
  },
  onDismiss:()=>{
    dispatch(onDismiss());
  },
  onChangeStatus:(order_id,status)=>{
    dispatch(orderStatus(order_id,status))
  },
  onStartShipping:(data)=>{
    dispatch(startShipping(data))
  }
}) 
export default connect (mapStateToProps,mapDispatchToProps) (Processing);