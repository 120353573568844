import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addFaq(data) {
    return request({
      url:   URLS.ADD_FAQ,
      method: 'POST',
      data:data
    });
}

function addFaqDis(data) {
    return request({
      url:   URLS.ADD_FAQ_DIS,
      method: 'POST',
      data:data
    });
}


function getFaqs() {
    return request({
      url:   URLS.GET_FAQS ,
      method: 'GET'
    });
}

//delete FAQ
function delFaqs(faq_id) {
    var sub_url = '/'+faq_id;
    return request({
      url:   URLS.DELETE_FAQ + sub_url,
      method: 'GET',
    });
}

function delFaqDis(faq_description_id) {
    var sub_url = '/'+faq_description_id;
    return request({
      url:   URLS.DELETE_FAQ_DIS + sub_url,
      method: 'GET',
    });
}

const FaqService = {
    addFaq,
    getFaqs,
    addFaqDis,
    delFaqs,
    delFaqDis
}
export default FaqService;