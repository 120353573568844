import React, { Component } from "react";
import { Button, Checkbox } from '../../../components';
import { 
  Table, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter 
} from "reactstrap";
import ReactPaginate from 'react-paginate';
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-datepicker";
import moment from "moment";
import { connect } from "react-redux";
import SweetAlert from "sweetalert2-react";

import {
  addTodayDeal,
  deleteToday,
  onDismiss,
  getTodayDeals,
  editTodayDeal
} from '../../../actions/BannerActions';
import CheckFrom from "../../../components/Common/CheckBoxForm";
import StoreProductSelect from "../Product/StoreProductSelect";

class TodaysDeal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editModel:false,
      selectedOffer:{},
      product_name:'',
      skip: 0,
      limit:12,
      isMounted: false,
      productSelected: '',
      modal: false,
      isActive: false,
      valid_from: moment(),
      valid_upto: moment(),
      week: []
    };

    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }

  onDismissPopup(){
    this.props.onDismiss();
    this.props.onGetOffer(this.props.store.id,this.state.skip,this.state.limit);
  }

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
    setTimeout(()=>{
      this.props.onGetOffer(this.props.store.id,this.state.skip,this.state.limit);
     },100);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleChangeFrom(date) {
    this.setState({
      valid_from: date
    });
  }

  handleChangeTo(date) {
    this.setState({
      valid_upto: date
    });
  }

  handleCheck = week => {
    this.setState({ week });
  };

  handleProduct = productSelected => {
    this.setState({ productSelected });
  };

  handleLimit = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleSubmit=(e)=> {
    e.preventDefault();
    var data = {
      store_id:this.props.store.id,
      product_id:this.state.productSelected.value
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data['valid_from'] = from.format('YYYY-MM-DD');
    data['valid_upto'] = upto.format('YYYY-MM-DD');  
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
    data['week_days']= days_array;
    
    if( this.validator.allValid() ){
      this.props.onAddDeal(data);
    }
    else {
     this.validator.showMessages();
     this.forceUpdate();
    }
  }

  handleDelete(e,id) {
    e.preventDefault();
    this.props.onDeleteOffers(id);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.status === true){
      this.setState({
        valid_from: moment(),
        valid_upto: moment(),
        week: [],
        productSelected: '',
      })
    }
  }

  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }

  trimTime (dateString) {
    dateString = dateString.replace('00:00:00', '');
    return dateString;
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetOffer(this.props.store.id, skip,this.state.limit);
  }

  handleEdit = (e,offer) => {
    e.preventDefault();
    if (typeof offer.product.id !== 'undefined') {
      var to = moment(offer.valid_upto).format("YYYY-MM-DD");
      var from = moment(offer.valid_from).format("YYYY-MM-DD"); 
      var days = offer.week_days.map((item)=>{
        return item.day_id;
      })
      this.setState({
        product_name:offer.product.title,
        selectedOffer:offer,
        isActive: true,
        editModel:true,
        valid_from: moment(from),
        valid_upto: moment(to),
        week:days
      });
    }
  }

  handleEditSubmit = (e) => {
    e.preventDefault();
    var data = {
      store_id:this.props.store.id,
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data['valid_from'] = from.format('YYYY-MM-DD');
    data['valid_upto'] = upto.format('YYYY-MM-DD');  
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
    data['week_days']= days_array;
    data['deal_id'] = this.state.selectedOffer.id;
    this.props.onEditDeal(data);
  }

  render() {
    const { productSelected } = this.state;
    const {to_deals} = this.props;

    return (
      <div>
        <div className="content">
          <div className={this.getLoder()}></div>
          <div className="table-product-wrapper">
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>PRODUCT NAME</th>
                  <th>DATE FROM</th>
                  <th>DATE TO</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {to_deals.length>0 ? to_deals.map((offer,index) => (
                  <tr key={index}>
                    <td>{index+1+this.state.skip}</td>
                    <td>{offer.product && offer.product.title}</td>
                    <td>{this.trimTime(offer.valid_from)}</td>
                    <td>{this.trimTime(offer.valid_upto)}</td>
                    <td>
                      <div className="group-action">
                        <button className="btn" onClick={(e)=>this.handleEdit(e,offer)}>
                          <i className="ico icon-edit" />
                        </button>
                        <button className="btn" onClick={((e)=>this.handleDelete(e,offer.id))}>
                          <i className="ico icon-delete" />
                        </button>
                      </div>
                    </td>
                  </tr>    
                )):<tr><td colSpan={'7'}>No data found</td></tr>}
              </tbody>
            </Table>
            <Button className="fixed-yellow" onClick={this.toggle}>Add</Button>
          </div>
          <div className="pagination-wrapper">
            <ReactPaginate 
              previousLabel={<i className="fas fa-angle-left"></i>}
              nextLabel={<i className="fas fa-angle-right"></i>}
              pageLinkClassName={"page-link"}
              breakClassName={"break-me"}
              pageCount={Math.ceil(this.props.count/this.state.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              activeClassName={"active"} />
          </div>
        </div>
        <SweetAlert
          show={this.props.status && this.props.popup}
          title="Success"
          text={this.props.message}
          onConfirm={this.onDismissPopup}/>
        <SweetAlert
          show={ !this.props.status && this.props.popup}
          title="Error"
          type="error"
          text={this.props.message}
          onConfirm={this.onDismissPopup}/>

        <Modal   
          isOpen={this.state.modal}  
          toggle={this.toggle}  
          className="common-modal" >
          <ModalHeader>Add Fantastic Deal</ModalHeader>
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              <div className="form-model">
                <div className="form-group">
                  <StoreProductSelect
                    onChange={this.handleProduct}
                    value={productSelected}/>
                  <div className="errorMsg">
                    {this.validator.message('product', productSelected.value, 'required')}
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label">Valid From</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.valid_from}
                    onChange={this.handleChangeFrom}
                    dateFormat="YYYY-MM-DD"
                    name="valid_from"/>
                </div>
                <div className="form-group">
                  <label className="control-label">Valid To</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.valid_upto}
                    onChange={this.handleChangeTo}
                    dateFormat="YYYY-MM-DD"
                    name="valid_upto"/>
                </div>
                <div className="form-group">
                  <div className="check-wrapper">
                    <Checkbox
                      label="Limit Days"
                      checked={this.state.isActive}
                      onChange={e => {
                        this.handleLimit(e);
                      }}/>
                  </div>
                  <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cancel" onClick={this.toggle}>
                Cancel
              </Button>
              <Button type="submit" className="btn-submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/* edit deal */}
        <Modal isOpen={this.state.editModel}  className="common-modal" >
          <ModalHeader>Edit Todays Deal</ModalHeader>
          <form onSubmit={this.handleEditSubmit}>
            <ModalBody>
              <div className="form-model">
                <div className="form-group">
                  <label className="control-label">Product Name</label>
                  <h6 className="p-bname">{this.state.product_name}</h6>
                </div>
                <div className="form-group">
                  <label className="control-label">Valid From</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.valid_from}
                    onChange={this.handleChangeFrom}
                    dateFormat="YYYY-MM-DD"
                    name="valid_from"/>
                </div>
                <div className="form-group">
                  <label className="control-label">Valid To</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.valid_upto}
                    onChange={this.handleChangeTo}
                    dateFormat="YYYY-MM-DD"
                    name="valid_upto"/>
                </div>
                <div className="form-group">
                  <div className="check-wrapper">
                    <Checkbox
                      label="Limit Days"
                      checked={this.state.isActive}
                      onChange={e => {
                        this.handleLimit(e);
                      }}/>
                  </div>
                  <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom isEditable={true} week={this.state.week}
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cancel" onClick={() => this.setState({editModel:false})}>
                Cancel
              </Button>
              <Button type="submit" className="btn-submit">
                Save
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

TodaysDeal.defaultProps = {
  to_deals:[],
  all_products:[]
}
const mapStateToProps = state => ({
  all_products: state.productReducer.all_products,
  loading: state.todaysReducer.loading,
  to_deals: state.todaysReducer.to_deals,
  status: state.todaysReducer.status,
  message: state.todaysReducer.message,
  count:state.todaysReducer.count,
  popup: state.todaysReducer.popup,
  store: state.storeUserReducer.store
});

const mapDispatchToProps = dispatch => ({
  onAddDeal: (data) => {
    dispatch(addTodayDeal(data));
  },
  onEditDeal:(data) => {
    dispatch(editTodayDeal(data));
  },
  onGetOffer: (store_id,skip,take) => {
    dispatch(getTodayDeals(store_id,skip,take));
  },
  onDeleteOffers:(id) => {
    dispatch(deleteToday(id));
  },
  onDismiss: () => {
    dispatch(onDismiss());
  }
});
export default connect(mapStateToProps,mapDispatchToProps)(TodaysDeal);
