import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addVariant(data) {
    return request({
      url:   URLS.ADD_VARIANT,
      method: 'POST',
      data:data
    });
}
function editVariant(data) {
    return request({
      url:   URLS.EDIT_VARIANT,
      method: 'POST',
      data:data
    });
}

function getVariant(attr_id) {
    var sub_url = '/'+attr_id;
    return request({
      url:   URLS.GET_VARIANT + sub_url,
      method: 'GET'
    });
}

function getVariants(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_VARIANTS + sub_url,
      method: 'GET'
    });
}


const variantService = {
    addVariant,
    getVariant,
    editVariant,
    getVariants
}

export default variantService;
