import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addBanner(data) {
    return request({
      url:   URLS.ADD_OFFER_BANNER,
      method: 'POST',
      data:data
    });
}
function addFantasticDeal(data) {
    return request({
      url:   URLS.ADD_FANTASTIC_DEAL,
      method: 'POST',
      data:data
    });
}

function addTodayDeal(data) {
    return request({
      url:   URLS.ADD_TODAYS_DEAL,
      method: 'POST',
      data:data
    });
}

function getAllbanners(store_id,type,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(type !== null && typeof type !== 'undefined') sub_url = sub_url+'/'+type;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_OFFER_BANNERS + sub_url,
      method: 'GET'
    });
}

function getFantasticDeals(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_FANTASTIC_DEALS + sub_url,
      method: 'GET'
    });
}

function getTodayDeals(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_TODAYS_DEALS + sub_url,
      method: 'GET'
    });
}

//delete deals and banners

 function deleteFatastic (id) {
     var sub_url = '/'+ id;
       return request({
        url:URLS.DELETE_FANTASTIC + sub_url,
        method:'GET'
       }) ;
 }

function deleteToday (id) {
    var sub_url = '/'+id;
    return request({
        url:URLS.DELETE_TODAYS + sub_url,
        method:'GET'
    })
}

function deleteBanner (id) {
    var sub_url = '/'+id;
    return request({
        url:URLS.DELETE_BANNER + sub_url,
        method: 'GET'
    })
}

function getBanner(banner_id) {
    var sub_url = '/'+banner_id;
    return request({
      url:   URLS.GET_OFFER_BANNER + sub_url,
      method: 'GET'
    });
}

//edit deals

function editFantasticDeal(data) {
    return request({
      url:   URLS.EDIT_FANTASTIC_DEAL,
      method: 'POST',
      data:data
    });
}
function editTodayDeal(data) {
    return request({
      url:   URLS.EDIT_TODYAS_DEAL,
      method: 'POST',
      data:data
    });
}
function editBanner(data) {
    return request({
      url:   URLS.EDIT_OFFER_BANNER,
      method: 'POST',
      data:data
    });
}

const BannerService = {
    addBanner,
    addTodayDeal,
    addFantasticDeal,
    getFantasticDeals,
    getTodayDeals,
    getAllbanners,
    deleteFatastic,
    deleteToday,
    deleteBanner,
    editBanner,
    editFantasticDeal,
    editTodayDeal,
    getBanner
}

export default BannerService;
