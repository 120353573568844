import * as Actions from '../constants/Actions'
import orderService from '../services/OrderService'

function getAllOrders(data, tab){

  return dispatch =>{
      dispatch(request());
      orderService.getAllOrders(data)
      .then(res =>{
          var all_orders = res.data;
          var count = res.count;
          dispatch(success(all_orders,count, tab))
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
  function request() { return { type: Actions.GET_ALL_ORDERS_LOADING} }
  function success(all_orders,count, tab) { return { type: Actions.GET_ALL_ORDERS_SUCCESS, all_orders: all_orders,count:count, tab} }
  function failed(error) { return { type: Actions.GET_ALL_ORDERS_FAILED, error:error} }
}

function getStoreOrders(data,tab){
  return dispatch=>{
    dispatch(request());
    orderService.getStoreOrders(data)
    .then(res=>{
      var store_orders = res.data;
      var count = res.count;
      dispatch(success(store_orders,count,tab))
    }).catch(error=>{
      dispatch(failed(error))
    })
  }
  function request(){return{type:Actions.GET_STORE_ORDERS_LOADING}}
  function success(store_orders,count,tab){return{type:Actions.GET_STORE_ORDERS_SUCCESS,store_orders,count,tab}}
  function failed(error){return{type:Actions.GET_STORE_ORDERS_FAILED,error}}
}



function getOrder(order_id) {
return dispatch =>{
  dispatch(request());
  orderService.getOrder(order_id)
  .then(res=>{
    var order= res.data;
    dispatch(success(order))
  }).catch(error=>{
    dispatch(failed(error))
  })
}
function request(){return {type:Actions.GET_ORDER_LOADING}}
function success(order){return {type:Actions.GET_ORDER_SUCCESS,order}}
function failed(error) {return {type:Actions.GET_ORDER_FAILED,error}}
}

function orderStatus(order_id,status) {
  return dispatch => {
    dispatch(request());
    orderService.orderStatus(order_id,status)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.CHANGE_ORDER_STATUS_LOADING}}
  function success(){return {type:Actions.CHANGE_ORDER_STATUS_SUCCESS}}
  function failed(error){return {type:Actions.CHANGE_ORDER_STATUS_FAILED,error}}

}

function startShipping(data) {
  return dispatch => {
    dispatch(request());
    orderService.startShipping(data)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.START_SHIPPING_LOADING}}
  function success(){return {type:Actions.START_SHIPPING_SUCCESS}}
  function failed(error){return {type:Actions.START_SHIPPING_FAILED,error}}

}

function editOrderItem(data) {
  return dispatch => {
    dispatch(request());
    orderService.editOrderItem(data)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.EDIT_ORDER_ITEM_LOADING}}
  function success(){return {type:Actions.EDIT_ORDER_ITEM_SUCCESS}}
  function failed(error){return {type:Actions.EDIT_ORDER_ITEM_FAILED,error}}

}

function deleteOrderItem(data) {
  return dispatch => {
    dispatch(request());
    orderService.deleteOrderItem(data)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.DELETE_ORDER_ITEM_LOADING}}
  function success(){return {type:Actions.DELETE_ORDER_ITEM_SUCCESS}}
  function failed(error){return {type:Actions.DELETE_ORDER_ITEM_FAILED,error}}

}

function onDismiss(){
  return dispatch =>{
      dispatch(success())
  }
  function success() { return { type: Actions.ALERT_DISMISS} }
}

function placeOrder(data) {
  return dispatch => {
    dispatch(request());
    orderService.placeOrder(data)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.PLACE_ORDER_LOADING}}
  function success(){return {type:Actions.PLACE_ORDER_SUCCESS}}
  function failed(error){return {type:Actions.PLACE_ORDER_FAILED,error}}

}



export {
  getAllOrders,
  getOrder,
  getStoreOrders,
  orderStatus,
  onDismiss,
  startShipping,
  placeOrder,
  editOrderItem,
  deleteOrderItem
}