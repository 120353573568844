import React, { Component } from 'react';
import {InputGroup,InputGroupText,InputGroupAddon,Input } from "reactstrap";
import {Button} from '../../../../components';  
import DatePicker from "react-datepicker";
import moment from "moment";
class SearchBarOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:moment()
    };
  }
  handleChangeDate =(date) => {
    this.setState({
      startDate: date
    });
    if(this.props.onChangeDate){
      this.props.onChangeDate(date)
    }
  }
  render() { 
    return ( 

    <div className="filter-wrapper">
      <form action="">
      <div className="d-flex align-items-center">
          <InputGroup className="filter-search mr-2">
            <Input  placeholder="Search"  type="text" onChange={this.props.onChange} value={this.props.search}/>
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i className="icon-search-find" />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <DatePicker className="form-control" 
                  selected={this.props.date}
                  onChange={this.handleChangeDate}
                  timeCaption="Time"
                  dateFormat="YYYY-MM-DD"
                  name="startDate"
                  placeholderText="Delivery Date Time"
                  />

            <Button className="btn-submit ml-2 mr-2" onClick={this.props.onFilter}>Filter</Button>
      </div>
      </form>
    </div>
     );
  }
}
 
export default SearchBarOrder;