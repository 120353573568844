import React, { Component } from 'react';
import { Table} from "reactstrap";
import {connect} from 'react-redux';
import {getAllDeliveryBoys,onDismiss, deliveryBoyStatus} from '../../../../actions/deliveryBoyAction';
import { ActionBtn,Button } from '../../../../components';
import * as Constant from '../../../../constants/AppConstants'
import SwitchButton from '../../../../components/CustomButton/switchButton';
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
class DeliveryBoys extends Component {
  constructor(props){
    super(props);
    this.state = { 
      offset:0,
      limit:12,
      dialogue:false,
      selected:0,
      alert: false
     }
  }
  componentDidMount(){
    this.props.onGetBoys(this.state.offset,this.state.limit, this.props.store.id)
  }
   handleStatus = (stat,id) => {
    this.setState({
      stat:stat,
      selected:id,
      dialogue:true
    });
  }
  handleDelete = (e,id)=>{
    e.preventDefault();
    this.setState({
      selectedId:id,
      alert:true
    })
  }
  onConfirm = ()=>{
    this.props.onChangeStatus(this.state.selectedId,Constant.USER_BLOCKED);
    this.setState({alert:false})
  }
  onStatus = () =>{
    var status ;
    if(typeof this.state.stat !== undefined && this.state.stat == true) {
      status = Constant.USER_ACTIVATED;
    }
    else {
      status = Constant.USER_SUSPENDED;
    }
    this.props.onChangeStatus(this.state.selected,status);
    this.setState({dialogue:false})
  }

  onDismissPopup =()=>{
    this.props.onGetBoys(0, this.state.limit, this.props.store.id)
    this.props.onDismiss();
  }
 
  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  handlePageClick = (data) => {
    this.props.onGetOrders(data)
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    var data = {
      offset: skip,
      limit:this.state.limit
    }
    this.props.onGetBrands(data);
  }
 

  render() { 
    const{delivery_boys} = this.props;
    return ( 
      <div>
      <div className="content">
      <div className={this.getLoder()}></div>
    <div className="table-product-wrapper">
      <Table>
        <thead>
          <tr>
            <th>SI.No</th>
            <th>Status</th>
            <th>Profile Pic</th>
            <th>Proof</th>
            <th>Unique No</th>
            <th>Name</th>
            <th>Mobile Number </th>
            <th>To Do</th>
            <th>Done </th>
            <th>CASH ON HAND</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {delivery_boys.map((boy,index)=>(
            <tr key={index}>
            <td>{index+1}</td>
            <td><SwitchButton  changeStatus={((stat)=>this.handleStatus(stat,boy.id))}  enabled={boy.status ===2? true:false}/></td>
            <td className="nameImg">
              <img src={boy.profile_pic} alt="wild" className="img-ico" />
            </td>
            <td className="nameImg">
              <img src={boy.proof_id} alt="wild" className="img-ico" />
            </td>
            <td>{boy.unique_number}</td>
            <td>{boy.name}</td>
            <td>{boy.mobile}</td>
            <td>Nil</td>
            <td>Nil</td>
            <td>0 <Button className="text-btn">COLLECT</Button></td>
            <td>
            <ActionBtn edit={true} deleteClick={((e)=>this.handleDelete(e,boy.id))} editClick={() => this.props.history.push(`/store/edit-delivery-boy/${boy.id}`)}/>
            </td>
            </tr>
          ))}
           
        </tbody>
      </Table>
    </div>

    <Button className="fixed-yellow"  onClick={() => this.props.history.push("/store/add-delivery-boy")}>Add delivery boy</Button>
    <div className="pagination-wrapper">
            <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
            nextLabel={<i className="fas fa-angle-right"></i>}
            pageLinkClassName={"page-link"}
            breakClassName={"break-me"}
            pageCount={Math.ceil(this.props.count/this.state.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            activeClassName={"active"} />
      </div>
    <SweetAlert
  show={this.state.dialogue }
  onConfirm={this.onStatus}
  onCancel = {() => this.setState({ dialogue: false })}
  title= {'Are you sure to suspend this delivery boy ?'}
  text="Change status"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
    <SweetAlert
  show={this.state.alert }
  onConfirm={this.onConfirm}
  onCancel = {() => this.setState({ alert: false })}
  title= {'Are you sure? All the data under this delivery boy will be deleted'}
  text="Delete delivery boy"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
  <SweetAlert
  show={this.props.status && this.props.popup}
  title="Success"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
  <SweetAlert
  show={ !this.props.status && this.props.popup}
  title="Error"
  type="error"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
  </div>

</div>
     );
  }
}

DeliveryBoys.defaultProps = {
  delivery_boys:[]
}

const mapStateToProps = state => ({
  loading:state.deliveryBoyReducer.loading,
  delivery_boys:state.deliveryBoyReducer.delivery_boys,
  count:state.deliveryBoyReducer.count,
  status:state.deliveryBoyReducer.status,
  message:state.deliveryBoyReducer.message,
  popup:state.deliveryBoyReducer.popup,
  store: state.storeUserReducer.store
})

const mapDispatchToProps = dispatch =>({
  onGetBoys: (offset,limit) => {
    dispatch(getAllDeliveryBoys(offset,limit))
  },
  onDismiss:()=>{
    dispatch(onDismiss());
  },
  onChangeStatus:(id,status) => {
    dispatch(deliveryBoyStatus(id,status))
  }

})
 
export default connect(mapStateToProps,mapDispatchToProps)(DeliveryBoys);
