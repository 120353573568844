import React, { Component } from 'react';
import { Table} from "reactstrap";
import { Button } from '../../../components';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {getAllCategoryOffers,delCategoryOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
class CategoryOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted:false,
            catLoading:false,
            skip: 0,
            limit:12
        };
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      componentDidMount () {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });   
      }
      });
      setTimeout(()=>{
        this.props.onGetCategoryOffers(this.props.store.id,this.state.skip,this.state.limit);
       },100);
      }
      onDismissPopup () {
        this.props.onDismiss();
        {this.props.onGetCategoryOffers(this.props.store.id,this.state.skip,this.state.limit);}
      }
      handleDelete(e,id) {
        e.preventDefault();
        this.props.onDeleteOffers(id);
      }
      trimTime (dateString) {
        dateString = new Date(dateString).toUTCString();
        dateString = dateString.split(' ').slice(0, 4).join(' ');
        return dateString;
    }
      getLoder = () =>{
        let classes = "bt-loader ";
        classes += this.props.catLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetCategories(skip,this.state.limit);
      }
    render() { 
      const {cat_offers} = this.props;
        return (
            <div>
                  <div className="content">
                  <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                      <th>ID</th>
                      <th>OFFER NAME</th>
                      <th>DISCOUNT</th>
                      <th>DATE FROM</th>
                      <th>DATE TO</th>
                      <th />
                      </tr>
                    </thead>
                    <tbody>
                    {cat_offers.length>0 ?cat_offers.map((offers,index) => (
                        <tr key={index}>
                        <td>{index+1+this.state.skip}</td>
                        <td>{offers.title}</td>
                        <td>{offers.disc_perc}%</td>
                        <td>{this.trimTime(offers.valid_from)}</td>
                        <td>{this.trimTime(offers.valid_upto)}</td>
                        <td>
                          <div className="group-action">
                            <Link className="btn" to={`/store/edit-category-offer/${offers.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,offers.id))}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'7'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
                </div>
                <Button className="fixed-yellow" onClick={() => this.props.history.push("/store/add-category-offer")}>Add</Button>
            
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
         );
    }
}
CategoryOffer.defaultProps= {
  cat_offers:[]
}
const mapStateToProps = (state) => ({
  catLoading: state.categoryOfferReducer.catLoading,
  cat_offers: state.categoryOfferReducer.cat_offers,
  status:state.categoryOfferReducer.status,
  message:state.categoryOfferReducer.message,
  popup:state.categoryOfferReducer.popup,
  count:state.categoryOfferReducer.count,
  store:state.storeUserReducer.store
})
const mapDispatchToProps = (dispatch)=> ({
  onGetCategoryOffers:(store_id,skip,take)=>{
    dispatch(getAllCategoryOffers(store_id,skip,take))
  },
  onDeleteOffers:(id)=>{
    dispatch(delCategoryOffer(id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(CategoryOffer)