import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import { StoreHeader, StoreSideBar } from "../../components";
import storeRoutes from "../../routes/StoreRoutes.jsx";

var ps;

class StoreDashboard extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  render() {
    return (
      <div className="wrapper">
        <StoreSideBar {...this.props} routes={storeRoutes} />
        <div className="main-panel" ref="mainPanel">
          <StoreHeader  {...this.props}/>
          <Switch>
            {storeRoutes.map((prop, key) => {
             if (prop.redirect)
             return <Redirect from={`${this.props.match.url}/`}  to={`${this.props.match.url}/`+prop.pathTo}  key={key} />;
           return (
             <Route exact path={`${this.props.match.path}/${prop.path}`} component={prop.component} key={key}/>)
            })}
          </Switch>
          {/* <Switch>
            {storeRoutes.map((prop, key) => {
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              return (
                
                <Route path={prop.path} 
                key={prop.name}
                render={({ match: { url } }) => (
                  <>
                    <Route exact path={`${url}`} title={prop.name} component={prop.component} key={prop.name} />
                    {prop.hasOwnProperty('routes') && prop.routes.map((item,index)=>{
                      return(
                        <Route path={`${url}/`+item.path} title="ggg" component={item.component} key={"chi"+index}/>
                      )
                    })}
                   
                  </>
                )}
                />
              );
            })}
          </Switch> */}
        </div>
      </div>
    );
  }
}

export default StoreDashboard;
