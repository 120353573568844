import * as Actions from '../constants/Actions';
import ProductService from '../services/ProductService';

//GET STORE PRODUCTS
function getStoreProducts(store_id,skip=null, take=null, query=null){
    return dispatch =>{
        dispatch(request());
        ProductService.getStoreProducts(store_id,skip,take, query)
        .then(res =>{
            var products = res.data;
            var count = res.count;
            dispatch(success(products,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_PRODUCTS_LOADING} }
    function success(products,count) { return { type: Actions.GET_STORE_PRODUCTS_SUCCESS,products:products,count:count} }
    function failed(error) { return { type: Actions.GET_STORE_PRODUCTS_FAILED, error:error} }
}
//get all products

function getAllProducts(store_id, skip=null, take=null, query=null){
    return dispatch =>{
        dispatch(request());
        ProductService.getAllProducts(store_id, skip,take, query)
        .then(res =>{
            var all_products = res.data;
            dispatch(success(all_products))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_PRODUCTS_LOADING} }
    function success(all_products) { return { type: Actions.GET_ALL_PRODUCTS_SUCCESS,all_products:all_products} }
    function failed(error) { return { type: Actions.GET_ALL_PRODUCTS_FAILED, error:error} }
}

function uploadStockPrice(data){
    return dispatch =>{
        dispatch(request());
        ProductService.uploadStockPrice(data)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.UPLOAD_PRODUCT_STOCK_LOADING} }
    function success() { return { type: Actions.UPLOAD_PRODUCT_STOCK_SUCCESS} }
    function failed(error) { return { type: Actions.UPLOAD_PRODUCT_STOCK_FAILED, error:error} }
}

//PRODUCT status
function groupStatus(store_id, group_id,status){
    return dispatch =>{
        dispatch(request());
        ProductService.groupStatus(store_id, group_id,status)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GROUP_STATUS_LOADING} }
    function success() { return { type: Actions.GROUP_STATUS} }
    function failed(error) { return { type: Actions.GROUP_STATUS_FAILED, error:error} }
}

function importToStores(data){
    return dispatch =>{
        dispatch(request());
        ProductService.importToStores(data)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.IMPORT_TO_STORES_LOADING} }
    function success() { return { type: Actions.IMPORT_TO_STORES} }
    function failed(error) { return { type: Actions.IMPORT_TO_STORES_FAILED, error:error} }
}

function getCustomerProducts(store, offset=null, limit=null, query=null){
    return dispatch =>{
        dispatch(request());
        ProductService.getCustomerProducts(store, offset, limit, query)
        .then(res =>{
            dispatch(success(res.data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_CUSTOMER_PRODUCTS_LOADING} }
    function success(products) { return { type: Actions.GET_CUSTOMER_PRODUCTS, products} }
    function failed(error) { return { type: Actions.GET_CUSTOMER_PRODUCTS_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}

export{
    getStoreProducts,
    getAllProducts,
    groupStatus,
    onDismiss,
    uploadStockPrice,
    importToStores,
    getCustomerProducts
}