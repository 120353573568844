import React, { Component } from 'react';
import { Table} from "reactstrap";
import { Button } from '../../../components';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import SwitchButton from '../../../components/CustomButton/switchButton';
import {getAllPacks,onDismiss,packStatus} from '../../../actions/OfferActions';
import * as Constant from '../../../constants/AppConstants';
import SweetAlert from 'sweetalert2-react';
class ComboPackOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMounted: false,
          packLoading:false,
          skip: 0,
          alert:false,
          dialogue:false,
          limit:12,
          selectedId:0,
          selected:0
        };
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      componentDidMount () {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
       
      }
      });
       setTimeout(()=>{
        this.props.OnGetPacks(this.props.store.id,this.state.skip,this.state.limit);
       },100);
      }

      onDismissPopup (){
        this.props.onDismiss();
        this.props.OnGetPacks(this.props.store.id,this.state.skip,this.state.limit);
      }

      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.OnGetPacks(this.props.store.id, skip,this.state.limit);
      };

      handleStatus = (stat,id) => {
        this.setState({
          stat:stat,
          selected:id,
          dialogue:true
        });
      }
      handleDelete = (e,id) => {
         e.preventDefault();
         this.setState({
          selectedId:id,
          alert:true
        }); 
      }
      onStatus = () =>{
        var status ;
        if(typeof this.state.stat !== undefined && this.state.stat == true) {
          status = Constant.ON_SALE_AVAILABLE;
        }
        else {
          status = Constant.ON_SALE_DISABLED;
        }
      this.props.onDeleteProduct(this.state.selected,status);
      this.setState({dialogue:false})
      }

      onConfirm = ()=>{
        this.props.onDeleteProduct(this.state.selectedId,Constant.ON_SALE_BLOCKED);
        this.setState({alert:false})
      }

      getLoder = () =>{
        let classes = "bt-loader ";
        classes += this.props.packLoading||this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
      const {packs} = this.props;
        return (
            <div>
                  <div className="content">
                  <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>SI.NO</th>
                        <th>STATUS</th>
                        <th>PRODUCT NAME</th>
                        <th>PRICE</th>
                        <th>QTY</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {packs.length >0 ? packs.map((pack,index) => (
                        <tr key={index}>
                        <td>{index+1 + this.state.skip}</td>
                        <td> <SwitchButton changeStatus={((stat)=>this.handleStatus(stat,pack.id))}  enabled={pack.status ===1? true:false}/></td>
                        <td className="nameImg">
                        <img src={pack.fields.featured_image} alt="wild" className="img-ico" />
                        <span className="p-name">{pack.title}</span>
                        </td>
                        <td>INR {pack.prices.selling_price}</td>
                        <td>{pack.stock && pack.stock.stock_level }</td>
                        <td>
                          <div className="group-action">
                            <Link className="btn" to={`/store/edit-group-product/${pack.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,pack.id))}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'5'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>

                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
                </div>
                <Button className="fixed-yellow" onClick={() => this.props.history.push("/store/add-group-product")}>Add</Button>
                <SweetAlert
                show={this.state.alert }
                onConfirm={this.onConfirm}
                onCancel = {() => this.setState({ alert: false })}
                title= {'Are you sure?'}
                text="Delete Product"
                type= {'warning'}
                showCancelButton= {true}
                confirmButtonText= {'Yes, Confirm it!'}
                cancelButtonText= {'No, keep it'}
                />
                   <SweetAlert
                show={this.state.dialogue }
                onConfirm={this.onStatus}
                onCancel = {() => this.setState({ dialogue: false })}
                title= {'Are you sure?'}
                text="Change status"
                type= {'warning'}
                showCancelButton= {true}
                confirmButtonText= {'Yes, Confirm it!'}
                cancelButtonText= {'No, keep it'}
                />
                <SweetAlert
                show={this.props.status && this.props.popup}
                title="Success"
                text={this.props.message}
                onConfirm={()=>this.onDismissPopup()}
                />
                <SweetAlert
                show={ !this.props.status && this.props.popup}
                title="Error"
                type="error"
                text={this.props.message}
                onConfirm={()=>this.onDismissPopup()}
                />
              </div>
            </div>
            
         );
    }
}
ComboPackOffer.defaultProps = {
  packs:[]
}
 
const mapStateToProps = (state) => ({
  packLoading: state.PackReducer.packLoading,
  packs: state.PackReducer.packs,
  count:state.PackReducer.count,
  store:state.storeUserReducer.store,
  loading: state.productReducer.loading,
  status:state.productReducer.status,
  message:state.productReducer.message,
  popup:state.productReducer.popup,
})
const mapDispatchToProps = (dispatch)=> ({
  OnGetPacks : (store_id,skip,take) => {
    dispatch(getAllPacks(store_id,skip,take));
  },
  onDismiss:()=>{
    dispatch(onDismiss());
  },
  onDeleteProduct:(id,status) => {
    dispatch(packStatus(id,status))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ComboPackOffer)