import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addVolumeOffer(data) {
    return request({
      url:   URLS.ADD_VOLUME_OFFER,
      method: 'POST',
      data:data
    });
}

function addBrandOffer(data) {
    return request({
      url:   URLS.ADD_BRAND_OFFER,
      method: 'POST',
      data:data
    });
}

function addCategoryOffer(data) {
    return request({
       url:   URLS.ADD_CATEGORY_OFFER,
      method: 'POST',
      data:data
    });
}

function addProductOffer(data) {
    return request({
       url:   URLS.ADD_PRODUCT_OFFER,
      method: 'POST',
      data:data
    });
}

function addPack(data) {
    return request({
       url:   URLS.ADD_PACKS,
      method: 'POST',
      data:data
    });
}

function addStorePack(data) {
  return request({
     url:   URLS.ADD_STORE_PACKS,
    method: 'POST',
    data:data
  });
}



//get volume offers
function getAllVolumeOffers(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ALL_VOLUME_OFFERS + sub_url,
      method: 'GET'
    });
}
//get product offers
function getAllProductOffers(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ALL_PRODUCT_OFFERS + sub_url,
      method: 'GET'
    });
}
//get category offers
function getAllCategoryOffers(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ALL_CATEGORY_OFFERS + sub_url,
      method: 'GET'
    });
}
//Get brand offers
function getAllBrandOffers(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ALL_BRAND_OFFERS + sub_url,
      method: 'GET'
    });
}

//Get all pack offers
function getAllPacks(store_id,skip,take) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ALL_PACKS + sub_url,
      method: 'GET'
    });
}

//delete offers
function delVolumeOffer(vol_id) {
    var sub_url = '/'+vol_id;
    return request({
      url:   URLS.DELETE_VOLUME_OFFER + sub_url,
      method: 'GET',
    });
}

function delProductOffer(pro_id) {
    var sub_url = '/'+pro_id;
    return request({
      url:   URLS.DELETE_PRODUCT_OFFER + sub_url,
      method: 'GET',
    });
}

function delCategoryOffer(id) {
    var sub_url = '/'+id;
    return request({
      url:   URLS.DELETE_CATEGORY_OFFER + sub_url,
      method: 'GET',
    });
}

function delBrandOffer(id) {
    var sub_url = '/'+id;
    return request({
      url:   URLS.DELETE_BRAND_OFFER + sub_url,
      method: 'GET',
    });
}

//EDIT PRODUCT OFFERS
function getProductOffer(offer_id) {
    var sub_url = '/'+offer_id;
    return request({
      url:   URLS.GET_PRODUCT_OFFER + sub_url,
      method: 'GET'
    });
}

function editProductOffer(data) {
    return request({
      url:URLS.EDIT_PRODUCT_OFFER,
      method: 'POST',
      data:data
    });
}

//EDIT AND GET VOLUME OFFER

function getVolumeOffer(offer_id) {
    var sub_url = '/'+offer_id;
    return request({
      url:   URLS.GET_VOLUME_OFFER + sub_url,
      method: 'GET'
    });
}


function editVolumeOffer(data) {
    return request({
      url:URLS.EDIT_VOLUME_OFFER,
      method: 'POST',
      data:data
    });
}

//EDIT AND GET BRAND OFFER

function getBrandOffer(offer_id) {
    var sub_url = '/'+offer_id;
    return request({
      url:   URLS.GET_BRAND_OFFER + sub_url,
      method: 'GET'
    });
}


function editBrandOffer(data) {
    return request({
      url:URLS.EDIT_BRAND_OFFER,
      method: 'POST',
      data:data
    });
}

//EDIT AND GET CATEGORY OFFER

function getCategoryOffer(offer_id) {
    var sub_url = '/'+offer_id;
    return request({
      url:   URLS.GET_CATEGORY_OFFER + sub_url,
      method: 'GET'
    });
}


function editCategoryOffer(data) {
    return request({
      url:URLS.EDIT_CATEGORY_OFFER,
      method: 'POST',
      data:data
    });
}


// EDIT PACK 
function editPack(data) {
    return request({
       url:   URLS.EDIT_PACK,
      method: 'POST',
      data:data
    });
}
function getPack(product_id) {
    var sub_url = '/'+product_id;
    return request({
      url:   URLS.GET_PACK + sub_url,
      method: 'GET'
    });
}

function packStatus(product_id,status) {
    var sub_url = '';
    if(product_id !== null && typeof product_id !== 'undefined') sub_url = sub_url+'/'+product_id;
    if(status !== null && typeof status !== 'undefined') sub_url = sub_url+'/'+status;
    return request({
      url:   URLS.PRODUCT_STATUS + sub_url,
      method: 'GET'
    });
}


const OfferService = {
    addVolumeOffer,
    addBrandOffer,
    addCategoryOffer,
    addProductOffer,
    addPack,
    getAllVolumeOffers,
    getAllProductOffers,
    getAllCategoryOffers,
    getAllBrandOffers,
    getAllPacks,
    delVolumeOffer,
    delProductOffer,
    delCategoryOffer,
    delBrandOffer,
    editProductOffer,
    getProductOffer,
    editCategoryOffer,
    getCategoryOffer,
    editBrandOffer,
    getBrandOffer,
    getVolumeOffer,
    editVolumeOffer,
    editPack,
    getPack,
    packStatus,
    addStorePack
}

export default OfferService;