import React, { Component } from 'react';

import AllOrders from '../../../common/order/AllOrders';

export default class AdminOrders extends Component{


  render(){
    return(
      <AllOrders isStore={false} history={this.props.history}/>
    )
  }
}