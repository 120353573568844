import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  brandsLoading: false,
  brandstoreLoading:false,
  loadbrand:false,
  brands: [],
  store_brands:[],
  brand: []
};

export default function brandReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_BRAND_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_BRAND:
      return {
        ...state,
        loading: false,
        message: "Brand added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_BRAND_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Brand add failed",
        status: false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.GET_BRANDS_LOADING:
      return {
        brandsLoading: true,
        brands: []
      };
    case Actions.GET_BRANDS:
      return {
        ...state,
        brandsLoading: false,
        brands: action.brands,
        count: action.count
      };
    case Actions.GET_BRANDS_FAILED:
      return {
        ...state,
        brandsLoading: false,
        brands: [],
        error: action.error
      };
      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

      case Actions.EDIT_BRAND_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_BRAND:
      return {
        ...state,
        loading: false,
        message: "Brand edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_BRAND_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Brand editing failed",
        status: false,
        error: action.error
      };

      case Actions.GET_BRAND_LOADING:
      return {
        brand: [],
        loadbrand:true,
      };
    case Actions.GET_BRAND:
      return {
        ...state,
        loadbrand:false,
        brand: action.brand,
      };
    case Actions.GET_BRAND_FAILED:
      return {
        ...state,
        brand: [],
        loadbrand:false,
        error: action.error
      };

      case Actions.GET_STORE_BRANDS_LOADING:
      return {
       brandstoreLoading: true,
        store_brands: []
      };
    case Actions.GET_STORE_BRANDS:
      return {
        ...state,
        brandstoreLoading: false,
        store_brands: action.store_brands,
        count1: action.count
      };
    case Actions.GET_STORE_BRANDS_FAILED:
      return {
        ...state,
        brandstoreLoading: false,
        store_brands: [],
        error: action.error
      };




    default:
      return state;
  }
}
