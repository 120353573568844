import * as Actions from '../constants/Actions';
import storeStaffService from '../services/storeStaffService';

function checkCustomerExist(mobile){
    return dispatch =>{
        dispatch(request());
        storeStaffService.checkCustomerExist(mobile)
        .then(res =>{
            var user = res.data;
            if(user){
                dispatch(success(user))
            }else{
                dispatch(failed(null))
            }
            
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.CHECK_USER_EXIST_LOADING} }
    function success(user) { return { type: Actions.CHECK_USER_EXIST_SUCCESS, user} }
    function failed(error) { return { type: Actions.CHECK_USER_EXIST_FAILED, error:error} }
}

function registerCustomer(data){
    return dispatch =>{
        dispatch(request());
        storeStaffService.registerCustomer(data)
        .then(res =>{
            var user = res.data;
            if(user){
                dispatch(success(user))
            }else{
                dispatch(failed(null))
            }
            
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.REGISTER_CUSTOMER_LOADING} }
    function success(user) { return { type: Actions.REGISTER_CUSTOMER_SUCCESS, user} }
    function failed(error) { return { type: Actions.REGISTER_CUSTOMER_FAILED, error:error} }
}

function getStoreCustomers(store_id,skip =null, take = null, query=null){
    return dispatch =>{
        dispatch(request());
        storeStaffService.getStoreCustomers(store_id,skip,take, query)
        .then(res =>{
            var customers = res.data;
            var count = res.count;
            dispatch(success(customers,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ALL_STORE_CUSTOMERS_LOADING} }
    function success(customers,count) { return { type: Actions.ALL_STORE_CUSTOMERS_SUCCESS, customers: customers,count:count} }
    function failed(error) { return { type: Actions.ALL_STORE_CUSTOMERS_FAILED, error:error} }
}

function getStoreStaffs(store_id,skip =null, take = null){
    return dispatch =>{
        dispatch(request());
        storeStaffService.getStoreStaffs(store_id,skip,take)
        .then(res =>{
            var staffs = res.data;
            var count = res.count;
            dispatch(success(staffs,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_STAFF_LOADING} }
    function success(staffs,count) { return { type: Actions.GET_STORE_STAFF_SUCCESS, staffs: staffs,count:count} }
    function failed(error) { return { type: Actions.GET_STORE_STAFF_FAILED, error:error} }
}

function getStoreStaff(user_id){
    return dispatch =>{
        dispatch(request());
        storeStaffService.getStoreStaff(user_id)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_SINGLE_STAFF_LOADING} }
    function success(staff) { return { type: Actions.GET_STORE_SINGLE_STAFF_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.GET_STORE_SINGLE_STAFF_FAILED, error:error} }
}

function editStoreStaff(data){
    return dispatch =>{
        dispatch(request());
        storeStaffService.editStoreStaff(data)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_STORE_STAFF_LOADING} }
    function success(staff) { return { type: Actions.EDIT_STORE_STAFF_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.EDIT_STORE_STAFF_FAILED, error:error} }
}

function deleteStoreStaff(user_id){
    return dispatch =>{
        dispatch(request());
        storeStaffService.deleteStoreStaff(user_id)
        .then(res =>{
            var staff = res.data;
            dispatch(success(staff))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.DELETE_STORE_STAFF_LOADING} }
    function success(staff) { return { type: Actions.DELETE_STORE_STAFF_SUCCESS, staff: staff} }
    function failed(error) { return { type: Actions.DELETE_STORE_STAFF_FAILED, error:error} }
}

function getStoreDashboard(store_id){
    return dispatch =>{
        dispatch(request());
        storeStaffService.getStoreDashboard(store_id)
        .then(res =>{
            var dashboard = res.data;
            dispatch(success(dashboard))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.STORE_DASHBOARD_LOADING} }
    function success(dashboard) { return { type: Actions.STORE_DASHBOARD_SUCCESS, dashboard: dashboard} }
    function failed(error) { return { type: Actions.STORE_DASHBOARD_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function addStoreStaff(data){
    return dispatch =>{
        dispatch(request());
        storeStaffService.addStoreStaff(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failed(error.data.message));
                }else{
                  dispatch(failed(error.data));
                }
              }else{
                dispatch(failed(error));
              }
        })
    }
    function request() { return { type: Actions.ADD_STORE_STAFF_LOADING} }
    function success(data) { return { type: Actions.ADD_STORE_STAFF_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADD_STORE_STAFF_FAILED, error:error} }
}


export {
    getStoreCustomers,
    getStoreDashboard,
    getStoreStaffs,
    getStoreStaff,
    editStoreStaff,
    deleteStoreStaff,
    addStoreStaff,
    onDismiss,
    checkCustomerExist,
    registerCustomer
};