import * as Actions from '../constants/Actions';

const initialState={
    loading:false,
    otp_res:{},
    store_otp_res:{}
}

export default function resetReducer(state=initialState,action){
    switch(action.type){

            case Actions.ADMIN_SEND_RESET_OTP_LOADING:
            return{
            ...state,
            loading:true,
            status: false,
            admin_id:{}
            }
            case Actions.ADMIN_SEND_RESET_OTP_SUCCESS:
            return{
            ...state,
            loading:false,
            admin_id:action.data,
            status: true

            }
            case Actions.ADMIN_SEND_RESET_OTP_FAILED:
            return{
            loading: false,
            status: false,
            error: action.error,
            admin_id:{}
            }


            case Actions.ADMIN_VERIFY_RESET_OTP_LOADING:
            return{
            ...state,
            loading:true,
            otp_res:{}
            }
            case Actions.ADMIN_VERIFY_RESET_OTP_SUCCESS:
            return{
            ...state,
            loading:false,
            otp_res:action.data,
            status: true
            }
            case Actions.ADMIN_VERIFY_RESET_OTP_FAILED:
            return{
            ...state,
            loading: false,
            status: false,
            popup: true,
            otp_res:{},
            message:"OTP Verification Failed",
            error: action.error
            }

            case Actions.ADMIN_RESET_PASS_LOADING:
            return {
            ...state,
            loading: true
            };
            case Actions.ADMIN_RESET_PASS_SUCCESS:
            return {
            ...state,
            loading: false,
            message:"Password reset successfully",
            popup: true,
            status: true
            };
            case Actions.ADMIN_RESET_PASS_FAILED:
            return {
            ...state,
            loading: false,
            popup: true,
            message:"Password reseting failed",
            status: false,
            error: action.error
            }; 
            
            
            // store

            case Actions.STORE_SEND_RESET_OTP_LOADING:
            return{
            ...state,
            user_id:{},
            loading:true
            }
            case Actions.STORE_SEND_RESET_OTP_SUCCESS:
            return{
            ...state,
            loading:false,
            user_id:action.data,
            status: true
            }
            case Actions.STORE_SEND_RESET_OTP_FAILED:
            return{
            ...state,
            user_id:{},
            loading: false,
            status: false,
            error: action.error
            }


            case Actions.STORE_VERIFY_RESET_OTP_LOADING:
            return{
            ...state,
            loading:true,
            store_otp_res:{}
            }
            case Actions.STORE_VERIFY_RESET_OTP_SUCCESS:
            return{
            ...state,
            loading:false,
            store_otp_res:action.data,
            status: true
            }
            case Actions.STORE_VERIFY_RESET_OTP_FAILED:
            return{
            ...state,
            store_otp_res:{},
            loading: false,
            status: false,
            popup: true,
            message:"OTP Verification Failed",
            error: action.error
            }

            case Actions.STORE_RESET_PASS_LOADING:
            return {
            ...state,
            loading: true
            };
            case Actions.STORE_RESET_PASS_SUCCESS:
            return {
            ...state,
            loading: false,
            message:"Password reset successfully",
            popup: true,
            status: true
            };
            case Actions.STORE_RESET_PASS_FAILED:
            return {
            ...state,
            loading: false,
            popup: true,
            message:"Password reseting failed",
            status: false,
            error: action.error
            }; 


            case Actions.ALERT_DISMISS:
            return {
              ...state,
              status: false,
              popup: false
            };

            case Actions.DATA_RESET:
            return{
               
            }

        default:
            return state;
    }
}