import React, { Component } from 'react';
import { Input,Table } from "reactstrap";
import { Button } from "../../components";
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import SimpleReactValidator from 'simple-react-validator';
class ColourSwatches extends Component {
    constructor (props){
        super(props);
        this.state = {
            color:'#ccc',
            attribute_values:this.props.attribute_values
        }
        this.addColour = this.addColour.bind(this);
        this.validator = new SimpleReactValidator();
    }
   changeHandler = (colors,i) => {
        let attribute_values = this.state.attribute_values;
        var obj = {};
        if(attribute_values.length > i){
            obj = attribute_values[i];
        }
        obj['value'] = colors.color;
        attribute_values[i] = obj;
        this.setState({
            attribute_values
        },()=>{
            this.props.onChangeColor(this.state.attribute_values)
        })
    }
    addColour () {
        var obj = {value:"#ccc",value_code:'',attribute_id:typeof this.state.attribute_values[0] !== 'undefined'? this.state.attribute_values[0].attribute_id:0}
        this.setState(prevState => ({ attribute_values: [...prevState.attribute_values, obj]}))
    }
    deleteColour = (i,e) => {
        let attribute_values = this.state.attribute_values;
        attribute_values.splice(i,1);
        this.setState({attribute_values},()=>{
            this.props.onChangeColor(this.state.attribute_values)
        });
        this.validator = new SimpleReactValidator();
    }

    textOnChange = (e,i)=>{
        let attribute_values = this.state.attribute_values;
        var obj = {};
        if(attribute_values.length > i){
            obj = attribute_values[i];
        }
        obj['value_code'] = e.target.value;
        attribute_values[i] = obj;
        this.setState({
            attribute_values
        },()=>{
            this.props.onChangeColor(this.state.attribute_values)
        })

    }

    reset = ()=>{
        this.setState({
            attribute_values:[]
        })
    }

    validate = ()=>{
        if( this.validator.allValid() ){
            return true
        }else{
            this.validator.showMessages();
            this.forceUpdate();
            return false
        }
    }

    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        if(nextProps.attribute_values && nextProps.isEditing){
            this.setState({attribute_values:nextProps.attribute_values})
        }
    }

    colourUI () {
        return this.state.attribute_values.map((atr,i) => (
            <tr key ={i}>       
            <td>
            <ColorPicker
            color={atr.value}
            onChange={(color)=>this.changeHandler(color,i)}
            placement="topLeft"/>
            <div className="errorMsg"> {this.validator.message('color value', atr.value, 'required')}</div>
            </td>
            
            <td><Input type="text" onChange ={(e)=>this.textOnChange(e,i)} value={atr.value_code} placeholder="Color name" />
            <div className="errorMsg"> {this.validator.message('color name', atr.value_code, 'required')}</div>
            </td>
            
            <td>
            <Button className="btn-tsp" onClick = {(e)=>this.deleteColour(i,e)} leftLabel="fa red fa-times"></Button>
            </td>
            </tr>
        ));
    }


    render() { 
        return ( 
            <React.Fragment>
            <Table>
            <thead>
            <tr>
            <th>Pick Colour</th>
            <th>Variant Name</th>
            <th></th>
            </tr>
            </thead>
            <tbody>
            {this.colourUI()}
            </tbody>
            </Table>
             {this.props.disableAdd?null:
             <Button className="btn-plus" onClick={()=>this.addColour()} leftLabel="fa fa-plus">ADD VARIANT</Button>}
             </React.Fragment>
         );
    }
}

ColourSwatches.defaultProps ={
    attribute_values:[],
    isEditing : false,
    disableAdd: false
}
 
export default ColourSwatches;