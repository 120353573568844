import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  contents: []
};

export default function aboutReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SAVE_ABOUT_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.SAVE_ABOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"About content added succesully",
        popup: true,
        status: true
      };
    case Actions.SAVE_ABOUT_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Failed to add content",
        status: false,
        error: action.error
      };

    case Actions.GET_ABOUT_LOADING:
      return {
        loading: true,
        contents: []
      };
    case Actions.GET_ABOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        contents: action.contents
      };
    case Actions.GET_ABOUT_FAILED:
      return {
        ...state,
        loading: false,
        contents: [],
        error: action.error
      };

    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


      
    default:
      return state;
  }
}
