import * as Actions from '../constants/Actions';
import UserService from '../services/UserService';
import * as Constants from '../constants/AppConstants'
import { BASE_URL } from '../constants/AppUrls';
import axios from 'axios';

function login(mobile,password){

    return dispatch =>{
        dispatch(request());
        UserService.login(mobile,password)
        .then(res =>{
            var data = res.data;
            var token = data.token;
            var permissions = data.permissions;
            var user = data.user;
            sessionStorage.setItem('token',token);
            sessionStorage.setItem('loged_in',true);
            sessionStorage.setItem('type','admin');
            sessionStorage.setItem('user',user);
            sessionStorage.setItem('permissions',permissions);
            dispatch(success(token,user,permissions))
            dispatch(setModel(Constants.USER_TYPE_ADMIN))
        }).catch(error =>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failed(error.data.message));
                }else{
                  dispatch(failed(error.data));
                }
              }else{
                dispatch(failed(error));
              }
            dispatch(setModel(Constants.USER_TYPE_STORE))
        })
    }
    function request() { return { type: Actions.ADMIN_LOGIN_LOADING} }
    function success(token,user,permissions) { return { type: Actions.ADMIN_LOGIN, token:token,permissions:permissions, user:user} }
    function failed(error) { return { type: Actions.ADMIN_LOGIN_FAILED, error:error} }
    function setModel(model) { return { type: Actions.SET_USER_MODEL, model:model} }
}

function setUserModel(model){
    return dispatch =>{
        dispatch(setModel(model))
    }
    function setModel(model) { return { type: Actions.SET_USER_MODEL, model:model} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function setSound(sound){
    return dispatch =>{
        dispatch(success(sound))
    }
    function success(sound) { return { type: Actions.SET_SOUND, sound} }
}

//admin permissions
function getAdminPermissions(){
    return dispatch =>{
        dispatch(request());
        UserService.getAdminPermissions()
        .then(res =>{
            var admin_permissions = res.data;
            dispatch(success(admin_permissions))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADMIN_PERMISSION_LOADING} }
    function success(admin_permissions) { return { type: Actions.ADMIN_PERMISSION, admin_permissions: admin_permissions} }
    function failed(error) { return { type: Actions.ADMIN_PERMISSION_FAILED, error:error} }
}

//store permissions
function getStorePermissions(){
    return dispatch =>{
        dispatch(request());
        UserService.getStorePermissions()
        .then(res =>{
            var store_permissions = res.data;
            dispatch(success(store_permissions))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.STORE_PERMISSION_LOADING} }
    function success(store_permissions) { return { type: Actions.STORE_PERMISSION, store_permissions: store_permissions} }
    function failed(error) { return { type: Actions.STORE_PERMISSION_FAILED, error:error} }
}

function storeLogin(mobile,password){

    return dispatch =>{
        dispatch(request());
        UserService.storeLogin(mobile,password)
        .then(res =>{
            var data = res.data;
            var token = data.token;
            var store = data.store;
            var user = data.user;
            sessionStorage.setItem('token',token);
            sessionStorage.setItem('type','store');
            sessionStorage.setItem('loged_in',true);
            sessionStorage.setItem('user',user);
            sessionStorage.setItem('store',store);
            dispatch(success(token,user,store))
            dispatch(setModel(Constants.USER_TYPE_STORE))
        }).catch(error =>{
            if(error.hasOwnProperty('data')){
                if(error.data.hasOwnProperty('message')){
                  dispatch(failed(error.data.message));
                }else{
                  dispatch(failed(error.data));
                }
              }else{
                dispatch(failed(error));
              }
            dispatch(setModel(Constants.USER_TYPE_STORE))
        })
    }
    function request() { return { type: Actions.STORE_LOGIN_LOADING} }
    function success(token,user,store) { return { type: Actions.STORE_LOGIN, token:token,store:store, user:user} }
    function failed(error) { return { type: Actions.STORE_LOGIN_FAILED, error:error} }
    function setModel(model) { return { type: Actions.SET_USER_MODEL, model:model} }
}


function logoutReset(){
    return dispatch =>{
        dispatch(success());
       
    }
    function success() { return { type: Actions.LOGOUT_RESET} }
}




function logout(){
    return dispatch =>{
        dispatch(request());
        UserService.logout()
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(success())
        })
    }
    function request() { return { type: Actions.ADMIN_LOGOUT_LOADING} }
    function success() { return { type: Actions.ADMIN_LOGOUT_SUCCESS} }
}

function storeLogout(){
    return dispatch =>{
        dispatch(request());
        UserService.storeLogout()
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(success())
        })
    }
    function request() { return { type: Actions.STORE_LOGOUT_LOADING} }
    function success() { return { type: Actions.STORE_LOGOUT_SUCCESS} }
}

function changeAdminPass (data) {
    return dispatch => {
        dispatch(request());
        UserService.changeAdminPass(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.ADMIN_CHANGE_PASS_LOADING} }
    function success() { return { type: Actions.ADMIN_CHANGE_PASS} }
    function failed(error) { return { type: Actions.ADMIN_CHANGE_PASS_FAILED, error:error} }
}

function changeStorePass (data) {
    return dispatch => {
        dispatch(request());
        UserService.changeStorePass(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.STORE_CHANGE_PASS_LOADING} }
    function success() { return { type: Actions.STORE_CHANGE_PASS} }
    function failed(error) { return { type: Actions.STORE_CHANGE_PASS_FAILED, error:error} }
}

function downloadExcel(url) {
    axios.get(url,
        {
            headers:
            {
                'Content-Disposition': "attachment; filename=template.xlsx",
                'Access-Control-Allow-Origin' : '*',
                'X-Requested-With': 'XMLHttpRequest'
            },
            responseType: 'blob'
        }
    ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => console.log(error));
}


export{
    login,
    logout,
    onDismiss,
    getAdminPermissions,
    getStorePermissions,
    setUserModel,
    storeLogin,
    storeLogout,
    logoutReset,
    changeAdminPass,
    changeStorePass,
    downloadExcel,
    setSound
}