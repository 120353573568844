import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function getAllOrders(data) {
  return request({
    url:URLS.GET_ALL_ORDERS,
    method:'POST',
    data:data
  }) 

  }

  function getStoreOrders(data) {
    return request({
      url:URLS.GET_STORE_ORDERS,
      method:'POST',
      data
    })
  }

function getOrder(order_id) {
  var sub_url ='/'+order_id;
  return request({
    url:URLS.GET_ORDER+ sub_url,
    method:'GET'
  })
}  

function orderStatus(order_id,status) {
  var sub_url = '';
  if(order_id !== null && typeof order_id !== 'undefined') sub_url = sub_url+'/'+order_id;
  if(status !== null && typeof order_id !== 'undefined') sub_url = sub_url+'/'+status;
  return request({
    method:'GET',
    url:URLS.CHANGE_ORDER_STATUS+sub_url   
  })

}


function startShipping(data) {
  return request({
    method:'POST',
    url:URLS.START_SHIPPING,
    data   
  })

}

function editOrderItem(data) {
  return request({
    method:'POST',
    url:URLS.EDIT_ORDER_ITEM,
    data   
  })

}

function deleteOrderItem(data) {
  return request({
    method:'POST',
    url:URLS.DELETE_ORDER_ITEM,
    data   
  })

}

function placeOrder(data) {
  return request({
    method:'POST',
    url:URLS.PLACE_ORDER,
    data   
  })

}


  const orderService = {
    getAllOrders,
    getOrder,
    orderStatus,
    getStoreOrders,
    startShipping,
    placeOrder,
    editOrderItem,
    deleteOrderItem
  }

  export default orderService;