import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  storeLoading: false,
  stores: [],
  store:{}
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {

    
    case Actions.ADD_STORE_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_STORE:
      return {
        ...state,
        loading: false,
        message: "Store added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_STORE_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Store add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_STORE_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_STORE:
      return {
        ...state,
        loading: false,
        message: "Store edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_STORE_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Store edit failed",
        status: false,
        error: action.error
      };


    case Actions.GET_STORES_LOADING:
      return {
        storeLoading: true,
        stores: []
      };
    case Actions.GET_STORES:
      return {
        ...state,
        storeLoading: false,
        stores: action.stores,
        count: action.count
      };
    case Actions.GET_STORES_FAILED:
      return {
        ...state,
        storeLoading: false,
        stores: [],
        error: action.error
      };

      case Actions.GET_STORE_LOADING:
      return {
        store: {},
        loading:true,
      };
    case Actions.GET_STORE:
      return {
        ...state,
        loading:false,
        store: action.store,
      };
    case Actions.GET_STORE_FAILED:
      return {
        ...state,
        store: {},
        loading:false,
        error: action.error
      };

      case Actions.STORE_STATUS_LOADING:
      return {
        loading:true,
        status: true,
        popup: false,
      };
    case Actions.STORE_STATUS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Store Status Changed",
        store: action.store,
      };
    case Actions.STORE_STATUS_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };



      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };
    default:
      return state;
  }
}
