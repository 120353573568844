import * as Actions from '../constants/Actions';
const initialState = {
  loading: false,
  status: false,
  reviews:[]
};

export default function reviewReducer(state = initialState, action) {

  switch(action.type) {
    case Actions.GET_REVIEWS_LOADING:
    return {
      loading: true,
      reviews: []
    };
  case Actions.GET_REVIEWS_SUCCESS:
    return {
      ...state,
      loading: false,
      reviews: action.reviews,
      count: action.count
    };
  case Actions.GET_REVIEWS_FAILED:
    return {
      ...state,
      loading: false,
      reviews: [],
      error: action.error
    };

    default :
    return state;

  }

}