import * as Actions from "../constants/Actions";

const initialState = {
  sound: null,
 
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SET_SOUND:
      return {
        ...state,
        sound: action.sound
      };
   
    default:
      return state;
  }
}
