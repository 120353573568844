import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  loading1: false,
  status1: false,
  popup1: false,
  packs: [],
  pack:[]
};
export default function PackReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_PACK_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_PACK:
      return {
        ...state,
        loading: false,
        message: "Packed product added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_PACK_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Packed product add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_PACK_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_PACK:
      return {
        ...state,
        loading: false,
        message: "Packed product edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_PACK_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Packed product edit failed",
        status: false,
        error: action.error
      };

     case Actions.GET_ALL_PACKS_LOADING:
      return {
       packLoading: true,
        packs: []
      };
    case Actions.GET_ALL_PACKS:
      return {
        ...state,
        packLoading: false,
        packs: action.packs,
        count: action.count
      };
    case Actions.GET_ALL_PACKS_FAILED:
      return {
        ...state,
        packLoading: false,
        packs: [],
        error: action.error
      };

      case Actions.GET_PACK_LOADING:
      return {
        ...state,
        pack: [],
      loading:true,
      };
    case Actions.GET_PACK:
      return {
        ...state,
        loading:false,
        pack: action.pack,
      };
    case Actions.GET_PACK_FAILED:
      return {
        ...state,
        pack: [],
        loading:false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false,
        status1: false,
        popup1: false,
      };


    default:
      return state;
  }
}