import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  items:[],
  store_item:{},
  item:{},
  itemProducts:[]
};

export default function itemReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_ITEM_LOADING:
      return {
        loading: true,
        popup: false,
      };
    case Actions.ADD_ITEM_SUCCESS:
      return {
        loading: false,
        message: "Item added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_ITEM_FAILED:
      return {
        loading: false,
        popup: true,
        message: "Item add failed",
        status: false,
        error: action.error
      };

      case Actions.GET_ITEMS_LOADING:
      return {
        ...state,
        loading: true,
        status: false,
        popup: false
      };
    case Actions.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items:action.data,
        count:action.count,
        status: false,
        popup: false,
        loading: false,
      };
    case Actions.GET_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        status: false,
        popup: false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        status: false,
        popup: false
      };

      case Actions.CHANGE_ITEM_STATUS_LOADING:
     return {
       ...state,
       loading: true,
       status: false,
       popup: false
     };
   case Actions.CHANGE_ITEM_STATUS_SUCCESS:
     return {
       ...state,
       status: true,
       popup: true,
       loading: false,
       message:'Item status changed',
     };
   case Actions.CHANGE_ITEM_STATUS_FAILED:
     return {
       ...state,
       loading: false,
       status: false,
       popup: true,
       message:'Item status change failed',
       error: action.error
     };


     case Actions.CHANGE_ITEM_VARIANT_STATUS_LOADING:
     return {
       ...state,
       loading: true,
       status: false,
       popup: false
     };
   case Actions.CHANGE_ITEM_VARIANT_STATUS_SUCCESS:
     return {
       ...state,
       status: true,
       popup: true,
       message:'Variant status changed',
       loading: false,
     };
   case Actions.CHANGE_ITEM_VARIANT_STATUS_FAILED:
     return {
       ...state,
       loading: false,
       status: false,
       message:'Variant status change failed',
       popup: true,
       error: action.error
     };

     case Actions.GET_ITEM_LOADING:
     return {
       ...state,
       loading: true,
       item:{},
       status: false,
       popup: false
     };
   case Actions.GET_ITEM_SUCCESS:
     return {
       ...state,
       status: false,
       popup: false,
       loading: false,
       item:action.data
     };
   case Actions.GET_ITEM_FAILED:
     return {
       ...state,
       loading: false,
       status: false,
       popup: false,
       error: action.error
     };


     case Actions.EDIT_ITEM_LOADING:
     return {
       ...state,
       loading: true,
       status: false,
       popup: false
     };
   case Actions.EDIT_ITEM_SUCCESS:
     return {
       ...state,
       status: true,
       popup: true,
       message:"Edit Product Success",
       loading: false,
     };
   case Actions.EDIT_ITEM_FAILED:
     return {
       ...state,
       loading: false,
       status: false,
       popup: true,
       message:"Edit Product Failed",
       error: action.error
     };


     case Actions.ADD_ITEM_VARIANTS_LOADING:
     return {
       ...state,
       status:false,
       popup:false,
       loading: true,
     };
   case Actions.ADD_ITEM_VARIANTS_SUCCESS:
     return {
       ...state,
       loading: false,
       status:true,
       popup:true,
       message:'Add Item Variant Successfull',
     };
   case Actions.ADD_ITEM_VARIANTS_FAILED:
     return {
       ...state,
       loading: false,
       status:false,
       message:'Add Item Variant Failed',
       popup:true,
       error: action.error
     };

   case Actions.EDIT_ITEM_VARIANTS_LOADING:
     return {
       ...state,
       status:false,
       popup:false,
       loading: true,
     };
   case Actions.EDIT_ITEM_VARIANTS_SUCCESS:
     return {
       ...state,
       loading: false,
       status:true,
       popup:true,
       message:'Edit Item Variant Successfull',
     };
   case Actions.EDIT_ITEM_VARIANTS_FAILED:
     return {
       ...state,
       loading: false,
       status:false,
       message:'Edit Item Variant Failed',
       popup:true,
       error: action.error
     };

     case Actions.GET_STORE_ITEM_LOADING:
     return {
       ...state,
       loading: true,
       store_item:{},
       status: false,
       popup: false
     };
   case Actions.GET_STORE_ITEM_SUCCESS:
     return {
       ...state,
       store_item:action.data,
       count:action.count,
       status: false,
       popup: false,
       loading: false,
     };
   case Actions.GET_STORE_ITEM_FAILED:
     return {
       ...state,
       store_item:{},
       loading: false,
       status: false,
       popup: false,
       error: action.error
     };

     case Actions.EDIT_STORE_ITEM_LOADING:
     return {
       ...state,
       loading: true,
       status: false,
       popup: false
     };
   case Actions.EDIT_STORE_ITEM_SUCCESS:
     return {
       ...state,
       status: true,
       popup: true,
       message:"Edit Store Product Success",
       loading: false,
     };
   case Actions.EDIT_STORE_ITEM_FAILED:
     return {
       ...state,
       loading: false,
       status: false,
       popup: true,
       message:"Edit Store Product Failed",
       error: action.error
     };

     case Actions.UPLOAD_PRODUCT_STOCK_LOADING:
      return {
        ...state,
        loading:true,
        status: true,
        popup: false,
      };
    case Actions.UPLOAD_PRODUCT_STOCK_SUCCESS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",
      };
    case Actions.UPLOAD_PRODUCT_STOCK_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };

     case Actions.GET_ITEM_PRODUCTS_LOADING:
     return {
       ...state,
       loading: true
     };
   case Actions.GET_ITEM_PRODUCTS_SUCCESS:
     return {
       ...state,
       loading: false,
       itemProducts: action.data
     };
   case Actions.GET_ITEM_PRODUCTS_FAILED:
     return {
       ...state,
       loading: false
     };
     case Actions.UNIQUE_BARCODE_SUCCESS:
     return {
       ...state,
       barcode: action.data
     };
     case Actions.UNIQUE_SKU_SUCCESS:
     return {
       ...state,
       sku: action.data
     };



    default:
      return state;
  }
}
