import * as Actions from '../constants/Actions';
import AddressService from '../services/AddressService';
import { setCurrencies } from '../reducers/resource.reducer'

function getCountries(){

    return dispatch =>{
        dispatch(request());
        AddressService.getCountries()
        .then(res =>{
            var countries = res.data;
            dispatch(success(countries))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_COUNTRIES_LOADING} }
    function success(countries) { return { type: Actions.GET_COUNTRIES, countries: countries} }
}

function getStates(country_id){

    return dispatch =>{
        dispatch(request());
        AddressService.getStates(country_id)
        .then(res =>{
            var states = res.data;
            dispatch(success(states))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STATES_LOADING} }
    function success(states) { return { type: Actions.GET_STATES, states: states} }
}

function getRegions(state_id){

    return dispatch =>{
        dispatch(request());
        AddressService.getRegions(state_id)
        .then(res =>{
            var regions = res.data;
            dispatch(success(regions))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_REGIONS_LOADING} }
    function success(regions) { return { type: Actions.GET_REGIONS, regions: regions} }
}

function getCities(region_id){

    return dispatch =>{
        dispatch(request());
        AddressService.getCities(region_id)
        .then(res =>{
            var cities = res.data;
            dispatch(success(cities))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_CITIES_LOADING} }
    function success(cities) { return { type: Actions.GET_CITIES, cities: cities} }
}


function getCurrencies(){
    return dispatch =>{
        AddressService.getCurrencies()
        .then(res =>{
            var currencies = res.data;
            dispatch(setCurrencies({currencies}))
        }).catch(error =>{
        })
    }
}



export {
    getCountries,
    getStates,
    getRegions,
    getCities,
    getCurrencies
} 