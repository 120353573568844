import * as Actions from '../constants/Actions';
import variantService from '../services/variantService';

function addVariant(data){
    return dispatch =>{
        dispatch(request());
        variantService.addVariant(data)
        .then(res =>{
            var variants = res.data;
            dispatch(success(variants))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_VARIANTS_LOADING} }
    function success(variants) { return { type: Actions.ADD_VARIANTS_SUCCESS,variants:variants} }
    function failed(error) { return { type: Actions.ADD_VARIANTS_FAILED, error:error} }
}

function editVariant(data){
    return dispatch =>{
        dispatch(request());
        variantService.editVariant(data)
        .then(res =>{
            var variants = res.data;
            dispatch(success(variants))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_VARIANT_LOADING} }
    function success(variants) { return { type: Actions.EDIT_VARIANT,variants:variants} }
    function failed(error) { return { type: Actions.EDIT_VARIANT_FAILED, error:error} }
}


function getVariant(attr_id){

    return dispatch =>{
        dispatch(request());
        variantService.getVariant(attr_id)
        .then(res =>{
            var variant = res.data;;
            dispatch(success(variant))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_VARIANT_LOADING} }
    function success(variant) { return { type: Actions.GET_VARIANT, variant: variant} }
    function failed(error) { return { type: Actions.GET_VARIANT_FAILED, error:error} }
}



function getVariants(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        variantService.getVariants(skip,take)
        .then(res =>{
            var variants = res.data;
            var count = res.count;
            dispatch(success(variants,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_VARIANTS_LOADING} }
    function success(variants,count) { return { type: Actions.GET_VARIANTS, variants: variants,count:count} }
    function failed(error) { return { type: Actions.GET_VARIANTS_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
    addVariant,
    getVariant,
    editVariant,
    getVariants,
    onDismiss
} 