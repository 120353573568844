import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  volLoading: false,
  volumes: [],
  offer:{}
};

export default function volumeOfferReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_VOLUME_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_VOLUME_OFFER:
      return {
        ...state,
        loading: false,
        message: "Volume offer added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_VOLUME_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Volume offer add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_VOLUME_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_VOLUME_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Volume offer edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_VOLUME_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Volume offer edit failed",
        status: false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.GET_ALL_VOLUME_OFFER_LOADING:
      return {
        volLoading: true,
        volumes: []
      };
    case Actions.GET_ALL_VOLUME_OFFER:
      return {
        ...state,
        volLoading: false,
        volumes: action.volumes,
        count: action.count
      };
    case Actions.GET_ALL_VOLUME_OFFER_FAILED:
      return {
        ...state,
        volLoading: false,
        volumes: [],
        error: action.error
      };

      case Actions.DELETE_VOLUME_OFFER_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_VOLUME_OFFER:
      return {
        ...state,
        loading: false,
        message:"Volume offer delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_VOLUME_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        message:"Volume offer delete failed",
        popup: true,
        status: false
      };

      case Actions.GET_VOLUME_OFFER_LOADING:
      return {
        offer: {},
      loading:true,
      };
    case Actions.GET_VOLUME_OFFER_SUCCESS:
      return {
        ...state,
        loading:false,
        offer: action.offer,
      };
    case Actions.GET_VOLUME_OFFER_FAILED:
      return {
        ...state,
        offer: {},
        loading:false,
        error: action.error
      };

    default:
      return state;
  }
}
