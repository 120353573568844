import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import { Button } from '../../../components';
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { addFaqDis, getFaqs, onDismiss } from "../../../actions/FaqActions";
import SweetAlert from "sweetalert2-react";
class AddFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      selectedCat: "",
      title: "",
      content: ""
    };
    this.validator = new SimpleReactValidator();
    this.onDismissPopup = this.onDismissPopup.bind(this);
  }
  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
        {
          this.props.onGetFaqs();
        }
      }
    });
  }
  onDismissPopup() {
    this.props.onDismiss();
  }
  handleCat = selectedCat => {
    this.setState({ selectedCat });
  };
  handleSubmit = e => {
    e.preventDefault();
    var data = {
      faq_id: this.state.selectedCat.value,
      title: this.state.title,
      content: this.state.content
    };

    if (this.validator.allValid()) {
      this.props.onAddFaqs(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
//WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
componentWillReceiveProps(nextProps) {
  if(nextProps.status===true) {
  this.setState({
    selectedCat: "",
    title: "",
    content: ""
  });
  }
}

  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading || this.props.faqLoading ? "bt-show" : "bt-hide";
    return classes;
  }

  render() {
    const { selectedCat } = this.state;
    let CatOptions = this.props.faqs.map(function(faq) {
      return { value: faq.id, label: faq.title };
    });
    return (
      <div>
        <div className="content">
          <div className={this.getLoder()} />
          <div className="form-section">
            <form onSubmit={this.handleSubmit}>
              <div className="form-filds-wrapper">
                <Row>
                  <Col md="7">
                    <div className="form-group">
                      <label className="control-label">Faq Category</label>
                      <Select
                        onChange={this.handleCat}
                        value={selectedCat}
                        options={CatOptions}
                        placeholder={"Select a Faq"}
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "Category",
                          selectedCat.value,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="form-group">
                      <label className="control-label">Question</label>
                      <input
                        type="text"
                        value={this.state.title}
                        onChange={e => this.setState({ title: e.target.value })}
                        className="form-control"
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "title",
                          this.state.title,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="form-group">
                      <label className="control-label">Answer</label>
                      <Input
                        type="textarea"
                        value={this.state.content}
                        rows={8}
                        onChange={e =>
                          this.setState({ content: e.target.value })
                        }
                        name="text"
                        placeholder="Type Here ..."
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "content",
                          this.state.content,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="submit-wrapper">
                      <Button type="submit" className="btn-submit">
                        Save
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
            <SweetAlert
              show={this.props.status && this.props.popup}
              title="Success"
              text={this.props.message}
              onConfirm={this.onDismissPopup}
            />
            <SweetAlert
              show={!this.props.status && this.props.popup}
              title="Error"
              type="error"
              text={this.props.message}
              onConfirm={this.onDismissPopup}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddFaq.defaultProps = {
  faqs: []
};
const mapStateToProps = state => ({
  loading: state.FaqReducer.loading,
  faqLoading:state.FaqReducer.faqLoading,
  status: state.FaqReducer.status,
  faqs: state.FaqReducer.faqs,
  message: state.FaqReducer.message,
  popup: state.FaqReducer.popup
});

const mapDispatchToProps = dispatch => ({
  onAddFaqs: data => {
    dispatch(addFaqDis(data));
  },
  onGetFaqs: () => {
    dispatch(getFaqs());
  },
  onDismiss: () => {
    dispatch(onDismiss());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFaq);
