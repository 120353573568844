import Dashboard from "../views/SuperAdmin/Dashboard/Dashboard.jsx";
import Products from "../views/SuperAdmin/Products/Products.jsx";
import Category from "../views/SuperAdmin/Category/Category";
import AddCategory from "../views/SuperAdmin/Category/AddCategory";
import Brands from "../views/SuperAdmin/Brands/Brands";
import AddBrand from "../views/SuperAdmin/Brands/AddBrands";
import Units from "../views/SuperAdmin/Units/Units.jsx";
import Attributes from "../views/SuperAdmin/Attributes/Attributes.jsx";
import AddAttribute from "../views/SuperAdmin/Attributes/AddAttribute";
import Users from "../views/SuperAdmin/Users/Users";
import AddUser from "../views/SuperAdmin/Users/AddUser";
import Customers from "../views/SuperAdmin/Customers/Customers";
import Stores from "../views/SuperAdmin/Stores/Stores";
import AddStoreData from "../views/SuperAdmin/Stores/AddStore";
import EditStore from "../views/SuperAdmin/Stores/EditStore";
import AboutUs from "../views/SuperAdmin/Content/AboutUs";
import Faq from "../views/SuperAdmin/Content/FAQ";
import NotiFication from "../views/SuperAdmin/Notifications/Notification";
import AddFaq from "../views/SuperAdmin/Content/AddFaq";
import EditBrand from "../views/SuperAdmin/Brands/EditBrand.jsx";
import EditAttribute from "../views/SuperAdmin/Attributes/EditAttribute.jsx";
import EditCategory from "../views/SuperAdmin/Category/EditCategory.jsx";
import MyAccount from "../views/SuperAdmin/MyAccount.jsx";
import PointTerms from "../views/SuperAdmin/Content/PointTerms.jsx";
import AddVariant from "../views/SuperAdmin/Variants/AddVariant.jsx";
import Variants from "../views/SuperAdmin/Variants/Variants.jsx";
import EditVariant from "../views/SuperAdmin/Variants/EditVariant.jsx";
import sendNotification from "../views/SuperAdmin/Notifications/sendNotification.jsx";
import DeliverySlots from "../views/SuperAdmin/Orders/DeliverySlots/DeliverySlots.jsx";
import AddDeliverySlot from "../views/SuperAdmin/Orders/DeliverySlots/AddDeliverySlot.jsx";
import AllOrders from "../views/SuperAdmin/Orders/AllOrders/AllOrders.jsx";
import Processing from "../views/common/order/processing/index.jsx";
import DeliveryBoys from "../views/SuperAdmin/Orders/DeliveryBoys/DeliveryBoys.jsx";
import AddDeliveryBoy from "../views/SuperAdmin/Orders/DeliveryBoys/AddDeliveryBoy.jsx";
import DeliveryBoy from "../views/SuperAdmin/Orders/DeliveryBoys/DeliveryBoy.jsx";
import FeedBacks from "../views/SuperAdmin/Orders/FeedBacks/FeedBacks.jsx";
import ProductForm from "../views/SuperAdmin/Products/ProductForm.jsx";
import AdminAccounts from "../views/SuperAdmin/Orders/Accounts/index.js";
import OrderDetail from "../views/common/order/OrderDetail.jsx";

import StoreUsers from "../views/StoreAdmin/Users/Users";
import AddStoreUser from "../views/StoreAdmin/Users/AddUser";
import EditStoreUser from "../views/StoreAdmin/Users/EditUser";
import CustomerForm from "../views/SuperAdmin/Customers/CustomerForm.jsx";
import PrivacyPolicy from "../views/SuperAdmin/Content/PrivacyPolicy.jsx";

const dashRoutes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: "icon-graph-bar",
    component: Dashboard
  },
  {
    path: "products",
    name: "Products",
    component: Products
  },
  {
    path: "add-product",
    name: "Add Products",
    component: ProductForm
  },
  {
    path: "category",
    name: "Categories",
    component: Category
  },
  {
    path: "add-category",
    name: "Add Category",
    component: AddCategory
  },
  {
    path: "edit-category/:id",
    name: "Edit Category",
    component: EditCategory
  },
  {
    path: "brands",
    name: "Brands",
    component: Brands
  },
  {
    path: "add-brand",
    name: "Add Brand",
    component: AddBrand
  },
  {
    path: "edit-brand/:id",
    name: "Edit Brand",
    component: EditBrand
  },
  {
    path: "units",
    name: "Units",
    component: Units
  },
  // {
  //   path: "tags",
  //   name: "Tags",
  //   component: Tags
  // },
  {
    path:"variants",
    name:"Variants",
    component:Variants
  },
  {
    path:"add-variant",
    name:"Add Variant",
    component:AddVariant
  },
  {
    path:"edit-variant/:id",
    name:"Edit Variant",
    component:EditVariant
  },
  {
    path:"attributes",
    name:"Attributes",
    component:Attributes
  },
  {
    path:"add-attribute",
    name:"Add Attribute",
    component:AddAttribute
  },
  {
    path:"edit-attribute/:id",
    name:"Edit Attribute",
    component:EditAttribute
  },
  {
    path:"staffs",
    name:"Users",
    component:Users
  },
  {
    path:"add-staff",
    name:"Add Staff",
    component:AddUser
  },
  {
    path:"customers",
    name:"Customers",
    component:Customers
  },
  {
    path:"edit-customer/:id",
    name:"Edit Customer",
    component: CustomerForm
  },
  {
    path:"stores",
    name:"Stores",
    component:Stores
  },
  {
    path:"add-store",
    name:"Add Store",
    component:AddStoreData
  },
  {
    path:"edit-store/:id",
    name:"Edit Store",
    component:EditStore
  },
  {
    path:"store-staffs",
    name:"Store Staffs",
    component: StoreUsers
  },
  {
    path:"add-store-staff",
    name:"Add Staff",
    component:AddStoreUser
  },
  {
    path:"edit-store-staff/:id",
    name:"Edit Staff",
    component: EditStoreUser
  },
  {
    path:"about-us",
    name:"About Us",
    component:AboutUs
  },
  {
    path:"terms",
    name:"Terms & Conditions",
    component:PointTerms
  },
  {
    path:"privacy-policy",
    name:"Privacy Policy",
    component:PrivacyPolicy
  },
  {
    path:"faq",
    name:"Faq",
    component:Faq
  },
  {
    path:"add-faq",
    name:"Add Faq",
    component:AddFaq
  },
  {
    path:"notifications",
    name:"Notifications",
    component:NotiFication
  },
  {
    name:"Send Notification",
    path:"send-notification",
    component:sendNotification
  },
  {
    name:"My Account",
    path: "myaccount",
    component: MyAccount
  },
  {
    path: "edit-product/:id",
    name: "Edit Product",
    component: ProductForm
  },
  {
    path:"delivery-slots",
    name:"Delivery Slots",
    component:DeliverySlots
  },

  {
    path:"add-slot",
    name:"Add delivery slots",
    component:AddDeliverySlot
  },
  {
    path: "edit-slot/:id",
    name: "Edit delivery slot",
    component: AddDeliverySlot
  },
  {
    path:"all-orders",
    name:"All",
    component:AllOrders
  },
  {
    path:"new-orders",
    name:"New Orders",
    component:Processing
  },
  {
    path:"delivery-boys",
    name:"Delivery boys",
    component:DeliveryBoys
  },

  {
    path:"add-delivery-boy",
    name:"Add delivery boy",
    component:AddDeliveryBoy
  },
  {
    path:"delivery-boy/:id",
    name:"Delivery Boy",
    component:DeliveryBoy
  },
  {
    path:"feedbacks",
    name:"Feedbacks",
    component:FeedBacks
  },
  {
    path:"accounts",
    name:"Accounts",
    component: AdminAccounts
  },
  {
    path:"order-detail/:id",
    name:"Order Detail",
    component: OrderDetail
  },
  { redirect: true, path: "/", pathTo: "dashboard", name: "Dashboard" }
];

export default dashRoutes;

