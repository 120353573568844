import * as Constant from '../constants/AppConstants'

export function parseTime(time) {
    if(time !== null && typeof time !== 'undefined'){
        var Ntime = time;
        var splittedString=Ntime.split(":");
        Ntime = splittedString.slice(0,-1).join(':');
        return Ntime;
    }
    else {
        return null;
    }
}

export function getDeliveryName(mode) {
    switch(mode){
        case Constant.SLOT_DELIVERY :
            return 'Slot Delivery';
        case Constant.FAST_DELIVERY:
            return 'Fast Delivery' ;
        case Constant.TAKE_AWAY :
            return 'Take Away' ;
        default :
            return null;          
    }
}

export function getPayMethod(mode) {
    switch(mode){
        case Constant.PAYMENT_ONLINE :
            return 'Online';
        case Constant.PAYMENT_COD:
            return 'COD' ;
        case Constant.PAYMENT_CARD_ON_DELIVERY:
        return 'Card on Delivery' ;
        default :
            return null;          
    }
}

export function getOrderStatus(mode) {
    switch(mode){
      case Constant.ORDER_CREATED :
          return 'New';
      case Constant.ORDER_ACCEPTED:
          return 'Processing' ;
      case Constant.ORDER_PACKING :
          return 'Processing' ;
      case Constant.ORDER_PACKED :
          return 'Processing';
      case Constant.ORDER_SHIPPING:  
          return 'Shipping';
      case Constant.ORDER_COMPLETED:  
          return 'Delivered';
        case Constant.ORDER_CANCELLED:  
          return 'Cancelled';
          case Constant.ORDER_FAILED:  
          return 'Failed';
      default :
          return null;          
    }
}

export function getPayStatus(mode) {
    switch(mode){
        case Constant.PAYMENT_SUCCESS :
            return 'Success';
        case Constant.PAYMENT_FAILED:
            return 'Failed' ;
            case Constant.PAYMENT_PENDING :
            return 'Pending' ;
        default :
            return null;          
    }
}
  
export function  getLoder(loading) {
    let classes = "bt-loader ";
    classes += loading ? 'bt-show' : 'bt-hide';
    return classes;
}

export function  getPayStatusClass(mode) {
    let classes = "st-block ";
    switch(mode) {
        case Constant.PAYMENT_SUCCESS :
            classes += 's-green';
            return classes;
        case Constant.PAYMENT_FAILED:
            classes += 's-red';
            return classes;
        case Constant.PAYMENT_PENDING :
            classes += 's-orange';
            return classes;

        default :
            return classes;  
    }
}

export function getLoaderClass(loading){
    let classes = "bt-loader ";
    classes += loading ? 'bt-show' : 'bt-hide';
    return classes;
}

export function showError(name, errors){
    if(errors && errors.hasOwnProperty(name)){
        return <div className="errorMsg">{errors[name]}</div>
    }
    return null
}

export const WEEK_OPTIONS = [
  
    {label: "Monday", value:1},
    {label: "Tuesday", value:2},
    {label: "Wednesday", value:3},
    {label: "Thursday", value:4},
    {label: "Friday", value:5},
    {label: "Saturday", value:6},
    {label: "Sunday", value:7},
]