import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function addItem(data) {
    return request({
      url:   URLS.ADD_ITEM,
      method: 'POST',
      data:data
    });
}

function addNewProducts(data){
    return request({
        url:   URLS.ADD_NEW_PRODUCTS,
        method: 'POST',
        data:data
      });
    
}

function getItems(skip,take, query){
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
    return request({
        url:   URLS.GET_ITEMS + sub_url,
        method: 'GET'
    });
}

function getStoreUnimported(store_id,skip,take){
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
        url:   URLS.GET_STORE_UNIMPORTED + sub_url,
        method: 'GET'
    });
}

function getStoreUnimportedItem(store_id,item_id){
    var sub_url = '/'+store_id+'/'+item_id;
    return request({
        url:   URLS.GET_STORE_UNIMPORTED_ITEM + sub_url,
        method: 'GET'
    });
}

function getItem(item_id){
    var sub_url = '/'+item_id;
    return request({
        url:   URLS.GET_ITEM + sub_url,
        method: 'GET'
    });
 
 }

function getItemProducts(skip,take){
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
        url:   URLS.GET_ITEM_PRODUCTS + sub_url,
        method: 'GET'
    }); 
}

function checkUniqueBarcode(barcode){
    var sub_url = '/'+barcode;
    return request({
        url:   URLS.CHECK_UNIQUE_BARCODE + sub_url,
        method: 'GET'
    });
 
 }

 function checkUniqueSKU(sku){
    var sub_url = '/'+sku;
    return request({
        url:   URLS.CHECK_UNIQUE_SKU + sub_url,
        method: 'GET'
    });
 
 }
 
 
 function editItem(data){
 
    return request({
        url:   URLS.EDIT_ITEM ,
        method: 'POST',
        data
    });
 
 }


 function getStoreItem(item_id, store_id){
    var sub_url = '/'+item_id + '/' + store_id;
    return request({
        url:   URLS.GET_STORE_ITEM + sub_url,
        method: 'GET'
    });
}

function editStoreItem(data) {
    return request({
      url:   URLS.EDIT_STORE_ITEM,
      method: 'POST',
      data:data
    });
}


const ItemService = {
    addItem,
    addNewProducts,
    getItems,
    getStoreUnimported,
    getStoreUnimportedItem,
    getItem,
    editItem,
    editStoreItem,
    getStoreItem,
    getItemProducts,
    checkUniqueBarcode,
    checkUniqueSKU
}

export default ItemService;