import React, { Component } from 'react';
import { Row,Col,Input,FormGroup } from "reactstrap";
import { Button } from "../../components";
import {getStorePermissions,changeStorePass,onDismiss} from '../../actions/userActions';
import { connect } from 'react-redux';
import userAvatar from "../../assets/img/avatar.png";
import SimpleReactValidator from "simple-react-validator";
import SweetAlert from 'sweetalert2-react';
class MyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false,
            current_password:'',
            password:'',
            c_password:'',
            passv: 'password',
            passc: 'password',
            passn: 'password'
        }
        this.validator = new SimpleReactValidator();
        this.onDismissPopup = this.onDismissPopup.bind(this);
    }
    componentDidMount = () => {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
        {this.props.onGetPermissions();}
      }
      });
      }

      onDismissPopup(){
        this.props.onDismiss();
      }

      handleSubmit=(e) => {
        e.preventDefault();
        var data = {
            current_password:this.state.current_password,
            new_password:this.state.password,
            c_password:this.state.c_password,
            user_id:this.props.user.id
        }

        if( this.validator.allValid() && this.state.c_password === this.state.password ){
            this.props.onChangePass(data);
          }
          else {
            this.validator.showMessages();
            this.forceUpdate();
          }  

      } 
      componentWillReceiveProps(nextProps) {
        if (nextProps.status === true) {
            this.setState({
                current_password:'',
                password:'',
                c_password:'',
               });
        }
    }

    getLoder=()=> {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    

    render() { 
        const {store_permissions} = this.props;
        return ( 
            
            <div>
            <div className="content">
            <div className={this.getLoder()}></div>
              <div className="form-section">  
                  <div className="form-filds-wrapper myaccount">
                    <Row>
                      <Col md="8">
                        <div className="form-group">
                         <div className="profile">
                         <img src={this.props.user.profile_pic !== null ? this.props.user.profile_pic :userAvatar} alt="wild"/>
                         </div>   
                        </div>
                      </Col>
                    </Row>
                    <Row>
                     <Col md="4">
                     <Row>
                     <Col md="12">
                        <div className="form-group">
                          <h5>{this.props.user.name !== null ? this.props.user.name : 'Store Admin'}</h5>
                        </div>
                      </Col>
                      <Col md="12">
                          <div className="form-group">
                          <label className="control-label">{this.props.user.mobile !== null ? this.props.user.mobile : ''}</label>
                          <label className="control-label">{this.props.user.email !== null ? this.props.user.email : ''}</label>
                          <label className="control-label">{this.props.user.unique_number !== null ? this.props.user.unique_number : ''}</label>
                          </div>
                          <div className="form-group">
                          <Button className="btn-plus" data-toggle="collapse" data-target="#reset" leftLabel="fa c1 icon-edit-1">Change Password</Button>
                          <div className="collapse" id="reset">
                    
                            <form onSubmit = {this.handleSubmit}>
                            <div className="form-group">
                            <label className="control-label"> Current Password</label>
                            <Input type={this.state.passn} onChange ={(e)=>this.setState({current_password:e.target.value})} value={this.state.current_password} className="form-control" />
                            <i className={this.state.passn != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passn}) => ({
                                    passn: passn === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                            <div className="errorMsg">{this.validator.message('Current password', this.state.current_password, 'required|min:6')}</div>
                            </div>
                            <div className="form-group">
                            <label className="control-label"> New Password</label>
                            <Input type={this.state.passv} onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                            <i className={this.state.passv != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passv}) => ({
                                    passv: passv === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                            <div className="errorMsg">{this.validator.message('New password', this.state.password, 'required|min:6')}</div>
                            </div>
                            <div className="form-group">
                            <label className="control-label">Confirm Password</label>
                            <Input type={this.state.passc} onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                            <i className={this.state.passc != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                              onClick={()=>{
                                this.setState(({passc}) => ({
                                  passc: passc === 'text' ? 'password' : 'text'
                                }))
                              }}></i>
                            <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required|min:6')}</div>
                            <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                            </div>
                            <div className="submit-wrapper">
                            <Button className="btn-submit" >Save</Button>
                            </div>
                            </form>
                          </div>
                          </div>
                        <SweetAlert
                        show={this.props.status && this.props.popup}
                        title="Success"
                        text={this.props.message}
                        onConfirm={this.onDismissPopup}
                        />
                        <SweetAlert
                        show={ !this.props.status && this.props.popup}
                        title="Error"
                        type="error"
                        text={this.props.message}
                        onConfirm={this.onDismissPopup}
                        />
                      </Col>
                     </Row>
                     </Col>
                    </Row>
                    <Row>
                        <Col md="8">
                     <div className="prvilages mt-3">
                     <FormGroup>
                     <label className="control-label"><h6 className="small-heading">My Privilages</h6></label>
                     <div className="privlages">
                        <ul>
                       {
                            store_permissions.map((store,i) => (
                                <li key={i}>{store.type}</li>
                            ))
                       }
                        </ul>
                     </div>
                        </FormGroup>
                     </div>
                        </Col>
                    </Row>
                  </div>
              </div>
            </div>
          </div>
         );
    }
}
MyAccount.defaultProps ={
    store_permissions:[],
    user:{}
}
const mapStateToProps = (state) => ({
    storeloggedIn: state.storeUserReducer.storeloggedIn,
    loading: state.storeUserReducer.loading,
    status:state.storeUserReducer.status,
    message:state.storeUserReducer.message,
    popup:state.storeUserReducer.popup,
    user:state.storeUserReducer.user,
    store_permissions:state.permissionReducer.store_permissions,
  })


const mapDispatchToProps = (dispatch)=> ({
  onGetPermissions:()=>{
      dispatch(getStorePermissions())
    },
    onChangePass:(data)=> {
      dispatch(changeStorePass(data))  
    },
    onDismiss:()=>{
        dispatch(onDismiss())
      }
})
  
export default connect(mapStateToProps,mapDispatchToProps)(MyAccount)
