import { createSlice } from '@reduxjs/toolkit'

export const customerSlice = createSlice({
  name: 'resources',
  initialState: {
    customer: null,
    reflector: true,
    loading: false,
    popup: false,
    status: false,
    message: ''
  },
  reducers: {
    setCustomer: (state, {payload}) => {
      state.customer = payload.customer
      state.reflector = !state.reflector
      state.loading = false
    },
    setLoading:(state, {payload}) => {
        state.loading = payload.loading
    },
    setStatus:(state, {payload})=>{
        state.popup = payload.popup
        state.status = payload.status
        state.message = payload.message
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCustomer, setLoading, setStatus } = customerSlice.actions

export default customerSlice.reducer