import React, { Component } from 'react';
import { UncontrolledCollapse, Row, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Button} from '../../../components';
import SimpleReactValidator from 'simple-react-validator';
import {connect} from "react-redux";
import {addFaq,getFaqs,onDismiss,delFaqs,delFaqDis} from "../../../actions/FaqActions";
import SweetAlert from 'sweetalert2-react';
class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            activeItem: 0,
            title:'',
            isMounted: false,
            selectedItems:[],
            selected:false,
            selectedIndex:0
        };
        this.toggle = this.toggle.bind(this);
        this.validator = new SimpleReactValidator();
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      toggle() {
        this.setState({
          modal: !this.state.modal
        });
      }
      componentDidMount() {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
        {this.props.onGetFaqs();}
      }
      });
      }
      onDismissPopup(){
       
        this.props.onDismiss();
        this.props.onGetFaqs();
        this.setState({
          selectedItems:[],
          selected:false
        })
      }
      handleDelete = (e,id) => {
        e.preventDefault();
        this.props.onDeleteFaq(id);
      }
      handleDeleteDis = (e,id) => {
        e.preventDefault();
        this.props.onDeleFaqDis(id);
      }
      handleSubmit = (e) => {
            e.preventDefault();
          var data = {
            title:this.state.title
          }
          if( this.validator.allValid() ){
          this.props.onAddFaqs(data);
          }
          else {
            this.validator.showMessages();
            this.forceUpdate();   
          }
      }
      componentWillReceiveProps(nextProps) {
        if (nextProps.status === true) {
          this.setState({ title: ''});
        }

        if(nextProps.faqs.length > 0 && this.state.selectedItems.length < 1 && !this.state.selected){
          if(nextProps.faqs[this.state.selectedIndex]){
           this.setState({
             selectedItems: nextProps.faqs[this.state.selectedIndex].items,
             selected:true
           })
          }
           
         }
      }



      componentDidUpdate(prevProps, prevState) {
        
      }

      getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading || this.props.faqLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }

    _handleClick= (items,index)=>{
      this.setState({
        activeItem: index,
        selected:true,
        selectedItems:items,
        selectedIndex:index
      })
     
    }


    render() { 
        const {faqs} = this.props;
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                    <div className="faq-listing-wrapper">
                      <Row>
                          <Col md="3">
                            <div className="left-faq-menu">
                                <h6>Categories</h6>
                                <div className="lists">
                                    <ul>
                                         {
                                             faqs.length >0 ? faqs.map((faq,index)=> (
                                                <li key={index}  className={this.state.activeItem === index ? 'group-action active' : 'group-action'} onClick={()=>this._handleClick(faq.items,index)}>{faq.title} <button className="btn" onClick={((e)=>this.handleDelete(e,faq.id))} >
                                                <i className="ico c2 icon-trash-b"/>
                                                </button></li>  
                                              )):'Not data found'
                                         }   
                                            
                                    </ul>
                                </div>
                                <div className="add-btn-wraper">
                                <Button onClick={this.toggle} className="btn-simple" leftLabel="fa fa-plus">Add New</Button>
                                </div>  
                            </div>
                          </Col>
                          <Col md="9">
                            {this.state.selectedItems.length>0 ? this.state.selectedItems.map((item,index)=>(
                              
                              <div className="faq-section" key={index}>
                                <div className="faq-head" id={"toggler"+index}>
                                <div className="delFaq group-action">
                                <button className="btn" onClick={((e)=>this.handleDeleteDis(e,item.id))} >  <i className="ico c2 icon-trash-b"/></button>
                                </div>
                                <h4>{item.title}</h4>
                                </div>  
                                <UncontrolledCollapse toggler={"#toggler"+index}>
                                <div className="faq-body">
                                <p>
                                {item.content}
                                </p>
                                </div>  
                                </UncontrolledCollapse>
                              </div>
                            )):'No data found'}
                            
                          </Col>
                      </Row>  
                    </div>
                    <Button className="fixed-yellow"  onClick={() => this.props.history.push("/admin/add-faq")}>Add</Button>
                </div>
{/* addfaq */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} className='common-modal' >
                <ModalHeader>Add Category</ModalHeader>
                <form onSubmit={this.handleSubmit}>
                <ModalBody>
                   <div className="form-model">
                   <div className="form-group">
                    <label className="control-label">Category Name</label>
                    <input type="text" value={this.state.title} onChange = {(e)=>this.setState({title:e.target.value})}  className="form-control" placeholder="Type here"/>
                    <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                  </div>
               
                   </div>     
                </ModalBody>
               
                <ModalFooter>
                <Button className="btn-cancel" onClick={this.toggle}>Cancel</Button>
                <Button type="submit" className="btn-submit">Save</Button>{' '}
                </ModalFooter>
                </form>
                </Modal>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
         );
    }
}
 
Faq.defaultProps = {
    faqs:[]  
}
const mapStateToProps = (state) => ({
    loading: state.FaqReducer.loading,
    status:state.FaqReducer.status,
    faqLoading:state.FaqReducer.faqLoading,
    faqs: state.FaqReducer.faqs,
    message:state.FaqReducer.message,
    popup:state.FaqReducer.popup
  });
  
  const mapDispatchToProps = (dispatch)=> ({
    onAddFaqs:(data)=>{
      dispatch(addFaq(data))
    },
    onGetFaqs:()=>{
      dispatch(getFaqs())
    },
    onDeleteFaq:(id) => {
      dispatch(delFaqs(id));
    },
    onDeleFaqDis:(id)=> {
      dispatch(delFaqDis(id))
    },
    onDismiss:()=>{
      dispatch(onDismiss())
    }
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Faq);
