import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addTags(tag) {
    return request({
      url:   URLS.ADD_TAG,
      method: 'POST',
      data:{
           tag
      }
    });
}

function getTags(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_TAGS + sub_url,
      method: 'GET'
    });
}
function delTag(tag_id) {
    var sub_url = '/'+tag_id;
    return request({
      url:   URLS.DELETE_TAG + sub_url,
      method: 'GET',
    });
}

function editTag(tag,tag_id) {
    return request({
      url:   URLS.EDIT_TAG,
      method: 'POST',
      data:{
          tag_id:tag_id,
          tag:tag
        }
    });
}

const TagService = {
    addTags,
    getTags,
    delTag,
    editTag
}
export default TagService;