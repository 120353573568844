import React, { Component } from 'react';
import { Row,Col,Input,FormGroup } from "reactstrap";
import PhoneInput from 'react-phone-number-input'
import { Button } from "../../../components";
import SweetAlert from "sweetalert2-react";
import Privilages from '../../../components/Common/Privilages';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import {getStoreStaff,onDismiss, editStoreStaff} from '../../../actions/storeStaffActions';
class EditUser extends Component {

    _id = 0;

    constructor(props) {
      super(props);
      this.state= {
        image:'',
        permissions:[],
        email:'',
        mobile:'',
        name:'',
        password:'',
        c_password:'',
        unique_number:'',
        passv: 'password',
        passc: 'password',
      }
      this.validator = new SimpleReactValidator();
      this.onDismissPopup = this.onDismissPopup.bind(this);
    }
    _onImageChange=(image)=> {
      this.setState({image});
    }

    hadlePrev = (permissions)=> {
      this.setState({permissions});
    }

    componentDidMount() {
        var urls = this.props.match.url.split('/');
        this._id = urls[urls.length - 1];
        this.props.onGetStaff(this._id);
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        user_id:this._id,
        store_id: this.props.store? this.props.store.id: 
          this.props.staff && this.props.staff.assignment && this.props.staff.assignment.length > 0?this.props.staff.assignment[0].store_id:null,
        profile_pic:this.state.image,
        name:this.state.name,
        email:this.state.email,
        mobile:this.state.mobile,
        unique_number:this.state.unique_number,
        password: this.state.password,
        c_password: this.state.c_password
      }
      var level  = this.state.permissions.filter(function (el) {
      return el != null;
      });
      var permissions = level.map((item)=>{
        return {'level_id':item.level_id}
      })
      data['levels'] =permissions;

      if( this.validator.allValid() && this.state.permissions.length > 0 && this.state.c_password === this.state.password){
          this.props.onEditStaff(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }
    }
    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
      if(nextProps.status === true) {
        this.setState({
          image:'', level:[], email:'', mobile:'', name:'',  password:'',  c_password:'', unique_number:''
        })
      }

      if( !nextProps.singleStaffLoading && nextProps.staff && typeof nextProps.staff.assignment !== 'undefined'){
        var array =[];    
        if(nextProps.staff.assignment[0]){
          var permissions = nextProps.staff.assignment[0].permissions;
            
          for(var i=0; i< permissions.length ; i++){
              array[permissions[i].access_level-1] = {level_id:permissions[i].access_level}
          }
        }

          this.setState({
              staff_image : nextProps.staff.profile_pic,
              name: nextProps.staff.name,
              email: nextProps.staff.email,
              mobile: nextProps.staff.mobile,
              image: nextProps.staff.profile_pic,
              unique_number: nextProps.staff.unique_number,
              permissions: array
          })
      }

    }

    onDismissPopup() {
      this.props.onDismiss();
      this.props.onGetStaff(this._id);
    }
       //loader
       getLoder() {
        let classes = "bt-loader ";
        classes += this.props.editLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
        return ( 
            <div>
            <div className="content">
            <div className={this.getLoder()}></div>
              <div className="form-section">  
              <form  onSubmit={this.handleSubmit}>
                  <div className="form-filds-wrapper">
                    <Row>
                      <Col md="8">
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Staff Image</FeaturedImage>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                     <Col md="8">
                     <Row>
                     <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Name</label>
                          <Input type="text" className="form-control" onChange ={(e)=>this.setState({name:e.target.value})} value={this.state.name}  placeholder="User name" />
                          <div className="errorMsg">{this.validator.message('Name', this.state.name, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                      <label className="control-label">Mobile Number</label>
                          <PhoneInput defaultCountry="IN" onChange ={(e)=>this.setState({mobile:e})} value={this.state.mobile}  className="form-control" placeholder="Mobile Number" />
                          <div className="errorMsg">{this.validator.message('mobile', this.state.mobile, 'required|phone')}</div>
                        </div>
                        </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Email</label>
                          <Input type="email" onChange ={(e)=>this.setState({email:e.target.value})} value={this.state.email}   className="form-control" placeholder="Email"/>
                          <div className="errorMsg">{this.validator.message('email', this.state.email, 'required|email')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Unique Number
                          </label>
                          <Input type="text" onChange ={(e)=>this.setState({unique_number:e.target.value})} value={this.state.unique_number} className="form-control" />
                          <div className="errorMsg">{this.validator.message('Unique Number', this.state.unique_number, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                         Password
                          </label>
                          <Input type={this.state.passv} onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                          <i className={this.state.passv != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passv}) => ({
                                    passv: passv === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                          <div className="errorMsg">{this.validator.message('password', this.state.password, 'required|min:6')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                           Confirm Password
                          </label>
                          <Input type={this.state.passc} onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                          <i className={this.state.passc != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                              onClick={()=>{
                                this.setState(({passc}) => ({
                                  passc: passc === 'text' ? 'password' : 'text'
                                }))
                              }}></i>
                          <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required|min:6')}</div>
                          <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                        </div>
                      </Col>
                     </Row>
                     </Col>
                    </Row>
                    <Row>
                        <Col md="8">
                     <div className="prvilages">
                     <FormGroup>
                     <label className="control-label">Acces Privilages</label>
                       <Privilages handlePrivilage={(permissions)=>this.hadlePrev(permissions)} isEditable={true} levels={this.state.permissions}/>
                        </FormGroup>
                        {this.state.permissions.length < 1?
                        <div className="errorMsg">acces level required</div>:null}
                     </div>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="12">
                        <div className="submit-wrapper">
                        <Button className="btn-submit" >Save</Button>
                        </div>
                        </Col>
                    </Row>
                  </div>
                  </form>
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
          </div>
         );
    }
}
 
EditUser.defultProps = {
  staff:{}
}
const mapStateToProps = (state) => ({
  singleStaffLoading: state.StaffReducer.loading,
  staff: state.StaffReducer.staff,
  editLoading:state.StaffReducer.editLoading,
  status: state.StaffReducer.status,
  message: state.StaffReducer.message,
  popup: state.StaffReducer.popup,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch) => ({
    onGetStaff:(user_id)=>{
        dispatch(getStoreStaff(user_id))
    },
    onDismiss:() => {
        dispatch(onDismiss());
    },
    onEditStaff:(data)=>{
      dispatch(editStoreStaff(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(EditUser)
