import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  customers: [],
  store_customers:[],
  dashboard:{},
  store_dashboard:{},
  user:null,
  exist:false,
  regLoading:false,
  seller:{},
  chart:[]
};

export default function commonReducer(state = initialState, action) {
  switch (action.type) {

    case Actions.ALL_CUSTOMERS_LOADING:
      return {
        loading: true,
        customers: []
      };
    case Actions.ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.customers,
        count: action.count
      };
    case Actions.ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        customers: [],
        error: action.error
      };

      case Actions.ALL_STORE_CUSTOMERS_LOADING:
      return {
        loading: true,
        store_customers: []
      };
    case Actions.ALL_STORE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        store_customers: action.customers,
        count: action.count
      };
    case Actions.ALL_STORE_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        store_customers: [],
        error: action.error
      };

      case Actions.ADMIN_DASHBORD_LOADING:
      return {
        loading: true,
        dashboard:{}
      };
    case Actions.ADMIN_DASHBORD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.dashboard
      };
    case Actions.ADMIN_DASHBORD_FAILED:
      return {
        ...state,
        loading: false,
        dashboard:{},
        error: action.error
      };

     case Actions.BEST_SELLER_LOADING:
      return {
        ...state,
        loading: true,
        seller:{}
      };
    case Actions.BEST_SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        seller: action.seller
      };
    case Actions.BEST_SELLER_FAILED:
      return {
        ...state,
        loading: false,
        seller:{},
        error: action.error
      };

      case Actions.SALES_CHART_LOADING:
      return {
        ...state,
        loading: true,
        chart:[]
      };
    case Actions.SALES_CHART_SUCCESS:
      return {
        ...state,
        loading: false,
        chart: action.chart
      };
    case Actions.SALES_CHART_FAILED:
      return {
        ...state,
        loading: false,
        chart:[],
        error: action.error
      };

    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

      case Actions.STORE_DASHBOARD_LOADING:
      return {
        loading: true,
        store_dashboard:{}
      };
    case Actions.STORE_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        store_dashboard: action.dashboard
      };
    case Actions.STORE_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
        store_dashboard:{},
        error: action.error
      };

      case Actions.CHECK_USER_EXIST_LOADING:
        return {
          ...state,
          exist:false,
          loading: true,
          user: null
        };
      case Actions.CHECK_USER_EXIST_SUCCESS:
        return {
          ...state,
          loading: false,
          exist:true,
          user: action.user
        };
      case Actions.CHECK_USER_EXIST_FAILED:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        case Actions.REGISTER_CUSTOMER_LOADING:
          return {
            ...state,
            exist:false,
            regLoading: true,
            user: null
          };
        case Actions.REGISTER_CUSTOMER_SUCCESS:
          return {
            ...state,
            regLoading: false,
            exist:true,
            user: action.user
          };
        case Actions.REGISTER_CUSTOMER_FAILED:
          return {
            ...state,
            regLoading: false,
            error: action.error
          };

      
    default:
      return state;
  }
}
