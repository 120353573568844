import * as Actions from '../constants/Actions';
import DeliveryBoyService from '../services/DeliveryBoyService';

function addDeliveryBoy(data){
  return dispatch =>{
      dispatch(request());
      DeliveryBoyService.addDeliveryBoy(data)
      .then(res =>{
          dispatch(success())
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
  function request() { return { type: Actions.ADD_DELIVERY_BOY_LOADING} }
  function success() { return { type: Actions.ADD_DELIVERY_BOY_SUCCESS} }
  function failed(error) { return { type: Actions.ADD_DELIVERY_BOY_FAILED, error:error} }
}

function updateDeliveryBoy(data){
  return dispatch =>{
      dispatch(request());
      DeliveryBoyService.updateDeliveryBoy(data)
      .then(res =>{
          dispatch(success())
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
  function request() { return { type: Actions.UPDATE_DELIVERY_BOY_LOADING} }
  function success() { return { type: Actions.UPDATE_DELIVERY_BOY_SUCCESS} }
  function failed(error) { return { type: Actions.UPDATE_DELIVERY_BOY_FAILED, error:error} }
}

function getAllDeliveryBoys(offset,limit, store=null){

  return dispatch =>{
      dispatch(request());
      DeliveryBoyService.getAllDeliveryBoys(offset,limit, store)
      .then(res =>{
         var delivery_boys = res.data;
         var count = res.count;
          dispatch(success(delivery_boys,count))
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
function request() { return { type: Actions.GET_ALL_DELIVERY_BOYS_LOADING} }
function success(delivery_boys,count) { return { type: Actions.GET_ALL_DELIVERY_BOYS_SUCCESS, delivery_boys,count} }
function failed(error) { return { type: Actions.GET_ALL_DELIVERY_BOYS_FAILED, error:error} }
}


function getDeliveryBoy(id) {
    return dispatch =>{
        dispatch(request());
        DeliveryBoyService.getDeliveryBoy(id)
        .then(res =>{
            var delivery_boy = res.data;
            dispatch(success(delivery_boy))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_DELVERY_BOY_LOADING} }
    function success(delivery_boy) { return { type: Actions.GET_DELVERY_BOY_SUCCESS, delivery_boy} }
    function failed(error) { return { type: Actions.GET_DELVERY_BOY_FAILED, error:error} }
}

function deliveryBoyStatus(id,status) {
  return dispatch => {
    dispatch(request());
    DeliveryBoyService.deliveryBoyStatus(id,status)
    .then(res=>{
      dispatch(success())
    }).catch(error => {
      dispatch(failed(error))
    })
  }

  function request(){return {type:Actions.CHANGE_DELIVERY_BOY_LOADING}}
  function success(){return {type:Actions.CHANGE_DELIVERY_BOY_SUCCESS}}
  function failed(error){return {type:Actions.CHANGE_DELIVERY_BOY_FAILED,error}}

}

function onDismiss(){
  return dispatch =>{
      dispatch(success())
  }
  function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
  addDeliveryBoy,
  getAllDeliveryBoys,
  getDeliveryBoy,
  deliveryBoyStatus,
  onDismiss,
  updateDeliveryBoy
}