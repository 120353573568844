import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { editProductOffer,getProductOffer, onDismiss } from "../../../actions/OfferActions";
import SweetAlert from "sweetalert2-react";
import SimpleReactValidator from 'simple-react-validator';
class EditProductOffer extends Component {
  _id = 0;
  constructor(props) {
    super(props);
    this.state = {
      product_name: ' ',
      isActive: false,
      isMounted: false,
      valid_from: moment(),
      valid_upto: moment(),
      disc_perc: "",
      disc_amount:'',
      title: "",
      week: [],
      type:1,
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }
  onDismissPopup() {
    this.props.onDismiss();
    this.props.onGetOffer(this._id);
  }
  handleChangeFrom(date) {
    this.setState({
      valid_from: date
    });
  }
  handleChangeTo(date) {
    this.setState({
      valid_upto: date
    });
  }
  handleCheck = week => {
    this.setState({ week });
  };
  componentDidMount() {
    var urls = this.props.match.url.split('/');
    this._id = urls[urls.length - 1];
    this.props.onGetOffer(this._id);
  }


  handleLimit = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  static getDerivedStateFromProps(nextProps, prevState){
    if(
      nextProps.ProductOffer 
      && typeof nextProps.ProductOffer.product !== 'undefined' 
      && !prevState.title){

        var days = nextProps.ProductOffer.week_days.map((item)=>{
          return item.day_id;
        })

        if(nextProps.ProductOffer.disc_perc !== "0") {
          var type = 1;
          var offer = nextProps.ProductOffer.disc_perc;
        }
        if(nextProps.ProductOffer.disc_amount !== "0") {
          var type = 2;
          var offer = nextProps.ProductOffer.disc_amount;
        }

        return {
          ...prevState,
          disc_perc:offer,
          type : type,
          title: nextProps.ProductOffer.title,
          product_name:nextProps.ProductOffer.product.title,
          isActive:true,
          valid_from: moment(nextProps.ProductOffer.valid_from),
          valid_upto: moment(nextProps.ProductOffer.valid_upto),
          week:days
        }
    }
    return {...prevState}
  }

  handleSubmit = e => {
    e.preventDefault();
    var data = {
      store_id:  this.props.store.id,
      title: this.state.title
    };
    if(parseFloat(this.state.type) === 1) {
      data['disc_perc']=parseFloat(this.state.disc_perc);
    }
    if(parseFloat(this.state.type) === 2){
      data['disc_amount']=parseFloat(this.state.disc_perc);
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data["valid_from"] = from.format("YYYY-MM-DD");
    data["valid_upto"] = upto.format("YYYY-MM-DD");
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
   data['week_days']= days_array;
    if( this.validator.allValid() ){
      data['offer_id'] = this._id;
     this.props.onEditOffer(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

     //loader
     getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }
  render() {
    return (
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
          <div className="form-section">
          <form onSubmit={this.handleSubmit}>
            <div className="form-filds-wrapper">
              <Row>
              <Col md="8">
                  <div className="form-group">
                    <label className="control-label">Product Name</label>
                    <h6 className="p-bname">{this.state.product_name}</h6>
                  </div>
                </Col>
                </Row>
                <Row>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Offer Name</label>
                    <input
                      type="text"
                      value={this.state.title}
                      onChange={e => this.setState({ title: e.target.value })}
                      className="form-control"
                      placeholder="Name"
                    />
                    <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                  </div>
                </Col>
                <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Value</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="type" value={this.state.type}  onChange={(e)=>{this.setState({type: e.target.value })}}>
                        <option value={1}>%</option>
                        <option value={2}>INR</option>
                        </Input>
                        </InputGroupAddon>
                        <Input value={this.state.disc_perc} type={'number'} onChange={(e)=>this.setState({disc_perc:e.target.value})} />
                       
                        <div className="errorMsg">{this.validator.message('discount', this.state.disc_perc, 'required')}</div>
                         </InputGroup>
                      </div>
                    </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Valid From</label>
                    <DatePicker
                      className="form-control"
                      selected={this.state.valid_from}
                      onChange={this.handleChangeFrom}
                      dateFormat="YYYY-MM-DD"
                      name="valid_from"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <label className="control-label">Valid To</label>
                    <DatePicker
                      className="form-control"
                      selected={this.state.valid_upto}
                      onChange={this.handleChangeTo}
                      dateFormat="YYYY-MM-DD"
                      name="valid_upto"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormGroup>
                    <div className="check-wrapper">
                      <Checkbox
                        label="Limit Days"
                        checked={this.state.isActive}
                        onChange={e => {
                          this.handleLimit(e);
                        }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <div className="check-box-inline prvilages">
                    {this.state.isActive ? (
                      <CheckFrom isEditable={true} week={this.state.week}
                        onCheckChange={week => this.handleCheck(week)}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="submit-wrapper">
                    <Button className="btn-submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </div>
            <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={()=>this.onDismissPopup()}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={()=>this.onDismissPopup()}
                  />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
EditProductOffer.defaultProps ={
  ProductOffer:{}
}


const mapStateToProps = state => ({
  loading: state.productOfferReducer.loading,
  status: state.productOfferReducer.status,
  message: state.productOfferReducer.message,
  popup: state.productOfferReducer.popup,
  store:state.storeUserReducer.store,
  ProductOffer:state.productOfferReducer.ProductOffer
});

const mapDispatchToProps = dispatch => ({
  onEditOffer: (data) => {
    dispatch(editProductOffer(data));
  },
  onGetOffer:(offer_id)=>{
    dispatch(getProductOffer(offer_id))
  },
  onDismiss: () => {
    dispatch(onDismiss());
  }
});
export default connect(mapStateToProps,mapDispatchToProps)(EditProductOffer);
