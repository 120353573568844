import React, { Component } from 'react';

import AllOrders from '../../../common/order/AllOrders';

export default class StoreOrders extends Component{


  render(){
    return(
      <AllOrders isStore={true} history={this.props.history}/>
    )
  }
}