import React,{Component} from 'react';
import { Table} from "reactstrap";
import {Link} from  'react-router-dom';
import {getStoreUnimported} from '../../../actions/itemActions';
import ReactPaginate from 'react-paginate';
import {connect} from 'react-redux';
import * as Constant from '../../../constants/AppConstants';
import SweetAlert from 'sweetalert2-react';
import {groupStatus,onDismiss} from "../../../actions/productActions";
class AdminProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      skip: 0,
      alert:false,
      dialogue:false,
      limit:12,
      selectedId:0,
      selected:0
    }
    this.onDismissPopup = this.onDismissPopup.bind(this);
  }
  componentDidMount () {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
      }
    });
    setTimeout(()=>{
      this.props.onGetStoreUnimported(this.props.store.id,this.state.skip,this.state.limit);
     },100);
  }
  onDismissPopup (){
    this.props.onDismiss();
    this.props.onGetStoreUnimported(this.props.store.id,this.state.skip,this.state.limit);
  }
  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetStoreUnimported(this.props.store.id,skip,this.state.limit);
  }
  handleStatus = (stat,id) => {
    this.setState({
      stat:stat,
      selected:id,
      dialogue:true
    });
  }
  handleDelete = (e,id) => {
     e.preventDefault();
     this.setState({
      selectedId:id,
      alert:true
    }); 
  }
  onStatus = () =>{
    var status ;
    if(typeof this.state.stat !== undefined && this.state.stat == true) {
      status = Constant.ON_SALE_AVAILABLE;
    }
    else {
      status = Constant.ON_SALE_DISABLED;
    }
  this.props.onDeleteProduct(this.props.store.id, this.state.selected,status);
  this.setState({dialogue:false})
  }
  onConfirm = ()=>{
    this.props.onDeleteProduct(this.props.store.id, this.state.selectedId,Constant.ON_SALE_BLOCKED);
    this.setState({alert:false})
  }
  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() { 
    const{products} = this.props;
  return (
    <div>
    <div className="content">
    <div className={this.getLoder()}></div>
  <div className="table-product-wrapper">
    <Table>
      <thead>
        <tr>
          <th>SI.NO</th>
          <th>PRODUCT NAME</th>
          <th>VARIANTS</th>
          <th />
        </tr>
      </thead>
      <tbody>
      {products.length >0 ? products.map((pack,index) => (
          <tr key={index}>
          <td>{index+1 + this.state.skip}</td>
          <td className="nameImg">
          <img src={pack.featured_image} alt="wild" className="img-ico" />
          <span className="p-name">{pack.title}</span>
          </td>
          <td className="nameImg">
              <span className="tag-name">{pack.count}</span>
            </td>
          <td>
            <div className="group-action">
              <Link className="btn" to={`/store/add-products/${pack.id}`}>
                <i className="ico icon-edit" />
              </Link>
              {/* <button className="btn" onClick={((e)=>this.handleDelete(e,pack.id))}>
                <i className="ico icon-delete" />
              </button> */}
            </div>
          </td>
        </tr>    
      )):<tr><td colSpan={'5'}>No data found</td></tr>}
          
       
      </tbody>
    </Table>
  </div>
  <div className="pagination-wrapper">
  <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
  nextLabel={<i className="fas fa-angle-right"></i>}
  pageLinkClassName={"page-link"}
  breakClassName={"break-me"}
  pageCount={Math.ceil(this.props.count/this.state.limit)}
  marginPagesDisplayed={2}
  pageRangeDisplayed={5}
  onPageChange={this.handlePageClick}
  containerClassName={"pagination"}
  pageClassName={"page-item"}
  activeClassName={"active"} />
  </div>
  {/* <Button className="fixed-yellow" onClick={() => this.props.history.push("/admin/add-product")}>Add</Button> */}
  <SweetAlert
  show={this.state.alert }
  onConfirm={this.onConfirm}
  onCancel = {() => this.setState({ alert: false })}
  title= {'Are you sure?'}
  text="Delete Product"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
     <SweetAlert
  show={this.state.dialogue }
  onConfirm={this.onStatus}
  onCancel = {() => this.setState({ dialogue: false })}
  title= {'Are you sure?'}
  text="Change status"
  type= {'warning'}
  showCancelButton= {true}
  confirmButtonText= {'Yes, Confirm it!'}
  cancelButtonText= {'No, keep it'}
  />
  <SweetAlert
  show={this.props.status && this.props.popup}
  title="Success"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
  <SweetAlert
  show={ !this.props.status && this.props.popup}
  title="Error"
  type="error"
  text={this.props.message}
  onConfirm={this.onDismissPopup}
  />
</div>
</div>
  );
}
}
AdminProducts.defaultProps= {
  products:[]
}
const mapStateToProps = (state)=>({
    products : state.importReducer.items,
    loading: state.importReducer.loading,
    count:state.importReducer.count,
    status:state.productReducer.status,
    message:state.productReducer.message,
    popup:state.productReducer.popup,
    store:state.storeUserReducer.store
})
const mapDispatchToProps = (dispatch)=>({
    onGetStoreUnimported : (store_id,skip,take)=>{
      dispatch(getStoreUnimported(store_id,skip,take))
    },
    onDismiss:()=>{
      dispatch(onDismiss());
    },
    onDeleteProduct:(store_id, id,status) => {
      dispatch(groupStatus(store_id, id,status))
    }
})
 
export default connect(mapStateToProps,mapDispatchToProps)(AdminProducts);
