import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  catLoading: false,
  categories: [],
  loadCat:false,
  store_categories: [],
  catstoreLoading:false,
  leafCategories: []
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_CATEGORY:
      return {
        ...state,
        loading: false,
        message: "Category added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Category add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_CATEGORY:
      return {
        ...state,
        loading: false,
        message: "Category edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Category editing failed",
        status: false,
        error: action.error
      };


      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.GET_CATEGORIES_LOADING:
      return {
        catLoading: true,
        categories: []
      };
    case Actions.GET_CATEGORIES:
      return {
        ...state,
        catLoading: false,
        categories: action.categories,
        count: action.count
      };
    case Actions.GET_CATEGORIES_FAILED:
      return {
        ...state,
        catLoading: false,
        categories: [],
        error: action.error
      };

      case Actions.GET_CATEGORY_LOADING:
      return {
        category: {},
      loadCat:true,
      };
    case Actions.GET_CATEGORY:
      return {
        ...state,
        loadCat:false,
        category: action.category,
      };
    case Actions.GET_CATEGORY_FAILED:
      return {
        ...state,
        category: {},
        loadCat:false,
        error: action.error
      };

      case Actions.DELETE_CATEGORY_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_CATEGORY:
      return {
        ...state,
        loading: false,
        message:"Delete category successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        message:'May under this category have active products.',
        popup: true,
        status: false
      };
      case Actions.GET_LEAF_CATEGORIES_LOADING:
     return {
       catLeafLoading: true,
       leafCategories: []
     };
   case Actions.GET_LEAF_CATEGORIES:
     return {
       ...state,
       catLeafLoading: false,
       leafCategories: action.categories,
     };
   case Actions.GET_LEAF_CATEGORIES_FAILED:
     return {
       ...state,
       catLeafLoading: false,
       leafCategories: [],
       error: action.error
     };

     case Actions.GET_ALL_CATEGORIES_LOADING:
     return {
      catstoreLoading: true,
       store_categories: []
     };
   case Actions.GET_ALL_CATEGORIES:
     return {
       ...state,
       catstoreLoading: false,
       store_categories: action.store_categories,
       count1: action.count
     };
   case Actions.GET_ALL_CATEGORIES_FAILED:
     return {
       ...state,
       catstoreLoading: false,
       store_categories: [],
       error: action.error
     };

    default:
      return state;
  }
}
