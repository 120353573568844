import React, { Component } from "react";
import { Row, Col, Input } from "reactstrap";
import { Button } from '../../../components';
import Select from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import { addAdminNotification,onDismiss } from "../../../actions/notificationActions";
import * as Constant from '../../../constants/AppConstants';
import SweetAlert from "sweetalert2-react";
const Options = [
    { value: Constant.SEND_NOTIFICATION_REGISTERED_CUSTOMERS, label: "Registered Customers"},
    { value: Constant.SEND_NOTIFICATION_GUEST_CUSTOMERS, label: "Guest Customers"},
    { value: Constant.SEND_NOTIFICATION_ALL_CUSTOMERS, label: "All Customers"}
]
class sendNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image:null,
      icon:null,
      type_selected: "",
      title: "",
      body: ""
    };
    this.validator = new SimpleReactValidator();
    this.onDismissPopup = this.onDismissPopup.bind(this);
  }
  onDismissPopup() {
    this.props.onDismiss();
  }
  handleType = (type_selected) => {
    this.setState({ type_selected });
  }

  _onImageChange=(image)=> {
    this.setState({image});
  }
  _onIconChange = (icon) => {
    this.setState({icon});
  }
  handleSubmit = e => {
    e.preventDefault();
    var data = {
      admin_id:this.props.user.id,  
      picture:this.state.image,  
      big_icon:this.state.icon,
      type: this.state.type_selected.value,
      title: this.state.title,
      body: this.state.body
    };

    if (this.validator.allValid()) {
      this.props.onAddNtfs(data);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
//WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
componentWillReceiveProps(nextProps) {
  if(nextProps.status===true) {
  this.setState({
    selectedCat: "",
    title: "",
    body: "",
    image:null,
    icon:null,
  });
  }
}

  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading || this.props.faqLoading ? "bt-show" : "bt-hide";
    return classes;
  }

  render() {
    const { type_selected } = this.state;
    return (
      <div>
        <div className="content">
          <div className={this.getLoder()} />
          <div className="form-section">
            <form onSubmit={this.handleSubmit}>
              <div className="form-filds-wrapper">
                <Row>
                  <Col md="7">
                  <div className="form-group">
                          <div className="flex-wrapper">
                          <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Image</FeaturedImage>
                            <FeaturedImage imageReset={this.state.icon} onImageChange={(icon)=>this._onIconChange(icon)}>Icon</FeaturedImage>
                          </div>
                    </div>
                    <div className="form-group">
                      <label className="control-label">User Type</label>
                      <Select
                        onChange={this.handleType}
                        value={type_selected}
                        options={Options}
                        placeholder={"Select user"}
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "User type",
                          type_selected.value,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="form-group">
                      <label className="control-label">Title</label>
                      <input
                        type="text"
                        value={this.state.title}
                        onChange={e => this.setState({ title: e.target.value })}
                        className="form-control"
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "title",
                          this.state.title,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col md="7">
                    <div className="form-group">
                      <label className="control-label">Message</label>
                      <Input
                        type="textarea"
                        value={this.state.body}
                        rows={8}
                        onChange={e =>
                          this.setState({ body: e.target.value })
                        }
                        name="text"
                        placeholder="Type Here ..."
                      />
                      <div className="errorMsg">
                        {this.validator.message(
                          "body",
                          this.state.body,
                          "required"
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <div className="submit-wrapper">
                      <Button type="submit" className="btn-submit">
                        Send 
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
            <SweetAlert
              show={this.props.status && this.props.popup}
              title="Success"
              text={this.props.message}
              onConfirm={this.onDismissPopup}
            />
            <SweetAlert
              show={!this.props.status && this.props.popup}
              title="Error"
              type="error"
              text={this.props.message}
              onConfirm={this.onDismissPopup}
            />
          </div>
        </div>
      </div>
    );
  }
}
sendNotification.defaultProps ={
  user:{}
}

const mapStateToProps = state => ({
  loading: state.adminNotificationReducer.loading,
  status: state.adminNotificationReducer.status,
  message: state.adminNotificationReducer.message,
  popup: state.adminNotificationReducer.popup,
  user:state.userReducer.user
});

const mapDispatchToProps = dispatch => ({
  onAddNtfs: data => {
    dispatch(addAdminNotification(data));
  },
  onDismiss: () => {
    dispatch(onDismiss());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(sendNotification);
