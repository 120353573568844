import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { getCurrencies } from '../../actions/addressActions';

export default function CurrencySelect({
    onChange,
    value
}){

    const [initial, setInitial] = useState(true)
    const resourceReducer = useSelector(state=> state.resourceReducer)
    const { currencies, reflector } = resourceReducer
    const dispatch = useDispatch()

    useEffect(()=>{
        if(initial){
            dispatch(getCurrencies())
            setInitial(false)
        }
    })

    const options = currencies?currencies.map((currency)=>{
        return {value: currency.id, label: currency.currency}
    }):[]

    return(
        <Select options={options} value={value} onChange={onChange}/>
    )
}