import * as Actions from '../constants/Actions';
import ItemService from '../services/ItemService';

function addItem(data){

    return dispatch =>{
        dispatch(request(data));
        ItemService.addItem(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_ITEM_LOADING} }
    function success(data) { return { type: Actions.ADD_ITEM_SUCCESS, data: data} }
    function failed(error) { return { type: Actions.ADD_ITEM_FAILED, error: error} }
}

function addNewProducts(data){

    return dispatch =>{
        dispatch(request(data));
        ItemService.addNewProducts(data)
        .then(res =>{
            var data = res.data;
        
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_NEW_PRODUCTS_LOADING} }
    function success(data) { return { type: Actions.ADD_NEW_PRODUCTS, data: data} }
    function failed(error) { return { type: Actions.ADD_NEW_PRODUCTS_FAILED, error: error} }
}

function getItems(skip=null,take=null, query=null){

    return dispatch =>{
        dispatch(request());
        ItemService.getItems(skip,take, query)
        .then(res =>{
            var data = res.data;
            var count = res.count;
            dispatch(success(data,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ITEMS_LOADING} }
    function success(data,count) { return { type: Actions.GET_ITEMS_SUCCESS, data: data,count} }
    function failed(error) { return { type: Actions.GET_ITEMS_FAILED, error: error} }
}

function getStoreUnimported(store_id,skip=null,take=null){

    return dispatch =>{
        dispatch(request());
        ItemService.getStoreUnimported(store_id,skip,take)
        .then(res =>{
            var data = res.data;
            var count = res.count;
            dispatch(success(data,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_UNIMPORTED_LOADING} }
    function success(data,count) { return { type: Actions.GET_STORE_UNIMPORTED_SUCCESS, data: data,count} }
    function failed(error) { return { type: Actions.GET_STORE_UNIMPORTED_FAILED, error: error} }
}

function getStoreUnimportedItem(store_id,item_id){

    return dispatch =>{
        dispatch(request());
        ItemService.getStoreUnimportedItem(store_id,item_id)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_UNIMPORTED_ITEM_LOADING} }
    function success(data) { return { type: Actions.GET_STORE_UNIMPORTED_ITEM_SUCCESS, data: data} }
    function failed(error) { return { type: Actions.GET_STORE_UNIMPORTED_ITEM_FAILED, error: error} }
}

function getItem(item_id){

    return dispatch =>{
        dispatch(request());
        ItemService.getItem(item_id)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ITEM_LOADING} }
    function success(data) { return { type: Actions.GET_ITEM_SUCCESS, data: data} }
    function failed(error) { return { type: Actions.GET_ITEMS_FAILED, error: error} }
 }
 
 function editItem(data){
 
    return dispatch =>{
        dispatch(request());
        ItemService.editItem(data)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_ITEM_LOADING} }
    function success() { return { type: Actions.EDIT_ITEM_SUCCESS} }
    function failed(error) { return { type: Actions.EDIT_ITEM_FAILED, error: error} }
 }

 function editStoreItem(data){
 
    return dispatch =>{
        dispatch(request());
        ItemService.editStoreItem(data)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.EDIT_STORE_ITEM_LOADING} }
    function success() { return { type: Actions.EDIT_STORE_ITEM_SUCCESS} }
    function failed(error) { return { type: Actions.EDIT_STORE_ITEM_FAILED, error: error} }
 }

 function getStoreItem(item_id, store_id){
    return dispatch =>{
        dispatch(request());
        ItemService.getStoreItem(item_id, store_id)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_STORE_ITEM_LOADING} }
    function success(data) { return { type: Actions.GET_STORE_ITEM_SUCCESS, data: data} }
    function failed(error) { return { type: Actions.GET_STORE_ITEM_FAILED, error: error} }
 }

 function getItemProducts(skip=null, take=null){
    return dispatch =>{
        dispatch(request());
        ItemService.getItemProducts(skip, take)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ITEM_PRODUCTS_LOADING} }
    function success(data) { return { type: Actions.GET_ITEM_PRODUCTS_SUCCESS, data: data} }
    function failed(error) { return { type: Actions.GET_ITEM_PRODUCTS_FAILED, error: error} }
 }


 function checkUniqueBarcode(barcode){
    return dispatch =>{
        // dispatch(request());
        ItemService.checkUniqueBarcode(barcode)
        .then(res =>{
            var data = res.data;
            if(data){
                data = ''
            }else{
                data = 'not found';
            }
            dispatch(success(data))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    // function request() { return { type: Actions.GET_ITEM_PRODUCTS_LOADING} }
    function success(data) { return { type: Actions.UNIQUE_BARCODE_SUCCESS, data: data} }
    // function failed(error) { return { type: Actions.GET_ITEM_PRODUCTS_FAILED, error: error} }
 }

function checkUniqueSKU(sku){
    return dispatch =>{
        // dispatch(request());
        ItemService.checkUniqueSKU(sku)
        .then(res =>{
            var data = res.data;
            if(data){
                data = ''
            }else{
                data = 'not found';
            }
            dispatch(success(data))
        }).catch(error =>{
            // dispatch(failed(error))
        })
    }
    // function request() { return { type: Actions.GET_ITEM_PRODUCTS_LOADING} }
    function success(data) { return { type: Actions.UNIQUE_SKU_SUCCESS, data: data} }
    // function failed(error) { return { type: Actions.GET_ITEM_PRODUCTS_FAILED, error: error} }
}

export{
    addItem,
    addNewProducts,
    getItems,
    getStoreUnimported,
    getStoreUnimportedItem,
    getItem,
    editItem,
    editStoreItem,
    getStoreItem,
    getItemProducts,
    checkUniqueSKU,
    checkUniqueBarcode
}
