import React, { Component } from 'react';
import { Row,Col} from "reactstrap";
class AccountBox extends Component {
  state = {  }
  render() { 
    return ( 
      <div className="top-box">
      <Row>
        <Col md={'3'}>
           <div className="box-wrap" style={{backgroundColor:'#55d8fe'}}>
             <div className="name">Sale</div>
             <Row>
               <Col md={'6'}>
                 <div className="box">
                   <div className="small-name">Online</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
               <Col md={'6'}>
                <div className="box">
                   <div className="small-name">COD</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
             </Row>
           </div>
        </Col>
        <Col md={'3'}>
           <div className="box-wrap" style={{backgroundColor:'#5de19f'}}>
             <div className="name">Credited</div>
             <Row>
               <Col md={'6'}>
                 <div className="box">
                   <div className="small-name">Online</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
               <Col md={'6'}>
                <div className="box">
                   <div className="small-name">COD</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
             </Row>
           </div>
        </Col>
        <Col md={'3'}>
           <div className="box-wrap" style={{backgroundColor:'#11817E'}}>
             <div className="name">Awaiting</div>
             <Row>
               <Col md={'6'}>
                 <div className="box">
                   <div className="small-name">Online</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
               <Col md={'6'}>
                <div className="box">
                   <div className="small-name">COD</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
             </Row>
           </div>
        </Col>
        <Col md={'3'}>
           <div className="box-wrap" style={{backgroundColor:'#b4b4c6'}}>
             <div className="name">Cancelled and Refunded</div>
             <Row>
               <Col md={'6'}>
                 <div className="box">
                   <div className="small-name">Online</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
               <Col md={'6'}>
                <div className="box">
                   <div className="small-name">COD</div>
                   <div className="price">INR<strong>12345</strong></div>
                 </div>
               </Col>
             </Row>
           </div>
        </Col>
      </Row> 
   </div> 
     );
  }
}
 
export default AccountBox;