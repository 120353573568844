import React, { Component } from 'react';
import Select from 'react-select'
class Fselect extends Component {
  render() { 
    const {label,value,options, ...props} = this.props; 
    return ( 
      <div className="select-wrapper">
           {label&&label!== 'undefined'  ? <lablel className="form-label">{label}</lablel> : null}
      <Select
          {...props}
          value={value}
          onChange={this.props.handleChange}
          options={options}
        />
      </div>
     );
  }
}


Fselect.defaultProps = {
  value: '',
  label:'',
  options:[]
}
export default Fselect;
