import * as Actions from '../constants/Actions';
import reviewService from '../services/reviewService';

function getAllReviews(offset,limit,store_id){

  return dispatch =>{
      dispatch(request());
      reviewService.getAllReviews(offset,limit,store_id)
      .then(res =>{
         var reviews = res.data;
         var count = res.count;
          dispatch(success(reviews,count))
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
function request() { return { type: Actions.GET_REVIEWS_LOADING} }
function success(reviews,count) { return { type: Actions.GET_REVIEWS_SUCCESS, reviews,count} }
function failed(error) { return { type: Actions.GET_REVIEWS_FAILED, error:error} }
}

export {
  getAllReviews
}