import React, { Component } from 'react';
class ActionBtn extends Component {
  state = {  }
  render() { 
    return ( 
      <div className="group-action">
     {this.props.download ? <button onClick={this.props.downClick} className="btn">
        <i className="ico icon-download icon-export" />
      </button>: null}
      {this.props.view ? <button onClick={this.props.viewClick} className="btn">
        <i className="ico icon-eye icon-export" />
      </button>: null}
      {this.props.edit ?  <button onClick={this.props.editClick} className="btn">
        <i className="ico icon-edit" />
      </button>: null}
      {this.props.delete ? <button onClick={this.props.deleteClick} className="btn">
        <i className="ico icon-delete" />
      </button>: null}
    </div>
     );
  }
}
ActionBtn.defaultProps = {
  download:false,
  edit:false,
  delete:true,
  view:false
}
export default ActionBtn;