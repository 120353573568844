import React, { Component } from 'react';
import {connect} from 'react-redux';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import SweetAlert from 'sweetalert2-react';
import Select from "react-select";
import swal from '@sweetalert/with-react'
import { 
    Table,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    Input,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import {
    getAllOrders,
    onDismiss,
    orderStatus,
    getStoreOrders,
    startShipping
} from '../../../actions/OrderActions'
import * as Constant from '../../../constants/AppConstants'
import { 
    getDeliveryName, 
    parseTime, 
    getPayMethod, 
    getPayStatusClass, 
    getLoder, 
    getPayStatus
} from '../../../utils';

import { ActionBtn,Button } from "../../../components";import { downloadExcel } from '../../../actions/userActions';
import { EXPORT_ORDERS } from '../../../constants/AppUrls';
import { getAllDeliveryBoys } from '../../../actions/deliveryBoyAction';


const PaymentMethodOptions = [
  {value:Constant.PAYMENT_ONLINE, label:"Online"},
  {value:Constant.PAYMENT_COD, label:"COD"},
//   {value:Constant.PAYMENT_CARD_ON_DELIVERY, label:"Card on delivery"}
]

class AllOrders extends Component {

    constructor(props){
        super(props);

        this.state = { 
            offset: 0,
            limit:10,
            status:null,
            selected:'',
            dialogue:false,
            startDate:null,
            endDate:null,
            paymentMethod:null,
            search: '',
            orders:[],
            orderStatus: null,
            deliveryBoy: null,
            editModal: false
        }
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({offset: skip});
        this.fetchOrders(skip)
    }

    handleChange = (status,id) =>{
        if(status === "5"){
            this.setState({
                selected:id,
                orderStatus: status,
                editModal: true
            })
        }else{
            this.setState({
                selected:id,
                orderStatus: status,
                dialogue:true
            })
        }
    }

    handleDateChange = (date, name)=>{
        this.setState({[name]:date})
    }

    onStatus = () => {
        this.props.onChangeStatus(this.state.selected,this.state.orderStatus);
        this.setState({dialogue:false})
    }

    onDismissPopup =()=>{
        this.fetchOrders()
        this.props.onDismiss();
    }

    fetchOrders = (skip=null)=>{
        const {isStore, store, orderStatus} = this.props
        const {
            status, 
            offset, 
            limit, 
            startDate, 
            endDate, 
            search, 
            paymentMethod
        } = this.state

        var data = {
            status, 
            offset, 
            limit, 
            startDate, 
            endDate, 
            search, 
            paymentMethod,
            allOrders: true
        }

        if(skip != null){
            data = {...data, offset:skip}
        }

        if(paymentMethod){
            data = {...data, paymentMethod:paymentMethod.value}
        }

        if(startDate){
            data = {...data, startDate:startDate.format('YYYY-MM-DD')}
        }

        if(endDate){
            data = {...data, endDate:endDate.format('YYYY-MM-DD')}
        }

        if(orderStatus){
            data = {...data, status: orderStatus}
        }


        if(isStore){
            data = {...data, store:store.id}
            this.props.onGetStoreOrders(data)
        }else{
            this.props.onGetOrders(data)
        }
    }

    componentDidMount() {
       this.fetchOrders()
       const {isStore, store} = this.props
       if(isStore){
            this.props.onGetDeliveryBoys(store.id)
        }else{
            this.props.onGetDeliveryBoys()
        }
    }

    onClickDownload = ()=>{
        const {isStore, store} = this.props
        var url = EXPORT_ORDERS
        if(isStore){
            url += '/'+store.id
        }
        downloadExcel(url)
    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(nextProps.isStore){
            return {...prevState, orders:nextProps.store_orders}
        }

        if(!nextProps.isStore){
            return {...prevState, orders:nextProps.all_orders}
        }

        return {...prevState}
    }

    render() { 
        const {orders} = this.state;
        const { deliveryBoys, disableSearch } = this.props

        var boyOptions = []
        if(deliveryBoys){
            boyOptions = deliveryBoys.map((boy)=>{
            return {label: boy.name, value: boy.id}
            })
        }
        return ( 
            <div>
                <div className="content">
                    <div className={getLoder(this.props.loading)} />
                    {!disableSearch ? <div className="common-filter-wrapper mb-2">
                        <Row className="align-items-center">
                            <Col md='3'>
                                <InputGroup className="filter-search mr-2">
                                    <Input  
                                        placeholder="Search"  
                                        type="text" 
                                        value={this.state.search} 
                                        onChange={(e)=>this.setState({search:e.target.value})}/>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <i className="icon-search-find" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md='3'>
                                <Select 
                                    placeholder="Payment Method" 
                                    options={PaymentMethodOptions} 
                                    onChange={(e)=>this.setState({paymentMethod:e})}
                                    value={this.state.paymentMethod}/>
                            </Col>
                            <Col md='3'>
                                <DatePicker 
                                    className="form-control"
                                    selected={this.state.startDate}
                                    onChange={(e)=>this.handleDateChange(e, 'startDate')}
                                    placeholderText="Date from"/>
                            </Col>
                            <Col md='3'>
                                <DatePicker 
                                    className="form-control"
                                    selected={this.state.endDate}
                                    onChange={(e)=>this.handleDateChange(e, 'endDate')}
                                    placeholderText="Date end"/>
                            </Col>
                            <Col md='2'>
                                <Button 
                                    className="btn-submit"
                                    onClick={()=>this.fetchOrders(0)}>Filter</Button>
                            </Col>
                        </Row> 
                    </div> : null}

                    <div className="table-product-wrapper table-orders">
                        <Table>
                            <thead>
                                <tr>
                                    <th>SI.NO</th>
                                    <th>Order ID</th>
                                    <th>Order Placed</th>
                                    <th>Delivery Time</th>
                                    <th>Customer Name</th>
                                    {!this.props.isStore? <th>Store</th>:null}
                                    <th>Payment Status</th>
                                    <th>Payment Method</th>
                                    <th>Fullfilment Status</th>
                                    <th>Amount</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {(orders &&orders.length > 0) && orders.map((order,index) =>(
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>
                                        <p>{order.order_id}</p>
                                        <p className="small">
                                            {getDeliveryName(order.delivery_mode)}
                                        </p>
                                    </td>
                                    <td>
                                        {order.order_time}
                                    </td>
                                    <td>
                                        {order.date} {parseTime(order.from)}-{parseTime(order.to)}
                                    </td>
                                    <td>
                                        <p>{order.customer.first_name +' '+ order.customer.second_name}</p>
                                        <p className="small">{order.customer.mobile}</p>
                                    </td>
                                    {!this.props.isStore? <th>
                                        <p>{order.store.title}</p>
                                    </th>:null}
                                    <td>
                                        {getPayStatus(order.payment_status)}
                                    </td>
                                    <td>
                                        <span className={getPayStatusClass(order.payment_status)}>
                                            {getPayMethod(order.payment_mode)}
                                        </span> 
                                    </td>
                                    <td>  
                                        {order.status === Constant.ORDER_CANCELLED ? <span style={{color:'red'}}>Cancelled</span>:
                                        order.status === Constant.ORDER_FAILED ? <span style={{color:'red'}}>Failed</span>:
                                        order.status === Constant.ORDER_COMPLETED ? <span style={{color:'green'}}>Completed</span>:
                                        <div className="status-changer">
                                                <select 
                                                    name="status" 
                                                    className="form-control" 
                                                    value={order.status} 
                                                    onChange={(e)=>{this.handleChange(e.target.value,order.id) }} >
                                                    <option value="1">New</option>
                                                    <option value="2">Processing</option>
                                                    <option value="5">Shipping</option>
                                                    <option value="6">Delivered</option>
                                                    <option value="7">Cancel</option>
                                                </select>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {order.final_amount?order.final_amount:'-'} | 
                                        {order.items.length>0 ? <span >{order.items.length}</span> :0} item's
                                    </td>
                                    <td>
                                        <ActionBtn 
                                            download={false} 
                                            edit={false} 
                                            view={true}
                                            viewClick={()=>{
                                                if(this.props.isStore){
                                                    this.props.history.push("/store/order-detail/"+order.id)
                                                }else{
                                                    this.props.history.push("/admin/order-detail/"+order.id)
                                                }
                                            }}
                                            delete={false}/>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Button className="fixed-yellow" onClick={()=>{ this.onClickDownload() }}>Download Excel</Button>
                    <div className="pagination-wrapper">
                            <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                            nextLabel={<i className="fas fa-angle-right"></i>}
                            pageLinkClassName={"page-link"}
                            breakClassName={"break-me"}
                            pageCount={Math.ceil(this.props.count/this.state.limit)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"} />
                    </div>
                </div>
                <SweetAlert
                    show={this.state.dialogue }
                    onConfirm={this.onStatus}
                    onCancel = {() => this.setState({ dialogue: false })}
                    title= {'Are you sure?'}
                    text="Change status"
                    type= {'warning'}
                    showCancelButton= {true}
                    confirmButtonText= {'Yes, Confirm it!'}
                    cancelButtonText= {'No, keep it'}/>
                <SweetAlert
                    show={this.props.status && this.props.popup}
                    title="Success"
                    text={this.props.message}
                    onConfirm={this.onDismissPopup}/>
                <SweetAlert
                    show={ !this.props.status && this.props.popup}
                    title="Error"
                    type="error"
                    text={this.props.message}
                    onConfirm={this.onDismissPopup}/>
                <Modal isOpen={this.state.editModal}  className='common-modal' >
                    <ModalHeader>Start Shipping By Choosing Delivery Partner</ModalHeader>
                    <ModalBody>
                    <div className="form-model">
                        <div className="form-group">
                        <Select onChange ={(e)=>{this.setState({deliveryBoy: e})}} value={this.state.deliveryBoy} options={boyOptions}  />
                            {!this.state.deliveryBoy && <div className="errorMsg">Choose a delivery boy</div>}
                        </div>
                    </div>     
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-cancel" onClick={() => this.setState({editModal:false})}>Cancel</Button>
                        <Button  className="btn-submit" onClick={()=>{
                            if(!this.state.deliveryBoy) return
                             var data = {order_id:this.state.selected, delivery_boy_id:this.state.deliveryBoy.value}
                             this.props.onStartShipping(data)
                        }}>Save</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

AllOrders.defaultProps = {
    all_orders:[]
}

const mapStateToProps = (state) => ({
    loading:state.orderReducer.loading,
    all_orders:state.orderReducer.all_orders,
    count:state.orderReducer.count,
    status:state.orderReducer.status,
    message:state.orderReducer.message,
    popup:state.orderReducer.popup,
    store:state.storeUserReducer.store,
    store_orders: state.orderReducer.store_orders,
    deliveryBoys:state.deliveryBoyReducer.delivery_boys,
})

const mapDispatchToProps = (dispatch) => ({
    onGetOrders:(data)=> {
        dispatch(getAllOrders(data))
    },
    onDismiss:()=>{
        dispatch(onDismiss());
    },
    onChangeStatus:(order_id,status)=>{
        dispatch(orderStatus(order_id,status))
    },
    onGetStoreOrders:(data)=> {
        dispatch(getStoreOrders(data))
    },
    onGetDeliveryBoys:(store)=>{
        dispatch(getAllDeliveryBoys(0, 100, store))
    },
    onStartShipping:(data)=>{
        dispatch(startShipping(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(AllOrders);