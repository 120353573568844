import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  faqLoading: false,
  faqs: []
};

export default function FaqReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_FAQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Add Faq successful",
        popup: true,
        status: true
      };
    case Actions.ADD_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Add Faq failed",
        status: false,
        error: action.error
      };

    case Actions.GET_FAQ_LOADING:
      return {
        faqLoading: true,
        faqs: []
      };
    case Actions.GET_FAQ_SUCCEESS:
      return {
        ...state,
        faqLoading: false,
        faqs: action.faqs
      };
    case Actions.GET_FAQ_FAILED:
      return {
        ...state,
        faqLoading: false,
        faqs: [],
        error: action.error
      };

    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

      case Actions.ADD_FAQ_DIS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_FAQ_DIS_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Faq discription added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_FAQ_DIS_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Faq discription adding failed",
        status: false
      };

      case Actions.DELETE_FAQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Faq Category delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_FAQ_FAILED:
      return {
        ...state,
        loading: false,
        message:"Faq category delete failed",
        popup: true,
        status: false
      };

      case Actions.DELETE_FAQ_DIS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_FAQ_DIS_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Faq discription delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_FAQ_DIS_FAILED:
      return {
        ...state,
        loading: false,
        message:"Faq discription delete failed",
        popup: true,
        status: false
      };

    default:
      return state;
  }
}
