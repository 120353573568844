import * as Actions from "../constants/Actions";

const initialState = {
  addrLoading: false,
  countries: [],
  states:[],
  regions:[],
  cities:[]
};

export default function AddressReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_COUNTRIES_LOADING:
      return {
        addrLoading: true,
        countries: [],
        states:[],
        regions:[],
        cities:[]
      };
    case Actions.GET_COUNTRIES:
      return {
        ...state,
        addrLoading: false,
        countries: action.countries
      };

      case Actions.GET_STATES_LOADING:
      return {
        ...state,
        states: []
      };
    case Actions.GET_STATES:
      return {
        ...state,
        states: action.states
      };

      case Actions.GET_REGIONS_LOADING:
      return {
        ...state,
        regions: []
      };
    case Actions.GET_REGIONS:
      return {
        ...state,
        regions: action.regions
      };

      case Actions.GET_CITIES_LOADING:
      return {
        ...state,
        cities: []
      };
    case Actions.GET_CITIES:
      return {
        ...state,
        cities: action.cities
      };

      

    default:
      return state;
  }
}
