import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function getCustomers(skip,take, query) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
    return request({
      url:   URLS.ALL_CUSTOMERS + sub_url ,
      method: 'GET'
    });
}

function getCustomer(id) {
  var sub_url = '';
  if(id !== null && typeof id !== 'undefined') sub_url = sub_url+'/'+id;
  return request({
    url:   URLS.GET_CUSTOMER + sub_url ,
    method: 'GET'
  });
}

function updateCustomer(data) {
  return request({
    url:   URLS.UPDATE_CUSTOMER ,
    method: 'POST',
    data
  });
}

function getAdminStaffs(skip,take){
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_ADMIN_STAFFS + sub_url ,
      method: 'GET'
    });
}

function getAdminStaff(admin_id){
    var sub_url = '/'+admin_id;
    return request({
      url:   URLS.GET_ADMIN_STAFF + sub_url ,
      method: 'GET'
    });
}

function editStaff(data){
    return request({
      url:   URLS.ADMIN_EDIT  ,
      method: 'POST',
      data
    });
}


function deleteStaff(admin_id){
    var sub_url = '/'+admin_id;
    return request({
      url:   URLS.ADMIN_DELETE + sub_url ,
      method: 'GET'
    });
}

function getAdminDashboard() {
    return request({
      url:   URLS.ADMIN_DASHBORD ,
      method: 'GET'
    });
}

function getTopSellers(store_id) {
  var sub_url = ''
  if(store_id){
    sub_url += '/'+store_id
  }
  return request({
    url:   URLS.BEST_SELLER ,
    method: 'GET'
  });
}

function getSalesChart(period, store) {
  var sub_url = '/'+period;
  if(store) sub_url += '/'+store
  return request({
    url:   URLS.SALES_CHART + sub_url,
    method: 'GET'
  });
}

function addStaff(data) {
    return request({
      url:   URLS.ADD_STAFF,
      method: 'POST',
      data:data
    });
}

function changeItemStatus(item_id,status){
    var sub_url = '/'+item_id+'/'+status;
    return request({
      url:   URLS.CHANGE_ITEM_STATUS + sub_url ,
      method: 'GET'
    });
 }

 function changeVarientStatus(variant_id,status){
    var sub_url = '/'+variant_id+'/'+status;
    return request({
      url:   URLS.CHANGE_ITEM_VARIANT_STATUS + sub_url ,
      method: 'GET'
    });
 }

const AdminService = {
    getCustomers,
    getAdminDashboard,
    changeVarientStatus,
    addStaff,
    getAdminStaffs,
    getAdminStaff,
    editStaff,
    deleteStaff,
    changeItemStatus,
    getCustomer,
    updateCustomer,
    getTopSellers,
    getSalesChart
}
export default AdminService;