import userReducer from './userReducer';
import tagReducer from './tagReducer';
import categoryReducer from './categoryReducer';
import brandReducer from './brandReducer';
import unitReducer from './unitReducer';
import attributeReducer from './AttributeReducer';
import storeReducer from './storeReducer';
import AddressReducer from './addressReducer';
import volumeOfferReducer from './volumeOfferReducer';
import storeUserReducer from './storeUserReducer';
import categoryOfferReducer from './categoryOfferReducer';
import brandOfferReducer from './brandOfferReducer';
import productOfferReducer from './productOfferReducer';
import userModelReducer from './userModel'
import bannerReducer from './bannerReducer';
import permissionReducer from './permissionReducer';
import productReducer from './productReducer';
import itemReducer from './itemReducer';
import todaysReducer from './todaysReducer';
import fantasticReducer from './fatasticReducer';
import PackReducer from './PackReducer';
import FaqReducer from './FaqReducer';
import aboutReducer from './aboutReducer';
import pointTermReducer from './pointTermReducer';
import commonReducer from './CommonReducer';
import StaffReducer from './staffReducer';
import adminReducer from './adminReducer';
import variantReducer from './VariantReducer';
import { combineReducers } from 'redux';
import groupReducer from './groupReducer';
import importReducer from './importReducer';
import notificationReducer from './notificationReducer';
import adminNotificationReducer from './adminNotificationReducer';
import resetReducer from './resetReducer';
import slotReducer from './deliverySlotReducer';
import orderReducer from './orderReducer';
import deliveryBoyReducer from './deliveryBoyReducer';
import reviewReducer from './reviewReducer';
import resourceReducer from './resource.reducer'
import customerReducer from './customer.reducer'
import settingsReducer from './settingsReducer'
import privacyReducer from './privacyReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

const rootReducer = combineReducers({
  userReducer,
  deliveryBoyReducer,
  reviewReducer,
  tagReducer,
  categoryReducer,
  brandReducer,
  unitReducer,
  attributeReducer,
  storeReducer,
  AddressReducer,
  volumeOfferReducer,
  storeUserReducer,
  categoryOfferReducer,
  brandOfferReducer,
  userModelReducer,
  bannerReducer,
  permissionReducer,
  productOfferReducer,
  itemReducer,
  productReducer,
  todaysReducer,
  fantasticReducer,
  PackReducer,
  FaqReducer,
  pointTermReducer,
  aboutReducer,
  commonReducer,
  StaffReducer,
  adminReducer,
  groupReducer,
  notificationReducer,
  variantReducer,
  importReducer,
  adminNotificationReducer,
  resetReducer,
  slotReducer,
  orderReducer,
  resourceReducer,
  customerReducer,
  settingsReducer,
  privacyReducer
})
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
export default persistedReducer;