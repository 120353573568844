import { createSlice } from '@reduxjs/toolkit'
import AddressService from '../services/AddressService';

export const resourceSlice = createSlice({
  name: 'resources',
  initialState: {
    currencies:[],
    reflector: true,
    sound: null
  },
  reducers: {
    setCurrencies: (state, {payload}) => {
      state.currencies = payload.currencies
      state.reflector = !state.reflector
    },
    setSound:(state, {payload})=>{
      state.sound = payload.sound
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrencies, setSound } = resourceSlice.actions

export default resourceSlice.reducer