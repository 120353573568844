import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  proLoding:false,
  products:[],
  all_products:[],
  reflector: false,
  customerProducts:[]
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {

    case Actions.GET_CUSTOMER_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_CUSTOMER_PRODUCTS:
      return {
        ...state,
        loading: false,
        customerProducts: action.products
        
      };
    case Actions.GET_CUSTOMER_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case Actions.GET_STORE_PRODUCTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.GET_STORE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products,
        count: action.count
        
      };
    case Actions.GET_STORE_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      case Actions.GET_ALL_PRODUCTS_LOADING:
      return {
        ...state,
        proLoding: true
      };
    case Actions.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        proLoding: false,
        all_products: action.all_products,
        reflector: !state.reflector,
        count: action.count
        
      };
    case Actions.GET_ALL_PRODUCTS_FAILED:
      return {
        ...state,
        proLoding: false,
        error: action.error
      };

    case Actions.GROUP_STATUS_LOADING:
      return {
        loading:true,
        status: true,
        popup: false,
      };
    case Actions.GROUP_STATUS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",
      };
    case Actions.GROUP_STATUS_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.IMPORT_TO_STORES_LOADING:
        return {
          loading:true,
          status: true,
          popup: false,
        };
      case Actions.IMPORT_TO_STORES:
        return {
          ...state,
          loading:false,
          popup: true,
          status: true,
          message: "Successfull",
        };
      case Actions.IMPORT_TO_STORES_FAILED:
        return {
          ...state,
          loading:false,
          popup: true,
          message: "Something went wrong",
          status: false,
          error: action.error
        };

    default:
      return state;
  }
}
