import React,{Component} from 'react';
import {
Row,
Col,FormGroup,
InputGroup, InputGroupAddon, Input,
} from "reactstrap";
import { Button, Checkbox } from '../../../components';
import {Link} from "react-router-dom";
import DatePicker from "react-datepicker";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {addVolumeOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class AddVolumeOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          popup:false,
          store_id :'',
          isActive: false,
          valid_from:moment(),
          valid_upto:moment(),
          vol_amount:'',
          disc_perc:'',
          disc_upto:'',
          title:'',
          week:[],
      }
      this.handleChangeFrom = this.handleChangeFrom.bind(this);
      this.handleChangeTo = this.handleChangeTo.bind(this);
      this.onDismissPopup = this.onDismissPopup.bind(this);
      this.validator = new SimpleReactValidator();
    } 

    handleChangeFrom(date) {
      this.setState({
        valid_from:date,
      });
    }
    onDismissPopup () {
      this.props.onDismiss();
    }

    handleChangeTo(date) {
      this.setState({
        valid_upto: date
      });
    }
    handleLimit = () => {
      this.setState({ isActive: !this.state.isActive });
    }
    handleCheck = (week) => {
      this.setState({week});
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        store_id :this.props.store.id,
        vol_amount:this.state.vol_amount,
        disc_perc:this.state.disc_perc,
        disc_upto:this.state.disc_upto,
        title:this.state.title
      }
      let from = this.state.valid_from;
      let upto = this.state.valid_upto;
      data['valid_from'] = from.format('YYYY-MM-DD');
      data['valid_upto'] = upto.format('YYYY-MM-DD');  
      
      var days_array = this.state.week.map((item)=>{
        return {'day_id':item}
      })
     data['week_days']= days_array;

     if( this.validator.allValid() ){
    this.props.onAddVolumeOffer(data);
     }
     else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.status === true) {
        this.setState({
          valid_from:moment(),
          valid_upto:moment(),
          vol_amount:'',
          disc_perc:'',
          disc_upto:'',
          title:'',
          week:[]
         });
      }
  }

     //loader
     getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }
    render() { 

        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
                <div className="form-filds-wrapper">
                  <Row>
                  <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Name</label>
                        <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})} className="form-control" placeholder="Name" />
                        <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Order Above</label>
                        <input type="number" value={this.state.vol_amount} onChange={(e)=>this.setState({vol_amount:e.target.value})} className="form-control" placeholder="Price in INR" />
                    
                      </div>
                    </Col>
                   </Row>
                   <Row>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Offer Discount</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="select" id="exampleSelect">
                        <option>%</option>
                        </Input>
                        </InputGroupAddon>
                        <Input value={this.state.disc_perc} type={"number"} onChange={(e)=>this.setState({disc_perc:e.target.value})}/>
                        <div className="errorMsg">{this.validator.message('discount', this.state.disc_perc, 'min:1|max:2')}</div>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Price up to</label>
                        <input type="number" value={this.state.disc_upto} onChange={(e)=>this.setState({disc_upto:e.target.value})}  className="form-control" placeholder="Price in INR" />
                        
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Valid From</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_from}
                        onChange={this.handleChangeFrom}
                        dateFormat="YYYY-MM-DD"
                        name="valid_from"
                        />
                      </div>
                      </Col>
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label">Valid To</label>
                        <DatePicker className="form-control" 
                        selected={this.state.valid_upto}
                        onChange={this.handleChangeTo}
                        dateFormat="YYYY-MM-DD"
                        name="valid_upto"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                      <div className="check-box-inline prvilages">
                      { this.state.isActive ? <CheckFrom onCheckChange ={(week)=>this.handleCheck(week)}/> : null }
                      
                      </div>
                      </Col>  
                    </Row>
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </form>
            </div>
          </div>
        </div>
         );
    }
}

const mapStateToProps = (state) => ({
  loading: state.volumeOfferReducer.loading,
  status:state.volumeOfferReducer.status,
  message:state.volumeOfferReducer.message,
  popup:state.volumeOfferReducer.popup,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onAddVolumeOffer:(data)=>{
    dispatch(addVolumeOffer(data));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddVolumeOffer)