import React, { Component } from 'react';
import { Row,Col,Input } from "reactstrap";
import Select from "react-select";
import SweetAlert from "sweetalert2-react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import PhoneInput from 'react-phone-number-input'

import { Button } from '../../../components';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import { setStatus } from '../../../reducers/customer.reducer';
import { getCustomer, updateCustomer } from '../../../actions/AdminActions';

class CustomerForm extends Component {
    constructor(props) {
      super(props);
      this.state= {
        image:'',
        email:'',
        mobile:'',
        name:'',
        second_name:'',
        password:'',
        c_password:'',
        edit: false,
        id: null,
        passv: 'password',
        passc: 'password',
      }
      this.validator = new SimpleReactValidator();
      this.onDismissPopup = this.onDismissPopup.bind(this);
    }
    _onImageChange=(image)=> {
      this.setState({image});
    }

    _onImageChangeProof=(image)=> {
      this.setState({proof_id: image});
    }

    hadlePrev = (permissions)=> {
      this.setState({permissions});
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var data = {
        profile_pic:this.state.image,
        first_name:this.state.name,
        second_name:this.state.second_name,
        email:this.state.email,
        mobile:this.state.mobile,
        password:this.state.password,
        c_password:this.state.c_password,
       
      }

        if( this.validator.allValid()&& this.state.c_password === this.state.password ){
            this.props.onUpdateCustomer({...data, id: this.state.id});
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }
    }
    
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
      if(nextProps.status === true && !this.state.edit) {
        this.setState({
          image:'', 
          email:'', 
          mobile:'', 
          name:'',  
          second_name:'',  
          password:'',  
          c_password:''
        })
      }
      if(nextProps.customer && !this.state.mobile && nextProps.customer.mobile && nextProps.customer.id == this.state.id){
        this.setState({
          image: nextProps.customer.profile_pic, 
          email: nextProps.customer.email, 
          mobile: nextProps.customer.mobile, 
          name:nextProps.customer.first_name,  
          second_name:nextProps.customer.second_name,  
          password:'',  
          c_password:'', 
        })
      }
    }

    onDismissPopup() {
      this.props.onDismiss({popup: false, status: false, message:''});
    }

    //loader
    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }

    componentDidMount(){
      var urls = this.props.match.url.split('/');
      if((urls.length - 2) > 0){
        if(urls[urls.length - 2].includes('edit')){
          this.setState({edit: true, id: urls[urls.length - 1]})
          this.props.onGetCustomer(urls[urls.length - 1]);
        }
      }
    }

    render() { 
        return ( 
            <div>
            <div className="content">
            <div className={this.getLoder()}></div>
              <div className="form-section">  
              <form  onSubmit={this.handleSubmit}>
                  <div className="form-filds-wrapper">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Add image</FeaturedImage>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                     <Col md="8">
                     <Row>
                     <Col md="6">
                        <div className="form-group">
                          <label className="control-label">First Name</label>
                          <Input type="text" className="form-control" onChange ={(e)=>this.setState({name:e.target.value})} value={this.state.name}  placeholder="User name" />
                          <div className="errorMsg">{this.validator.message('First Name', this.state.name, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Second Name</label>
                          <Input type="text" className="form-control" onChange ={(e)=>this.setState({second_name:e.target.value})} value={this.state.second_name}  placeholder="User name" />
                          <div className="errorMsg">{this.validator.message('Second Name', this.state.second_name, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                      <label className="control-label">Mobile Number</label>
                          <PhoneInput defaultCountry="IN" onChange ={(e)=>this.setState({mobile:e})} value={this.state.mobile}  className="form-control" placeholder="Mobile Number" />
                          <div className="errorMsg">{this.validator.message('mobile', this.state.mobile, 'required|phone')}</div>
                        </div>
                        </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Email</label>
                          <Input type="email" onChange ={(e)=>this.setState({email:e.target.value})} value={this.state.email}   className="form-control" placeholder="Email"/>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                         Password
                          </label>
                          <Input type={this.state.passv} onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                          <i className={this.state.passv != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passv}) => ({
                                    passv: passv === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                          <div className="errorMsg">{this.validator.message('password', this.state.password, 'required|min:6')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                      <div className="form-group">
                          <label className="control-label">
                           Confirm Password
                          </label>
                          <Input type={this.state.passc} onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                          <i className={this.state.passc != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                              onClick={()=>{
                                this.setState(({passc}) => ({
                                  passc: passc === 'text' ? 'password' : 'text'
                                }))
                              }}></i>
                          <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required|min:6')}</div>
                          <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                        </div>
                      </Col>
                     </Row>
                     </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <div className="submit-wrapper">
                        <Button className="btn-submit" >Save</Button>
                        </div>
                        </Col>
                    </Row>
                  </div>
                  </form>
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
            </div>
          </div>
         );
    }
}
 

const mapStateToProps = (state) => ({
  loading: state.customerReducer.loading,
  status: state.customerReducer.status,
  message: state.customerReducer.message,
  popup: state.customerReducer.popup,
  customer:state.customerReducer.customer
})

const mapDispatchToProps = (dispatch) => ({
onUpdateCustomer:(data) => {
  dispatch(updateCustomer(data));
},
onDismiss:(value) => {
  dispatch(setStatus(value));
},
onGetCustomer: (id) => {
  dispatch(getCustomer(id))
}
})

export default connect(mapStateToProps,mapDispatchToProps)(CustomerForm)

