import * as Actions from '../constants/Actions';

const initialState={
    loading:false,
    storeloggedIn:false,
    logoutLoading:false,
    store:[],
    user:{}
}

export default function storeUserReducer(state=initialState,action){
    switch(action.type){


        case Actions.LOGOUT_RESET:
            return{
               
            }
       
        case Actions.STORE_LOGIN_LOADING:
            return{
                ...state,
                loading:true,
                store:[],
                user:{}
            }
        case Actions.STORE_LOGIN:
            return{
                ...state,
                loading:false,
                token: action.token,
                user: action.user,
                store:action.store,
                storeloggedIn:true
            }
        case Actions.STORE_LOGIN_FAILED:
            return{
                loading:false,
                store:[],
                error: action.error,
                user:{}
            }

        case Actions.STORE_LOGOUT_LOADING:
            return{
                ...state,
                logoutLoading:true,
                user:{}
            }
        case Actions.STORE_LOGOUT_SUCCESS:
            return{
                logoutLoading:false,
                storeloggedIn:false,
                user:{}
            }

            case Actions.STORE_CHANGE_PASS_LOADING:
            return {
              ...state,
              loading: true
            };
          case Actions.STORE_CHANGE_PASS:
            return {
              ...state,
              loading: false,
              message:"Change password successfully",
              popup: true,
              status: true
            };
          case Actions.STORE_CHANGE_PASS_FAILED:
            return {
              ...state,
              loading: false,
              popup: true,
              message:"Password changing failed",
              status: false,
              error: action.error
            };   

            case Actions.ALERT_DISMISS:
            return {
              ...state,
              status: false,
              popup: false
            };

        default:
            return state;
    }
}