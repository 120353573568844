import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  attrLoading: false,
  attributes: [],
  loadattr:false,
  attribute: []
};

export default function attributeReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.ADD_ATTRIBUTE_LOADING:
          return {
            ...state,
            loading: true
          };
        case Actions.ADD_ATTRIBUTE:
          return {
            ...state,
            loading: false,
            message: "Attribute added successfully",
            popup: true,
            status: true
          };
        case Actions.ADD_ATTRIBUTE_FAILED:
          return {
            ...state,
            loading: false,
            popup: true,
            message: "Attribute add failed",
            status: false,
            error: action.error
          };
    
          case Actions.ALERT_DISMISS:
          return {
            ...state,
            status: false,
            popup: false
          };
    
        case Actions.GET_ATTRIBUTES_LOADING:
          return {
            attrLoading: true,
            attributes: []
          };
        case Actions.GET_ATTRIBUTES:
          return {
            ...state,
            attrLoading: false,
            attributes: action.attributes,
            count: action.count
          };
        case Actions.GET_ATTRIBUTES_FAILED:
          return {
            ...state,
            attrLoading: false,
            attributes: [],
            error: action.error
          };
          case Actions.ALERT_DISMISS:
          return {
            ...state,
            status: false,
            popup: false
          };
          case Actions.EDIT_ATTRIBUTE_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_ATTRIBUTE:
      return {
        ...state,
        loading: false,
        message: "Attribute edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_ATTRIBUTE_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Attribute editing failed",
        status: false,
        error: action.error
      };
      case Actions.GET_ATTRIBUTE_LOADING:
      return {
        attribute: [],
        loadattr:true,
      };
    case Actions.GET_ATTRIBUTE:
      return {
        ...state,
        loadattr:false,
        attribute: action.attribute,
      };
    case Actions.GET_ATTRIBUTE_FAILED:
      return {
        ...state,
        attribute: [],
        loadattr:false,
        error: action.error
      };
    
        default:
          return state;
      }
}