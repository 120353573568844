import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Badge,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  Button
} from "reactstrap";
import Echo from "laravel-echo"
import Sound from 'react-sound';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import userAvatar from "../../assets/img/avatar.png";
import noti from "../../assets/img/ryan.jpg";
import storeRoutes from "../../routes/StoreRoutes.jsx";
import {storeLogout, logoutReset} from '../../actions/userActions';
import {getStoreProducts} from '../../actions/productActions';
import { connect } from 'react-redux';
import soundfile from '../../assets/sound/ring.mp3'
import { getStoreCustomers } from "../../actions/storeStaffActions";
import OrderNotification from "./OrderNotification";
import { getAllOrders, getStoreOrders } from "../../actions/OrderActions";

const pStyle = {
padding:'6px 0',
};

class StoreHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      term:'',
      isMounted: false,
      color: "transparent",
      playing:false
    };
    this.toggle = this.toggle.bind(this);
    this._onClick = this._onClick.bind(this);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "white"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  getBrand() {
    var name;
    storeRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (this.props.match.path+'/' === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (this.props.match.path+'/'+prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "white"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  }
  
  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        // this.setState({ isMounted: false });
        window.addEventListener("resize", this.updateColor.bind(this));
      }
    });
    var type = sessionStorage.getItem('type');
    var logedin = sessionStorage.getItem('loged_in');
    if(!logedin || type !== 'store'){
      this.props.onLogoutReset();
      this.props.history.push("/store/login");
    }
    
  }
  componentDidUpdate(e) {
    if(this.state.isMounted){
      if (
        window.innerWidth < 993 &&
        e.history.location.pathname !== e.location.pathname &&
        document.documentElement.className.indexOf("nav-open") !== -1
      ) {
        document.documentElement.classList.toggle("nav-open");
      }
    }
   
  }

  componentWillUnmount(){
    this.setState({
      isMounted:false
    })
  }

  _onClick(){
    sessionStorage.setItem('token','');
    sessionStorage.setItem('loged_in',false);
    this.props.onStoreLogout();
  }

  handleSearch = (e) => {
    if(this.props.location.pathname.includes('customers')){
      this.props.onGetCustomers(0,12, e.target.value);
    }else{
      this.props.onGetStoreProducts(this.props.store.id,0,12, e.target.value);
      if('/store/products' != this.props.location.pathname){
        this.props.history.push("/store/products");
      } 
    }
    this.setState({term:e.target.value});
  }

  getPlaceholder = ()=>{
    if(this.props.location.pathname.includes('customers')){
      return 'Search Customers...'
    }else{
      return 'Search...'
    }
  }


  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if(!nextProps.storeloggedIn){
      this.props.onLogoutReset();
        this.props.history.push("/store/login");
    }
  }
 getLoder = () =>{
    let classes = "bt-loader ";
    classes += this.props.logoutLoading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() {

    if(!this.props.store) return null
    var store_id = this.props.store?this.props.store.id:0
    var channel_name = 'order-notification-channel-'+store_id

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "white"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
          <div className={this.getLoder()}></div>
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{this.getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <form className="header-form">
              <InputGroup className="no-border">
              <Input value={this.state.term} placeholder={this.getPlaceholder()} onChange = {(e)=>this.handleSearch(e)} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="icon-search-find" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
            <Nav navbar>
            {/* <UncontrolledDropdown className="notification-wrapper">
                <DropdownToggle nav>
                  <p className="bell-wrapper">
                  <i className="now-ui-icons icon-bell" />
                  <Badge />
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                <DropdownItem header>Notifications</DropdownItem>
                <DropdownItem divider />
                  <DropdownItem>
                      <div className="noti-wrapper">
                          <div className="img-w">
                          <img src={noti} alt="wild" className="left"/>
                          </div>
                          <p>
                          <span className="dark-text">David Lee  <span className="d-t">12min</span> </span>
                          <span>Lorem ipsum dolor sit amet Consectetur…</span>
                          </p>
                      </div>  
                  </DropdownItem>
                  <DropdownItem>
                  <div className="noti-wrapper">
                  <div className="img-w">
                          <img src={noti} alt="wild" className="left"/>
                          </div>
                          <p>
                          <span className="dark-text">Alex Johnson <span className="d-t">15min</span> </span>  
                          <span>Lorem ipsum dolor sit amet Consectetur…</span>
                          </p>
                      </div>  
                  </DropdownItem>
                  <DropdownItem>
                  <div className="noti-wrapper">
                  <div className="img-w">
                          <img src={noti} alt="wild" className="left"/>
                          </div>
                          <p>
                          <span className="dark-text">Jonathan Chen <span className="d-t">1 hour ago</span> </span>  
                          <span>Lorem ipsum dolor sit amet Consectetur…</span>
                          </p>
                      </div>  
                  </DropdownItem>
                  <DropdownItem header><Link to="/notifications" className="noti-link">View all notifications</Link></DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown> */}
                 <NavItem>
                  <p style={pStyle}>{this.props.store.title}</p>
              </NavItem>
                <UncontrolledDropdown>
                <DropdownToggle caret nav>
                  <p>
                  <span>{this.props.user.name !== null && typeof this.props.user.name !=='undefined'? this.props.user.name : 'Store Admin'}</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to="/store/myaccount">My account</Link>
                  </DropdownItem>
                  <DropdownItem onClick={this._onClick}>Logout</DropdownItem>
                </DropdownMenu>
                </UncontrolledDropdown>
              <NavItem>
                <Link to="/store/myaccount" className="nav-link avatar-link">
             
                  <img src={this.props.user.profile_pic !== null ? this.props.user.profile_pic :userAvatar}
                    alt="wild" className="avatar" />
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
        {/* <Sound
          url={soundfile}
          playStatus={this.state.playing? Sound.status.PLAYING:Sound.status.STOPPED}
          volume={100}
          autoLoad={true}
          onFinishedPlaying={()=>this.setState({playing:false})}
        /> */}
        <OrderNotification 
          loading={this.props.loading}
          history={this.props.history}
          channel_id={channel_name} isStore={true} store={this.props.store} onGetOrders={this.props.onGetStoreOrders}/>
      </Navbar>
    );
  }
}
StoreHeader.defaultProps ={
  user:{}
}
const mapStateToProps = (state) => ({
  storeloggedIn: state.storeUserReducer.storeloggedIn,
  user:state.storeUserReducer.user,
  logoutLoading:state.storeUserReducer.logoutLoading,
  store:state.storeUserReducer.store,
  loading:state.orderReducer.loading,
})


const mapDispatchToProps = (dispatch)=> ({
  onStoreLogout:()=>{
    dispatch(storeLogout())
  },
  onGetStoreProducts : (store_id,skip,take, query)=>{
    dispatch(getStoreProducts(store_id,skip,take, query))
  },
  onLogoutReset:()=> {
    dispatch(logoutReset())
  },
  onGetCustomers:(store_id,skip,take, query)=>{
    dispatch(getStoreCustomers(store_id,skip,take, query))
  },
  onGetStoreOrders:(data)=> {
    dispatch(getStoreOrders(data))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreHeader)


