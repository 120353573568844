import React from 'react'
import Select from "react-select";
import { connect } from 'react-redux';

import { getCustomerProducts } from '../../../actions/productActions';

class CustomerProductsSelect extends React.Component{

    componentDidMount(){
        this.onLoad()
    }

    onInputChange = (query)=>{
        if(query && query.length > 2){
            this.onLoad(query)
        }
    }

    onLoad = (query=null)=>{
        if(this.props.store){
            this.props.onGetCustomerProducts(this.props.store.id, 0, 10, query)
        }
    }

    render(){
        const {products, value, onChange} = this.props
        const options = products?products.map((product)=>{
            return {label:product.title, value:product}
        }):[]

        return(
            <Select
                options={options}
                onInputChange={this.onInputChange}
                value={value}
                onChange={onChange}/>
        )
    }
}

const mapStateToProps = (state) => ({
    store:state.storeUserReducer.store,
    products: state.productReducer.customerProducts
});

const mapDispatchToProps = (dispatch) => ({
    onGetCustomerProducts:(store, offset, limit, query=null)=>{
        dispatch(getCustomerProducts(store, offset, limit, query))
    },
});

export default connect(mapStateToProps,mapDispatchToProps)(CustomerProductsSelect);