import React,{Component} from 'react';
import {
Row,
Col,FormGroup,
InputGroup, InputGroupAddon, Input,
} from "reactstrap";
import { Button } from '../../../components';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import MultipleImage from "../../../components/imageUpload/MultipleImage";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select"; 
import { connect } from 'react-redux';
import {getUnits} from '../../../actions/unitActions';
import {getTags} from '../../../actions/tagActions';
import {editPack,getPack,onDismiss} from "../../../actions/OfferActions";
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class EditComboPack extends Component {
  _id= 0;
    constructor(props) {
        super(props);
        this.state = {
          products:[],
          isMounted: false,
          title:'',
          sku:'',
          barcode:'',
          images:[],
          stock_details:{stock:'',preorder_level:''},
          wholessale_price:'',
          selling_price:'',
          disc_perc:'',
          image:null,
          items:[{selected_item:'',qty:1}],
          selectedTag: [],
          selectedProduct: ' ',
          group_description:'',
          weight:'0',
          weight_unit:0,
          variation: 0,
          by:1
      }
      this.validator = new SimpleReactValidator();
      this.onDismissPopup = this.onDismissPopup.bind(this);
      this.handleChangeFrom = this.handleChangeFrom.bind(this);
      this.handleChangeTo = this.handleChangeTo.bind(this);
    } 
        componentDidMount() {
          var urls = this.props.match.url.split('/');
          this._id = urls[urls.length - 1];
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
        {this.props.onGetTags();
        this.props.onGetUnits();
        this.props.onGetPack(this._id);
        }
      }
      });
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.pack && typeof nextProps.pack.id !== 'undefined' ) {
        
            var items = nextProps.pack.pack_items.map(function(result) {
           return{selected_item:{ value: result.product.id, label: result.product.title},qty:result.qty};
         });
      
          var newtags = [];
          if(typeof nextProps.pack.tags !== 'undefined' ) {
              newtags = nextProps.pack.tags.map(function(tag) {return { value: tag.id, label: tag.tag};
           });
          }
          var images = nextProps.pack.images.map((img,i)=>{
            return {key : i, url:img.image, loaded:true, newImage:true}
          });
    
          this.setState({
            products:nextProps.pack.pack_items,
            title:nextProps.pack.title,
            sku:nextProps.pack.fields.sku,
            barcode:nextProps.pack.fields.barcode,
            group_description:nextProps.pack.description,
            images:images,
            stock_details:{stock:nextProps.pack.stock.stock_level,preorder_level:nextProps.pack.stock.preorder_level},
            selling_price:nextProps.pack.prices.selling_price,
            image:nextProps.pack.fields.featured_image,
            items:items,
            selectedTag:newtags,
            weight:nextProps.pack.weight,
            weight_unit:nextProps.pack.weight_unit
          })
        }
      }
  


      handleOffer = () => {
        this.setState({ haveOffer: !this.state.haveOffer });
      }
      onDismissPopup(){
        this.props.onDismiss();
        this.props.onGetPack(this._id);
      }
      handleTag  = (selectedTag) => {
        this.setState({ selectedTag });
      }
      handleProduct = (selectedProduct,i) => {
        var items = this.state.items;
        var item = items[i];
        item['selected_item'] = selectedProduct;
        items[i] = item;
        this.setState({ items });
      };
    handleChangeFrom(date) {
      this.setState({
        valid_from:date,
      });
    }
    handleChangeTo(date) {
      this.setState({
        valid_upto: date
      });
    }
    handleCheck = (week) => {
      this.setState({week});
    }
    handleLimit = () => {
      this.setState({ isActive: !this.state.isActive });
    }
    addProduct = () => {
      this.setState(prevState => ({ items: [...prevState.items, {"selected_item":null,"qty":1}]}))
    }
    deleteProduct = (e,i) => {
       let items = this.state.items;
       items.splice(i,1);
       this.setState({items});      
    }
    _onImagesChange = (images) => {
        this.setState({images})
    }
    _onImageChange = (image) => {
      this.setState({image})
    }
    handleDeal = (deal,type) => {
      if(type === 'today'){
        this.setState({isTodays:deal});
      }

      if(type === 'fantastic'){
        this.setState({isFantastic:deal});
      }
      
    }

    handleSubmit = (e) => {
      e.preventDefault();
      var itemObj = this.state.items.map((item,index)=>{
        return { item_id:item.selected_item.value,qty:item.qty };
      });
      var images =  this.state.images;
      images = images.map((img)=>{
          return {image:img.url}
      })
      var packs = [{
        title:this.state.title,
        base_unit:1,
        sku:this.state.sku,
        barcode:this.state.barcode,
        featured_image:this.state.image,
        images:images,
        weight:this.state.weight,
        weight_unit:this.state.weight_unit,
        stock_details:this.state.stock_details,
        description:this.state.group_description
      }];
      if(this.state.selectedTag.length > 0){
         
        var obj = this.state.selectedTag.map((item,index)=>{
          return { tag: item.label };
        });
        packs[0]['tags'] = obj;
      }
      packs[0]['items'] = itemObj;

      var prices = {
        wholessale_price:'',
        selling_price:this.state.selling_price,
      }
      packs[0]['prices'] =prices;

      var data = {
      store_id:this.props.store.id,
      item_id:0,
      }
      packs[0]['product_id']= this._id;
      data['packs'] =packs;

  
      if( this.validator.allValid() ){
      
        this.props.onEditPack(data);
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        }

    }
    //loader
    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }


    groupProductUI() {
      let productOptions = [];
   
        return    this.state.items.map((item,i) =>(
            <Row key ={i} className="form-group">
            <Col md="7">
            <Select  onChange={(e)=>this.handleProduct(e,i)} value={item.selected_item} options={productOptions} isDisabled={true} placeholder={"Select a Product"}  />
            </Col>
            <Col md="5">
            <input type="number" name="qty" value = {item.qty} onChange={(e)=>{
              var it = item;
              it['qty'] = parseInt(e.target.value);
              var items = this.state.items;
              items[i] = it;
                this.setState({items:items})
            }}  className="form-control" placeholder="Quantity" disabled />
            </Col>
            </Row>
        )) 
    }


    render() { 
      const { selectedTag } = this.state;
      let Tagoptions = this.props.tags.map(function (tag) {
        return { value: tag.tag, label: tag.tag };
      })
      let unitOptions = this.props.units.map(function (unit) {
        return { value: unit.id, label: unit.unit };
      })
        return ( 
            <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">  
            <form onSubmit={this.handleSubmit}>
                <div className="form-filds-wrapper">
                  <Row>
                  <Col md="12">
                      <div className="form-group">
                      <div className="flex-wrapper">
                        <FeaturedImage imageReset={this.state.image} onImageChange={(image)=>this._onImageChange(image)}>Featured Image</FeaturedImage>
                        <MultipleImage images= {this.state.images} imageReset={this.state.images} onImagesChange={(images) => this._onImagesChange(images)} isEdit={true}/>
                        </div>
                        <div className="errorMsg">{this.validator.message('image', this.state.image, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Combo Title</label>
                        <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})} className="form-control" placeholder="Mixed Fruit super combo" />
                        <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                  <Col md="4">
                      <div className="form-group">
                        <label className="control-label">SKU</label>
                        <input type="text" value={this.state.sku} onChange={(e)=>this.setState({sku:e.target.value})} className="form-control" placeholder="SKU" />
                        <div className="errorMsg">{this.validator.message('sku', this.state.sku, 'required')}</div>
                      </div>
                    </Col>  
                    <Col md="4">
                      <div className="form-group">
                        <label className="control-label"> Barcode</label>
                        <input type="text" value={this.state.barcode} onChange={(e)=>this.setState({barcode:e.target.value})} className="form-control" placeholder="Barcode" />
                        <div className="errorMsg">{this.validator.message('barcode', this.state.barcode, 'required')}</div>
                      </div>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                        <label className="control-label">Opening Stock</label>
                        <input type="number" className="form-control" value={this.state.stock_details.stock} onChange={(e)=>{
                            var it = this.state.stock_details;
                            it['stock'] = e.target.value;;
                            this.setState({stock_details:it})
                          }}/>
                        </FormGroup>
                      </Col>  
                      <Col md="4">
                      <FormGroup>
                      <label className="control-label">Low Stock Threshold</label>
                      <input type="number" className="form-control" placeholder="5 Unit" value={this.state.stock_details.preorder_level} onChange={(e)=>{
                            var it = this.state.stock_details;
                            it['preorder_level'] = e.target.value;;
                            this.setState({stock_details:it})
                          }}/>
                         </FormGroup>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Price variation by</label>
                        <InputGroup>
                        <InputGroupAddon addonType="prepend">
                        <Input type="select" name="select" id="variation" value={this.state.by} onChange={(e)=>{this.setState({by:e.target.value})}}>
                        <option value={1}>INR</option>
                        <option value={2}>%</option>
                        </Input>
                        </InputGroupAddon>
                        <Input type={"number"} value={this.state.variation} onChange={(e)=>this.setState({variation:e.target.value})} className="form-control" placeholder="Price in INR" />
                        <div className="errorMsg">{this.validator.message('variation', this.state.variation, 'required')}</div>
                        </InputGroup>
                      </div>
                      </Col>
                      <Col md="4">
                    <div className="form-group">
                        <label className="control-label">Weight</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                          <Input type="select" name="select" value={this.state.weight_unit}  onChange={(e)=>{this.setState({weight_unit: e.target.value })}}>
                          {unitOptions.map((opt,ind)=>(
                            <option value={opt.value} key={ind}>{opt.label}</option>
                          ))}
                          </Input>
                          </InputGroupAddon>
                          <Input type={'number'} value={this.state.weight} onChange={(e)=>this.setState({weight:e.target.value})} />
                          </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                      <Col md="12">
                        <div className="formg-group">
                        <label className="control-label">Products and Quantity</label>
                        </div>
                        <div className="combos">
                            <div className="form-group">
                          {this. groupProductUI()}
                     
                        </div>
                        </div>
                      </Col>
                  </Row>
                            
                  <Row>
                  
                      <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Tags</label>
                        <Select  onChange ={this.handleTag} value={selectedTag} options={Tagoptions} isMulti />
                      </div>
                      </Col>  
                      <Col md="12">
                      <div className="form-group">
                        <label className="control-label">Description</label>
                        <textarea name="description" value={this.state.group_description} onChange={(e) => { this.setState({ group_description: e.target.value }) }} className="form-control" rows="8"></textarea>
                      </div>
                      </Col>
                    </Row>
    
                  <Row>
                      <Col md="12">
                      <div className="submit-wrapper">
                      <Button className="btn-submit" >Save</Button>
                      </div>
                      </Col>
                  </Row>
                </div>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </form>
            </div>
          </div>
        </div>
         );

 
    }
}


EditComboPack.defaultProps = {
  tags: [],
  products:[],
  pack:[],
  store:{} 
}
const mapStateToProps = (state) => ({
  loading: state.PackReducer.loading,
  status:state.PackReducer.status,
  message:state.PackReducer.message,
  popup:state.PackReducer.popup,
  tags: state.tagReducer.tags,
  units:state.unitReducer.units,
  pack:state.PackReducer.pack,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onEditPack:(data)=>{
    dispatch(editPack(data))
  },
  onGetTags:()=>{
    dispatch(getTags())
  },
  onGetUnits:()=>{
    dispatch(getUnits())
  },
  onGetPack:(id) => {
    dispatch(getPack(id));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditComboPack)