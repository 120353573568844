import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  group:{}
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {

      case Actions.EDIT_PRODUCTS_LOADING:
      return {
        ...state,
        status:false,
        popup:false,
        loading: true,
      };
    case Actions.EDIT_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        status:true,
        message:'Edit Product Successfull',
        popup:true,
        group: action.group
      };
    case Actions.EDIT_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
        status:false,
        message:'Edit Product Failed',
        popup:true,
        error: action.error
      };

      case Actions.ADD_VARIANTS_LOADING:
      return {
        ...state,
        status:false,
        popup:false,
        loading: true,
      };
    case Actions.ADD_VARIANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        status:true,
        message:'New Product Added Successfull',
        popup:true,
        group: action.group
      };
    case Actions.ADD_VARIANTS_FAILED:
      return {
        ...state,
        loading: false,
        status:false,
        message:'New Product Add Failed',
        popup:true,
        error: action.error
      };


      case Actions.GROUP_STATUS_LOADING:
      return {
        ...state,
        loading:true,
        status: true,
        popup: false,
      };
    case Actions.GROUP_STATUS:
      return {
        ...state,
        loading:false,
        popup: true,
        status: true,
        message: "Successfull",
      };
    case Actions.GROUP_STATUS_FAILED:
      return {
        ...state,
        loading:false,
        popup: true,
        message: "Something went wrong",
        status: false,
        error: action.error
      };


      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
