import * as Actions from '../constants/Actions';
import CategoryService from '../services/CategoryService';

function addCategory(data){
    return dispatch =>{
        dispatch(request());
        CategoryService.addCategory(data)
        .then(res =>{
            var categories = res.data;
            dispatch(success(categories))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_CATEGORY_LOADING} }
    function success(categories) { return { type: Actions.ADD_CATEGORY,categories:categories} }
    function failed(error) { return { type: Actions.ADD_CATEGORY_FAILED, error:error} }
}

function editCategory (data) {
    return dispatch => {
        dispatch(request());
        CategoryService.editCategory(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_CATEGORY_LOADING} }
    function success() { return { type: Actions.EDIT_CATEGORY} }
    function failed(error) { return { type: Actions.EDIT_CATEGORY_FAILED, error:error} }
}

function getCategory(cat_id){
    return dispatch =>{
        dispatch(request());
        CategoryService.getCategory(cat_id)
        .then(res =>{
            var category = res.data;

            dispatch(success(category))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_CATEGORY_LOADING} }
    function success(category) { return { type: Actions.GET_CATEGORY, category: category} }
    function failed(error) { return { type: Actions.GET_CATEGORY_FAILED, error:error} }
}


function getCategories(skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        CategoryService.getCategories(skip,take)
        .then(res =>{
            var categories = res.data;
            var count = res.count;
            dispatch(success(categories,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_CATEGORIES_LOADING} }
    function success(categories,count) { return { type: Actions.GET_CATEGORIES, categories: categories,count:count} }
    function failed(error) { return { type: Actions.GET_CATEGORIES_FAILED, error:error} }
}

function delCategory (cat_id) {
    return dispatch => {
        dispatch(request());
        CategoryService.delCategory(cat_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            if(error.hasOwnProperty('data')){
                dispatch(failed(error.data))
            }else{
                dispatch(failed(error))
            }
             
        })
    }
    function request() { return { type: Actions.DELETE_CATEGORY_LOADING} }
    function success() { return { type: Actions.DELETE_CATEGORY} }
    function failed(error) { return { type: Actions.DELETE_CATEGORY_FAILED, error:error} }
}

function getAllCategories(store_id,skip = null,take=null) {
    return dispatch =>{
        dispatch(request());
        CategoryService.getAllCategories(store_id,skip,take)
        .then(res =>{
            var store_categories = res.data;
            var count = res.count;
            dispatch(success(store_categories,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_CATEGORIES_LOADING} }
    function success(store_categories,count) { return { type: Actions.GET_ALL_CATEGORIES, store_categories: store_categories,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_CATEGORIES_FAILED, error:error} }
}

function getLeafCategories(skip = null , take=null){
    return dispatch =>{
        dispatch(request());
        CategoryService.getLeafCategories(skip,take)
        .then(res =>{
            var categories = res.data;
            dispatch(success(categories))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_LEAF_CATEGORIES_LOADING} }
    function success(categories) { return { type: Actions.GET_LEAF_CATEGORIES, categories: categories} }
    function failed(error) { return { type: Actions.GET_LEAF_CATEGORIES_FAILED, error:error} }
 }


function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
    addCategory,
    editCategory,
    getCategories,
    getCategory,
    getLeafCategories,
    onDismiss,
    delCategory,
    getAllCategories
}