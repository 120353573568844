import React from 'react'
import Select from "react-select";
import { getStoreProducts, getAllProducts } from '../../../actions/productActions';
import { connect } from 'react-redux';

class StoreProductSelect extends React.Component{

    componentDidMount(){
        const {store} = this.props
        this.props.onGetProducts(store.id, 0, 15, '')

        this.state = {reflector:false}
    }

    onSearch = (query)=>{
        const {store} = this.props
        this.props.onGetProducts(store.id, 0, 15, query)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        return {...prevState, reflector:nextProps.reflector}
    }

    render(){
        const {value, onChange, products, ...props} = this.props
        let productOptions = products && products.map(function(product) {
            return { value: product.id, label: product.title };
        });
        return(
            <Select
                options={productOptions}
                onChange={onChange}
                value={value}
                onInputChange={this.onSearch}
                placeholder={"Select product"}
                {...props}/>
        )
    }
}

const mapStateToProps = state => ({
    products: state.productReducer.all_products,
    reflector: state.productReducer.reflector,
    store: state.storeUserReducer.store
});

const mapDispatchToProps = dispatch => ({
    onGetProducts: (store_id, skip, take, query) => {
        dispatch(getAllProducts(store_id, skip, take, query));
    },
});

export default connect(mapStateToProps,mapDispatchToProps)(StoreProductSelect);