import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  catLoading:false,
  cat_offers:[],
  offer:{}
};
export default function categoryOfferReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_CATEGORY_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_CATEGORY_OFFER:
      return {
        ...state,
        loading: false,
        message: "Category offer added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_CATEGORY_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Category offer add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_CATEGORY_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_CATEGORY_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: "Category offer edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_CATEGORY_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Category offer edit failed",
        status: false,
        error: action.error
      };

      case Actions.DELETE_CATEGORY_OFFER_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_CATEGORY_OFFER:
      return {
        ...state,
        loading: false,
        message:"Category offer delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_CATEGORY_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        message:"Category offer delete failed",
        popup: true,
        status: false
      };

     case Actions.GET_ALL_CATEGORY_OFFER_LOADING:
      return {
        catLoading: true,
        cat_offers: []
      };
    case Actions.GET_ALL_CATEGORY_OFFER:
      return {
        ...state,
        catLoading: false,
        cat_offers: action.cat_offers,
        count: action.count
      };
    case Actions.GET_ALL_CATEGORY_OFFER_FAILED:
      return {
        ...state,
        catLoading: false,
        cat_offers: [],
        error: action.error
      };
  
      case Actions.GET_CATEGORY_OFFER_LOADING:
      return {
        offer: {},
      loading:true,
      };
    case Actions.GET_CATEGORY_OFFER_SUCCESS:
      return {
        ...state,
        loading:false,
        offer: action.offer,
      };
    case Actions.GET_CATEGORY_OFFER_FAILED:
      return {
        ...state,
        offer: {},
        loading:false,
        error: action.error
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
