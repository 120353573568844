import React,{Component} from 'react';
import { Alert } from 'reactstrap';
import {Link} from 'react-router-dom';
import './Login.css';
import logo from "../../assets/img/logo.png";
import textLogo from "../../assets/img/logotwxt.png";
import { connect } from 'react-redux';
import {storeResetPass,onDismiss} from '../../actions/resetActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';

class ResetStorePassword extends Component {
  constructor(props){
    super(props);
    this.state = {
        new_password : '',
        c_password :''
    }
    this.validator = new SimpleReactValidator();
  }

   onDismissPopup = () =>{
    this.props.onDismiss();
    this.props.history.push("/store/login");
  }

  
  componentDidMount() {
    if(!this.props.user_id && !this.props.store_otp_res.otp) {
        this.props.history.push("/store/forgot");
    }
  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.status === true){
   this.setState({
    new_password : '',
    c_password :''
   });
    }
   }
  


getLoder() {
  let classes = "bt-loader ";
  classes += this.props.loading ? 'bt-show' : 'bt-hide';
  return classes;
}
  onResetPass(e)  {
    var data = {
        new_password:this.state.new_password,
        c_password:this.state.c_password,
        user_id:this.props.user_id,
        otp:this.props.store_otp_res.otp
    }
    e.preventDefault();
    if( this.validator.allValid() && this.state.new_password === this.state.c_password  ){
       this.props.OnResetPassword(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 
    render() {
        return(
              <div className="login-wrapper">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="bg-white col-md-6 order-2">
          <div className="login-form-wrapper width-cst">
            <div className="login-box">
              <div className="logo">
                <img src={textLogo} alt="wild" className="img-fluid" />
              </div>
              <div className="login-dis">
                Enter your new password here
              </div>

              <form className="form-vertical">
                <div className="form-group">
                  <input type="text" onChange={event => this.setState({new_password:event.target.value})}  placeholder="New Password" className="form-control" />
                  <div className="errorMsg">{this.validator.message('New Password', this.state.new_password, 'required')}</div>
                </div>
                <div className="form-group">
                  <input type="text" onChange={event => this.setState({c_password:event.target.value})}  placeholder="Confirm New Password" className="form-control" />
                  <div className="errorMsg">{this.validator.message('Confirm Password', this.state.c_password, 'required')}</div>
                  <div className="errorMsg"> {this.state.new_password !== this.state.c_password ? 'Password mismatched' : null }</div>
                </div>

                <div className="form-group row">

                  <div className="col-md-12">
                    <div className="forgot text-right">
                    <Link className="d-block small" to="/store/login">Back to home</Link>
                    </div>
                  </div>
                </div>

                <div className="bt-wrapper">
                  <button onClick ={(e)=>this.onResetPass(e)} className="waves-effect waves-light btn">Submit</button>
                  <span className={this.getLoder()}></span>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="bg-yellow col-md-6 order-1">
          <div className="img-logo width-cst">
            <img src={logo} alt="wild" className="img-fluid" />
          </div>
        </div>
      </div>
      <SweetAlert
        show={this.props.status && this.props.popup}
        title="Success"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
        <SweetAlert
        show={ !this.props.status && this.props.popup}
        title="Error"
        type="error"
        text={this.props.message}
        onConfirm={this.onDismissPopup}
        />
    </div>
  </div>
        )
    }
}
ResetStorePassword.deaultProps = {
  error:[],
  store_otp_res:{}  
}
const mapStateToProps = (state) => ({
  loading: state.resetReducer.loading,
  status:state.resetReducer.status,
  popup:state.resetReducer.popup,
  message:state.resetReducer.message,
  error:state.resetReducer.error,
  user_id:state.resetReducer.user_id,
  store_otp_res:state.resetReducer.store_otp_res
})

const mapDispatchToProps = (dispatch)=> ({
  OnResetPassword:(data)=>{
    dispatch(storeResetPass(data))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetStorePassword)




