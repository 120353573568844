import React,{Component} from 'react';
import { Alert } from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import {Link} from 'react-router-dom';
import './Login.css';
import logo from "../../assets/img/logo.png";
import textLogo from "../../assets/img/logotwxt.png";
import { connect } from 'react-redux';
import {storeLogin,setUserModel,logoutReset} from '../../actions/userActions';
import * as Constant from '../../constants/AppConstants';
import SimpleReactValidator from 'simple-react-validator';
class StoreLogin extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile : '',
      password : '',
      errors :[],
      isLoading:false,
      type: 'password'
    }
    this.validator = new SimpleReactValidator();
  }

  showHide = () => this.setState(({type}) => ({
    type: type === 'text' ? 'password' : 'text'
  }))

  componentDidMount(){
    this.props.setUserModel(Constant.USER_TYPE_STORE);
    setTimeout(()=>{
      this.props.onLogoutReset();
    },100)
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
   if( nextProps.storeloggedIn && nextProps.token != null){
    var type = sessionStorage.getItem('type');
    var logedin = sessionStorage.getItem('loged_in');
    if(logedin && type === 'store'){
      this.props.history.push("/store");
    }

   }
  }
  

getLoder() {
  let classes = "bt-loader ";
  classes += this.props.loading ? 'bt-show' : 'bt-hide';
  return classes;
}
  signUp(e)  {
    e.preventDefault();
    if( this.validator.allValid() ){
      this.props.onStoreLogin(this.state.mobile,this.state.password);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }   
   
  } 
    render() {
        return(
              <div className="login-wrapper">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="bg-white col-md-6 order-2">
          <div className="login-form-wrapper width-cst">
            <div className="login-box">
              <div className="logo">
                <img src={textLogo} alt="wild" className="img-fluid" />
              </div>
              <div className="login-dis">
                Welcome Back! Please login to your account.
              </div>

              <form className="form-vertical">
                <div className="login-message">
                <Alert color={this.props.loggedIn ?"success" : "danger"}>
                {this.props.loggedIn ? 'logined succesfully' : this.props.error}
                </Alert>
                </div>
                <div className="form-group">
                <PhoneInput defaultCountry="IN" type="text" onChange={event => this.setState({mobile:event})}  placeholder="Username" className="form-control" />
                  <div className="errorMsg">{this.validator.message('username', this.state.mobile, 'required|phone')}</div>
                </div>
                <div className="form-group">
                  <input type={this.state.type}  onChange ={event=> this.setState({password:event.target.value})} placeholder="Password" className="form-control" />
                  <i className={this.state.type != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                  onClick={this.showHide}></i>
                  <div className="errorMsg">{this.validator.message('password', this.state.password, 'required')}</div>
                </div>
                <div className="form-group row">

                  <div className="col-md-12">
                    <div className="forgot text-right">
                    <Link className="d-block small" to="/store/forgot">Forgot Password?</Link>
                    </div>
                  </div>
                </div>

                <div className="bt-wrapper">
                  <button onClick ={(e)=>this.signUp(e)} className="waves-effect waves-light btn">Login</button>
                  <span className={this.getLoder()}></span>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="bg-yellow col-md-6 order-1">
          <div className="img-logo width-cst">
            <img src={logo} alt="wild" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
        )
    }
}

const mapStateToProps = (state) => ({
  title: state.storeUserReducer.title,
  loading: state.storeUserReducer.loading,
  storeloggedIn: state.storeUserReducer.storeloggedIn,
  user: state.storeUserReducer.user,
  token: state.storeUserReducer.token,
  error:state.storeUserReducer.error
})

const mapDispatchToProps = (dispatch)=> ({
  onStoreLogin:(mobile,password)=>{
    dispatch(storeLogin(mobile,password))
  },
  setUserModel:(model) =>{
    dispatch(setUserModel(model));
  },
  onLogoutReset:()=>{
    dispatch(logoutReset())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(StoreLogin)





