import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';
function addUnit(data) {
    return request({
      url:   URLS.ADD_UNIT,
      method: 'POST',
      data:data
    });
}

function getUnits(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_UNITS + sub_url,
      method: 'GET'
    });
}

function editUnit(data) {
    return request({
      url:   URLS.EDIT_UNIT,
      method: 'POST',
      data:data
    });
}

const UnitService = {
    addUnit,
    editUnit,
    getUnits
}

export default UnitService;

