import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  faLoading:false,
  fa_deals:[]
};

export default function fantasticReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_FANTASTIC_DEAL_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_FANTASTIC_DEAL:
      return {
        ...state,
        loading: false,
        message: "Fantastic deal added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_FANTASTIC_DEAL_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Fantastic deal add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_FANTASTIC_DEAL_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_FANTASTIC_DEAL:
      return {
        ...state,
        loading: false,
        message: "Fantastic deal edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_FANTASTIC_DEAL_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Fantastic deal edit failed",
        status: false,
        error: action.error
      };

      
      case Actions.DELETE_FANTASTIC_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_FANTASTIC:
      return {
        ...state,
        loading: false,
        message:"Fantastic deal deleted successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_FANTASTIC_FAILED:
      return {
        ...state,
        loading: false,
        message:"Fantastic deal delete failed",
        popup: true,
        status: false
      };

      case Actions.GET_FANTASTIC_DEALS_LOADING:
      return {
        faLoading: true,
        fa_deals: []
      };
    case Actions.GET_FANTASTIC_DEALS:
      return {
        ...state,
        faLoading: false,
        fa_deals: action.fa_deals,
        count: action.count
      };
    case Actions.GET_FANTASTIC_DEALS_FAILED:
      return {
        ...state,
        faLoading: false,
        fa_deals: [],
        error: action.error
      };



      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };
    default:
      return state;
  }
}
