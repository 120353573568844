import React, { Component } from 'react';
import { Input,Table } from "reactstrap";
import { Button } from "../../components";
import SimpleReactValidator from 'simple-react-validator';
class TextSwatches extends Component {
constructor(props) {
    super(props);
    this.state = {
        attribute_values:this.props.attribute_values
    }
    this.validator = new SimpleReactValidator();

}
addText = () => {
    var obj = {value:"",value_code:'',attribute_id:typeof this.state.attribute_values[0] !== 'undefined'? this.state.attribute_values[0].attribute_id:0}
    this.setState(prevState => ({ attribute_values: [...prevState.attribute_values, obj]}))
}

deleteText = (i,e) => {
    let attrbute_values =this.state.attribute_values;
    attrbute_values.splice(i,1);
    this.setState({attrbute_values},()=>{
        this.props.onChangeText(this.state.attribute_values)
    });
    this.validator = new SimpleReactValidator();
}

validate = ()=>{
    if( this.validator.allValid() ){
        return true
    }else{
        this.validator.showMessages();
        this.forceUpdate();
        return false
    }
}
reset = ()=>{
    this.setState({
        attribute_values:[]
    })
}

handleChang = (e,i) => {
let attribute_values = this.state.attribute_values;
var obj = {};
if (attribute_values.length>i){
    obj =attribute_values[i];
}
if(e.target.name === "value" ) {
    obj['value'] = e.target.value;
}
if(e.target.name === "value_code") {
    obj['value_code'] = e.target.value;
}
attribute_values[i] = obj;

this.setState({attribute_values},
    ()=>{
        this.props.onChangeText(this.state.attribute_values); }
    );
}
componentWillReceiveProps(nextProps) {
    if(nextProps.attribute_values && nextProps.isEditing){
        this.setState({attribute_values:nextProps.attribute_values})
    }
}

textUI () {
    const { disableInput } = this.props
    return  this.state.attribute_values.map((attr,i)=>(
        <tr key={i}>
        <td>
            <Input disabled={disableInput} type="text" name="value" value={attr.value} onChange={(e)=>this.handleChang(e,i)} placeholder="Attribute name" />
            <div className="errorMsg"> {this.validator.message('name', attr.value, 'required')}</div>
        </td>
        <td>
            <Input type="text" disabled={disableInput} name="value_code" value={attr.value_code} onChange={(e)=>this.handleChang(e,i)} placeholder="Type attribute symbol" />
            <div className="errorMsg"> {this.validator.message('code', attr.value_code, 'required')}</div>
        </td>
        <td>
        <Button className="btn-tsp" onClick={(e)=>this.deleteText(i,e) } leftLabel="fa red fa-times"></Button>
        </td>
        </tr>
    ))

}

    render() { 
        return ( 
            <React.Fragment>
            <Table>
            <thead>
            <tr>
            <th>Variant Name</th>
            <th>Variant Code</th>
            <th></th>
            </tr>
            </thead>
            <tbody>
            {this.textUI()}
            </tbody>
            </Table>
             {this.props.disableAdd ? null:
             <Button className="btn-plus" onClick = {()=>this.addText()} leftLabel="fa fa-plus">ADD VARIANT</Button>}
             </React.Fragment>
         );
    }
}

TextSwatches.defaultProps ={
    attribute_values:[],
    isEditing:false,
    disableAdd: false
}
 
export default TextSwatches;