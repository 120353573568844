import React, { Component } from 'react';
import { Table} from "reactstrap";
import { Button } from '../../../components';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {getAllVolumeOffers,delVolumeOffer,onDismiss} from '../../../actions/OfferActions';
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
class VolumeOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMounted: false,
          volLoading:false,
          skip: 0,
          limit:12
        };
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      componentDidMount () {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
         this.setState({ isMounted: false });
      }
      });
      setTimeout(()=>{
        this.props.OnGetOffers(this.props.store.id,this.state.skip,this.state.limit);
       },100);
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetCategories(skip,this.state.limit);
      }
      onDismissPopup () {
        this.props.onDismiss();
        {this.props.OnGetOffers(this.props.store.id);}
      }
  
      trimTime (dateString) {
          dateString = new Date(dateString).toUTCString();
          dateString = dateString.split(' ').slice(0, 4).join(' ');
          return dateString;
      }
      handleDelete(e,id) {
        e.preventDefault();
        this.props.onDeleteOffers(id);
      }
      getLoder = () =>{
        let classes = "bt-loader ";
        classes += this.props.volLoading ? 'bt-show' : 'bt-hide';
        return classes;
      }
    render() { 
      const {volumes} = this.props;
        return (
            <div>
                  <div className="content">
                  <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>SI.NO</th>
                        <th>OFFER NAME</th>
                        <th>DISCOUNT</th>
                        <th>PRICE</th>
                        <th>PRICE UPTO</th>
                        <th>DATE FROM</th>
                        <th>DATE TO</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                    {volumes.length>0?volumes.map((volume,index) => (
                        <tr key={index}>
                        <td>{index+1+ this.state.skip}</td>
                        <td>{volume.title}</td>
                        <td>{volume.disc_perc}%</td>
                       
                        <td>{volume.vol_amount}</td>
                        <td>{volume.disc_upto}</td>
                        <td>{moment(volume.valid_from).format('DD-MM-YYYY')}</td>
                        <td>{moment(volume.valid_upto).format('DD-MM-YYYY')}</td>
                        <td>
                          <div className="group-action">
                            <Link className="btn" to={`/store/edit-volume-offer/${volume.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,volume.id))}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'7'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
                  <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
                </div>
                <Button className="fixed-yellow" onClick={() => this.props.history.push("/store/add-volume-offer")}>Add</Button>
            
              </div>
            </div>
         );
    }
}
VolumeOffer.defaultProps = {
  volumes:[]
}

const mapStateToProps = (state) => ({
  volLoading: state.volumeOfferReducer.volLoading,
  volumes: state.volumeOfferReducer.volumes,
  loading: state.volumeOfferReducer.loading,
  status:state.volumeOfferReducer.status,
  message:state.volumeOfferReducer.message,
  popup:state.volumeOfferReducer.popup,
  count:state.volumeOfferReducer.count,
  store:state.storeUserReducer.store
})
const mapDispatchToProps = (dispatch)=> ({
  OnGetOffers : (store_id,skip,take) => {
    dispatch(getAllVolumeOffers(store_id,skip,take));
  },
  onDeleteOffers:(vol_id)=>{
    dispatch(delVolumeOffer(vol_id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(VolumeOffer)