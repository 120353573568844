import React, { Component } from 'react';
import { FormGroup,Row,Col,Input,Label } from "reactstrap";
import { Button } from '../../../components';
import Select from "react-select";
import ColourSwatches from '../../../components/swatches/Colour';
import TextSwatches from '../../../components/swatches/Text';
import {connect} from 'react-redux';
import {addVariant,onDismiss} from '../../../actions/variantActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
const options = [
    { value: "Colour", label: "Color",isColour:true },
    { value: "Text", label: "Text",isColour:false }
  ];
class AddVariant extends Component {
    constructor (){
        super();
        this.state = {
            isColour:true,
            value: "Colour",
            attribute:'',
            code:'',
            attribute_values:[],
            isFormValidationErrors : true,
            submitted:false
        }
        this.onDismissPopup = this.onDismissPopup.bind(this);
        this.validator = new SimpleReactValidator();
        this.child = React.createRef();
        this.child2 = React.createRef();
    }
    onDismissPopup(){
        if(this.props.status){
            if (this.state.isColour )
            {
                this.child.current.reset();
            }else{
                this.child2.current.reset();
            }
           
        }
        this.props.onDismiss();
      }
    handleSubmit = (e) => {
        e.preventDefault();
        var type=1;
        var validation = false;
        if (this.state.isColour )
        {
            type=2;
            validation = this.child.current.validate();
        }
        else {
            type=1
            validation = this.child2.current.validate();
        }


        
       
        var data = {
          code: this.state.code,
          attribute: this.state.attribute,
          type:type,
          attribute_values: this.state.attribute_values
        };
        if( this.validator.allValid() && validation){
            this.props.onAddAttribute(data);
          }
          else {
            this.validator.showMessages();
            this.forceUpdate();
          }  
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.status === true) {
            this.setState({
                attribute:'',
                code:'',
                attribute_values:[],
                attribute:'',
               });
        }
    }

handleChange = (selectedOption) => {
    if(selectedOption.value == 'Colour') {
        this.setState({ isColour:true, attribute_values:[] });
    }
   else {
    this.setState({ isColour:false, attribute_values:[] });
   }
  }

  getLoder=()=> {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }

    render() { 
     
        const { selectedOption } = this.state;
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className="form-section">
                <form onSubmit ={(e)=>this.handleSubmit(e)}>
                <div className="form-border-wrapper">
                <Row className="pad">
                        <Col md="4">
                        <FormGroup>
                          <Label className="control-label">Variant Name</Label>
                          <Input type="text" value={this.state.attribute} onChange={(e)=>this.setState({attribute:e.target.value})} placeholder="Type here"/>
                          <div className="errorMsg">{this.validator.message('attribute', this.state.attribute, 'required')}</div>
                        </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                          <Label className="control-label">Variant Type</Label>
                          <Select value={selectedOption} onChange={this.handleChange} options={options} defaultValue={[options[0]]} />
                        </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                          <Label className="control-label">Variant Code</Label>
                          <Input type="text" value={this.state.code} onChange={(e)=>this.setState({code:e.target.value})} placeholder="Type here" />
                          <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                        </FormGroup>
                        </Col>
                        <Col md="8">
                            <div className="inner-table-wrapper">
                            {this.state.isColour ? this.renderColourSelect() :this.renderTextSelect()}
                            <div className="errorMsg">{this.validator.message('attribute value', this.state.attribute_values[0] , 'required')}</div>
                           
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="submit-wrapper">
                <Button className="btn-submit" >Save</Button>
                </div>
                </form>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </div>
              
                </div>
            </div>
         )
    }
    renderColourSelect(){
        return (
            <ColourSwatches onChangeColor={(value)=>this.addColor(value)} ref={this.child}/>
        )
    }

    addColor= (value)=>{
        this.setState({
            attribute_values:value
        })
    }

    addText = (value) => {
        this.setState({
            attribute_values:value
        });
    }
    
    renderTextSelect (){
        return (
           <TextSwatches onChangeText={(value)=>this.addText(value)} ref={this.child2}/>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.variantReducer.loading,
    status:state.variantReducer.status,
    message:state.variantReducer.message,
    popup:state.variantReducer.popup
});

const mapDispatchToProps = (dispatch) => ({
    onAddAttribute:(data)=>{
        dispatch(addVariant(data))
      },
      onDismiss:()=>{
        dispatch(onDismiss())
      }
});

export default connect (mapStateToProps,mapDispatchToProps)(AddVariant);
 
