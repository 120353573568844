import React,{Component} from 'react';
import { Alert } from 'reactstrap';
import {Link} from 'react-router-dom';
import useSound from 'use-sound';
import PhoneInput from 'react-phone-number-input'

import './Login.css';
import logo from "../../assets/img/logo.png";
import textLogo from "../../assets/img/logotwxt.png";
import { connect, useDispatch } from 'react-redux';
import {login, setUserModel,logoutReset, setSound} from '../../actions/userActions'
import SimpleReactValidator from 'simple-react-validator';
import * as Constant from '../../constants/AppConstants';
import ring from '../../assets/sound/ring.mp3';

function SoundLogin({onPress, onSetSound}){
  // const [play, { sound }] = useSound(ring, {
  //   interrupt: false,
  //   soundEnabled: true,
  //   volume: 1
  // });

  const onClick = (e)=>{
    onPress(e)
  }
  return <button onClick ={onClick} className="waves-effect waves-light btn">Login</button>
}

class Login extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile : '',
      password : '',
      type: 'password',
      errors :[],
      isLoading:false,
    }
    this.validator = new SimpleReactValidator();
  }



componentDidMount() {
  this.props.setUserModel(Constant.USER_TYPE_ADMIN);
  setTimeout(()=>{
    this.props.onLogoutReset();
  },100)
}

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
   
   if( nextProps.loggedIn && nextProps.token != null){
    var type = sessionStorage.getItem('type');
    var logedin = sessionStorage.getItem('loged_in');
    if(logedin && type === 'admin'){
      this.props.history.push("/admin");
    }
    
   }
  }
  
  showHide = () => this.setState(({type}) => ({
    type: type === 'text' ? 'password' : 'text'
  }))

getLoder() {
  let classes = "bt-loader ";
  classes += this.props.loading ? 'bt-show' : 'bt-hide';
  return classes;
}
  signUp(e)  {
    e.preventDefault();
    if( this.validator.allValid() ){
      this.props.onLogin(this.state.mobile,this.state.password);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    } 
  } 
    render() {
        return(
              <div className="login-wrapper">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="bg-white col-md-6 order-2">
          <div className="login-form-wrapper width-cst">
            <div className="login-box">
              <div className="logo">
                <img src={textLogo} alt="wild" className="img-fluid" />
              </div>
              <div className="login-dis">
                Welcome Back! Please login to your account.
              </div>

              <form className="form-vertical">
                <div className="login-message">
                <Alert color={this.props.loggedIn ?"success" : "danger"}>
                {this.props.loggedIn ? 'logined succesfully' : this.props.error}
                </Alert>
                </div>
                <div className="form-group">
                <PhoneInput defaultCountry="IN" type="text" onChange={event => this.setState({mobile:event})}  placeholder="Username" className="form-control" />
                  <div className="errorMsg">{this.validator.message('username', this.state.mobile, 'required|phone')}</div>
                </div>
                <div className="form-group">
                  <input type={this.state.type}  onChange ={event=> this.setState({password:event.target.value})} placeholder="Password" className="form-control" />
                  <i className={this.state.type != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                  onClick={this.showHide}></i>
                  <div className="errorMsg">{this.validator.message('password', this.state.password, 'required')}</div>
                </div>
                <div className="form-group row">

                  <div className="col-md-12">
                    <div className="forgot text-right">
                    <Link className="d-block small" to="/admin/forgot">Forgot Password?</Link>
                    </div>
                  </div>
                </div>

                <div className="bt-wrapper">
                  <SoundLogin onPress ={(e)=>this.signUp(e)} onSetSound={this.props.onSetSound}/>
                  <span className={this.getLoder()}></span>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="bg-yellow col-md-6 order-1">
          <div className="img-logo width-cst">
            <img src={logo} alt="wild" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
        )
    }
}
Login.deaultProps = {
  error:[]
}
const mapStateToProps = (state) => ({
  title: state.userReducer.title,
  loading: state.userReducer.loading,
  loggedIn: state.userReducer.loggedIn,
  user: state.userReducer.user,
  token: state.userReducer.token,
  error:state.userReducer.error
})

const mapDispatchToProps = (dispatch)=> ({
  onLogin:(mobile,password)=>{
    dispatch(login(mobile,password))
  },
  setUserModel:(model)=>{
    dispatch(setUserModel(model))
  },
  onLogoutReset:()=>{
    dispatch(logoutReset())
  },
  onSetSound:(sound)=>{
    dispatch(setSound(sound))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)




