import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  proLoading:false,
  product_offers:[],
  ProductOffer:{}
};

export default function productOfferReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_PRODUCT_OFFER_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_PRODUCT_OFFER:
      return {
        ...state,
        loading: false,
        message: "Product offer added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_PRODUCT_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Product offer add failed",
        status: false,
        error: action.error
      };


      case Actions.EDIT_PRODUCT_OFFER_LOADING:
      return {
        loading: true,
        popup: false,
      };
    case Actions.EDIT_PRODUCT_OFFER:
      return {
        ...state,
        loading: false,
        message: "Product offer edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_PRODUCT_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Product offer edit failed",
        status: false,
        error: action.error
      };



      case Actions.DELETE_PRODUCT_OFFER_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_PRODUCT_OFFER:
      return {
        ...state,
        loading: false,
        message:"Product offer delete successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_PRODUCT_OFFER_FAILED:
      return {
        ...state,
        loading: false,
        message:"Product offer delete failed",
        popup: true,
        status: false
      };

      case Actions.GET_ALL_PRODUCT_OFFER_LOADING:
      return {
        proLoading: true,
        product_offers: []
      };
    case Actions.GET_ALL_PRODUCT_OFFER:
      return {
        ...state,
        proLoading: false,
        product_offers: action.product_offers,
        count: action.count
      };
    case Actions.GET_ALL_PRODUCT_OFFER_FAILED:
      return {
        ...state,
        proLoading: false,
        product_offers: [],
        error: action.error
      };

      case Actions.GET_PRODUCT_OFFER_LOADING:
      return {
        ProductOffer: {},
      loading:true,
      };
    case Actions.GET_PRODUCT_OFFER:
      return {
        ...state,
        loading:false,
        ProductOffer: action.ProductOffer,
      };
    case Actions.GET_PRODUCT_OFFER_FAILED:
      return {
        ...state,
        ProductOffer: {},
        loading:false,
        error: action.error
      };


      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
