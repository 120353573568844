import * as Actions from '../constants/Actions';
import FaqService from '../services/FaqService';

function addFaq(data){
    return dispatch =>{
        dispatch(request());
        FaqService.addFaq(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_FAQ_LOADING} }
    function success(data) { return { type: Actions.ADD_FAQ_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADD_FAQ_FAILED, error:error} }
}

function getFaqs(){
    return dispatch =>{
        dispatch(request());
        FaqService.getFaqs()
        .then(res =>{
            var faqs = res.data;
            dispatch(success(faqs))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_FAQ_LOADING} }
    function success(faqs) { return { type: Actions.GET_FAQ_SUCCEESS, faqs: faqs} }
    function failed(error) { return { type: Actions.GET_FAQ_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}


function addFaqDis (data) {
    return dispatch =>{
        dispatch(request());
        FaqService.addFaqDis(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_FAQ_DIS_LOADING} }
    function success(data) { return { type: Actions.ADD_FAQ_DIS_SUCCESS,data:data} }
    function failed(error) { return { type: Actions.ADD_FAQ_DIS_FAILED, error:error} }
}

function delFaqs (faq_id) {
    return dispatch => {
        dispatch(request());
        FaqService.delFaqs(faq_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_FAQ_LOADING} }
    function success() { return { type: Actions.DELETE_FAQ_SUCCESS} }
    function failed(error) { return { type: Actions.DELETE_FAQ_FAILED, error:error} }
}

function delFaqDis (faq_description_id) {
    return dispatch => {
        dispatch(request());
        FaqService.delFaqDis(faq_description_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_FAQ_DIS_LOADING} }
    function success() { return { type: Actions.DELETE_FAQ_DIS_SUCCESS} }
    function failed(error) { return { type: Actions.DELETE_FAQ_DIS_FAILED, error:error} }
}




export {
    addFaq,
    getFaqs,
    delFaqs,
    delFaqDis,
    onDismiss,
    addFaqDis
};