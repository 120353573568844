import * as Actions from '../constants/Actions';
import OfferService from '../services/OfferService';

//add volume offer
function addVolumeOffer(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addVolumeOffer(data)
        .then(res =>{
            var volumes = res.data;
            dispatch(success(volumes))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_VOLUME_OFFER_LOADING} }
    function success(volumes) { return { type: Actions.ADD_VOLUME_OFFER,volumes:volumes} }
    function failed(error) { return { type: Actions.ADD_VOLUME_OFFER_FAILED, error:error} }
}

//add brand offer

function addBrandOffer(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addBrandOffer(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_BRAND_OFFER_LOADING} }
    function success(data) { return { type: Actions.ADD_BRAND_OFFER,data:data} }
    function failed(error) { return { type: Actions.ADD_BRAND_OFFER_FAILED, error:error} }
}
//add category offer

function addCategoryOffer(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addCategoryOffer(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_CATEGORY_OFFER_LOADING} }
    function success(data) { return { type: Actions.ADD_CATEGORY_OFFER,data:data} }
    function failed(error) { return { type: Actions.ADD_CATEGORY_OFFER_FAILED, error:error} }
}

//add product offer
function addProductOffer(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addProductOffer(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_PRODUCT_OFFER_LOADING} }
    function success(data) { return { type: Actions.ADD_PRODUCT_OFFER,data:data} }
    function failed(error) { return { type: Actions.ADD_PRODUCT_OFFER_FAILED, error:error} }
}

//add pack offer

function addPack(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addPack(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_PACK_LOADING} }
    function success(data) { return { type: Actions.ADD_PACK,data:data} }
    function failed(error) { return { type: Actions.ADD_PACK_FAILED, error:error} }
}

function addStorePack(data){
    return dispatch =>{
        dispatch(request());
        OfferService.addStorePack(data)
        .then(res =>{
            var data = res.data;
            dispatch(success(data))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.ADD_PACK_LOADING} }
    function success(data) { return { type: Actions.ADD_PACK,data:data} }
    function failed(error) { return { type: Actions.ADD_PACK_FAILED, error:error} }
}




//get volume offers
function getAllVolumeOffers(store_id,skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        OfferService.getAllVolumeOffers(store_id,skip,take)
        .then(res =>{
            var volumes = res.data;
            var count = res.count;
            dispatch(success(volumes,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_VOLUME_OFFER_LOADING} }
    function success(volumes,count) { return { type: Actions.GET_ALL_VOLUME_OFFER, volumes: volumes,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_VOLUME_OFFER_FAILED, error:error} }
}

//GET BRANDS OFFERS
function getAllBrandOffers(store_id,skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        OfferService.getAllBrandOffers(store_id,skip,take)
        .then(res =>{
            var brand_offers = res.data;
            var count = res.count;
            dispatch(success(brand_offers,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_BRAND_OFFER_LOADING} }
    function success(brand_offers,count) { return { type: Actions.GET_ALL_BRAND_OFFER, brand_offers: brand_offers,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_BRAND_OFFER_FAILED, error:error} }
}

function onDismiss(){
    return dispatch =>{
        dispatch(success())
    }
    function success() { return { type: Actions.ALERT_DISMISS} }
}
//GET ALL PRODUCT OFFERS

function getAllProductOffers(store_id,skip = null , take=null){
    return dispatch =>{
        dispatch(request());
        OfferService.getAllProductOffers(store_id,skip,take)
        .then(res =>{
            var product_offers = res.data;
            var count = res.count;
            dispatch(success(product_offers,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_PRODUCT_OFFER_LOADING} }
    function success(product_offers,count) { return { type: Actions.GET_ALL_PRODUCT_OFFER, product_offers: product_offers,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_PRODUCT_OFFER_FAILED, error:error} }
}

//GET ALL CATEGORY OFFERS
function getAllCategoryOffers(store_id,skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        OfferService.getAllCategoryOffers(store_id,skip,take)
        .then(res =>{
            var cat_offers = res.data;
            var count = res.count;
            dispatch(success(cat_offers,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_CATEGORY_OFFER_LOADING} }
    function success(cat_offers,count) { return { type: Actions.GET_ALL_CATEGORY_OFFER, cat_offers: cat_offers,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_CATEGORY_OFFER_FAILED, error:error} }
}

//GET ALL pack OFFERS
function getAllPacks(store_id,skip = null , take=null){

    return dispatch =>{
        dispatch(request());
        OfferService.getAllPacks(store_id,skip,take)
        .then(res =>{
            var packs = res.data;
            var count = res.count;
            dispatch(success(packs,count))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_ALL_PACKS_LOADING} }
    function success(packs,count) { return { type: Actions.GET_ALL_PACKS, packs: packs,count:count} }
    function failed(error) { return { type: Actions.GET_ALL_PACKS_FAILED, error:error} }
}


//delete offer

function delVolumeOffer (vol_id) {
    return dispatch => {
        dispatch(request());
        OfferService.delVolumeOffer(vol_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_VOLUME_OFFER_LOADING} }
    function success() { return { type: Actions.DELETE_VOLUME_OFFER} }
    function failed(error) { return { type: Actions.DELETE_VOLUME_OFFER_FAILED, error:error} }
}

function delProductOffer (pro_id) {
    return dispatch => {
        dispatch(request());
        OfferService.delProductOffer(pro_id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_PRODUCT_OFFER_LOADING} }
    function success() { return { type: Actions.DELETE_PRODUCT_OFFER} }
    function failed(error) { return { type: Actions.DELETE_PRODUCT_OFFER_FAILED, error:error} }
}

function delCategoryOffer (id) {
    return dispatch => {
        dispatch(request());
        OfferService.delCategoryOffer(id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_CATEGORY_OFFER_LOADING} }
    function success() { return { type: Actions.DELETE_CATEGORY_OFFER} }
    function failed(error) { return { type: Actions.DELETE_CATEGORY_OFFER_FAILED, error:error} }
}

function delBrandOffer (id) {
    return dispatch => {
        dispatch(request());
        OfferService.delBrandOffer(id).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.DELETE_BRAND_OFFER_LOADING} }
    function success() { return { type: Actions.DELETE_BRAND_OFFER} }
    function failed(error) { return { type: Actions.DELETE_BRAND_OFFER_FAILED, error:error} }
}


//edit offers

function getProductOffer(offer_id){
    return dispatch =>{
        dispatch(request());
        OfferService.getProductOffer(offer_id)
        .then(res =>{
            var ProductOffer = res.data;

            dispatch(success(ProductOffer))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_PRODUCT_OFFER_LOADING} }
    function success(ProductOffer) { return { type: Actions.GET_PRODUCT_OFFER, ProductOffer: ProductOffer} }
    function failed(error) { return { type: Actions.GET_PRODUCT_OFFER_FAILED, error:error} }
}

function editProductOffer (data) {
    return dispatch => {
        dispatch(request());
        OfferService.editProductOffer(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_PRODUCT_OFFER_LOADING} }
    function success() { return { type: Actions.EDIT_PRODUCT_OFFER} }
    function failed(error) { return { type: Actions.EDIT_PRODUCT_OFFER_FAILED, error:error} }
}

//edit and get volume offer

function getVolumeOffer(offer_id){
    return dispatch =>{
        dispatch(request());
        OfferService.getVolumeOffer(offer_id)
        .then(res =>{
            var offer = res.data;

            dispatch(success(offer))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_VOLUME_OFFER_LOADING} }
    function success(offer) { return { type: Actions.GET_VOLUME_OFFER_SUCCESS, offer: offer} }
    function failed(error) { return { type: Actions.GET_VOLUME_OFFER_FAILED, error:error} }
}

function editVolumeOffer (data) {
    return dispatch => {
        dispatch(request());
        OfferService.editVolumeOffer(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_VOLUME_OFFER_LOADING} }
    function success() { return { type: Actions.EDIT_VOLUME_OFFER_SUCCESS} }
    function failed(error) { return { type: Actions.EDIT_VOLUME_OFFER_FAILED, error:error} }
}

//edit and get category offer
function getCategoryOffer(offer_id){
    return dispatch =>{
        dispatch(request());
        OfferService.getCategoryOffer(offer_id)
        .then(res =>{
            var offer = res.data;

            dispatch(success(offer))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_CATEGORY_OFFER_LOADING} }
    function success(offer) { return { type: Actions.GET_CATEGORY_OFFER_SUCCESS, offer: offer} }
    function failed(error) { return { type: Actions.GET_CATEGORY_OFFER_FAILED, error:error} }
}

function editCategoryOffer(data) {
    return dispatch => {
        dispatch(request());
        OfferService.editCategoryOffer(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_CATEGORY_OFFER_LOADING} }
    function success() { return { type: Actions.EDIT_CATEGORY_OFFER_SUCCESS} }
    function failed(error) { return { type: Actions.EDIT_CATEGORY_OFFER_FAILED, error:error} }
}

//edit and brand offer
function getBrandOffer(offer_id){
    return dispatch =>{
        dispatch(request());
        OfferService.getBrandOffer(offer_id)
        .then(res =>{
            var offer = res.data;

            dispatch(success(offer))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_BRAND_OFFER_LOADING} }
    function success(offer) { return { type: Actions.GET_BRAND_OFFER_SUCCESS, offer: offer} }
    function failed(error) { return { type: Actions.GET_BRAND_OFFER_FAILED, error:error} }
}

function editBrandOffer(data) {
    return dispatch => {
        dispatch(request());
        OfferService.editBrandOffer(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_BRAND_OFFER_LOADING} }
    function success() { return { type: Actions.EDIT_BRAND_OFFER_SUCCESS} }
    function failed(error) { return { type: Actions.EDIT_BRAND_OFFER_FAILED, error:error} }
}

//EDIT PACK 

function getPack(product_id){
    return dispatch =>{
        dispatch(request());
        OfferService.getPack(product_id)
        .then(res =>{
            var pack = res.data;

            dispatch(success(pack))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_PACK_LOADING} }
    function success(pack) { return { type: Actions.GET_PACK, pack: pack} }
    function failed(error) { return { type: Actions.GET_PACK_FAILED, error:error} }
}

function editPack(data) {
    return dispatch => {
        dispatch(request());
        OfferService.editPack(data).then(
            res => {
                dispatch(success())
            }
        ).catch(error => {
            dispatch(failed(error)) 
        })
    }
    function request() { return { type: Actions.EDIT_PACK_LOADING} }
    function success() { return { type: Actions.EDIT_PACK} }
    function failed(error) { return { type: Actions.EDIT_PACK_FAILED, error:error} }
}

//pack action

function packStatus(product_id,status){
    return dispatch =>{
        dispatch(request());
        OfferService.packStatus(product_id,status)
        .then(res =>{
            dispatch(success())
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.PRODUCT_STATUS_LOADING} }
    function success() { return { type: Actions.PRODUCT_STATUS} }
    function failed(error) { return { type: Actions.PRODUCT_STATUS_FAILED, error:error} }
}

export {
    addVolumeOffer,
    addBrandOffer,
    addCategoryOffer,
    addProductOffer,
    addPack,
    getAllProductOffers,
    getAllBrandOffers,
    getAllVolumeOffers,
    getAllCategoryOffers,
    getAllPacks,
    delVolumeOffer,
    delProductOffer,
    delBrandOffer,
    delCategoryOffer,
    onDismiss,
    editProductOffer,
    getProductOffer,
    editCategoryOffer,
    getCategoryOffer,
    editBrandOffer,
    getBrandOffer,
    getVolumeOffer,
    editVolumeOffer,
    editPack,
    getPack,
    packStatus,
    addStorePack
} 