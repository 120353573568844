import React, { Component } from 'react';
import {Checkbox } from "../../components";
class CheckFrom extends Component {
    constructor(props) {
        super(props);
        this.state={
            week_days:this.props.isEditable?this.props.week:[]

        }
    }
    _handle =(e)=>{
        var id = parseInt(e.target.name);
        var array = this.state.week_days;
        if(array.includes(id)){
            const index = array.indexOf(id);
            array.splice(index, 1);
           
        }else{
            array.push(id);
        }
        this.setState({week_days:array},()=>{
            
            this.props.onCheckChange(array)
        })
    }

    render() { 
        const { week_days} = this.state;
        return ( 
            <div className="check-wrapper">
            <Checkbox label="SUN" name={7} checked={week_days.includes(7)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="MON" name={1} checked={week_days.includes(1)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="TUE" name={2} checked={week_days.includes(2)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="WED" name={3} checked={week_days.includes(3)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="THU" name={4} checked={week_days.includes(4)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="FRI" name={5} checked={week_days.includes(5)} onChange={(e)=>{this._handle(e)}}/>
            <Checkbox label="SAT" name={6} checked={week_days.includes(6)} onChange={(e)=>{this._handle(e)}}/>
            </div>
         );
    }
}

CheckFrom.CheckFrom ={
    week:[],
    isEditable:false
}
 
export default CheckFrom;
