
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, UncontrolledCollapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";

import logo from "../../logo.svg";
import {connect} from 'react-redux';
import * as Constant from '../../constants/AppConstants';
var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
       collapse: false,
        activeClass:false
       };
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  componentDidMount() {
    ps = new PerfectScrollbar(this.refs.sidebar, {
      suppressScrollX: true,
      suppressScrollY: false
    });
  }
  componentWillUnmount() {
    ps.destroy();
  }

  checkValue(value,arr){
    if(typeof arr === 'undefined') return false;
   var status = false;
   for(var i=0; i<arr.length; i++){
     var name = arr[i];
     if(name == value){
       status = true;
       break;
     }
   }
 
   return status;
 }

  render() {
    const {permissions} = this.props;
    var active = "";
    var selected= "";
    if(this.props.location.pathname.indexOf('/admin/faq') > -1) {
    active = "active";
    }
    if (this.props.location.pathname.indexOf('/admin/about-us') > -1) {
    active = "active";
    }
    if ( this.props.location.pathname.indexOf('/admin/point-terms') > -1 ) {
    active = "active";
    }
    if(this.props.location.pathname.indexOf('/admin/products') > -1) {
    selected = "active";
    }
    if (this.props.location.pathname.indexOf('/admin/category') > -1) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/admin/brands') > -1 ) {
    selected = "active";
    }
    if(this.props.location.pathname.indexOf('/admin/units') > -1) {
    selected = "active";
    }
    if (this.props.location.pathname.indexOf('/admin/tags') > -1) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/admin/attributes') > -1 ) {
    selected = "active";
    }
    if ( this.props.location.pathname.indexOf('/admin/variants') > -1 ) {
    selected = "active";
    }
  
    
    return (
      <div className="sidebar" data-color="black">
        <div className="logo">
          <Link to="/admin" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </Link>
          <h8 style={{color: 'white', padding: 20, paddingTop: 30}}>Admin Dashboard</h8>
        </div>
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            <li>
              <NavLink className="nav-link" to="/admin/dashboard">
                <i className="icon-graph-bar" />
                <p>Dashboard</p>
              </NavLink>
            </li>

            {this.checkValue(Constant.MENU_TYPE_CATALOGUE,permissions)?
                <li>
                <a className={selected} aria-expanded="false" id="Catologue">
                  <i className="icon-catolougue" />
                  <p>Catologue</p>
                </a>
                <UncontrolledCollapse toggler="#Catologue">
                  <Link className="dropdown-item" to="/admin/products">
                    Products
                  </Link>
                  <Link className="dropdown-item" to="/admin/category">
                    Categories
                  </Link>
                  <Link className="dropdown-item" to="/admin/brands">
                    Brands
                  </Link>
                  <Link className="dropdown-item" to="/admin/units">
                    Units
                  </Link>
                  {/* <Link className="dropdown-item" to="/admin/tags">
                    Tags
                  </Link> */}
                  {/* <Link className="dropdown-item" to="/admin/attributes">
                    Attributes
                  </Link> */}
                  <Link className="dropdown-item" to="/admin/variants">
                       Variants
                  </Link>
                </UncontrolledCollapse>
              </li> :null
            }
               <li>
                <a className='' aria-expanded="false" id="orders">
                  <i className="icon-graph-bar" />
                  <p>Orders</p>
                </a>
                <UncontrolledCollapse toggler="#orders">
                  {/* <NavLink className="dropdown-item" to="/admin/new-orders">
                    Processing
                  </NavLink> */}
                  <NavLink className="dropdown-item" to="/admin/all-orders">
                  All Orders
                  </NavLink>
                  <NavLink className="dropdown-item" to="/admin/accounts">
                  Accounts
                  </NavLink>
                  {/* <NavLink className="dropdown-item" to="/admin/delivery-slots">
                  Delivery Slots
                  </NavLink>
                  <NavLink className="dropdown-item" to="/admin/delivery-boys">
                  Delivery Boys
                  </NavLink> */}
                  <NavLink className="dropdown-item" to="/admin/feedbacks">
                  Feedbacks
                  </NavLink>
                </UncontrolledCollapse>
              </li>
            <li>
                <a className='' aria-expanded="false" id="stores">
                  <i className="icon-store" />
                  <p>Store</p>
                </a>
                <UncontrolledCollapse toggler="#stores">
                  <NavLink className="dropdown-item" to="/admin/stores">
                  Stores
                  </NavLink>
                  <NavLink className="dropdown-item" to="/admin/store-staffs">
                  Store Staffs
                  </NavLink>
                </UncontrolledCollapse>
              </li>
            {/* {this.checkValue(Constant.MENU_TYPE_CONTENT,permissions) ? */}
              <li>
              <a className={active}  id="Content">
                <i className="icon-content" />
                <p>Content</p>
              </a>
              <UncontrolledCollapse toggler="#Content">
                <NavLink className="dropdown-item" to="/admin/about-us">
                  About Us
                </NavLink>
                <NavLink className="dropdown-item" to="/admin/faq">
                  Faq
                </NavLink>
                <NavLink className="dropdown-item" to="/admin/terms">
                  Terms & Conditions
                </NavLink>
                <NavLink className="dropdown-item" to="/admin/privacy-policy">
                  Privacy Policy
                </NavLink>
              </UncontrolledCollapse>
            </li>
            {/* :null} */}
          {/* {this.checkValue(Constant.MENU_TYPE_CUSTOMER,permissions)? */}
             <li>
             <NavLink className="nav-link" to="/admin/customers">
               <i className="icon-customer" />
               <p>Customers</p>
             </NavLink>
           </li>
           {/* :null
        } */}
         
         {this.checkValue(Constant.MENU_TYPE_STAFF,permissions)?
            <li>
              <NavLink className="nav-link" to="/admin/staffs">
                <i className="icon-user" />
                <p>Staffs</p>
              </NavLink>
            </li>:null}
            
           <li>
           <NavLink className="nav-link" to="/admin/notifications">
               <i className="icon-bell" />
               <p>Notifications</p>
             </NavLink>
           </li>
         
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.userReducer.loading,
  permissions:state.userReducer.permissions
})
const mapDispatchToProps = (dispatch)=> ({
})
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)

