import React, { forwardRef, useRef, useState } from 'react';
import {connect, useSelector} from 'react-redux';
import Select from "react-select";
import SimpleReactValidator from 'simple-react-validator';

import {
  Row,
  Col,
  FormGroup,
} from "reactstrap";

import ColourSwatches from "../../../../components/swatches/Colour";
import TextSwatches from '../../../../components/swatches/Text';
import * as AppConstants from '../../../../constants/AppConstants'
import { Button } from '../../../../components';
import { showError } from '../../../../utils';

function AttributePage({
  disabled,
  ...props
}, ref){

    const attributes = useSelector(state => state.variantReducer.variants)
    const [ selectedAttributes, setSelectedAttributes ] = useState([])
    const [ reflector, setReflector ] = useState(true)
    const validator = new SimpleReactValidator()
    const colorRef = useRef()
    const textRef = useRef()

    React.useImperativeHandle(ref, ()=>({
      onValidate(){
        return isValid()
      },
      onSetSelectedData(selected){
        setSelectedAttributes(selected)
        setReflector(!reflector)
      }
    }))

    const addMore = ()=>{
        var array = selectedAttributes
        array.push({});
        setSelectedAttributes(array)
        setReflector(!reflector)
    }
 
    const isValid = () => {
        if( validator.allValid() ){
          return selectedAttributes;
        }
        else {
          validator.showMessages();
          setReflector(!reflector)
        }
        return null
    }
     
    const handleChange = (index,  selected)=>{
       var array = selectedAttributes;
       array[index] = attributes[selected.index];
       array[index]['attribute_id'] = attributes[selected.index].id;
       selectedAttributes[index] = selected;
       setSelectedAttributes(selectedAttributes)
       setReflector(!reflector)
    }
 
    const removeAttribute = (index)=>{
       selectedAttributes.splice(index, 1);
        setSelectedAttributes(selectedAttributes)
        setReflector(!reflector)
    }
 
     const addColor= (value,index)=>{
       var array = selectedAttributes;
       var item = array[index] ;
       item.attribute_values = value;
       array[index] = item;
       setSelectedAttributes(array)
     }
 
     const addText = (value,index) => {
       var array = selectedAttributes;
       var item = array[index] ;
       item.attribute_values = value;
       setSelectedAttributes(array)
     }

    let attributeOptions = attributes?attributes.map(function (attr,i) {
        return { 
          value: attr.id, 
          label: attr.attribute, 
          index: i, 
          attribute_id:attr.id, 
          attribute_values:attr.attribute_values
        };
    }):[]

    return (
        <div className="attribute-wrapper" style={{ pointerEvents: disabled ? 'none' : 'auto' }}>
          {selectedAttributes.map((item,index)=>{
            return(
              <div className="form-filds-wrapper" key={index}>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                          <Select  
                            options={attributeOptions} 
                            onChange ={(selected)=>handleChange(index, selected)} 
                            name="Select"
                            value={item}/>
                          <div className="errorMsg">{validator.message('select', item.value, 'required')}</div>
                          {showError('select', validator.errorMessages)}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <Button 
                        className="btn-tsp" 
                        leftLabel="icon-delete fa red" 
                        onClick={()=>removeAttribute(index)}></Button>
                    </Col>
                    {typeof item !== 'undefined' ? 
                      <Col md="8">
                            <div className="inner-table-wrapper">
                              {AppConstants.ATTRIBUTE_TYPE_COLOR === item.type ? 
                              <ColourSwatches 
                                attribute_values={item.attribute_values} 
                                onChangeColor={(value)=>addColor(value,index)} 
                                isEditing={true}
                                disableAdd={true}
                                ref={colorRef}/> : 
                              <TextSwatches 
                                attribute_values={item.attribute_values} 
                                isEditing={true}
                                disableAdd={true}
                                disableInput={true}
                                onChangeText={(value)=>addText(value,index)} 
                                ref={textRef}/>}
                            </div>
                      </Col>
                    :null}
                  </Row>
              </div> 
            )
          })}
          <div className="add-btn-wraper">
              <Button className="btn-simple" 
                leftLabel="fa fa-plus" 
                onClick={addMore}>Add Attributes</Button>
          </div> 
        </div>  
  );
}

export default forwardRef(AttributePage) 