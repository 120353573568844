import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function addCategory(data) {
    return request({
      url:   URLS.ADD_CATEGORY,
      method: 'POST',
      data:data
    });
}

function editCategory(data) {
    return request({
      url:URLS.EDIT_CATEGORY,
      method: 'POST',
      data:data
    });
}

function getCategory(cat_id) {
    var sub_url = '/'+cat_id;
    return request({
      url:   URLS.GET_CATEGORY + sub_url,
      method: 'GET'
    });
}

function getCategories(skip,take) {
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_CATEGORIES + sub_url,
      method: 'GET'
    });
}

function getAllCategories(store_id,skip,take) {
    var sub_url = '/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_STORE_CATEGORIES + sub_url,
      method: 'GET'
    });
}

function delCategory(cat_id) {
    var sub_url = '/'+cat_id;
    return request({
      url:   URLS.DELETE_CATEGORY + sub_url,
      method: 'GET',
    });
}

function getLeafCategories(skip,take){
    var sub_url = '';
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_LEAF_CATEGORIES + sub_url,
      method: 'GET',
    });
 }

const CategoryService = {
    addCategory,
    editCategory,
    getCategories,
    getCategory,
    getLeafCategories,
    getAllCategories,
    delCategory
}

export default CategoryService;

