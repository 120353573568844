import React, { Component } from 'react';
import { FormGroup,Row,Col,Table,Input,Label } from "reactstrap";
import { Button } from '../../../components';
import TextSwatches from '../../../components/swatches/Text';
import {connect} from 'react-redux';
import {addAttribute,onDismiss} from '../../../actions/attributeActions';
import SweetAlert from 'sweetalert2-react';
import SimpleReactValidator from 'simple-react-validator';
class AddAttribute extends Component {
    constructor (){
        super();
        this.state = {
            attribute:'',
            code:'',
            attribute_values:[],
        }
        this.onDismissPopup = this.onDismissPopup.bind(this);
        this.validator = new SimpleReactValidator();
        this.child = React.createRef();
        this.child2 = React.createRef();
    }
    onDismissPopup(){
        
        this.props.onDismiss();
      }
    handleSubmit = (e) => {
        e.preventDefault();
        var data = {
          code: this.state.code,
          name: this.state.attribute,
          attribute_items: this.state.attribute_values
        };
        if( this.validator.allValid() && this.state.attribute_values.length > 0){
            this.props.onAddAttribute(data);
          }
          else {
            this.validator.showMessages();
            this.forceUpdate();
          }  
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.status === true) {
            this.setState({
                attribute:'',
                code:'',
                attribute_values:[],
                attribute:'',
               });
        }
    }

    handleChang = (e,i) => {
        let attribute_values = this.state.attribute_values;
        var obj = {};
        if (attribute_values.length>i){
            obj =attribute_values[i];
        }
        obj['attribute'] = e.target.value;
        attribute_values[i] = obj;
        this.setState({
            attribute_values
        })
        
    }

  getLoder=()=> {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }

  addText = () => {
    var obj = {value:"",value_code:'',attribute_id:typeof this.state.attribute_values[0] !== 'undefined'? this.state.attribute_values[0].attribute_id:0}
    this.setState(prevState => ({ attribute_values: [...prevState.attribute_values, obj]}))
}

deleteText = (i,e) => {
    let attrbute_values =this.state.attribute_values;
    attrbute_values.splice(i,1);
    this.setState({attrbute_values});
    this.validator = new SimpleReactValidator();
}


  textUI () {
    return  this.state.attribute_values.map((attr,i)=>(
        <tr key={i}>
        <td >
            <Input type="text" name="value" value={attr.attribute ? attr.attribute : ''} onChange={(e)=>this.handleChang(e,i)} placeholder="Attribute name" />
            <div className="errorMsg"> {this.validator.message('name', attr.attribute, 'required')}</div>
        </td>

        <td>
        <Button className="btn-tsp" onClick={(e)=>this.deleteText(i,e) } leftLabel="fa red fa-times"></Button>
        </td>
        </tr>
    ))

}

    render() { 
     
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className="form-section">
                <form onSubmit ={(e)=>this.handleSubmit(e)}>
                <div className="form-border-wrapper">
                <Row className="pad">
                        <Col md="4">
                        <FormGroup>
                          <Label className="control-label">Attribute Name</Label>
                          <Input type="text" value={this.state.attribute} onChange={(e)=>this.setState({attribute:e.target.value})} placeholder="Type here"/>
                          <div className="errorMsg">{this.validator.message('attribute', this.state.attribute, 'required')}</div>
                        </FormGroup>
                        </Col>
                        <Col md="4">
                        <FormGroup>
                          <Label className="control-label">Attribute Code</Label>
                          <Input type="text" value={this.state.code} onChange={(e)=>this.setState({code:e.target.value})} placeholder="Type here" />
                          <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                        </FormGroup>
                        </Col>
                        <Col md="8">
                            <div className="inner-table-wrapper">
                            <Table>
                            <thead>
                            <tr>
                            <th>Name</th>
                            <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.textUI()}
                            </tbody>
                            </Table>
                            <Button className="btn-plus" onClick = {()=>this.addText()} leftLabel="fa fa-plus">ADD ATTRIBUTE</Button>
                        
                            </div>
                            <div className="errorMsg"> {this.validator.message('values', this.state.attribute_values[0], 'required')}</div>
                        </Col>
                        
                    </Row>
                </div>
                <div className="submit-wrapper">
                <Button className="btn-submit" >Save</Button>
                </div>
                </form>
                <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                </div>
              
                </div>
            </div>
         )
    }


    
    renderTextSelect (){
        return (
           <TextSwatches onChangeText={(value)=>this.addText(value)} ref={this.child2}/>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.attributeReducer.loading,
    status:state.attributeReducer.status,
    message:state.attributeReducer.message,
    popup:state.attributeReducer.popup
});

const mapDispatchToProps = (dispatch) => ({
    onAddAttribute:(data)=>{
        dispatch(addAttribute(data))
      },
      onDismiss:()=>{
        dispatch(onDismiss())
      }
});

export default connect (mapStateToProps,mapDispatchToProps)(AddAttribute);
 
