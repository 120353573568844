import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  attrLoading: false,
  variants: [],
  loadattr:false,
  variant: []
};

export default function variantReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.ADD_VARIANTS_LOADING:
          return {
            ...state,
            loading: true
          };
        case Actions.ADD_VARIANTS_SUCCESS:
          return {
            ...state,
            loading: false,
            message: "Variant added successfully",
            popup: true,
            status: true
          };
        case Actions.ADD_VARIANTS_FAILED:
          return {
            ...state,
            loading: false,
            popup: true,
            message: "Variant add failed",
            status: false,
            error: action.error
          };
    
          case Actions.ALERT_DISMISS:
          return {
            ...state,
            status: false,
            popup: false
          };
    
        case Actions.GET_VARIANTS_LOADING:
          return {
            attrLoading: true,
            variants: []
          };
        case Actions.GET_VARIANTS:
          return {
            ...state,
            attrLoading: false,
            variants: action.variants,
            count: action.count
          };
        case Actions.GET_VARIANTS_FAILED:
          return {
            ...state,
            attrLoading: false,
            variants: [],
            error: action.error
          };
          case Actions.ALERT_DISMISS:
          return {
            ...state,
            status: false,
            popup: false
          };
          case Actions.EDIT_VARIANT_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_VARIANT:
      return {
        ...state,
        loading: false,
        message: "Variant edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_VARIANT_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Variant editing failed",
        status: false,
        error: action.error
      };
      case Actions.GET_VARIANT_LOADING:
      return {
        variant: [],
        loadattr:true,
      };
    case Actions.GET_VARIANT:
      return {
        ...state,
        loadattr:false,
        variant: action.variant,
      };
    case Actions.GET_VARIANT_FAILED:
      return {
        ...state,
        variant: [],
        loadattr:false,
        error: action.error
      };
    
        default:
          return state;
      }
}