import React, { Component } from "react";
import { Row, Col, Input,FormGroup } from "reactstrap";
import PhoneInput from 'react-phone-number-input'
import { Button, Checkbox } from '../../../components';
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import Select from "react-select";
import Privilages from '../../../components/Common/Privilages';
import LocationSearchInput from './mapFeild';  
import SweetAlert from 'sweetalert2-react';
import { connect } from 'react-redux';
import {addStore,onDismiss} from '../../../actions/storeActions';
import { getCountries,getStates,getRegions,getCities} from '../../../actions/addressActions';
import SimpleReactValidator from 'simple-react-validator';
import CurrencySelect from "../../../components/SelectComponents/CurrencySelect";

function AMap({onMapChange}){

  return <LocationSearchInput onMapChange={onMapChange} markers={[]}/>
}

class AddStoreData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title:'',
      code:'',
      store_unique_id:'',
      building:'',
      street:'',
      city:'',
      region:'',
      zip:'',
      emailid:'',
      phone:'',
      email:'',
      name:'',
      unique_number:'',
      mobile:'',
      password:'',
      c_password:'',
      store_map:'',
      store_image:'',
      admin_image:'',
      country_selected:'',
      region_selected:'',
      state_selected:'',
      city_selected:'',
      isMounted: true,
      level:[],
      radius:'',
      min_amount:'',
      number_orders:'',
      offer: '',
      fast_delivery:true,
      normal_delivery:true,
      take_away: false,
      currency:null,
      delivery_charge:'',
      delivery_time: 30,
      enable_cod: true,
      enable_online: true,
      passv: 'password',
      passc: 'password',
    }
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
     this.setState({ isMounted: false });
    {this.props.onGetCountries();}
  }
  });
  }
  _onImageChange = (store_image) => {
    this.setState({store_image});
  }
  _onProfileChange = (admin_image) => {
    this.setState({admin_image});
  }
  handleRegion = (region_selected) => {
      this.setState({region_selected});
      this.props.onGetCities(region_selected.value);
     
  }
  handleCountry = (country_selected) =>{
    this.setState({country_selected});
    this.props.onGetStates(country_selected.value);
  }

  handleCurrency = (currency) =>{
    this.setState({currency});
  }

  handleState = (state_selected) => {
    this.setState({state_selected});
    this.props.onGetRegions(state_selected.value);
  }
  handleCity = (city_selected) => {
    this.setState({city_selected});
  }
  selectLocation= (store_map)=> {
    this.setState({store_map});
  }
  onDismissPopup (){
    this.props.onDismiss();
  }
  hadlePrev = (level)=> {
    this.setState({level});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      title:this.state.title,
      code:this.state.title,
      store_unique_id:parseInt(this.state.store_unique_id),
      featured_image:this.state.store_image,
      min_amount: this.state.min_amount,
      offer: this.state.offer,
      radius: this.state.radius,
      number_orders: this.state.number_orders,
      normal_delivery: this.state.normal_delivery,
      fast_delivery: this.state.fast_delivery,
      take_away: this.state.take_away,
      country:this.state.country_selected.value,
      state:this.state.state_selected.value,
      region:this.state.region_selected.value,
      city:this.state.city_selected.value,
      building:this.state.building,
      street:this.state.street,
      zip:this.state.zip,
      contact: this.state.mobile,
      email: this.state.emailid,
      latitude: this.state.store_map.latitude,
      longitude: this.state.store_map.longitude,
      currency: this.state.currency?this.state.currency.value:null,
      delivery_charge: this.state.delivery_charge,
      delivery_time: this.state.delivery_time,
      enable_cod: this.state.enable_cod,
      enable_online: this.state.enable_online
    }
    // var contacts = [];
    // if(this.state.phone.length > 5){
    //   var mobile ={
    //     contact:this.state.phone,
    //     type:1
    //   }
  
    //   contacts.push(mobile);
    // }


    // if(this.state.emailid.length > 5){
    //   var emailID ={
    //     contact:this.state.emailid,
    //     type:2
    //   }
    //   contacts.push(emailID);
    // }
   
   
    // var address = {
    //   country:this.state.country_selected.value,
    //   state:this.state.state_selected.value,
    //   region:this.state.region_selected.value,
    //   city:this.state.city_selected.value,
    //   building:this.state.building,
    //   street:this.state.street,
    //   zip:this.state.zip,
    //   level:this.state.level
    // }
   
    var level  = this.state.level.filter(function (el) {
      return el != null;
      });

    var user = {
      profile_pic:this.state.admin_image,
      name:this.state.name,
      mobile:this.state.mobile,
      email:this.state.email,
      unique_number:this.state.unique_number,
      password:this.state.password,
      c_password:this.state.c_password
    }
    user['levels'] = level;
    // data['address'] = address;
    // data['contacts'] = contacts;
    data['user']= user;

    if( this.validator.allValid() && this.state.level.length > 0 && this.state.c_password === this.state.password){
      this.props.onAddStore(data);
    }
    else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.status === true){
      this.setState({
        title:'',code:'',store_unique_id:'',building:'',street:'', city:'', region:'',level:'',
        zip:'',emailid:'', phone:'', email:'',name:'', unique_number:'',mobile:'',
        password:'', c_password:'', store_map:{}, store_image:'',admin_image:'',
        country_selected:'',region_selected:'',state_selected:'',city_selected:'', level:[]
        });
    }
  }
      //loader
      getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }

  render() {
    const {region_selected, normal_delivery, fast_delivery, take_away, number_orders} = this.state;

    return (
      <div>
        <div>
          <div className="content">
          <div className={this.getLoder()}></div>
            <div className="form-section">
            <form onSubmit={this.handleSubmit}>
              <div className="form-filds-wrapper mt-0">
                <Row>
                  <Col md="8">
                    <Row>
                      <Col md="12">
                        <div className="smal-heading">
                          <h6>Store Details</h6>
                        </div>
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage  imageReset={this.state.store_image} onImageChange={(store_image)=>this._onImageChange(store_image)} />
                          </div>
                          <div className="errorMsg">{this.validator.message('image', this.state.store_image, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Fast Delivery" checked={fast_delivery} onChange={(e)=>{this.setState({fast_delivery:e.target.checked})}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Normal Delivery" checked={normal_delivery} onChange={(e)=>{this.setState({normal_delivery:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Enable COD" checked={this.state.enable_cod} onChange={(e)=>{this.setState({enable_cod:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col>
                      {/* <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Take Away" checked={take_away} onChange={(e)=>{this.setState({take_away:e.target.checked})}}/>
                          </div>
                        </FormGroup>
                      </Col> */}
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Store name</label>
                          <Input value={this.state.title} onChange ={(e)=>this.setState({title:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Store Unique ID
                          </label>
                          <Input value={this.state.store_unique_id} onChange ={(e)=>this.setState({store_unique_id:e.target.value})} type="number" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Code
                          </label>
                          <Input value={this.state.code} onChange ={(e)=>this.setState({code:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('code', this.state.code, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Delivery Radius
                          </label>
                          <Input value={this.state.radius} onChange ={(e)=>this.setState({radius:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('radius', this.state.radius, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Delivery Charge
                          </label>
                          <Input value={this.state.delivery_charge} onChange ={(e)=>this.setState({delivery_charge:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('delivery_charge', this.state.delivery_charge, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Fast Delivery Time
                          </label>
                          <Input value={this.state.delivery_time} onChange ={(e)=>this.setState({delivery_time:e.target.value})} 
                            type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('delivery_time', this.state.delivery_time, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Minimum Delivery Amount
                          </label>
                          <Input value={this.state.min_amount} onChange ={(e)=>this.setState({min_amount:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('amount', this.state.min_amount, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Number of Orders
                          </label>
                          <Input value={this.state.number_orders} onChange ={(e)=>this.setState({number_orders:e.target.value})} type="text" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                           Offer by %
                          </label>
                          <Input value={this.state.offer} onChange ={(e)=>this.setState({offer:e.target.value})} type="text" className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Building
                          </label>
                          <Input value={this.state.building} onChange ={(e)=>this.setState({building:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('building', this.state.building, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Street
                          </label>
                          <Input value={this.state.street} onChange ={(e)=>this.setState({street:e.target.value})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('street', this.state.street, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Currency</label>
                          <CurrencySelect  onChange ={this.handleCurrency} value={this.state.currency} placeholder={'Choose a currency'}/>
                          <div className="errorMsg">{this.validator.message('country', this.state.currency && this.state.currency.value, 'required')}</div>
                      </div>
                      </Col>
                      {/* <Col md="6">
                        <div className="form-group">
                          <label className="control-label">Country</label>
                          <Select options={countryOptions} onChange ={this.handleCountry} value={country_selected} placeholder={'Choose a country'}/>
                          <div className="errorMsg">{this.validator.message('country', country_selected.value, 'required')}</div>
                      </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">State</label>
                          <Select options={stateOptions} onChange ={this.handleState} value={state_selected} placeholder={'Choose a state'}/>
                          <div className="errorMsg">{this.validator.message('country', country_selected.value, 'required')}</div>
                       </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Region
                          </label>
                          <Select options={regionOptions} onChange ={this.handleRegion} value={region_selected} placeholder={'Choose a region'} />
                          <div className="errorMsg">{this.validator.message('region', region_selected.value, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            City
                          </label>
                          <Select options={cityOPtions} onChange ={this.handleCity} value={city_selected} placeholder="Choose a city" />
                          <div className="errorMsg">{this.validator.message('city', city_selected.value, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Zip/ Postal Code
                          </label>
                          <Input value={this.state.zip} onChange ={(e)=>this.setState({zip:e.target.value})} type="number" className="form-control" />
                          <div className="errorMsg">{this.validator.message('zip', this.state.zip, 'required')}</div>
                        </div>
                      </Col> */}
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Contact Number
                          </label>
                          <PhoneInput defaultCountry="IN" value={this.state.phone} onChange ={(e)=>this.setState({phone:e})} type="text" className="form-control" />
                          <div className="errorMsg">{this.validator.message('phone', this.state.phone, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Email Address
                          </label>
                          <Input value={this.state.emailid} onChange ={(e)=>this.setState({emailid:e.target.value})} type="email" className="form-control" />
                          <div className="errorMsg">{this.validator.message('email', this.state.emailid, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div className="form-group">
                        <label className="control-label">Add map place</label>
                        <AMap onMapChange={(store_map)=>this.selectLocation(store_map)}/>
                        <div className="errorMsg">{this.validator.message('location', this.state.store_map, 'required')}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="smal-heading">
                          <h6>Store Admin Details</h6>
                        </div>
                        <div className="form-group">
                          <div className="flex-wrapper">
                            <FeaturedImage imageReset={this.state.admin_image} onImageChange={(admin_image)=>this._onProfileChange(admin_image)}/>
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin Username
                          </label>
                          <Input type="text"  onChange ={(e)=>this.setState({name:e.target.value})} value={this.state.name} className="form-control" />
                          <div className="errorMsg">{this.validator.message('name', this.state.name, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin Phone Number
                          </label>
                          <PhoneInput defaultCountry="IN"  onChange ={(e)=>this.setState({mobile:e})} value={this.state.mobile} className="form-control" />
                          <div className="errorMsg">{this.validator.message('mobile', this.state.mobile, 'required')}</div>
                    </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin Email
                          </label>
                          <Input type="email"  onChange ={(e)=>this.setState({email:e.target.value})} value={this.state.email}  className="form-control" />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin ID
                          </label>
                          <Input type="text" onChange ={(e)=>this.setState({unique_number:e.target.value})} value={this.state.unique_number} className="form-control" />
                          <div className="errorMsg">{this.validator.message('unique number', this.state.unique_number, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin Password
                          </label>
                          <Input type={this.state.passv} onChange ={(e)=>this.setState({password:e.target.value})} value={this.state.password} className="form-control" />
                          <i className={this.state.passv != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                                onClick={()=>{
                                  this.setState(({passv}) => ({
                                    passv: passv === 'text' ? 'password' : 'text'
                                  }))
                                }}></i>
                          <div className="errorMsg">{this.validator.message('password', this.state.password, 'required')}</div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label className="control-label">
                            Store Admin Confirm Password
                          </label>
                          <Input type={this.state.passc} onChange ={(e)=>this.setState({c_password:e.target.value})} value={this.state.c_password} className="form-control" />
                          <i className={this.state.passc != 'password' ? 'far eye-btn fa-eye-slash' : 'far eye-btn fa-eye'}
                              onClick={()=>{
                                this.setState(({passc}) => ({
                                  passc: passc === 'text' ? 'password' : 'text'
                                }))
                              }}></i>
                          <div className="errorMsg">{this.validator.message('confirm password', this.state.c_password, 'required')}</div>
                          <div className="errorMsg"> {this.state.c_password !== this.state.password ? 'Password mismatched' : null }</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                     <div className="prvilages">
                     <FormGroup>
                     <label className="control-label">Acces Privilages</label>
                       <Privilages handlePrivilage={(level)=>this.hadlePrev(level)}/>
                        </FormGroup>
                        {this.state.level.length < 1?
                        <div className="errorMsg">acces level required</div>:null}
                     </div>
                        </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <div className="submit-wrapper">
                      <Button type="submit" className="btn-submit">Save</Button>
                    </div>
                  </Col>
                </Row>
              </div>
              <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
              
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LocationSearchInput.defaultProps ={
  countries:[],
  states:[],
  regions:[],
  cities:[],
}

const mapStateToProps = (state) => ({
  loading: state.storeReducer.loading,
  status:state.storeReducer.status,
  message:state.storeReducer.message,
  popup:state.storeReducer.popup,
  addrLoading: state.AddressReducer.addrLoading,
  countries: state.AddressReducer.countries,
  states: state.AddressReducer.states,
  regions: state.AddressReducer.regions,
  cities: state.AddressReducer.cities,
})

const mapDispatchToProps = (dispatch)=> ({
  onAddStore:(data)=>{
    dispatch(addStore(data));
  },
  onGetCountries:()=>{
    dispatch(getCountries())
  },
  onGetStates:(country_id)=>{
    dispatch(getStates(country_id));
  },
  onGetRegions:(state_id)=>{
    dispatch(getRegions(state_id));
  },
  onGetCities:(region_id)=>{
    dispatch(getCities(region_id));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddStoreData)
