import React from 'react'
import { CardTitle, Card, Table } from 'reactstrap'

export default function SellingTable({items, title}){
    return (
        <Card className="table-product-wrapper" style={{minHeight: 300}}>
            <CardTitle style={{fontSize:14, paddingLeft: 10, paddingTop:15}}>{title}</CardTitle>
            <Table>
                <thead>
                    <tr>
                    <th>PRODUCT NAME</th>
                    <th>QUANTITY</th>
                    <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                {items && items.length >0 ? items.map((item,index) => (
                    <tr key={index}>
                        <td>{item.product.title}</td>
                        <td>{item.qty}</td>
                        <td>{item.price}</td>
                    </tr>    
                )):<tr><td colSpan={'5'}>No data found</td></tr>}
                </tbody>
                </Table>
        </Card>
    )
}