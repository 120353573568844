import React,{Component} from 'react';
import { Button } from '../../../components';
import { Table } from "reactstrap";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import {getAllbanners,deleteBanner,onDismiss} from '../../../actions/BannerActions';
import SweetAlert from 'sweetalert2-react';
import ReactPaginate from 'react-paginate';
class SlidingBanner extends Component {
      constructor(props) {
        super(props);
        this.state = {
          skip: 0,
          limit:12,
          isMounted: false,
        };
        this.onDismissPopup = this.onDismissPopup.bind(this);
      }
      componentDidMount() {
        this.setState({ isMounted: true }, () => {
          if (this.state.isMounted) {
            this.setState({ isMounted: false });
          }
        });
        setTimeout(()=>{
          this.props.onGetOffer(this.props.store.id,2);
         },100);
      }
      onDismissPopup () {
        this.props.onDismiss();
        this.props.onGetOffer(this.props.store.id,2);
      }
      handleDelete(e,id) {
        e.preventDefault();
        this.props.onDeleteOffers(id);
      }
      getLoder() {
        let classes = "bt-loader ";
        classes += this.props.loading ? 'bt-show' : 'bt-hide';
        return classes;
      }
      trimTime (dateString) {
        dateString = dateString.replace('00:00:00', '');
        return dateString;
      }
      handlePageClick = (data) => {
        let selected = data.selected;
        let skip = selected * this.state.limit
        this.setState({skip: skip});
        this.props.onGetCategories(skip,this.state.limit);
      }

    render() { 
      const {banners} = this.props;
        return ( 
<div>
      <div className="content">
      <div className={this.getLoder()}></div>
        <div className="table-product-wrapper">
        <Table>
                    <thead>
                      <tr>
                      <th>ID</th>
                      <th>NAME</th>
                      <th>DATE FROM</th>
                      <th>DATE TO</th>
                      <th />
                      </tr>
                    </thead>
                    <tbody>
                    {banners.length>0 ? banners.map((offer,index) => (
                        <tr key={index}>
                        <td>{index+1+this.state.skip}</td>
                        <td className="nameImg">
                        {/* <img src={offer.mob_image} alt="wild" className="img-ico" /> */}
                        <span className="p-name">{offer.title}</span>
                        </td>
                        <td>{this.trimTime(offer.valid_from)}</td>
                        <td>{this.trimTime(offer.valid_upto)}</td>
                        <td>
                          <div className="group-action">
                          <Link className="btn" to={`/store/edit-still-banner/${offer.id}`}>
                              <i className="ico icon-edit" />
                            </Link>
                            <button className="btn" onClick={((e)=>this.handleDelete(e,offer.id))}>
                              <i className="ico icon-delete" />
                            </button>
                          </div>
                        </td>
                      </tr>    
                    )):<tr><td colSpan={'7'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
        <Button className="fixed-yellow"  onClick={() => this.props.history.push("/store/add-sliding-banner")}>Add</Button>
        </div>
        <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />
                </div>
        <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
      </div>
               
    </div>
         );
    }
}
 

SlidingBanner.defaultProps = {
  banners:[]
}
const mapStateToProps = state => ({
  loading: state.bannerReducer.loading,
  banners: state.bannerReducer.banners,
  status:state.bannerReducer.status,
  message:state.bannerReducer.message,
  count:state.bannerReducer.count,
  popup:state.bannerReducer.popup,
  store: state.storeUserReducer.store
});

const mapDispatchToProps = dispatch => ({
  onGetOffer: (store_id,type,skip,take) => {
    dispatch(getAllbanners(store_id,type,skip,take));
  },
  onDeleteOffers:(id)=>{
    dispatch(deleteBanner(id))
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SlidingBanner);
