import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  tagsLoading: false,
  tags: []
};

export default function tagReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_TAG_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.ADD_TAG:
      return {
        ...state,
        loading: false,
        message:"Add tag successful",
        popup: true,
        status: true
      };
    case Actions.ADD_TAG_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Add tag failed",
        status: false,
        error: action.error
      };

    case Actions.GET_TAGS_LOADING:
      return {
        tagsLoading: true,
        tags: []
      };
    case Actions.GET_TAGS:
      return {
        ...state,
        tagsLoading: false,
        tags: action.tags,
        count: action.count
      };
    case Actions.GET_TAGS_FAILED:
      return {
        ...state,
        tagsLoading: false,
        tags: [],
        error: action.error
      };
    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };

    case Actions.DELETE_TAG_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_TAG:
      return {
        ...state,
        loading: false,
        message:"Delete tag successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_TAG_FAILED:
      return {
        ...state,
        loading: false,
        message:"Delete tag failed",
        popup: true,
        status: false
      };

      case Actions.EDIT_TAG_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.EDIT_TAG:
      return {
        ...state,
        loading: false,
        message:"Edit tag successful",
        popup: true,
        status: true
      };
    case Actions.EDIT_TAG_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Edit tag failed",
        status: false
      };

    default:
      return state;
  }
}
