import * as Actions from '../constants/Actions';
import deliverySlotService from '../services/deliverySlotService';

function addDeliverySlot(data){
  return dispatch =>{
      dispatch(request());
      deliverySlotService.addDeliverySlot(data)
      .then(res =>{
          var slots = res.data;
          dispatch(success(slots))
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
  function request() { return { type: Actions.ADD_DELIVERY_SLOT_LOADING} }
  function success(slots) { return { type: Actions.ADD_DELIVERY_SLOT_SUCCESS,slots:slots} }
  function failed(error) { return { type: Actions.ADD_DELIVERY_SLOT_FAILED, error:error} }
}


function getAllDeliverySlots(skip=null, take=null, store_id=null){

  return dispatch =>{
      dispatch(request());
      deliverySlotService.getAllDeliverySlots(skip, take, store_id)
      .then(res =>{
          var slots = res.data;
          var count = res.count
          dispatch(success(slots, count))
      }).catch(error =>{
          dispatch(failed(error))
      })
  }
function request() { return { type: Actions.GET_ALL_SLOTS_LOADING} }
function success(slots, count) { return { type: Actions.GET_ALL_SLOTS_SUCCESS, slots: slots, count} }
function failed(error) { return { type: Actions.GET_ALL_SLOTS_FAILED, error:error} }
}


function deleteDeliverySlot (slot_id) {
  return dispatch => {
      dispatch(request());
      deliverySlotService.deleteDeliverySlot(slot_id).then(
          res => {
              dispatch(success())
          }
      ).catch(error => {
          dispatch(failed(error)) 
      })
  }
  function request() { return { type: Actions.DELETE_SLOT_LOADING} }
  function success() { return { type: Actions.DELETE_SLOT_SUCCESS} }
  function failed(error) { return { type: Actions.DELETE_SLOT_FAILED, error:error} }
}

function getDeliverySlot(slot_id) {
    return dispatch =>{
        dispatch(request());
        deliverySlotService.getDeliverySlot(slot_id)
        .then(res =>{
            var slot = res.data;
            dispatch(success(slot))
        }).catch(error =>{
            dispatch(failed(error))
        })
    }
    function request() { return { type: Actions.GET_SLOT_LOADING} }
    function success(slot) { return { type: Actions.GET_SLOT_SUCCESS, slot: slot} }
    function failed(error) { return { type: Actions.GET_SLOT_FAILED, error:error} }
}

function editDeliverySlot(data) {
    return dispatch => {
        dispatch(request());
        deliverySlotService.editDeliverySlot(data)
        .then(res=>{
            dispatch(success())
        }).catch(error => {
            dispatch(failed(error))
        })
    }
    function request(){return {type:Actions.EDIT_DELIVERY_SLOT_LOADING}}
    function success(){return{type:Actions.EDIT_DELIVERY_SLOT_SUCCESS}}
    function failed(error){return{type:Actions.EDIT_DELIVERY_SLOT_FAILED,error:error}}
}


function onDismiss(){
  return dispatch =>{
      dispatch(success())
  }
  function success() { return { type: Actions.ALERT_DISMISS} }
}


export {
  addDeliverySlot,
  getAllDeliverySlots,
  deleteDeliverySlot,
  getDeliverySlot,
  editDeliverySlot,
  onDismiss
}