import React, { Component } from "react";
import { Table } from "reactstrap";
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import {getAllCategories} from '../../../actions/categoryActions';

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catLoading:false,
      isMounted: false,
      skip: 0,
      limit:12
    }
  }
 
  componentDidMount () {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
        this.setState({ isMounted: false });
    setTimeout(() => {
      this.props.onGetCategories(this.props.store.id,this.state.skip,this.state.limit)
    },50);
  }
  });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let skip = selected * this.state.limit
    this.setState({skip: skip});
    this.props.onGetCategories(this.props.store.id,skip,this.state.limit);
  };
  handleDelete = (e,id)=> {
    e.preventDefault();
    this.props.onDeleteCategory(id);
  }
  getLoder() {
    let classes = "bt-loader ";
    classes += this.props.catstoreLoading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() { 
    const {store_categories} = this.props;

    return ( 
      <div>
      <div className="content">
      <div className={this.getLoder()}></div>
        <div className="table-product-wrapper">
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>CATEGORY NAME</th>
                <th>CATEGORY CODE</th>
              </tr>
            </thead>
            <tbody>
              {store_categories.length >0 ? store_categories.map((category,index) => (
              <tr key = {index}>
              <td>{index+1 + this.state.skip }</td>
            <td className="nameImg">
            <img src={category.featured_image} alt="wild" className="img-ico" />
              <span className="p-name">{category.name}</span>
            </td>
            <td>{category.code}</td>
            </tr>
              )):<tr><td colSpan={'5'}>No data found</td></tr>}
           
 
            </tbody>
          </Table>
        {/* <Button className="fixed-yellow"  onClick={() => this.props.history.push("/store/add-category")}>Add</Button> */}
        </div>
        <div className="pagination-wrapper">
        <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
        nextLabel={<i className="fas fa-angle-right"></i>}
        pageLinkClassName={"page-link"}
        breakClassName={"break-me"}
        pageCount={Math.ceil(this.props.count/this.state.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={this.handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        activeClassName={"active"} />
        </div>
      </div>
    </div>
     );
  }
}

Category.defaultProps = {
  store_categories : [],
  store:{}
}
 

const mapStateToProps = (state) => ({
  catstoreLoading:state.categoryReducer.catstoreLoading,
  store_categories: state.categoryReducer.store_categories,
  message:state.categoryReducer.message,
  count:state.categoryReducer.count1,
  popup:state.categoryReducer.popup,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onGetCategories:(store_id,skip,take)=>{
    dispatch(getAllCategories(store_id,skip,take))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Category)


