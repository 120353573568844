import * as Actions from "../constants/Actions";
import * as Constants from '../constants/AppConstants'

const initialState = {
  user_type:Constants.USER_TYPE_STORE
};

export default function userModelReducer(state = initialState, action) {
    switch(action.type){
       
        case Actions.SET_USER_MODEL:
            return{
                user_type:action.model
            }
        default:
            return state;
    }
}