import React, { Component } from 'react';
import { Table } from "reactstrap";
import { connect } from "react-redux";
import{getStoreCustomers} from '../../../actions/storeStaffActions';
import ReactPaginate from 'react-paginate';
import userAvatar from "../../../assets/img/avatar.png";
class Customers extends Component {
    constructor(){
        super();
        this.state = { 
            isMounted: false,
            skip: 0,
            limit:12
         }
    }

    componentDidMount () {
      this.setState({ isMounted: true }, () => {
        if (this.state.isMounted) {
          this.setState({ isMounted: false });
    }
    });
    setTimeout(()=>{
      this.props.onGetCustomers(this.props.store.id,this.state.skip,this.state.limit);
     },100);
    }

    handlePageClick = (data) => {
      let selected = data.selected;
      let skip = selected * this.state.limit
      this.setState({skip: skip});
      this.props.onGetCustomers(this.props.store.id, skip,this.state.limit);
    }

    getLoder() {
      let classes = "bt-loader ";
      classes += this.props.loading ? 'bt-show' : 'bt-hide';
      return classes;
    }
    render() { 
      const {customers} = this.props;
        return ( 
            <div>
                <div className="content">
                <div className={this.getLoder()}></div>
                <div className="table-product-wrapper">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>POFILE PIC</th>
                        <th>NAME </th>
                        <th>MOBILE NO</th>
                      </tr>
                    </thead>
                    <tbody>
                    {customers.length>0 ? customers.map((customer,index) => (
                        <tr key={customer.id}>
                        <td>
                          {index+1 + this.state.skip}
                        </td>
                        <td className="nameImg">
                        <img src={customer.profile_pic !== null ? customer.profile_pic : userAvatar} alt="wild" className="img-round" />
                        </td>
                        <td>{customer.first_name+ ' ' +customer.second_name }</td>
                        <td>{customer.mobile}</td>
                      </tr>    
                    )):<tr><td colSpan={'4'}>No data found</td></tr>}
                        
                     
                    </tbody>
                  </Table>
                </div>
                <div className="pagination-wrapper">
                <ReactPaginate previousLabel={<i className="fas fa-angle-left"></i>}
                nextLabel={<i className="fas fa-angle-right"></i>}
                pageLinkClassName={"page-link"}
                breakClassName={"break-me"}
                pageCount={Math.ceil(this.props.count/this.state.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName={"active"} />

                </div>
                </div>
            </div>
        );
    }
}
Customers.defaultProps = {
  customers : []
}

const mapStateToProps = (state) => ({
  loading:state.commonReducer.loading,
  customers: state.commonReducer.store_customers,
  count:state.commonReducer.count,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onGetCustomers:(store_id,skip,take)=>{
    dispatch(getStoreCustomers(store_id,skip,take))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Customers)
