import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  notifications:[]
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.SEND_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.SEND_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        message:"Notification send successfully",
        popup: true,
        status: true
      };
    case Actions.SEND_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message:"Notification failed",
        status: false,
        error: action.error
      };

      case Actions.GET_STORE_NOTIFICATIONS_LOADING:
      return {
        loading: true,
        notifications: []
      };
    case Actions.GET_STORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.notifications,
        count:action.count
      };
    case Actions.GET_STORE_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        notifications: [],
        error: action.error
      };


    case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };


    default:
      return state;
  }
}
