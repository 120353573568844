import React, { Component } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import FeaturedImage from "../../../components/imageUpload/FeatureImage";
import { Button, Checkbox } from '../../../components';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CheckFrom from "../../../components/Common/CheckBoxForm";
import Select from "react-select";
import * as Constant from '../../../constants/AppConstants';
import {connect} from 'react-redux';
import SweetAlert from 'sweetalert2-react';
import { getAllProducts } from "../../../actions/productActions";
import {getAllCategories} from '../../../actions/categoryActions';
import {editBanner,onDismiss} from '../../../actions/BannerActions';
import SimpleReactValidator from 'simple-react-validator';
class EditSlidingBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radio_selected: '1',
      isMounted: false,
      productSelected : [],
      catSelected : [],
      isActive: false,
      clickable:1,
      title:'',
      valid_from:moment(),
      valid_upto:moment(),
      mobile: null,
      web: null,
      offer_type:'',
      week:[],
    };
    this.handleChangeFrom = this.handleChangeFrom.bind(this);
    this.handleChangeTo = this.handleChangeTo.bind(this);
    this.onDismissPopup = this.onDismissPopup.bind(this);
    this.validator = new SimpleReactValidator();
  }

  onDismissPopup(){
    this.props.onDismiss();
  }
  componentDidMount () {
    this.setState({ isMounted: true }, () => {
      if (this.state.isMounted) {
     this.setState({ isMounted: false });
    {this.props.onGetProductOffers(this.props.store.id)}
    {this.props.onGetCategoryOffers(this.props.store.id)}
  }
  });
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.status === true){
      this.setState({
         productSelected :[],
         catSelected : [],
         isActive: false,
         title:'',
         valid_from:moment(),
         valid_upto:moment(),
         mobile: null,
         web: null,
         offer_type:'',
         week:[]
      })
     }
  }

  handleCheckBox = (e)=>{
    if(e.target.checked){
      this.setState({
        clickable:1
      })
    }else{
      this.setState({
        clickable:2
      })
    }
  }

  _onWebImageChange= (web)=> {
    this.setState({web});
  }

  _onMobImageChange = (mobile) => {
    this.setState({mobile});
  }

  handleProduct = (productSelected) => {
    this.setState({productSelected});
  }
  handleCat = (catSelected) => {
    this.setState({catSelected});
  }

  handleChangeFrom(date) {
    this.setState({
      valid_from:date,
    });
  }
  handleChangeTo(date) {
    this.setState({
      valid_upto: date
    });
  }
  handleCheck = (week) => {
    this.setState({week});
  }
  handleLimit = () => {
    this.setState({ isActive: !this.state.isActive });
  }
  handleSubmit=(e)=> {
    e.preventDefault();
    var data = {
      store_id:this.props.store.id,
      title :this.state.title,
      web_image:this.state.web,
      mob_image:this.state.mobile,
      banner_type:Constant.BANNER_TYPE_SLIDE,
      offer_type:this.state.radio_selected,
      clickable: this.state.clickable
    }
    let from = this.state.valid_from;
    let upto = this.state.valid_upto;
    data['valid_from'] = from.format('YYYY-MM-DD');
    data['valid_upto'] = upto.format('YYYY-MM-DD');  
    var days_array = this.state.week.map((item)=>{
      return {'day_id':item}
    })
   data['week_days']= days_array;

   if(this.state.radio_selected == '1'){
    var obj = this.state.productSelected.map((item)=>{
      return { item_id: item.value };
    });
    data['items'] = obj;
   }
   else {
    var obj = this.state.catSelected.map((item)=>{
      return { item_id: item.value };
    });
    data['items'] = obj;
   }
   if( this.validator.allValid() ){
   this.props.onEditBanner(data);
   }
   else {
    this.validator.showMessages();
    this.forceUpdate();
  }  
}


   //loader
   getLoder() {
    let classes = "bt-loader ";
    classes += this.props.loading ? 'bt-show' : 'bt-hide';
    return classes;
  }
  render() {
   const {productSelected,catSelected, clickable} = this.state;
  let catOptions = this.props.store_categories.map(function (offer) {
    return { value: offer.id, label: offer.name};
   });
   let productOptions = this.props.all_products.map(function(product){
     return {value:product.id,label:product.title}
   })
    return (
      <div>
        <div className="content">
        <div className={this.getLoder()}></div>
          <div className="form-section">
          <form onSubmit={this.handleSubmit}>
            <div className="form-filds-wrapper">
              <Row>
                <Col md="8">
                  <div className="form-group">
                    <div className="flex-wrapper">
                      <FeaturedImage imageReset={this.state.mobile}
                        onImageChange={(mobile) => this._onMobImageChange(mobile)}
                      >
                        For Mobile(150px X 110 Px)
                      </FeaturedImage>
                      <FeaturedImage imageReset={this.state.web}
                        onImageChange={(web) => this._onWebImageChange(web)}
                      >
                        For Web(1920x X 650 Px)
                      </FeaturedImage>
                    </div>
                    <div className="errorMsg">{this.validator.message('image', this.state.mobile, 'required')}</div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="8">
                  <div className="form-group">
                    <label className="control-label">Banner Name</label>
                    <input type="text" value={this.state.title} onChange={(e)=>this.setState({title:e.target.value})}  className="form-control" />
                    <div className="errorMsg">{this.validator.message('title', this.state.title, 'required')}</div>
                  </div>
                </Col>
                <Col md="8">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Clickable Banner" checked={clickable == 1?true:false}  onChange={(e)=>{this.handleCheckBox(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                {clickable == 1 &&
                 <Col md="8">
                 <div className="form-group">
                   <div className="radio-wrapper">
                     <input type="radio"  onChange={(e) => this.setState({ radio_selected: e.target.value })}  name="product-v" value={Constant.OFFER_TYPE_PRODUCT} id="option1" defaultChecked={this.state.radio_selected === '1'}/>
                     <label htmlFor="option1"> {" "} <span> <span /></span>{" "}Product Offer</label>
                     <input type="radio" name="product-v" onChange={(e) => this.setState({ radio_selected: e.target.value })}  id="option2" value={Constant.OFFER_TYPE_CATEGORY} defaultChecked={this.state.radio_selected === '2'}/>
                     <label htmlFor="option2"><span> <span />{" "} </span> Category Offer</label>
                   </div>
                   {this.state.radio_selected === '1' ?<Select onChange={this.handleProduct} value={productSelected} options={productOptions} isMulti placeholder={"Search and choose Product Offers"}/>:
                 <Select onChange={this.handleCat} options={catOptions} value={catSelected} isMulti placeholder={"Search and choose Category Offers"}/>}
               </div>
               </Col>
                }
               
              </Row>
              <Row>
              <Col md="4">
                    <div className="form-group">
                    <label className="control-label">Valid From</label>
                    <DatePicker className="form-control" 
                    selected={this.state.valid_from}
                    onChange={this.handleChangeFrom}
                    dateFormat="YYYY-MM-DD"
                    name="valid_from"
                    />
                    </div>
                    </Col>
                    <Col md="4">
                    <div className="form-group">
                    <label className="control-label">Valid To</label>
                    <DatePicker className="form-control" 
                    selected={this.state.valid_upto}
                    onChange={this.handleChangeTo}
                    dateFormat="YYYY-MM-DD"
                    name="valid_upto"
                    />
                    </div>
                    </Col>
              </Row>
              <Row>
                      <Col md="4">
                        <FormGroup>
                          <div className="check-wrapper">
                            <Checkbox label="Limit Days" checked={this.state.isActive} onChange={(e)=>{this.handleLimit(e)}} />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                      <div className="check-box-inline prvilages">
                      { this.state.isActive ? <CheckFrom onCheckChange ={(week)=>this.handleCheck(week)}/> : null }
                      
                      </div>
                      </Col> 
                    </Row>
              <Row>
                <Col md="12">
                  <div className="submit-wrapper">
                    <Button className="btn-submit">Save</Button>
                  </div>
                </Col>
              </Row>
            </div>
            <SweetAlert
                  show={this.props.status && this.props.popup}
                  title="Success"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
                  <SweetAlert
                  show={ !this.props.status && this.props.popup}
                  title="Error"
                  type="error"
                  text={this.props.message}
                  onConfirm={this.onDismissPopup}
                  />
          </form>
          </div>
        </div>
      </div>
    );
  }
}
EditSlidingBanner.defaultProps = {
  store_categories:[],
  all_products:[]
}

const mapStateToProps = (state) => ({
  store_categories: state.categoryReducer.store_categories,
  all_products: state.productReducer.all_products,
  loading:state.bannerReducer.loading,
  status:state.bannerReducer.status,
  message:state.bannerReducer.message,
  popup:state.bannerReducer.popup,
  store:state.storeUserReducer.store
})

const mapDispatchToProps = (dispatch)=> ({
  onGetProductOffers:(store_id)=>{
    dispatch(getAllProducts(store_id))
  },
  onGetCategoryOffers:(store_id)=>{
    dispatch(getAllCategories(store_id))
  },
  onEditBanner:(data)=> {
    dispatch(editBanner(data));
  },
  onDismiss:()=>{
    dispatch(onDismiss())
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(EditSlidingBanner)
