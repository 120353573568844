import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function addDeliveryBoy(data) {
  return request({
    url:URLS.ADD_DELIVERY_BOY,
    method:'POST',
    data
  })
}

function updateDeliveryBoy(data) {
  return request({
    url:URLS.UPDATE_DELIVERY_BOY,
    method:'POST',
    data
  })
}

function getAllDeliveryBoys(offset,limit, store) {
  var sub_url = '';
  if(offset !== null && typeof offset !== 'undefined') sub_url = sub_url+'/'+offset;
  if(limit !== null && typeof limit !== 'undefined') sub_url = sub_url+'/'+limit;
  if(store !== null && typeof store !== 'undefined') sub_url = sub_url+'/'+store;
  return request({
    url:URLS.GET_DELIVERY_BOYS + sub_url,
    method:'GET'
  })
}

function getDeliveryBoy(id) {
  var sub_url = '/'+id;
  return request({
    url:URLS.GET_DELIVERY_BOY + sub_url,
    method:'GET'
  })
}

function deliveryBoyStatus(id,status) {
  var sub_url = '';
  if(id !== null && typeof id !== 'undefined') sub_url = sub_url+'/'+id;
  if(status !== null && typeof status !== 'undefined') sub_url = sub_url+'/'+status;
  return request({
    method:'GET',
    url:URLS.CHANGE_DELIVERY_BOY+sub_url   
  })

}

const DeliveryBoyService = {
  addDeliveryBoy,
  getAllDeliveryBoys,
  getDeliveryBoy,
  deliveryBoyStatus,
  updateDeliveryBoy
}

export default DeliveryBoyService;