import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function getStoreCustomers(store_id,skip,take, query) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    if(query !== null && typeof query !== 'undefined') sub_url = sub_url+'/'+query;
    return request({
      url:   URLS.ALL_STORE_CUSTOMERS + sub_url ,
      method: 'GET'
    });
}

function getStoreStaffs(store_id,skip,take){
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    if(skip !== null && typeof skip !== 'undefined') sub_url = sub_url+'/'+skip;
    if(take !== null && typeof take !== 'undefined') sub_url = sub_url+'/'+take;
    return request({
      url:   URLS.GET_STORE_STAFFS + sub_url ,
      method: 'GET'
    });
}

function getStoreStaff(user_id){
    var sub_url = '/'+user_id;
    return request({
      url:   URLS.GET_STORE_STAFF + sub_url ,
      method: 'GET'
    });
}

function editStoreStaff(data){
    return request({
      url:   URLS.STORE_STAFF_EDIT  ,
      method: 'POST',
      data
    });
}


function deleteStoreStaff(user_id){
    var sub_url = '/'+user_id;
    return request({
      url:   URLS.STORE_STAFF_DELETE + sub_url ,
      method: 'GET'
    });
}

function getStoreDashboard(store_id) {
    var sub_url = '';
    if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
    return request({
      url:   URLS.STORE_DASHBORD + sub_url ,
      method: 'GET'
    });
}

function addStoreStaff(data) {
    return request({
      url:   URLS.ADD_STORE_STAFF,
      method: 'POST',
      data:data
    });
}


function checkCustomerExist(mobile) {
  return request({
    url:   URLS.CHECK_USER_EXIST + '/' +mobile,
    method: 'GET'
  });
}

function registerCustomer(data) {
  return request({
    url:   URLS.REGISTER_CUSTOMER,
    method: 'POST',
    data
  });
}

const storeStaffService = {
    getStoreCustomers,
    getStoreDashboard,
    getStoreStaffs,
    getStoreStaff,
    editStoreStaff,
    deleteStoreStaff,
    addStoreStaff,
    checkCustomerExist,
    registerCustomer
}
export default storeStaffService;