// login
export const ADMIN_LOGIN_LOADING = "ADMIN_LOGIN_LOADING";
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";

//change admin password
export const ADMIN_CHANGE_PASS_LOADING = "ADMIN_CHANGE_PASS_LOADING";
export const ADMIN_CHANGE_PASS = "ADMIN_CHANGE_PASS";
export const ADMIN_CHANGE_PASS_FAILED = "ADMIN_CHANGE_PASS_FAILED";

export const ADMIN_PERMISSION_LOADING = "ADMIN_PERMISSION_LOADING";
export const ADMIN_PERMISSION = "ADMIN_PERMISSION";
export const ADMIN_PERMISSION_FAILED = "ADMIN_PERMISSION_FAILED";

// logout
export const ADMIN_LOGOUT_LOADING = "ADMIN_LOGOUT_LOADING";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";

//user type model
export const SET_USER_MODEL = "SET_USER_MODEL";

//user type model
export const SET_SOUND = "SET_SOUND";

//store login
export const STORE_LOGIN_LOADING = "STORE_LOGIN_LOADING";
export const STORE_LOGIN = "STORE_LOGIN";
export const STORE_LOGIN_FAILED = "STORE_LOGIN_FAILED";

//change store password
export const STORE_CHANGE_PASS_LOADING = "STORE_CHANGE_PASS_LOADING";
export const STORE_CHANGE_PASS = "STORE_CHANGE_PASS";
export const STORE_CHANGE_PASS_FAILED = "STORE_CHANGE_PASS_FAILED";


export const STORE_PERMISSION_LOADING = "STORE_PERMISSION_LOADING";
export const STORE_PERMISSION = "STORE_PERMISSION";
export const STORE_PERMISSION_FAILED = "STORE_PERMISSION_FAILED";

//store status
export const STORE_STATUS_LOADING = "STORE_STATUS_LOADING";
export const STORE_STATUS = "STORE_STATUS";
export const STORE_STATUS_FAILED = "STORE_STATUS_FAILED";

//store logout
export const STORE_LOGOUT_LOADING = "STORE_LOGOUT_LOADING";
export const STORE_LOGOUT_SUCCESS = "STORE_LOGOUT_SUCCESS";

// add tag

export const ADD_TAG_LOADING = "ADD_TAG_LOADING";
export const ADD_TAG = "ADD_TAG";
export const ADD_TAG_FAILED = "ADD_TAG_FAILED";

//GET tags
export const GET_TAGS_LOADING = "GET_TAGS_LOADING";
export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_FAILED = "GET_TAGS_FAILED";

//alert
export const ALERT_DISMISS = "ALERT_DISMISS";
export const LOGIN_RESET = "LOGIN_RESET";

// delete tag

export const DELETE_TAG_LOADING = "DELETE_TAG_LOADING";
export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";

//edit tag
export const EDIT_TAG_LOADING = "EDIT_TAG_LOADING";
export const EDIT_TAG = "EDIT_TAG";
export const EDIT_TAG_FAILED = "EDIT_TAG_FAILED";

//add category
export const ADD_CATEGORY_LOADING = "ADD_CATEGORY_LOADING";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_FAILED = "ADD_CATEGORY_FAILED";

//GET categories
export const GET_CATEGORIES_LOADING = "GET_CATEGORIES_LOADING";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";

//get leaf categories
export const GET_LEAF_CATEGORIES_LOADING = "GET_LEAF_CATEGORIES_LOADING";
export const GET_LEAF_CATEGORIES = "GET_LEAF_CATEGORIES";
export const GET_LEAF_CATEGORIES_FAILED = "GET_LEAF_CATEGORIES_FAILED";

//get all store categories
export const GET_ALL_CATEGORIES_LOADING = "GET_ALL_CATEGORIES_LOADING";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";

//delete category

export const DELETE_CATEGORY_LOADING = "DELETE_CATEGORY_LOADING";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";

//edit category
export const EDIT_CATEGORY_LOADING = "EDIT_CATEGORY_LOADING";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_FAILED = "EDIT_CATEGORY_FAILED";

//get category
export const GET_CATEGORY_LOADING = "GET_CATEGORY_LOADING";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_FAILED = "GET_CATEGORY_FAILED";

//add brands
export const ADD_BRAND_LOADING = "ADD_BRAND_LOADING";
export const ADD_BRAND = "ADD_BRAND";
export const ADD_BRAND_FAILED = "ADD_BRAND_FAILED";

//get brands
export const GET_BRANDS_LOADING = "GET_BRANDS_LOADING";
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_FAILED = "GET_BRANDS_FAILED";

//edit brand
export const EDIT_BRAND_LOADING = "EDIT_BRAND_LOADING";
export const EDIT_BRAND = "EDIT_BRAND";
export const EDIT_BRAND_FAILED = "EDIT_BRAND_FAILED";

//get brand
export const GET_BRAND_LOADING = "GET_BRAND_LOADING";
export const GET_BRAND = "GET_BRAND";
export const GET_BRAND_FAILED = "GET_BRAND_FAILED";

//get store brands
export const GET_STORE_BRANDS_LOADING = "GET_STORE_BRANDS_LOADING";
export const GET_STORE_BRANDS = "GET_STORE_BRANDS";
export const GET_STORE_BRANDS_FAILED = "GET_STORE_BRANDS_FAILED";


//add unit
export const ADD_UNIT_LOADING = "ADD_UNIT_LOADING";
export const ADD_UNIT = "ADD_UNIT";
export const ADD_UNIT_FAILED = "ADD_UNIT_FAILED";

//get units
export const GET_UNITS_LOADING = "GET_UNITS_LOADING";
export const GET_UNITS = "GET_UNITS";
export const GET_UNITS_FAILED = "GET_UNITS_FAILED";

//edit unit
export const EDIT_UNIT_LOADING = "EDIT_UNIT_LOADING";
export const EDIT_UNIT = "EDIT_UNIT";
export const EDIT_UNIT_FAILED = "EDIT_UNIT_FAILED";

//add attribute
export const ADD_ATTRIBUTE_LOADING = "ADD_ATTRIBUTE_LOADING";
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const ADD_ATTRIBUTE_FAILED = "ADD_ATTRIBUTE_FAILED";

//get attributes

export const GET_ATTRIBUTES_LOADING = "GET_ATTRIBUTES_LOADING";
export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const GET_ATTRIBUTES_FAILED = "GET_ATTRIBUTES_FAILED";

//edit attribute
export const EDIT_ATTRIBUTE_LOADING = "EDIT_ATTRIBUTE_LOADING";
export const EDIT_ATTRIBUTE = "EDIT_ATTRIBUTE";
export const EDIT_ATTRIBUTE_FAILED = "EDIT_ATTRIBUTE_FAILED";
//get attribute
export const GET_ATTRIBUTE_LOADING = "GET_ATTRIBUTE_LOADING";
export const GET_ATTRIBUTE = "GET_ATTRIBUTE";
export const GET_ATTRIBUTE_FAILED = "GET_ATTRIBUTE_FAILED";


//VARIANT
export const ADD_VARIANT_LOADING = "ADD_VARIANT_LOADING";
export const ADD_VARIANT_SUCCESS = "ADD_ATTRIBUTE_SUCCESS";
export const ADD_VARIANT_FAILED = "ADD_VARIANT_FAILED";

export const EDIT_VARIANT_LOADING = "EDIT_VARIANT_LOADING";
export const EDIT_VARIANT = "EDIT_VARIANT";
export const EDIT_VARIANT_FAILED = "EDIT_VARIANT_FAILED";

export const GET_VARIANT_LOADING = "GET_VARIANT_LOADING";
export const GET_VARIANT = "GET_VARIANT";
export const GET_VARIANT_FAILED = "GET_VARIANT_FAILED";

export const GET_VARIANTS_LOADING = "GET_VARIANTS_LOADING";
export const GET_VARIANTS = "GET_VARIANTS";
export const GET_VARIANTS_FAILED = "GET_VARIANTS_FAILED";


//add store
export const ADD_STORE_LOADING = "ADD_STORE_LOADING";
export const ADD_STORE = "ADD_STORE";
export const ADD_STORE_FAILED = "ADD_STORE_FAILED";

//get stores
export const GET_STORES_LOADING = "GET_STORES_LOADING";
export const GET_STORES = "GET_STORES";
export const GET_STORES_FAILED = "GET_STORES_FAILED";

//edit store
export const EDIT_STORE_LOADING = "EDIT_STORE_LOADING";
export const EDIT_STORE = "EDIT_STORE";
export const EDIT_STORE_FAILED = "EDIT_STORE_FAILED";

// get store 

export const GET_STORE_LOADING = "GET_STORE_LOADING";
export const GET_STORE = "GET_STORE";
export const GET_STORE_FAILED = "GET_STORE_FAILED";

//get countries
export const GET_COUNTRIES_LOADING = "GET_COUNTRIES_LOADING";
export const GET_COUNTRIES = "GET_COUNTRIES";

//get States
export const GET_STATES_LOADING = "GET_STATES_LOADING";
export const GET_STATES = "GET_STATES";

//get regions
export const  GET_REGIONS_LOADING = "GET_REGIONS_LOADING";
export const GET_REGIONS = "GET_REGIONS";

//get cities
export const  GET_CITIES_LOADING= "GET_CITIES_LOADING";
export const GET_CITIES = "GET_CITIES";

//add volume
export const ADD_VOLUME_OFFER_LOADING = "ADD_VOLUME_OFFER_LOADING";
export const ADD_VOLUME_OFFER = "ADD_VOLUME_OFFER";
export const ADD_VOLUME_OFFER_FAILED = "ADD_VOLUME_OFFER_FAILED";

//get volumes
export const GET_ALL_VOLUME_OFFER_LOADING = "GET_ALL_VOLUME_OFFER_LOADING";
export const GET_ALL_VOLUME_OFFER = "GET_ALL_VOLUME_OFFER";
export const GET_ALL_VOLUME_OFFER_FAILED = "GET_ALL_VOLUME_OFFER_FAILED";

//get volume offer
export const GET_VOLUME_OFFER_LOADING = "GET_VOLUME_OFFER_LOADING";
export const GET_VOLUME_OFFER_SUCCESS = "GET_VOLUME_OFFER_SUCCESS";
export const GET_VOLUME_OFFER_FAILED = "GET_VOLUME_OFFER_FAILED";

//edit volume offer
export const EDIT_VOLUME_OFFER_LOADING = "EDIT_VOLUME_OFFER_LOADING";
export const EDIT_VOLUME_OFFER_SUCCESS = "EDIT_VOLUME_OFFER_SUCCESS";
export const EDIT_VOLUME_OFFER_FAILED = "EDIT_VOLUME_OFFER_FAILED";

//add brand offer
export const ADD_BRAND_OFFER_LOADING = "ADD_BRAND_OFFER_LOADING";
export const ADD_BRAND_OFFER = "ADD_BRAND_OFFER";
export const ADD_BRAND_OFFER_FAILED = "ADD_BRAND_OFFER_FAILED";

//get all brand offers
export const GET_ALL_BRAND_OFFER_LOADING = "GET_ALL_BRAND_OFFER_LOADING";
export const GET_ALL_BRAND_OFFER = "GET_ALL_BRAND_OFFER";
export const GET_ALL_BRAND_OFFER_FAILED = "GET_ALL_BRAND_OFFER_FAILED";

//get brandOffer
export const GET_BRAND_OFFER_LOADING = "GET_BRAND_OFFER_LOADING";
export const GET_BRAND_OFFER_SUCCESS = "GET_BRAND_OFFER_SUCCESS";
export const GET_BRAND_OFFER_FAILED = "GET_BRAND_OFFER_FAILED";

//edit BrandOffer
export const EDIT_BRAND_OFFER_LOADING = "EDIT_BRAND_OFFER_LOADING";
export const EDIT_BRAND_OFFER_SUCCESS = "EDIT_BRAND_OFFER_SUCCESS";
export const EDIT_BRAND_OFFER_FAILED = "EDIT_BRAND_OFFER_FAILED";


//add category offer

export const ADD_CATEGORY_OFFER_LOADING = "ADD_CATEGORY_OFFER_LOADING";
export const ADD_CATEGORY_OFFER = "ADD_CATEGORY_OFFER";
export const ADD_CATEGORY_OFFER_FAILED = "ADD_CATEGORY_OFFER_FAILED";

//get all category offers
export const GET_ALL_CATEGORY_OFFER_LOADING = "GET_ALL_CATEGORY_OFFER_LOADING";
export const GET_ALL_CATEGORY_OFFER = "GET_ALL_CATEGORY_OFFER";
export const GET_ALL_CATEGORY_OFFER_FAILED = "GET_ALL_CATEGORY_OFFER_FAILED";

//get category offer
export const GET_CATEGORY_OFFER_LOADING = "GET_CATEGORY_OFFER_LOADING";
export const GET_CATEGORY_OFFER_SUCCESS = "GET_CATEGORY_OFFER_SUCCESS";
export const GET_CATEGORY_OFFER_FAILED = "GET_VOLUME_OFFER_FAILED";

//edit category offer
export const EDIT_CATEGORY_OFFER_LOADING = "EDIT_CATEGORY_OFFER_LOADING";
export const EDIT_CATEGORY_OFFER_SUCCESS = "EDIT_CATEGORY_OFFER_SUCCESS";
export const EDIT_CATEGORY_OFFER_FAILED = "EDIT_CATEGORY_OFFER_FAILED";


//add product offer

export const ADD_PRODUCT_OFFER_LOADING = "ADD_PRODUCT_OFFER_LOADING";
export const ADD_PRODUCT_OFFER = "ADD_PRODUCT_OFFER";
export const ADD_PRODUCT_OFFER_FAILED = "ADD_PRODUCT_OFFER_FAILED";

//edit product offer
export const EDIT_PRODUCT_OFFER_LOADING = "EDIT_PRODUCT_OFFER_LOADING";
export const EDIT_PRODUCT_OFFER = "EDIT_PRODUCT_OFFER";
export const EDIT_PRODUCT_OFFER_FAILED = "EDIT_PRODUCT_OFFER_FAILED";

//get product offer
export const GET_PRODUCT_OFFER_LOADING = "GET_PRODUCT_OFFER_LOADING";
export const GET_PRODUCT_OFFER = "GET_PRODUCT_OFFER";
export const GET_PRODUCT_OFFER_FAILED = "GET_PRODUCT_OFFER_FAILED";

//get all product offers
export const GET_ALL_PRODUCT_OFFER_LOADING = "GET_ALL_PRODUCT_OFFER_LOADING";
export const GET_ALL_PRODUCT_OFFER = "GET_ALL_PRODUCT_OFFER";
export const GET_ALL_PRODUCT_OFFER_FAILED = "GET_ALL_PRODUCT_OFFER_FAILED";

//EDIT OFFER BANNER

export const ADD_BANNER_OFFER_LOADING = "ADD_BANNER_OFFER_LOADING";
export const ADD_BANNER_OFFER = "ADD_BANNER_OFFER";
export const ADD_BANNER_OFFER_FAILED = "ADD_BANNER_OFFER_FAILED";

//GET all banners
export const GET_ALL_BANNERS_LOADING = "GET_ALL_BANNERS_LOADING";
export const GET_ALL_BANNERS = "GET_ALL_BANNERS";
export const GET_ALL_BANNERS_FAILED = "GET_ALL_BANNERS_FAILED";

//Add PRODUCT
export const ADD_ITEM_LOADING = "ADD_ITEM_LOADING";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";
export const ADD_ITEM_FAILED = "ADD_ITEM_FAILED";

export const ADD_NEW_PRODUCTS_LOADING = "ADD_NEW_PRODUCTS_LOADING";
export const ADD_NEW_PRODUCTS = "ADD_NEW_PRODUCTS";
export const ADD_NEW_PRODUCTS_FAILED = "ADD_NEW_PRODUCTS_FAILED";

export const GET_STORE_PRODUCTS_LOADING = "GET_STORE_PRODUCTS_LOADING";
export const GET_STORE_PRODUCTS_SUCCESS = "GET_STORE_PRODUCTS_SUCCESS";
export const GET_STORE_PRODUCTS_FAILED = "GET_STORE_PRODUCTS_FAILED";

export const GET_ALL_PRODUCTS_LOADING = "GET_ALL_PRODUCTS_LOADING";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILED = "GET_ALL_PRODUCTS_FAILED";

export const EDIT_PRODUCTS_LOADING = "EDIT_PRODUCTS_LOADING";
export const EDIT_PRODUCTS_SUCCESS = "EDIT_PRODUCTS_SUCCESS";
export const EDIT_PRODUCTS_FAILED = "EDIT_PRODUCTS_FAILED";

export const ADD_VARIANTS_LOADING = "ADD_VARIANTS_LOADING";
export const ADD_VARIANTS_SUCCESS = "ADD_VARIANTS_SUCCESS";
export const ADD_VARIANTS_FAILED = "ADD_VARIANTS_FAILED";

// Add fantastc deal
export const ADD_FANTASTIC_DEAL_LOADING = "ADD_FANTASTIC_DEAL_LOADING";
export const ADD_FANTASTIC_DEAL = "ADD_FANTASTIC_DEAL";
export const ADD_FANTASTIC_DEAL_FAILED = "ADD_FANTASTIC_DEAL_FAILED";

//GET fantstc deals

export const GET_FANTASTIC_DEALS_LOADING = "GET_FANTASTIC_DEALS_LOADING";
export const GET_FANTASTIC_DEALS = "GET_FANTASTIC_DEALS";
export const GET_FANTASTIC_DEALS_FAILED = "GET_FANTASTIC_DEALS_FAILED";

//add todays deal

export const ADD_TODAYS_DEAL_LOADING = "ADD_TODAYS_DEAL_LOADING";
export const ADD_TODAYS_DEAL = "ADD_TODAYS_DEAL";
export const ADD_TODAYS_DEAL_FAILED = "ADD_TODAYS_DEAL_FAILED";

//get todays deals
export const GET_TODAYS_DEALS_LOADING = "GET_TODAYS_DEALS_LOADING";
export const GET_TODAYS_DEALS = "GET_TODAYS_DEALS";
export const GET_TODAYS_DEALS_FAILED = "GET_TODAYS_DEALS_FAILED";

//Add packs
export const ADD_PACK_LOADING = "ADD_PACK_LOADING";
export const ADD_PACK = "ADD_PACK";
export const ADD_PACK_FAILED = "ADD_PACK_FAILED";

//get packs
export const GET_ALL_PACKS_LOADING = "GET_ALL_PACKS_LOADING";
export const GET_ALL_PACKS = "GET_ALL_PACKS";
export const GET_ALL_PACKS_FAILED = "GET_ALL_PACKS_FAILED";

//get pack
export const GET_PACK_LOADING = "GET_PACK_LOADING";
export const GET_PACK = "GET_PACK";
export const GET_PACK_FAILED = "GET_PACK_FAILED";

//edit pack
export const EDIT_PACK_LOADING = "EDIT_PACK_LOADING";
export const EDIT_PACK = "EDIT_PACK";
export const EDIT_PACK_FAILED = "EDIT_PACK_FAILED";

//product status
export const PRODUCT_STATUS_LOADING = "PRODUCT_STATUS_LOADING";
export const PRODUCT_STATUS = "PRODUCT_STATUS";
export const PRODUCT_STATUS_FAILED = "PRODUCT_STATUS_FAILED";

export const GROUP_STATUS_LOADING = "GROUP_STATUS_LOADING";
export const GROUP_STATUS = "GROUP_STATUS";
export const GROUP_STATUS_FAILED = "GROUP_STATUS_FAILED";



//delete offer

export const DELETE_CATEGORY_OFFER_LOADING = "DELETE_CATEGORY_OFFER_LOADING";
export const DELETE_CATEGORY_OFFER = "DELETE_CATEGORY_OFFER";
export const DELETE_CATEGORY_OFFER_FAILED = "DELETE_CATEGORY_OFFER_FAILED";


export const DELETE_VOLUME_OFFER_LOADING = "DELETE_VOLUME_OFFER_LOADING";
export const DELETE_VOLUME_OFFER = "DELETE_VOLUME_OFFER";
export const DELETE_VOLUME_OFFER_FAILED = "DELETE_VOLUME_OFFER_FAILED";


export const DELETE_PRODUCT_OFFER_LOADING = "DELETE_PRODUCT_OFFER_LOADING";
export const DELETE_PRODUCT_OFFER = "DELETE_PRODUCT_OFFER";
export const DELETE_PRODUCT_OFFER_FAILED = "DELETE_PRODUCT_OFFER_FAILED";


export const DELETE_BRAND_OFFER_LOADING = "DELETE_BRAND_OFFER_LOADING";
export const DELETE_BRAND_OFFER = "DELETE_BRAND_OFFER";
export const DELETE_BRAND_OFFER_FAILED = "DELETE_BRAND_OFFER_FAILED";

//delelte deals

export const DELETE_FANTASTIC_LOADING = "DELETE_FANTASTIC_LOADING";
export const DELETE_FANTASTIC = "DELETE_FANTASTIC_OFFER";
export const DELETE_FANTASTIC_FAILED = "DELETE_FANTASTIC_FAILED";

export const DELETE_TODAYS_LOADING = "DELETE_TODAYS_LOADING";
export const DELETE_TODAYS = "DELETE_TODAYS_OFFER";
export const DELETE_TODAYS_FAILED = "DELETE_TODAYS_FAILED";

export const DELETE_BANNER_LOADING = "DELETE_BANNER_LOADING";
export const DELETE_BANNER = "DELETE_BANNER";
export const DELETE_BANNER_FAILED = "DELETE_BANNER_FAILED";

//edit deals
export const EDIT_BANNER_OFFER_LOADING = "EDIT_BANNER_OFFER_LOADING";
export const EDIT_BANNER_OFFER = "EDIT_BANNER_OFFER";
export const EDIT_BANNER_OFFER_FAILED = "EDIT_BANNER_OFFER_FAILED";

export const EDIT_FANTASTIC_DEAL_LOADING = "EDIT_FANTASTIC_DEAL_LOADING";
export const EDIT_FANTASTIC_DEAL = "EDIT_FANTASTIC_DEAL";
export const EDIT_FANTASTIC_DEAL_FAILED = "EDIT_FANTASTIC_DEAL_FAILED";

export const EDIT_TODAYS_DEAL_LOADING = "EDIT_TODAYS_DEAL_LOADING";
export const EDIT_TODAYS_DEAL = "EDIT_TODAYS_DEAL";
export const EDIT_TODAYS_DEAL_FAILED = "EDIT_TODAYS_DEAL_FAILED";

export const GET_OFFER_BANNER_LOADING = "GET_OFFER_BANNER_LOADING";
export const GET_OFFER_BANNER_SUCCESS = "GET_OFFER_BANNER_SUCCESS";
export const GET_OFFER_BANNER_FAILED = "GET_OFFER_BANNER_FAILED";

//Faq
export const ADD_FAQ_LOADING = "ADD_FAQ_LOADING";
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS";
export const ADD_FAQ_FAILED = "ADD_FAQ_FAILED";

export const GET_FAQ_LOADING = "GET_FAQ_LOADING";
export const GET_FAQ_SUCCEESS = "GET_FAQ_SUCCEESS";
export const GET_FAQ_FAILED = "GET_FAQ_FAILED";

export const ADD_FAQ_DIS_LOADING = "ADD_FAQ_DIS_LOADING";
export const ADD_FAQ_DIS_SUCCESS = "ADD_FAQ_DIS_SUCCESS";
export const ADD_FAQ_DIS_FAILED = "ADD_FAQ_DIS_FAILED";

export const DELETE_FAQ_LOADING = "DELETE_FAQ_LOADING";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";

export const DELETE_FAQ_DIS_LOADING ="DELETE_FAQ_DIS_LOADING";
export const DELETE_FAQ_DIS_SUCCESS ="DELETE_FAQ_DIS_SUCCESS";
export const DELETE_FAQ_DIS_FAILED = "DELETE_FAQ_DIS_FAILED";

//ABOUT AND POINT TERMS

export const SAVE_ABOUT_LOADING = "SAVE_ABOUT_LOADING";
export const SAVE_ABOUT_SUCCESS = "SAVE_ABOUT_SUCCESS";
export const SAVE_ABOUT_FAILED = "SAVE_ABOUT_FAILED";

export const GET_ABOUT_LOADING = "GET_ABOUT_LOADING";
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_FAILED = "GET_ABOUT_FAILED";

export const SAVE_POINT_LOADING = "SAVE_POINT_LOADING";
export const SAVE_POINT_SUCCESS = "SAVE_POINT_SUCCESS";
export const SAVE_POINT_FAILED = "SAVE_POINT_FAILED";

export const GET_POINT_LOADING = "GET_POINT_LOADING";
export const GET_POINT_SUCCESS = "GET_POINT_SUCCESS";
export const GET_POINT_FAILED = "GET_POINT_FAILED";

export const SAVE_PRIVACY_LOADING = "SAVE_PRIVACY_LOADING";
export const SAVE_PRIVACY_SUCCESS = "SAVE_PRIVACY_SUCCESS";
export const SAVE_PRIVACY_FAILED = "SAVE_PRIVACY_FAILED";

export const GET_PRIVACY_LOADING = "GET_PRIVACY_LOADING";
export const GET_PRIVACY_SUCCESS = "GET_PRIVACY_SUCCESS";
export const GET_PRIVACY_FAILED = "GET_PRIVACY_FAILED";

//dASHBOARD

export const ADMIN_DASHBORD_LOADING = "ADMIN_DASHBORD_LOADING";
export const ADMIN_DASHBORD_SUCCESS = "ADMIN_DASHBORD_SUCCESS";
export const ADMIN_DASHBORD_FAILED = "ADMIN_DASHBORD_FAILED";

export const BEST_SELLER_LOADING = "BEST_SELLER_LOADING";
export const BEST_SELLER_SUCCESS = "BEST_SELLER_SUCCESS";
export const BEST_SELLER_FAILED = "BEST_SELLER_FAILED";

export const SALES_CHART_LOADING = "SALES_CHART_LOADING";
export const SALES_CHART_SUCCESS = "SALES_CHART_SUCCESS";
export const SALES_CHART_FAILED = "SALES_CHART_FAILED";

export const STORE_DASHBOARD_LOADING = "STORE_DASHBOARD_LOADING";
export const STORE_DASHBOARD_SUCCESS = "STORE_DASHBOARD_SUCCESS";
export const STORE_DASHBOARD_FAILED = "STORE_DASHBOARD_FAILED";

// ALL CUSTOMERS
export const ALL_CUSTOMERS_LOADING = "ALL_CUSTOMERS_LOADING";
export const ALL_CUSTOMERS_SUCCESS = "ALL_CUSTOMERS_SUCCESS";
export const ALL_CUSTOMERS_FAILED = "ALL_CUSTOMERS_FAILED";

//STORE STAFFS
export const ALL_STORE_CUSTOMERS_LOADING = "ALL_STORE_CUSTOMERS_LOADING";
export const ALL_STORE_CUSTOMERS_SUCCESS = "ALL_STORE_CUSTOMERS_SUCCESS";
export const ALL_STORE_CUSTOMERS_FAILED = "ALL_STORE_CUSTOMERS_FAILED";

//STAFF
export const ADD_STAFF_LOADING = "ADD_STAFF_LOADING";
export const ADD_STAFF_SUCCESS = "ADD_STAFF_SUCCESS";
export const ADD_STAFF_FAILED = "ADD_STAFF_FAILED";

export const GET_ADMIN_STAFF_LOADING = "GET_ADMIN_STAFF_LOADING";
export const GET_ADMIN_STAFF_SUCCESS = "GET_ADMIN_STAFF_SUCCESS";
export const GET_ADMIN_STAFF_FAILED = "GET_ADMIN_STAFF_FAILED";

export const GET_ADMIN_SINGLE_STAFF_LOADING = "GET_ADMIN_SINGLE_STAFF_LOADING";
export const GET_ADMIN_SINGLE_STAFF_SUCCESS = "GET_ADMIN_SINGLE_STAFF_SUCCESS";
export const GET_ADMIN_SINGLE_STAFF_FAILED = "GET_ADMIN_SINGLE_STAFF_FAILED";

export const EDIT_ADMIN_LOADING = "EDIT_ADMIN_LOADING";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILED = "EDIT_ADMIN_FAILED";

export const DELETE_ADMIN_LOADING = "DELETE_ADMIN_LOADING";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED";

//STORE STAFF

export const ADD_STORE_STAFF_LOADING = "ADD_STORE_STAFF_LOADING";
export const ADD_STORE_STAFF_SUCCESS = "ADD_STORE_STAFF_SUCCESS";
export const ADD_STORE_STAFF_FAILED = "ADD_STORE_STAFF_FAILED";

export const GET_STORE_STAFF_LOADING = "GET_STORE_STAFF_LOADING";
export const GET_STORE_STAFF_SUCCESS = "GET_STORE_STAFF_SUCCESS";
export const GET_STORE_STAFF_FAILED = "GET_STORE_STAFF_FAILED";

export const GET_STORE_SINGLE_STAFF_LOADING = "GET_STORE_SINGLE_STAFF_LOADING";
export const GET_STORE_SINGLE_STAFF_SUCCESS = "GET_STORE_SINGLE_STAFF_SUCCESS";
export const GET_STORE_SINGLE_STAFF_FAILED = "GET_STORE_SINGLE_STAFF_FAILED";

export const EDIT_STORE_STAFF_LOADING = "EDIT_STORE_STAFF_LOADING";
export const EDIT_STORE_STAFF_SUCCESS = "EDIT_STORE_STAFF_SUCCESS";
export const EDIT_STORE_STAFF_FAILED = "EDIT_STORE_STAFF_FAILED";

export const DELETE_STORE_STAFF_LOADING = "DELETE_STORE_STAFF_LOADING";
export const DELETE_STORE_STAFF_SUCCESS = "DELETE_STORE_STAFF_SUCCESS";
export const DELETE_STORE_STAFF_FAILED = "DELETE_STORE_STAFF_FAILED";

//notification

export const SEND_NOTIFICATIONS_LOADING = "SEND_NOTIFICATIONS_LOADING";
export const SEND_NOTIFICATIONS_SUCCESS = "SEND_NOTIFICATIONS_SUCCESS";
export const SEND_NOTIFICATIONS_FAILED = "SEND_NOTIFICATIONS_FAILED";

export const GET_STORE_NOTIFICATIONS_LOADING = "GET_STORE_NOTIFICATIONS_LOADING";
export const GET_STORE_NOTIFICATIONS_SUCCESS = "GET_STORE_NOTIFICATIONS_SUCCESS";
export const GET_STORE_NOTIFICATIONS_FAILED = "GET_STORE_NOTIFICATIONS_FAILED";

//Admin notification

export const SEND_ADMIN_NOTIFICATIONS_LOADING = "SEND_ADMIN_NOTIFICATIONS_LOADING";
export const SEND_ADMIN_NOTIFICATIONS_SUCCESS = "SEND_ADMIN_NOTIFICATIONS_SUCCESS";
export const SEND_ADMIN_NOTIFICATIONS_FAILED = "SEND_ADMIN_NOTIFICATIONS_FAILED";

export const GET_ADMIN_NOTIFICATIONS_LOADING = "GET_ADMIN_NOTIFICATIONS_LOADING";
export const GET_ADMIN_NOTIFICATIONS_SUCCESS = "GET_ADMIN_NOTIFICATIONS_SUCCESS";
export const GET_ADMIN_NOTIFICATIONS_FAILED = "GET_ADMIN_NOTIFICATIONS_FAILED";

//product
export const GET_ITEMS_LOADING = "GET_ITEMS_LOADING";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILED = "GET_ITEMS_FAILED";

export const GET_STORE_UNIMPORTED_LOADING = "GET_STORE_UNIMPORTED_LOADING";
export const GET_STORE_UNIMPORTED_SUCCESS = "GET_STORE_UNIMPORTED_SUCCESS";
export const GET_STORE_UNIMPORTED_FAILED = "GET_STORE_UNIMPORTED_FAILED";

export const GET_STORE_UNIMPORTED_ITEM_LOADING = "GET_STORE_UNIMPORTED_ITEM_LOADING";
export const GET_STORE_UNIMPORTED_ITEM_SUCCESS = "GET_STORE_UNIMPORTED_ITEM_SUCCESS";
export const GET_STORE_UNIMPORTED_ITEM_FAILED = "GET_STORE_UNIMPORTED_ITEM_FAILED";

export const CHANGE_ITEM_STATUS_LOADING = "CHANGE_ITEM_STATUS_LOADING";
export const CHANGE_ITEM_STATUS_SUCCESS = "CHANGE_ITEM_STATUS_SUCCESS";
export const CHANGE_ITEM_STATUS_FAILED = "CHANGE_ITEM_STATUS_FAILED";

export const CHANGE_ITEM_VARIANT_STATUS_LOADING = "CHANGE_ITEM_VARIANT_STATUS_LOADING";
export const CHANGE_ITEM_VARIANT_STATUS_SUCCESS = "CHANGE_ITEM_VARIANT_STATUS_SUCCESS";
export const CHANGE_ITEM_VARIANT_STATUS_FAILED = "CHANGE_ITEM_VARIANT_STATUS_FAILED";

export const GET_ITEM_LOADING = "GET_ITEM_LOADING";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const GET_ITEM_FAILED = "GET_ITEM_FAILED";

export const EDIT_ITEM_LOADING = "EDIT_ITEM_LOADING";
export const EDIT_ITEM_SUCCESS = "EDIT_ITEM_SUCCESS";
export const EDIT_ITEM_FAILED = "EDIT_ITEM_FAILED";

export const ADD_ITEM_VARIANTS_LOADING = "ADD_ITEM_VARIANTS_LOADING";
export const ADD_ITEM_VARIANTS_SUCCESS = "ADD_ITEM_VARIANTS_SUCCESS";
export const ADD_ITEM_VARIANTS_FAILED = "ADD_ITEM_VARIANTS_FAILED";

export const EDIT_ITEM_VARIANTS_LOADING = "EDIT_ITEM_VARIANTS_LOADING";
export const EDIT_ITEM_VARIANTS_SUCCESS = "EDIT_ITEM_VARIANTS_SUCCESS";
export const EDIT_ITEM_VARIANTS_FAILED = "EDIT_ITEM_VARIANTS_FAILED";

export const GET_STORE_ITEM_LOADING = "GET_STORE_ITEM_LOADING";
export const GET_STORE_ITEM_SUCCESS = "GET_STORE_ITEM_SUCCESS";
export const GET_STORE_ITEM_FAILED = "GET_STORE_ITEM_FAILED";

export const EDIT_STORE_ITEM_LOADING = "EDIT_STORE_ITEM_LOADING";
export const EDIT_STORE_ITEM_SUCCESS = "EDIT_STORE_ITEM_SUCCESS";
export const EDIT_STORE_ITEM_FAILED = "EDIT_STORE_ITEM_FAILED";

export const LOGOUT_RESET = "LOGOUT_RESET";
export const DATA_RESET = "DATA_RESET";

//Admin password reset
export const ADMIN_SEND_RESET_OTP_LOADING = "ADMIN_SEND_RESET_OTP_LOADING";
export const ADMIN_SEND_RESET_OTP_SUCCESS = "ADMIN_SEND_RESET_OTP_SUCCESS";
export const ADMIN_SEND_RESET_OTP_FAILED = "ADMIN_SEND_RESET_OTP_FAILED";

export const ADMIN_VERIFY_RESET_OTP_LOADING = "ADMIN_VERIFY_RESET_OTP_LOADING";
export const ADMIN_VERIFY_RESET_OTP_SUCCESS = "ADMIN_VERIFY_RESET_OTP_SUCCESS";
export const ADMIN_VERIFY_RESET_OTP_FAILED = "ADMIN_VERIFY_RESET_OTP_FAILED";

export const ADMIN_RESET_PASS_LOADING = "ADMIN_RESET_PASS_LOADING";
export const ADMIN_RESET_PASS_SUCCESS = "ADMIN_RESET_PASS_SUCCESS";
export const ADMIN_RESET_PASS_FAILED = "ADMIN_RESET_PASS_FAILED";


// Store password reset

export const STORE_SEND_RESET_OTP_LOADING = "STORE_SEND_RESET_OTP_LOADING";
export const STORE_SEND_RESET_OTP_SUCCESS = "STORE_SEND_RESET_OTP_SUCCESS";
export const STORE_SEND_RESET_OTP_FAILED = "STORE_SEND_RESET_OTP_FAILED";

export const STORE_VERIFY_RESET_OTP_LOADING = "STORE_VERIFY_RESET_OTP_LOADING";
export const STORE_VERIFY_RESET_OTP_SUCCESS = "STORE_VERIFY_RESET_OTP_SUCCESS";
export const STORE_VERIFY_RESET_OTP_FAILED = "STORE_VERIFY_RESET_OTP_FAILED";

export const STORE_RESET_PASS_LOADING = "STORE_RESET_PASS_LOADING";
export const STORE_RESET_PASS_SUCCESS = "STORE_RESET_PASS_SUCCESS";
export const STORE_RESET_PASS_FAILED = "STORE_RESET_PASS_FAILED";


export const GET_ITEM_PRODUCTS_LOADING = "GET_ITEM_PRODUCTS_LOADING";
export const GET_ITEM_PRODUCTS_SUCCESS = "GET_ITEM_PRODUCTS_SUCCESS";
export const GET_ITEM_PRODUCTS_FAILED = "GET_ITEM_PRODUCTS_FAILED";

export const UNIQUE_BARCODE_SUCCESS = "UNIQUE_BARCODE_SUCCESS";
export const UNIQUE_SKU_SUCCESS = "UNIQUE_SKU_SUCCESS";

export const UPLOAD_PRODUCT_STOCK_LOADING = "UPLOAD_PRODUCT_STOCK_LOADING";
export const UPLOAD_PRODUCT_STOCK_SUCCESS = "UPLOAD_PRODUCT_STOCK_SUCCESS";
export const UPLOAD_PRODUCT_STOCK_FAILED = "UPLOAD_PRODUCT_STOCK_FAILED";

//DELIVERY SLOTS

export const ADD_DELIVERY_SLOT_LOADING = "ADD_DELIVERY_SLOT_LOADING";
export const ADD_DELIVERY_SLOT_SUCCESS = "ADD_DELIVERY_SLOT_SUCCESS";
export const ADD_DELIVERY_SLOT_FAILED = "ADD_DELIVERY_SLOT_FAILED";

export const EDIT_DELIVERY_SLOT_LOADING = "EDIT_DELIVERY_SLOT_LOADING";
export const EDIT_DELIVERY_SLOT_SUCCESS = "EDIT_DELIVERY_SLOT_SUCCESS";
export const EDIT_DELIVERY_SLOT_FAILED = "EDIT_DELIVERY_SLOT_FAILED";

export const GET_ALL_SLOTS_LOADING = "GET_ALL_SLOTS_LOADING";
export const GET_ALL_SLOTS_SUCCESS = "GET_ALL_SLOTS_SUCCESS";
export const GET_ALL_SLOTS_FAILED = "GET_ALL_SLOTS_FAILED";

export const GET_SLOT_LOADING = "GET_SLOT_LOADING";
export const GET_SLOT_SUCCESS = "GET_SLOT_SUCCESS";
export const GET_SLOT_FAILED = "GET_SLOT_FAILED";

export const DELETE_SLOT_LOADING = "DELETE_SLOT_LOADING";
export const DELETE_SLOT_SUCCESS = "DELETE_SLOT_SUCCESS";
export const DELETE_SLOT_FAILED = "DELETE_SLOT_FAILED";


//ORDERS

export const GET_ALL_ORDERS_LOADING = "GET_ALL_ORDERS_LOADING";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_FAILED = "GET_ALL_ORDERS_FAILED";

export const GET_STORE_ORDERS_LOADING = "GET_STORE_ORDERS_LOADING";
export const GET_STORE_ORDERS_SUCCESS = "GET_STORE_ORDERS_SUCCESS";
export const GET_STORE_ORDERS_FAILED = "GET_STORE_ORDERS_FAILED";

export const GET_ORDER_LOADING = "GET_ORDER_LOADING";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "GET_ORDER_FAILED";

export const CHANGE_ORDER_STATUS_LOADING = "CHANGE_ORDER_STATUS_LOADING";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAILED = "CHANGE_ORDER_STATUS_FAILED";


export const START_SHIPPING_LOADING = "START_SHIPPING_LOADING";
export const START_SHIPPING_SUCCESS = "START_SHIPPING_SUCCESS";
export const START_SHIPPING_FAILED = "START_SHIPPING_FAILED";

// DELIVERY BOY

export const ADD_DELIVERY_BOY_LOADING = "ADD_DELIVERY_BOY_LOADING";
export const ADD_DELIVERY_BOY_SUCCESS = "ADD_DELIVERY_BOY_SUCCESS";
export const ADD_DELIVERY_BOY_FAILED = "ADD_DELIVERY_BOY_FAILED";


export const UPDATE_DELIVERY_BOY_LOADING = "UPDATE_DELIVERY_BOY_LOADING";
export const UPDATE_DELIVERY_BOY_SUCCESS = "UPDATE_DELIVERY_BOY_SUCCESS";
export const UPDATE_DELIVERY_BOY_FAILED = "UPDATE_DELIVERY_BOY_FAILED";

export const GET_ALL_DELIVERY_BOYS_LOADING = "GET_ALL_DELIVERY_BOYS_LOADING";
export const GET_ALL_DELIVERY_BOYS_SUCCESS = "GET_ALL_DELIVERY_BOYS_SUCCESS";
export const GET_ALL_DELIVERY_BOYS_FAILED = "GET_ALL_DELIVERY_BOYS_FAILED";

export const GET_DELVERY_BOY_LOADING = "GET_DELVERY_BOY_LOADING";
export const GET_DELVERY_BOY_SUCCESS = "GET_DELVERY_BOY_SUCCESS";
export const GET_DELVERY_BOY_FAILED = "GET_DELVERY_BOY_FAILED";

export const CHANGE_DELIVERY_BOY_LOADING = "CHANGE_DELIVERY_BOY_LOADING";
export const CHANGE_DELIVERY_BOY_SUCCESS = "CHANGE_DELIVERY_BOY_SUCCESS";
export const CHANGE_DELIVERY_BOY_FAILED = "CHANGE_DELIVERY_BOY_FAILED";

// REVIEW

export const GET_REVIEWS_LOADING = "GET_REVIEWS_LOADING";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILED = "GET_REVIEWS_FAILED";


export const IMPORT_TO_STORES_LOADING = "IMPORT_TO_STORES_LOADING";
export const IMPORT_TO_STORES = "IMPORT_TO_STORES";
export const IMPORT_TO_STORES_FAILED = "IMPORT_TO_STORES_FAILED";

export const GET_CUSTOMER_PRODUCTS_LOADING = "GET_CUSTOMER_PRODUCTS_LOADING";
export const GET_CUSTOMER_PRODUCTS = "GET_CUSTOMER_PRODUCTS";
export const GET_CUSTOMER_PRODUCTS_FAILED = "GET_CUSTOMER_PRODUCTS_FAILED";

export const CHECK_USER_EXIST_LOADING = "CHECK_USER_EXIST_LOADING";
export const CHECK_USER_EXIST_SUCCESS = "CHECK_USER_EXIST_SUCCESS";
export const CHECK_USER_EXIST_FAILED = "CHECK_USER_EXIST_FAILED";

export const REGISTER_CUSTOMER_LOADING = "REGISTER_CUSTOMER_LOADING";
export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS";
export const REGISTER_CUSTOMER_FAILED = "REGISTER_CUSTOMER_FAILED";

export const PLACE_ORDER_LOADING = "PLACE_ORDER_LOADING";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILED = "PLACE_ORDER_FAILED";

export const EDIT_ORDER_ITEM_LOADING = "EDIT_ORDER_ITEM_LOADING";
export const EDIT_ORDER_ITEM_SUCCESS = "EDIT_ORDER_ITEM_SUCCESS";
export const EDIT_ORDER_ITEM_FAILED = "EDIT_ORDER_ITEM_FAILED";

export const DELETE_ORDER_ITEM_LOADING = "DELETE_ORDER_ITEM_LOADING";
export const DELETE_ORDER_ITEM_SUCCESS = "DELETE_ORDER_ITEM_SUCCESS";
export const DELETE_ORDER_ITEM_FAILED = "DELETE_ORDER_ITEM_FAILED";
