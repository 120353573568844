import request from '../httpClient/request';
import * as URLS from '../constants/AppUrls';

function getAllReviews(offset,limit,store_id) {
  var sub_url = '';
  if(offset !== null && typeof offset !== 'undefined') sub_url = sub_url+'/'+offset;
  if(limit !== null && typeof limit !== 'undefined') sub_url = sub_url+'/'+limit;
  if(store_id !== null && typeof store_id !== 'undefined') sub_url = sub_url+'/'+store_id;
  return request({
      url:URLS.GET_REVIEWS+sub_url,
      mathod:'GET'
  })
}

const reviewService = {
  getAllReviews
}

export default reviewService;