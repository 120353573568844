import React, { forwardRef, useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import classnames from "classnames";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

import ItemForm from './ItemForm';

const TABS = [
  {
    label:"Info"
  }
]

function HasNoVariantForm({
    activeTab,
    onChangeTab
}, ref){

    const [image, setImage] = useState(null)
    const [ images, setImages ] = useState([])
    const [ category, setCategory ] = useState(null)
    const [ weight, setWeight ] = useState('')
    const [ weightUnit, setWeightUnit ] = useState('')
    const [ brand, setBrand ] = useState(null)
    const [ unit, setUnit ] = useState(null)
    const [ group_name, setGroupName ] = useState('')
    const [ group_description, setGroupDescription ] = useState('')
    const [ tags, setTags ] = useState([])
    const [ reflector, setReflector] = useState(false)
    const validator = new SimpleReactValidator()

    React.useImperativeHandle(ref, ()=>({
        onValidate(){
            if( validator.allValid() ){
                return {
                    category_id: category.value,
                    brand_id: brand.value,
                    unit_id: unit.value,
                    title: group_name,
                    description: group_description,
                    featured_image: image,
                    images: images,
                    weight: weight?weight.value:null ,
                    tags,
                    weight_unit: weightUnit?weightUnit.value: null
                };
            }
        },
        reset(){
            setBrand(null)
            setCategory(null)
            setUnit(null)
            setGroupName('')
            setGroupDescription('')
            setImages([])
            setImage(null)
            setTags([])
        }
    }))

    const onImageChange = (image)=> {
       setImage(image)
    }
    
    const onImagesChange = (images) => {
        setImages(images)
    }

    return (
        <React.Fragment>
          <div className="bottom-nav">
            <Nav tabs>
            {TABS.map((tab, index)=>{
                return <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === index
                    })}
                    onClick={()=>onChangeTab(index)}
                  >
                  {tab.label}</NavLink>
                </NavItem>
              })}
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
              <ItemForm
                image={image}
                images={images}
                category={category}
                brand={brand}
                unit={unit}
                tags={tags}
                validator={validator}
                onChangeTags={(tags)=>{
                  setTags(tags)
                  setReflector(!reflector)
                }}
                onImageChange={onImageChange}
                onImagesChange={onImagesChange}
                onChangeCategory={(category) => setCategory(category)}
                onChangeBrand={(brand) => setBrand(brand)} 
                onChangeUnit={(unit) => setUnit(unit)}
                group_name={group_name}
                group_description={group_description}
                weight={weight}
                weight_unit={weightUnit}
                enableWeight={true}
                onChangeText={(e)=>{
                  if(e.target.name === "group_description"){
                      setGroupDescription(e.target.value)
                  }else if(e.target.name === "weight"){
                      setWeight(e.target.value)
                  }else if(e.target.name === "weight_unit"){
                      setWeightUnit(e.target.value)
                  }else{
                      setGroupName(e.target.value)
                  }
              }}
              />
          </TabPane>
          </TabContent>
        </React.Fragment>
    )
}
export default forwardRef(HasNoVariantForm);