import * as Actions from "../constants/Actions";

const initialState = {
  loading: false,
  status: false,
  popup: false,
  to_deals:[]
};

export default function todaysReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.ADD_TODAYS_DEAL_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.ADD_TODAYS_DEAL:
      return {
        ...state,
        loading: false,
        message: "Todays deal added successfully",
        popup: true,
        status: true
      };
    case Actions.ADD_TODAYS_DEAL_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Todays deal add failed",
        status: false,
        error: action.error
      };

      case Actions.EDIT_TODAYS_DEAL_LOADING:
      return {
        ...state,
        loading: true,
        popup: false,
      };
    case Actions.EDIT_TODAYS_DEAL:
      return {
        ...state,
        loading: false,
        message: "Todays deal edited successfully",
        popup: true,
        status: true
      };
    case Actions.EDIT_TODAYS_DEAL_FAILED:
      return {
        ...state,
        loading: false,
        popup: true,
        message: "Todays deal edit failed",
        status: false,
        error: action.error
      };


      case Actions.DELETE_TODAYS_LOADING:
      return {
        ...state,
        loading: true
      };
    case Actions.DELETE_TODAYS:
      return {
        ...state,
        loading: false,
        message:"Todays deal deleted successful",
        popup: true,
        status: true
      };
    case Actions.DELETE_TODAYS_FAILED:
      return {
        ...state,
        loading: false,
        message:"Todays deal delete failed",
        popup: true,
        status: false
      };

      case Actions.ALERT_DISMISS:
      return {
        ...state,
        status: false,
        popup: false
      };
      case Actions.GET_TODAYS_DEALS_LOADING:
      return {
        loading: true,
        to_deals: []
      };
    case Actions.GET_TODAYS_DEALS:
      return {
        ...state,
        loading: false,
        to_deals: action.to_deals,
        count: action.count
      };
    case Actions.GET_TODAYS_DEALS_FAILED:
      return {
        ...state,
        loading: false,
        to_deals: [],
        error: action.error
      };

    default:
      return state;
  }
}
