import React from "react";
import {
  Card,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { CardCategory } from '../../../components';
import Stores from "../../../assets/img/icon_storedb.svg";
import Customers from "../../../assets/img/icon_customerdb.svg";
import Products from "../../../assets/img/icon_productsdb.svg";
import Sales from "../../../assets/img/Produvt-offer.png";
import Order from "../../../assets/img/order.svg";
import { connect } from 'react-redux';
import{getAdminDashboard, getBestSellerDashboard, getSalesChart} from '../../../actions/AdminActions';
import SellingTable from '../../common/dashboard/SellingTable'
import SalesChart from '../../common/dashboard/SalesChart'
class Dashboard extends React.Component {
  constructor(){
    super();
    this.state = { 
        isMounted: false
     }
}
componentDidMount () {
  this.setState({ isMounted: true }, () => {
    if (this.state.isMounted) {
      this.setState({ isMounted: false });
  {
    this.props.adminDashboard()
    this.props.adminBestSellerDashboard()
    this.props.getCharts("Week")
  }
}
});
}
  //WARNING! To be deprecated in React v17. Use componentDidMount instead.
//  componentDidMount() {
//    if(!this.props.loggedIn){
//     this.props.history.push("/login");
//    }
//  }

  onChangeChart = (period)=>{
    this.props.getCharts(period)
  }

  render() {
    const {dashboard, seller, chart} = this.props;
    return (
      <div>
        <div className="content">
          <Row>
            <Col xs={12} md={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Stores</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.stores !== 'undefined' ? dashboard.stores :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Stores}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Customers</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.customers !== 'undefined' ? dashboard.customers :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Customers} className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Products</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.products !== 'undefined' ? dashboard.products :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Products}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Completed Orders</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.orders !== 'undefined' ? dashboard.orders :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Order}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-dash">
              <Row className="align-items-center">
                <Col xs={12} md={7}>
                <div className="card-details">
                  <CardCategory>Total Sales</CardCategory>
                  <CardTitle tag="h4">{typeof dashboard.sales !== 'undefined' ? dashboard.sales :'0'}</CardTitle>
                </div>
                </Col>
                <Col xs={12} md={5}>
                <div className="card-logo">
                <img src={Sales}  className="icon-img" />
                </div>
                </Col>
              </Row>
              </Card>
            </Col>
            <Col xs={12} md={12}>
              <SalesChart
                chart={chart}
                onChange={this.onChangeChart}/>
            </Col>
            <Col xs={12} md={6}>
              <SellingTable
                title="Top Selling Products"
                items={seller && seller.top ? seller.top:[]}/>
            </Col>
            <Col xs={12} md={6}>
              <SellingTable
                title="Least Selling Products"
                items={seller && seller.less ? seller.less:[]}/>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
Dashboard.defaultProps = {
  dashboard:{}
}

const mapStateToProps = (state) => ({
  loggedIn: state.userReducer.loggedIn,
  loading:state.commonReducer.loading,
  dashboard: state.commonReducer.dashboard,
  seller: state.commonReducer.seller,
  chart: state.commonReducer.chart,
})


const mapDispatchToProps = (dispatch)=> ({
  adminDashboard:()=>{
    dispatch(getAdminDashboard())
  },
  adminBestSellerDashboard:()=>{
    dispatch(getBestSellerDashboard())
  },
  getCharts:(period)=>{
    dispatch(getSalesChart(period))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)


